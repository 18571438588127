import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hideDeleteCartDialog, resetCart } from 'pmt-modules/cart'

import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { DialogTitle, DialogContent, DialogActions } from 'pmt-ui/Dialog'
import Button from 'pmt-ui/Button'
import { withStyles } from 'pmt-ui/styles'


const styles = theme => ({
  dialogTitle: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: theme.spacing(3),
  },
  dialogActions: {
    marginTop: theme.spacing(6),
  },
  cancelButton: {
    width: '100%',
    height: 73,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
  },
  deleteButton: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
  },
})

const DeleteCartDialog = ({
  entry,
  item,
  padding,
  hideDeleteCartDialog,
  resetCart,
  classes,
  ...props
}) => (
  <React.Fragment>
    <DialogTitle disableTypography className={classes.dialogTitle}>
      <TypographyCustom type="327" skipColor>
        {tr('kiosk.order.dialog.cart_delete.title')}
      </TypographyCustom>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <TypographyCustom type="244">
        {tr('kiosk.order.dialog.cart_delete.info')}
        <br /><br />
        <strong>{tr('kiosk.order.dialog.cart_delete.question')}</strong>
      </TypographyCustom>
    </DialogContent>
    <DialogActions classes={{ root: classes.dialogActions }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="outlined"
            onClick={hideDeleteCartDialog}
            className={classes.cancelButton}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.dialog.cart_delete.button.cancel')}
            </TypographyCustom>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              resetCart()
              hideDeleteCartDialog()
            }}
            className={classes.deleteButton}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.dialog.cart_delete.button.confirm')}
            </TypographyCustom>
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </React.Fragment>
)

// Dialog root properties
DeleteCartDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

DeleteCartDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 766,
    padding: theme.spacing(6),
  },
})

const mapStateToProps = (state, props) => ({})

export default compose(
  connect(mapStateToProps, {
    hideDeleteCartDialog,
    resetCart,
  }),
  withStyles(styles)
)(DeleteCartDialog)
