import {
  ModalType,
} from 'pmt-modules/dialog'

import DialogError from 'pmt-ui/ErrorBlock/DialogError'

import CartDiffItemsDialog from '../components/Cart/CartDiffItemsDialog'
import LoseCartDialog from '../components/OrderFooter/LoseCartDialog'
import DeleteCartDialog from '../components/OrderFooter/DeleteCartDialog'
import ProductDialog from '../pages/order/product/dialog'
import SuggestionDialog from '../pages/order/catalog/components/SuggestionDialog'
import UpsellingDialog from '../pages/order/catalog/components/UpsellingDialog'
import InactivityDialog from '../components/Inactivity/Dialog'
import PrinterDisabledDialog from '../pages/order/confirm/components/PrinterDisabledDialog'
import PostOrderErrorBackHomeDialog from '../pages/order/error/components/PostOrderErrorBackHomeDialog'
import DietaryInfoDialog from '../pages/order/catalog/components/DietaryInfoDialog'

const dialogs = [
  {
    type: ModalType.ERROR,
    view: DialogError,
  },
  {
    type: ModalType.LOOSE_CART_VIEW,
    view: LoseCartDialog,
  },
  {
    type: ModalType.DELETE_CART,
    view: DeleteCartDialog,
  },
  {
    type: ModalType.PRODUCT_VIEW,
    view: ProductDialog,
  },
  {
    type: ModalType.SUGGESTION_VIEW,
    view: SuggestionDialog,
  },
  {
    type: ModalType.UPSELLING_VIEW,
    view: UpsellingDialog,
  },
  {
    type: ModalType.INACTIVITY,
    view: InactivityDialog,
  },
  {
    type: ModalType.PRINTER_DISABLED,
    view: PrinterDisabledDialog,
  },
  {
    type: ModalType.POST_ORDER_ERROR_BACK_HOME,
    view: PostOrderErrorBackHomeDialog,
  },
  {
    type: ModalType.CART_DIFF_ITEMS,
    view: CartDiffItemsDialog,
  },
  {
    type: ModalType.DIETARY_INFO,
    view: DietaryInfoDialog,
  },
]

export default dialogs
