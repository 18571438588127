import { appConfigMiddlewares } from 'pmt-modules/appConfig'
import { authRestaurantMiddlewares } from 'pmt-modules/authRestaurant'
import { cartMiddlewares } from 'pmt-modules/cart'
import { cartDiffMiddlewares } from 'pmt-modules/cartDiff'
import { kioskMiddlewares } from 'pmt-modules/kiosk'
import { kioskCheckoutMiddlewares } from 'pmt-modules/kioskCheckout'
import { kioskInteractorMiddlewares } from 'pmt-modules/kioskInteractor'
import { kioskOrderMiddlewares } from 'pmt-modules/kioskOrder'
import { orderMenuMiddlewares } from 'pmt-modules/orderMenu'
import { orderPreviewMiddlewares } from 'pmt-modules/orderPreview'
import { orderFrontMiddlewares } from 'pmt-modules/orderFront'
import { orderSettingsMiddlewares } from 'pmt-modules/orderSettings'
import { restaurantUserAccountMiddlewares } from 'pmt-modules/restaurantUserAccount'
import { restaurantThemeMiddleware } from 'pmt-modules/restaurant'
import { topUpMiddlewares } from 'pmt-modules/topUp'

export default [
  ...appConfigMiddlewares,
  ...authRestaurantMiddlewares,
  ...cartMiddlewares,
  ...cartDiffMiddlewares,
  ...kioskMiddlewares,
  ...kioskCheckoutMiddlewares,
  ...kioskInteractorMiddlewares,
  ...kioskOrderMiddlewares,
  ...orderMenuMiddlewares,
  ...orderPreviewMiddlewares,
  ...orderFrontMiddlewares,
  ...orderSettingsMiddlewares,
  ...restaurantUserAccountMiddlewares,
    restaurantThemeMiddleware,
  ...topUpMiddlewares,
]
