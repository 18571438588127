import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { FormGroup, FormControlLabel } from 'pmt-ui/Form'
import Grid from 'pmt-ui/Grid'
import Radio from 'pmt-ui/Radio'
import Checkbox from 'pmt-ui/Checkbox'
import NavigationCheck from 'pmt-ui/svg-icons/navigation/check'

import OptionValueCard from 'app/components/OptionValueCard'

const styles = theme => ({
  iconValid: {
    float: 'left',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  valueInput: {
    float: 'left',
  },
  valueName: {
    float: 'left',
    marginLeft: theme.spacing(1),
    minHeight: 28,
    maxHeight: 84,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '26px',
  },
  valueNameChecked: {
    maxWidth: 'calc(100% - 40px)',
  },
  valueNameDisabled: {
    color: theme.pmt.colors.grey91,
  },
})

const ButtonType = {
  ROUND: 'ROUND',
  SQUARE: 'SQUARE',
}

const OptionValue = ({
  restaurant,
  show,
  value,
  type,
  checked,
  disabled,
  handleOnClickOptionValue,
  hasImageValue,
  classes,
}) => {
  // for now, we can select a value only once, so we consider it as checked if the quantity is 1
  const checkedInput = value.quantity === 1

  if ((checked && !checkedInput) || (!show && !checked)) {
    return null
  }

  return (
    <Grid item xs={hasImageValue && !checked ? 4 : 6} container direction="column">
      {checked ? (
        <React.Fragment>
          <NavigationCheck className={classes.iconValid} />
          <TypographyCustom
            type="247"
            className={classNames(classes.valueName, classes.valueNameChecked, {
              [classes.valueNameDisabled]: disabled,
            })}
          >
            {value.name}
            {value.additionalPrice > 0 && (
              <React.Fragment>
                &nbsp;(
                {`+${value.additionalPriceFormatted}`})
              </React.Fragment>
            )}
          </TypographyCustom>
        </React.Fragment>
      ) : hasImageValue ? (
        <OptionValueCard
          checked={checkedInput}
          disabled={disabled}
          onClick={!disabled ? handleOnClickOptionValue : null}
          restaurant={restaurant}
          value={value}
        />
      ) : (
        <FormGroup row>
          <FormControlLabel
            control={
              type === ButtonType.ROUND ? (
                <Radio
                  checked={checkedInput}
                  disabled={disabled}
                  value=""
                  color="primary"
                  className={classes.valueInput}
                  onClick={!disabled ? handleOnClickOptionValue : null}
                />
              ) : (
                <Checkbox
                  checked={checkedInput}
                  disabled={disabled}
                  value=""
                  color="primary"
                  className={classes.valueInput}
                  onClick={!disabled ? handleOnClickOptionValue : null}
                />
              )
            }
            label={
              <TypographyCustom
                type="244"
                className={classNames(classes.valueName, {
                  [classes.valueNameDisabled]: disabled,
                })}
              >
                {value.name}
                {value.additionalPrice > 0 && (
                  <React.Fragment>
                    &nbsp;(
                    {`+${value.additionalPriceFormatted}`})
                  </React.Fragment>
                )}
              </TypographyCustom>
            }
          />
        </FormGroup>
      )}
    </Grid>
  )
}

OptionValue.defaultProps = {
  checked: false,
}

OptionValue.ButtonType = ButtonType

export default withStyles(styles)(OptionValue)
