import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import {
  getInactivityTimeoutInstance,
  resetKiosk,
  setInactivityTimeout,
  showPrinterDisabledDialog,
} from 'pmt-modules/kiosk'
import { getDataKioskPeripheralsStatus } from 'pmt-modules/kioskInteractor/selectors'
import { getDataOrderPost } from 'pmt-modules/orderPost'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-700
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b557fe3c917fa060750f656
 */
@withAppConfig
@withRestaurant
class OrderConfirmPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (isNil(props.orderData)) {
      props.resetKiosk()
    }
  }

  componentDidMount() {
    if (!this.props.kioskPeripheralsStatus || !this.props.kioskPeripheralsStatus.printer.status) {
      this.props.showPrinterDisabledDialog()
    } else {
      this.props.setInactivityTimeout(() => {
        this.props.resetKiosk()
      }, 10000)
    }
  }

  handleGoBackHome = () => {
    EventManager.dispatch(EventManager.Events.ON_CONFIRM_CALLBACK)

    this.props.setInactivityTimeout()
    this.props.resetKiosk()
  }

  render() {
    const { frontAppConfig, isFetchingRestaurant, route, orderData, restaurant } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        <View
          frontAppConfig={frontAppConfig}
          orderData={orderData}
          restaurant={restaurant}
          onGoBackHome={this.handleGoBackHome}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  inactivityTimeout: getInactivityTimeoutInstance(state),
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  orderData: getDataOrderPost(state),
})

export default connect(
  mapStateToProps,
  {
    resetKiosk,
    setInactivityTimeout,
    showPrinterDisabledDialog,
  }
)(OrderConfirmPage)
