import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { duplicateItemOnCart, removeFromCart, deleteFromCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ContentRemove from 'pmt-ui/svg-icons/content/remove'
import Trash from 'pmt-ui/svg-icons/pmt/Trash'
import { TypographyCustom } from 'pmt-ui/Typography'

import SquareIcon from '../SquareIcon'

const styles = theme => ({
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  quantity: {
    lineHeight: '44px', // align with buttons
    minWidth: theme.spacing(4),
  },
  squareContainer: {
    padding: theme.spacing(1),
    '&:first-child': {
      marginRight: theme.spacing(2),
    },
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
})

const ItemQuantity = ({
  classes,
  item,
  deleteFromCart,
  removeFromCart,
  restaurant,
  duplicateItemOnCart,
  withEdit,
}) => (
  <div className={classes.quantityContainer}>
    {withEdit && item.quantity === 1 ? (
      <SquareIcon
        classes={{ squareContainer: classes.squareContainer }}
        icon={<Trash viewBox="0 0 512 512" />}
        onClick={() => {
          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DELETE, {
            item,
            restaurant,
          })
          deleteFromCart(item)
        }}
      />
    ) : (
      withEdit && (
        <SquareIcon
          classes={{ squareContainer: classes.squareContainer }}
          icon={<ContentRemove />}
          onClick={() => {
            EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DECREASE, {
              item,
            })
            removeFromCart(item)
          }}
        />
      )
    )}
    <TypographyCustom component="span" type="247" align="center" className={classes.quantity}>
      {!withEdit && 'x'}{item.quantity}
    </TypographyCustom>
    {withEdit && (
      <SquareIcon
        classes={{ squareContainer: classes.squareContainer }}
        icon={<ContentAdd />}
        primaryColor
        onClick={() => {
          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_INCREASE, {
            item,
          })
          duplicateItemOnCart(item)
        }}
      />
    )}
  </div>
)

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    duplicateItemOnCart,
    removeFromCart,
    deleteFromCart,
  })
)(ItemQuantity)
