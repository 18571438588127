import every from 'lodash/every'

import { isLocalhost } from 'pmt-modules/environment'
import { Peripherals } from 'pmt-modules/kioskInteractor/constants'

import Logger from 'pmt-utils/logger'

import { xmlToJson, removeChildAndWhitespace } from 'pmt-utils/xml'

// retain nixdorf apis.
const nixdorf = {
  posPrinter: null,
  eft: null,
}

// set global to know if script is loaded once
let hasLoadedScriptsOnce = false

class Nixdorf {
  constructor() {
    // on localhost, you may want to change this value regarding the VM ip
    this.host = isLocalhost() ? '192.168.76.128' : 'localhost'
    this.port = '8050'
    this.client = 'client1'

    // number of maximum execution to retry connectivity state
    this.maxExecTime = 15

    this.scriptsLoaded = {}
    this.hasLoadedAllScripts = false

    // load all the required scripts.
    if (!hasLoadedScriptsOnce) {
      const domainUrl = `http://${this.host}:${this.port}`

      // wait for jquery to be loaded before loading anything else
      this.insertScript(`${domainUrl}/webadapter/DeviceTest/jquery-1.10.2.js`, () => {
        const scripts = [
          'webadapter/ClientLib/BaseDeviceConst.js',
          'webadapter/ClientLib/UposConst.js',
          'webadapter/ClientLib/DeviceServiceMain.js',
          'webadapter/ClientLib/DeviceServiceConst.js',
          'webadapter/ClientLib/EFT.js',
          'webadapter/ClientLib/EFTConst.js',
          'webadapter/ClientLib/POSPrinter.js',
          'webadapter/ClientLib/POSPrinterConst.js',
        ]
        scripts.forEach(script => {
          this.scriptsLoaded[`${domainUrl}/${script}`] = false
          this.insertScript(`${domainUrl}/${script}`, this.onScriptLoaded)
        })
      })
    }

    hasLoadedScriptsOnce = true
  }

  // https://stackoverflow.com/questions/14521108/dynamically-load-js-inside-js
  insertScript = (scriptUrl, callback = null) => {
    var script = document.createElement('script')
    script.onload = function() {
      //do stuff with the script
      console.info(`loaded ${scriptUrl}`)
      callback && callback(scriptUrl)
    }
    script.src = scriptUrl

    document.head.appendChild(script) //or something of the likes
  }

  onScriptLoaded = scriptUrl => {
    // set if script has loaded
    this.scriptsLoaded[scriptUrl] = true

    /**
     * Connects a client to a device server.
     *
     * @param clientID      		An identifier that uniquely identifies the client.
     * @param host          		Optional: Server host name. Default: localhost.
     * @param port          		Optional: Server port number. Default: 8080.
     * @param secure            TODO: ??
     * @param requestForRelease	Optional: Boolean flag, whether a request for release of the device
     * server should be send, if another client is connected to the device server.
     * @returns             		The own instance (this).
     */

    window.deviceAdmin &&
      window.deviceAdmin.connect(
        this.client, // clientID
        this.host, // server address
        this.port, // port
        false,
        false
      )
  }

  /**
   * Returns the list of peripheral available on current kiosk.
   * Retrieves the current status of all peripherals.
   */
  getPeripheralsStatus() {
    nixdorf.posPrinter = new window.POSPrinter('POSPrinter')
    nixdorf.eft = new window.EFT('POSEFT1')

    const checkPrinter = new Promise((resolve, reject) => {
      nixdorf.posPrinter
        .setEnabled('TRUE')
        .success(() => {
          nixdorf.posPrinter
            .checkHealth(1)
            .success(data => {
              resolve({
                [Peripherals.PRINTER]: {
                  status: true,
                  details: data,
                },
              })
            })
            .error(errorMessage => {
              console.log('Nixdorf', 'printer checkHealth', JSON.stringify({ errorMessage }))
              reject({ errorMessage })
            })
        })
        .error(errorMessage => {
          console.log('Nixdorf', 'printer enabled', JSON.stringify({ errorMessage }))
          reject({ errorMessage })
        })
    })

    // temporary return fixed payment status as method don't exist yet
    const checkPayment = new Promise(resolve => {
      resolve({
        [Peripherals.PAYMENT]: {
          status: true,
          details: null,
        },
      })
    })

    return new Promise((resolve, reject) => {
      let checksToRun = [checkPrinter, checkPayment]
      let status = {}

      window.deviceAdmin
        .getDeviceList()
        .success(data => {
          Promise.all(checksToRun).then(values => {
            values.forEach(checkResult => {
              status = {
                ...status,
                ...checkResult,
              }
            })

            console.log('Nixdorf', 'Peripheral status', JSON.stringify({ data, status, values }))

            resolve(status)
          })
        })
        .error(errorMessage => {
          console.log('Nixdorf', 'Failed on getPeripheralsStatus', JSON.stringify(errorMessage))
          reject({ errorMessage })
        })
    })
  }

  /**
   * To see if the JS SDK is correctly initialized
   */
  isConnected = () => {
    // check if all scripts are loaded
    this.hasLoadedAllScripts = every(this.scriptsLoaded, isLoaded => isLoaded)

    // loading js files might take some time, and connection may not be ready
    // we wait a bit and try again until it succeeds or executed too many times
    return new Promise((resolve, reject) => {
      const check = () => {
        if (window.deviceAdmin && window.deviceAdmin.getIsConnected()) {
          resolve({
            isConnected: true,
          })
        } else if (this.maxExecTime <= 0 && this.hasLoadedAllScripts) {
          reject({
            isConnected: false,
          })
        } else {
          this.maxExecTime--
          setTimeout(check, 1000)
        }
      }
      setTimeout(check, 1000)
    })
  }

  /**
   * Performs a print action based on the provided content.
   */
  print = content => {
    return new Promise((resolve, reject) => {
      nixdorf.posPrinter
        .setEnabled('TRUE')
        .success(() => {
          nixdorf.posPrinter
            .printNormal(window.POSPrinterConst.PTR_S_RECEIPT, content)
            .success(() => {
              resolve({
                printed: true,
              })
            })
            .error((errorMessage, parameters) => {
              Logger.error('Nixdorf', 'Print error', {
                errorMessage,
                parameters,
              })
              reject({
                errorMessage,
                parameters,
              })
            })
        })
        .error(errorMessage => {
          Logger.error('Nixdorf', 'Printer is not enabled', {
            errorMessage,
          })
          reject({
            errorMessage,
          })
        })
    })
  }

  /**
   * Send command to perform a payment transaction.
   */
  pay(amount, options) {
    // format amount to look like X.YY
    amount = parseFloat(amount)
    amount = amount.toFixed(2)

    let successMessage = []

    console.log('Nixdorf', 'init payment', JSON.stringify({ amount, options }))

    // we take initial device request and adapt it to create a response
    const prepareDeviceResponse = (deviceRequest, inputResult, inputResultTag) => {
      const message = deviceRequest
        .replace('<?xml version="1.0" encoding="UTF-8"?>', '')
        .replace('<DeviceRequest', '<DeviceResponse')
        .replace('</DeviceRequest', '</DeviceResponse')
      const parser = new DOMParser()
      const xmlDocument = parser.parseFromString(`<root>${message}</root>`, 'text/xml')
      xmlDocument.getElementsByTagName('DeviceResponse')[0].setAttribute('OverallResult', 'Success')

      // setting output
      const outputs = xmlDocument.getElementsByTagName('Output')
      if (outputs.length > 0) {
        outputs[0].setAttribute('OutResult', 'Success')
      }

      // removing textlines
      const textlines = xmlDocument.getElementsByTagName('TextLine')
      while (textlines.length > 0) {
        removeChildAndWhitespace(textlines[0])
      }

      // removing commands
      const commands = xmlDocument.getElementsByTagName('Command')
      while (commands.length > 0) {
        removeChildAndWhitespace(commands[0])
      }

      if (inputResult !== undefined) {
        const input = xmlDocument.getElementsByTagName('Input')[0]
        input.setAttribute('InResult', 'Success')
        const inputValue = xmlDocument.createElement('InputValue')
        input.appendChild(inputValue)
        const inString = xmlDocument.createElement(inputResultTag)
        inputValue.appendChild(inString)
        const inputResultNode = xmlDocument.createTextNode(inputResult)
        inString.appendChild(inputResultNode)
      }

      const xml = new XMLSerializer().serializeToString(xmlDocument)
      return `<?xml version="1.0" encoding="UTF-8"?>${xml
        .replace('<root>', '')
        .replace('</root>', '')}`
    }

    const sendDeviceResponse = (transactionId, sequenceId, deviceResponse, reject) => {
      nixdorf.eft
        .deviceResponse(transactionId, sequenceId, window.btoa(deviceResponse))
        .success(parameters => {
          // Nothing to do
        })
        .error((errorcode, parameters) => {
          console.log(
            'Nixdorf',
            'Paymyent failed on device response',
            JSON.stringify({ errorcode, parameters })
          )
          reject({
            errorcode,
            parameters,
          })
        })
    }

    /**
     * The integration of EFT devices is characterized by:
     *  - Following a message centric approach, a client willing to communicate with an EFT
     *  device must prepare and digest raw EFT messages.
     *  - The EFT implementation in the device server deals with communication only. It
     *  receives raw EFT messages from the client and forwards them to the EFT device
     *  and vice versa.
     *  - The EFT implementation in the device server must adhere the communication
     *  pattern which is based on:
     *    o Initial request: The request a client sends initially in order to start an EFT
     *    transaction.
     *    o Device request: An EFT device may request additional input from the user or
     *    wants to send data to other peripherals like cashier display or printer. This is
     *    request is forwarded to the client.
     *    o Device response: A client responses to device request by sending a device
     *    response carrying the information whether the interaction with the user or
     *    peripherals was successful.
     *    o Final response: The final response of the EFT indicating that the transaction
     *    is finished. This response carries the overall result. This response is
     *    forwarded to the client.
     *
     *  These characteristics are depicted in the following diagram: (see diagram_payment.png)
     *    1. The client send a requestType initialRequest to the Device Server
     *    2. The device server interact with the OPI terminal
     *    3. The device server gives an event eventType deviceRequest to the client
     *
     * The initialRequest method returns a transaction ID identifying the newly started transaction.
     * The ID is used to link requests, responses and related EFT events.
     *
     * # Connection loss between client and device service
     * The EFT transaction is hold in the device service until the EFT device aborts the transaction
     * actively or the device service EFT modules run into a timeout situation. A client will not be
     * able to start a new transaction until the ongoing one is finalized by the aforementioned
     * cases. A corresponding error is returned when calling initialRequest.
     *
     * The device service implements OPI – Open Payment Initiative – according to specification
     * V1.3.
     *
     * # EFT exceptions
     *
     * The eft exception consists of an error message and an error code.
     * See values for possible error codes below (defined on EFTConst):
     * EFT_RET_ERROR_CODE_TIMEOUT                  1     Timeout
     * EFT_RET_ERROR_CODE_FATAL                    2     Fatal
     * EFT_RET_ERROR_CODE_MISSING_FIELD            3     Missing field
     * EFT_RET_ERROR_CODE_TEMPLATE_UNAVAILABLE     4     Template unavailable
     * EFT_RET_ERROR_CODE_DEVICE_UNREACHABLE       5     Device unreachable
     * EFT_RET_ERROR_CODE_FIELD_NOT_DECOMPOSABLE   6     Transaction ID not decomposable
     * EFT_RET_ERROR_CODE_VALIDATION_FAILED        7     Validation failed
     *
     *
     * # EFT EVENT
     *
     * The EFT implementation has only one general EFT event which is used to handle two different
     * events, the device request and the final response.
     *
     * <DeviceRequest RequestType="" ApplicationSender="" WorkstationID="" TerminalID="" POPID="" RequestID="" SequenceID="">
     *      <Output OutDeviceTarget="" InputSynchronize="" Complete="" Immediate="">
     *          <TextLine Row="" Column="" CharSet="" Erase="" Echo="" Cursor="" TimeOut="" Color="" Alignment="" Height="" Width="" CharStyle1="" CharStyle2="" CharStyle3="" PaperCut="" MenuItem=""></TextLine>
     *          <Buzzer DurationBeep="" CounterBeep="" DurationPause=""></Buzzer>
     *          <OutSecureData>
     *              <Hex></Hex>
     *          </OutSecureData>
     *          <MAC>
     *              <Hex></Hex>
     *          </MAC>
     *          <ImageFile></ImageFile>
     *      </Output>
     *      <Input InDeviceTarget="">
     *          <Command Length="" MinLength="" MaxLength="" Decimals="" Separator="" CardReadElement="" TimeOut=""></Command>
     *          <InSecureData></InSecureData>
     *      </Input>
     *      <Event EventType="" TimeOut="">
     *          <EventData>
     *              <Dispenser></Dispenser>
     *              <CardIdent>
     *                  <Track1>
     *                      <Byte></Byte>
     *                      <Ascii></Ascii>
     *                  </Track1>
     *                  <Track2>
     *                      <Byte></Byte>
     *                      <Ascii></Ascii>
     *                  </Track2>
     *                  <Track3>
     *                      <Byte></Byte>
     *                      <Ascii></Ascii>
     *                  </Track3>
     *                  <ICC></ICC>
     *                  <Barcode></Barcode>
     *                  <Instring></Instring>
     *                  <CardPAN></CardPAN>
     *                  <StartDate></StartDate>
     *                  <ExpiryDate></ExpiryDate>
     *                  <CardCircuit></CardCircuit>
     *              </CardIdent>
     *              <RestrictionCodes AlternativeCode=""></RestrictionCodes>
     *          </EventData>
     *      </Event>
     *  </DeviceRequest>
     *
     * data                 A key value collection containing additional data.
     * data.transactionID   Transaction ID as returned by the initial request.
     * data.message         The base64 encoded EFT message string.
     * errorCode            Set in case the result property evaluates to false.
     * errorMessage Only    set in case the Result property evaluates to false.
     * eventType            Maybe either "deviceRequest" or “finalResponse”
     * result               Event result. If true, a valid message can be retrieved by
     *                      the message key in the data collection.
     *
     *
     * ## Event types:
     * Two different event types are supported to be determined by the EventType property:
     *
     * ### Device request
     * An EFT device may request additional input from the user or wants to send data to other
     * peripherals like cashier display or printer. A device request may arise synchronously as part
     * of an ongoing EFT transaction or asynchronously. In the second case the EFT device
     * issues a device request without an EFT transaction being started.
     *
     *
     * ### Final response
     * The final response indicates that the transaction is finished. This response carries the
     * overall result.
     *
     */
    const processEventFromClient = (event, resolve, reject) => {
      // check event result
      if (!event.result) {
        Logger.info('Nixdorf', 'Payment event has no result', { event })
        nixdorf.eft.abortRequest(event.data.transactionID, 'No result for eft event')
        reject({ event })
        return
      } else {
        const message = window.atob(event.data.message)
        const json = xmlToJson(new DOMParser().parseFromString(message, 'text/xml'))

        console.log('Nixdorf', 'payment event', JSON.stringify({ event }))

        switch (event.eventType) {
          case 'deviceRequest':
            if (json.DeviceRequest['@attributes'].RequestType.toUpperCase() !== 'INPUT') {
              const output = json.DeviceRequest.Output

              // some informations from payment that need to be printed
              // typically the bank receipt after a successfull transaction
              if (output['@attributes'].OutDeviceTarget.toUpperCase() === 'PRINTER') {
                successMessage = output.TextLine.map(line => line['#text'])
              }

              sendDeviceResponse(
                event.data.transactionID,
                event.data.sequenceID,
                prepareDeviceResponse(message),
                reject
              )
            } else {
              const input = json.DeviceRequest.Input

              switch (input.Command['#text'].toUpperCase()) {
                // a confirmation has been requested, but as we don't know if pinpad has a keyboard and
                // for ux, we force confirmation here by sending 'Y' as validation inputResult
                case 'GETCONFIRMATION':
                  sendDeviceResponse(
                    event.data.transactionID,
                    event.data.sequenceID,
                    prepareDeviceResponse(message, 'Y', 'InString'),
                    reject
                  )
                  break
                default:
                  console.log(
                    'Nixdorf',
                    'Paymyent failed on deviceRequest',
                    JSON.stringify({ event })
                  )
                  // any other requested input is not handled yet, we cannot perform transaction for the moment
                  reject({
                    event,
                  })
                  break
              }
            }
            break
          case 'finalResponse':
            switch (json.CardServiceResponse['@attributes'].OverallResult.toUpperCase()) {
              case 'SUCCESS':
                console.log(
                  'Nixdorf',
                  'payment final response',
                  JSON.stringify({
                    event,
                    amountPaid: json.CardServiceResponse.Tender.TotalAmount['#text'],
                    hasClientReceipt: false,
                    successMessage,
                  })
                )

                resolve({
                  amountPaid: json.CardServiceResponse.Tender.TotalAmount['#text'],
                  hasClientReceipt: false,
                  successMessage,
                })
                break
              default:
                // every other case is a failure
                console.log(
                  'Nixdorf',
                  'Paymyent failed on final response',
                  JSON.stringify({ event })
                )
                reject({
                  event,
                })
            }
            break
          default:
            break
        }
      }
    }

    return new Promise((resolve, reject) => {
      const request = `<?xml version="1.0" encoding="UTF-8"?>
        <CardServiceRequest RequestID="${options.id}" WorkstationID="${
        this.client
      }" RequestType="CardPayment">
          <POSData>
            <POSTimeStamp>${new Date().toISOString()}</POSTimeStamp>
          </POSData>
          <TotalAmount Currency="EUR">${amount}</TotalAmount>
        </CardServiceRequest>
      `

      const base64 = window.btoa(request)
      // Maximum time between sending the initial request and receiving the final response.
      const timeout = undefined

      nixdorf.eft
        .initialRequest(this.client, base64, timeout)
        .success(parameters => {
          // Succeed to create a transaction request. The transaction has not been processed yet.
          // We use processEventFromClient to receive the events that notify the updates of the
          // payment process flow.
          // parameters[window.EFTConst.EFT_RET_TRANSACTION_ID] contains the created transaction id.
          // there is probably nothing to do here
        })
        .error((errorcode, parameters) => {
          // failed to create a transaction request.
          // parameters[window.EFTConst.EFT_RET_ERROR_CODE]
          console.log(
            'Nixdorf',
            'Paymyent failed on init',
            JSON.stringify({ errorcode, parameters })
          )
          reject({
            errorcode,
            parameters,
            amount,
            options,
          })
        })
        .event(evData => {
          processEventFromClient(evData, resolve, reject)
        })
    })
  }
}

const NixdorfInteractor = new Nixdorf()
export default NixdorfInteractor
