//
// This module store global configuration and data for the kiosk,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { FrontFlowActions } from './actions'

export * from './actions'
export * from './constants'
export * from './selectors'

const DEFAULT = {
  flowType: null,
}

export const frontFlowReducer = (state = Immutable.fromJS(DEFAULT), action) => {
  switch (action.type) {
    case FrontFlowActions.SET_FLOW_TYPE:
      return state.merge({
        flowType: action.flowType,
      })

    default:
      return state
  }
}
