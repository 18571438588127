import React from 'react'
import isNull from 'lodash/isNull'

import { LoginFormView } from 'pmt-modules/authRestaurant/forms/login'

import { withStyles } from 'pmt-ui/styles'
import Card from 'pmt-ui/Card'
import Button from 'pmt-ui/Button'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Typography from 'pmt-ui/Typography'
import { LoadingBlock, LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import LogoGrey from 'pmt-ui/svg-icons/pmt/LogoGrey'

/**
 * @mockup N/A
 */
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  // Allow absolute positioning within the field's wrapper.
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    width: 400,
    height: 125,
  },
  card: {
    width: '80%',
    margin: '0 auto',
    padding: theme.spacing(5),
    marginTop: theme.spacing(3),
  },
  form: {
    position: 'relative',
    display: 'block',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  errorBlock: {
    marginBottom: theme.spacing(2),
  },
})

const View = ({
  isFetchingAuthRestaurant,
  formIsValid,
  authError,
  onAuthCredentialsChange,
  onSubmitLogin,
  classes,
}) => (
  <div className={classes.root}>
    <div className={classes.logoContainer}>
      <LogoGrey viewBox={'0 0 150 43.5'} className={classes.logo} />
    </div>

    <Card className={classes.card}>
      <Typography variant="h5" className={classes.title}>
        Borne PayMyTable
      </Typography>

      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSubmitLogin()
        }}
      >
        <LoadingBlockWrapper show={isFetchingAuthRestaurant}>
          <LoadingBlock show={isFetchingAuthRestaurant} />
        </LoadingBlockWrapper>

        {!isNull(authError) && (
          <ErrorBlock
            classes={{
              errorBlock: classes.errorBlock,
            }}
            error={{
              message: `Identifiants invalides`,
            }}
          />
        )}

        <LoginFormView />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="u-sizeFull u-marginTop30"
          disabled={!formIsValid}
        >
          Démarrer la borne
        </Button>
      </form>
    </Card>
  </div>
)

export default withStyles(styles)(View)
