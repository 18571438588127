import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'

import SquareMode from '../../../components/SquareMode'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  darkLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.25,
    zIndex: 10,
  },
  overContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
    textAlign: 'center',
    height: 300,
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  squareGridContainer: {
    marginTop: theme.spacing(20),
  },
  squareGrid: {
    marginTop: theme.spacing(10),
  },
  square: {
    margin: '0 auto',
  },
  icon: {
    marginTop: theme.spacing(5),
    fontSize: 160,
  },
})

const View = ({ frontAppConfig, restaurant, kioskSettings, handleOnSelectMode, classes }) => (
  <React.Fragment>
    <div
      className={classes.background}
      style={{ backgroundImage: `url('${kioskSettings.order.backgroundImage}')` }}
    >
      <div className={classes.darkLayout} />
      <div className={classes.overContainer}>
        <div className={classes.logoContainer}>
          {frontAppConfig.theme.logo.display && (
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          )}
        </div>
        <Grid container spacing={3} className={classes.squareGridContainer}>
          {kioskSettings.order.modes.map((mode, index) => (
            <Grid item xs className={classes.squareGrid} key={index}>
              <SquareMode
                label={tr(`kiosk.order.page.mode.label.${mode}`)}
                icon={<IconOrderMode type={mode} classes={{ root: classes.icon }} />}
                classes={{ root: classes.square }}
                onClick={() => handleOnSelectMode(parseInt(mode, 10))}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  </React.Fragment>
)

export default withStyles(styles)(View)
