import React from 'react'
import { connect } from 'react-redux'

import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
 class TopUpHomePage extends React.PureComponent {
  handleStart = () => {
    this.props.redirectTo(getRoute('KIOSK__TOP_UP__LOGIN'))
  }

  render() {
    const { isFetchingRestaurant, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {({ kioskSettings }) => (
          <View restaurant={restaurant} kioskSettings={kioskSettings} onStart={this.handleStart} />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(
  mapStateToProps,
  {
    redirectTo,
  }
)(TopUpHomePage)
