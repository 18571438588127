import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'

import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  bye: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    whiteSpace: 'pre-line',
  },
  scanButton: {
    width: 500,
    height: 98,
    lineHeight: 1,
    marginTop: theme.spacing(12),
    padding: `${theme.spacing(3)}px 0`,
    border: `3px solid ${theme.palette.primary.main}`,
  },
})

const ConfirmDefault = ({ classes, onGoToScan }) => (
  <Fragment>
    <TypographyCustom type="607" align="center" className={classes.bye}>
      {tr('kiosk.checkout.page.confirm.default.bye')}
    </TypographyCustom>
    <Button variant="outlined" color="primary" onClick={onGoToScan} className={classes.scanButton}>
      <TypographyCustom type="327" skipColor>
        {tr('kiosk.checkout.page.check.scan_again')}
      </TypographyCustom>
    </Button>
  </Fragment>
)

export default withStyles(styles)(ConfirmDefault)
