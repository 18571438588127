import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

// return filled part for each menu part
const recursiveGetFilledPart = partCategory => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories = partCategory.categories.map(partCategory => {
      return recursiveGetFilledPart(partCategory)
    })
  }

  if (
    !isNil(partCategory.products) &&
    partCategory.nbChosenValues < partCategory.max &&
    partCategory.min === 1
      ? partCategory.max === 1
      : false
  ) {
    partCategory.nbChosenValues = 1
    partCategory.products = partCategory.products.map(product => {
      product.quantity = 1
      return product
    })
  }

  return partCategory
}

const getFilledMenu = menu => {
  menu.parts = menu.parts.map(part => {
    part = recursiveGetFilledPart(part)

    return part
  })

  return menu
}

export default getFilledMenu
