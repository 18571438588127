import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

// https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html
import MBirdSdk from '@paymytable/acrelec-web-sdk'

import { Peripherals } from 'pmt-modules/kioskInteractor/constants'

import { existsOnArray } from 'pmt-utils/array'
import { getAmountInCents } from 'pmt-utils/currency'
import Logger from 'pmt-utils/logger'

// acrelec sends message to the sdk by using the MBirdSdk defined globally on window
// the first solution is to include the MBirdSdk into the index.html
// but we don't want to do that, so it set globally here #fuckIt
window.MBirdSdk = MBirdSdk

class Acrelec {
  /* defined as long as kioskPage INACTIVITY_TIMEOUT to avoid conflicts */
  static SCAN_DURATION = 10
  static READ_DURATION = 10

  static PaymentError = {
    PERIPHERAL_NOT_CONFIGURED: 'PERIPHERAL_NOT_CONFIGURED',
    PERIPHERAL_BUSY: 'PERIPHERAL_BUSY',
    PERIPHERAL_DEAD_LOCK: 'PERIPHERAL_DEAD_LOCK',
    INVALID_AMOUNT: 'INVALID_AMOUNT',
    USER_CANCELLED_TRANSACTION: 'USER_CANCELLED_TRANSACTION',
    UNKNOWN: 'UNKNOWN',
  }

  static AUTHORIZED_APPS = ['fr.paymytable.mag-card-reader']

  /**
   * since Acrelec scan is for a fixed duration, we keep listening to the scanner while
   * isScanning is set to true, until scanStop is called
   * If we receive a message from Acrelec scanner while isScanning is set to false, we ignore it.
   */
  isScanning = false

  /**
   * since Acrelec NFCReader is for a fixed duration, we keep listening to the NFCReader while
   * isNFCReading is set to true, until nfcStop is called
   * If we receive a message from Acrelec NFC reader while isNFCReading is set to false, we ignore it.
   */
  isNFCReading = false

  /**
   * Peripherals should always be formatted like this
   * peripheralType : {
   *   status: bool,
   *   details,
   * }
   * peripheral type are these (to be completed) : see Peripherals constants
   *  - nfc
   *  - payment
   *  - printer
   *  - scanner
   */
  static formatPeripherals(data) {
    return {
      [Peripherals.NFC]: {
        status: data.NFC.Status,
        details: JSON.stringify(data.NFC),
      },
      [Peripherals.PAYMENT]: {
        status: data.Payment.Status,
        details: JSON.stringify(data.Payment),
      },
      [Peripherals.PRINTER]: {
        status: data.Printer.Status,
        details: JSON.stringify(data.Printer),
      },
      [Peripherals.SCANNER]: {
        status: data.Scanner.Status,
        details: JSON.stringify(data.Scanner),
      },
    }
  }

  static isAuthorizedApp(identifier) {
    return existsOnArray(Acrelec.AUTHORIZED_APPS, v => v === identifier)
  }

  /**
   * Returns the list of peripheral set on current kiosk according to current kiosk profile.
   * Retrieves the current status of all peripherals as it is known by Core. This call is NOT time
   * consuming as the states are retrieved from the memory.
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#peripherals-status-details
   */
  getPeripheralsStatus() {
    return new Promise((resolve, reject) => {
      MBirdSdk.Peripherals.StatusDetails()
        .then(result => {
          const peripherals = Acrelec.formatPeripherals(result)
          resolve(peripherals)
        })
        .catch(exception => {
          reject({
            exception,
          })
        })
    })
  }

  /**
   * To see if the JS SDK is correctly initialized you can check it with the following code
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#initialization
   */
  isConnected() {
    return new Promise((resolve, reject) => {
      if (MBirdSdk && MBirdSdk.isConnected()) {
        MBirdSdk.Core.DeveloperTools()
          .then(function(result) {
            // do something with the result
          })
          .catch(function(error) {
            // handle error
          })

        resolve({
          isConnected: true,
        })
      } else {
        reject({
          isConnected: false,
        })
      }
    })
  }

  /**
   * Performs a print action based on the provided TAG content. TAG content must be in the Acrelec
   * TAG printer language format (detailed on specific document).
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#print-tag-content
   */
  print(content) {
    return new Promise((resolve, reject) => {
      MBirdSdk.Printer.TagContent(content)
        .then(result => {
          resolve({
            printed: true,
          })
        })
        .catch(exception => {
          reject({
            exception,
          })
        })
    })
  }

  /**
   * Send command to register an Apps to the local network.
   * An App can register under a Context that is optionally.
   * To have an App available on the network and found through the discover search, call discover
   * method, it has to register itself as discoverable through the register call.
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#register
   */
  register() {
    return new Promise((resolve, reject) => {
      MBirdSdk.Sharing.Register('PMT')
        .then(result => {
          resolve({
            isRegistered: true,
          })
        })
        .catch(exception => {
          reject({
            exception,
          })
        })
    })
  }

  /**
   * Performs a scan action for a maximum number of seconds.
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#scan
   */
  scan() {
    return new Promise((resolve, reject) => {
      this.isScanning = true

      /**
       * Since Acrelec scan listen for a fixed duration, we need to re-run the Acrelec scan after
       * the end of the duration if we still are in scanning mode (isScanning set to true).
       */
      const runScan = () =>
        MBirdSdk.Scanner.Scan(Acrelec.SCAN_DURATION)
          .then(result => {
            // If we receive a message from Acrelec scanner while isScanning is set to false, ignore it
            if (this.isScanning) {
              if (!isEmpty(result)) {
                resolve({
                  scannedData: result,
                })
              } else {
                // Acrelec scan duration ended (timeout), we re-run the scan
                runScan()
              }
            }
          })
          .catch(exception => {
            reject({
              exception,
            })
          })

      runScan()
    })
  }

  /**
   * Stop scanning state
   */
  scanStop() {
    this.isScanning = false
  }

  /**
   * Performs a nfc read action for a maximum number of seconds.
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#nfcreader
   */
  nfcRead() {
    return new Promise((resolve, reject) => {
      this.isNFCReading = true

      /**
       * since Acrelec NFCReader is for a fixed duration, we need to re-run the Acrelec read after
       * the end of the duration if we still are in reading mode (isNFCReading set to true).
       */
      const runNfcRead = () =>
        MBirdSdk.Nfc.Read(Acrelec.READ_DURATION)
          .then(result => {
            // If we receive a message from Acrelec scanner while isNFCReading is set to false, ignore it
            if (this.isNFCReading) {
              if (!isEmpty(result)) {
                resolve({
                  readData: result,
                })
              } else {
                // Acrelec scan duration ended (timeout), we re-run the scan
                runNfcRead()
              }
            }
          })
          .catch(exception => {
            reject({
              exception,
            })
          })

      runNfcRead()
    })
  }

  /**
   * Stop reading state
   */
  nfcStop() {
    this.isNFCReading = false
  }

  /**
   * Triggers when a new message is received from local network
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#sharingonnewmessage
   */
  onNewMessage(success) {
    MBirdSdk.Sharing.OnNewMessage(data => {
      try {
        console.table(data)
        const message = {
          source: {
            ip: data.Source.EntityIp,
            identifier: data.Source.AppIdentifier,
            context: data.Source.Context,
          },
          message: data.Message,
          receptionData: moment().format(),
          rawData: data,
        }

        /**
         * Avoid handling of messages of unauthorized apps.
         * If the source is not an authorized app, we ignore the message
         */
        if (Acrelec.isAuthorizedApp(message.source.identifier)) {
          success(message)
        }
      } catch (exception) {
        Logger.warn('Kiosk message', 'Exception on new message', {
          data,
          exception,
        })
      }
    })
  }

  /**
   * Send command to perform a payment transaction.
   * The App must have access to payment peripheral to be able to perform payment attempts.
   * During the pay call, the App may receive payment progress callbacks.
   * https://developer.acrelec.com/resources/sdk/jssdk/js_sdk_3.0.0.html#pay
   */
  pay(amount, options) {
    const amountInCents = getAmountInCents(amount)

    return new Promise((resolve, reject) => {
      MBirdSdk.Payment.Pay(amountInCents, options.id)
        .then(
          res => {
            MBirdSdk.Trace.Transaction(true, options.id)
              .then(() => {
                // do nothing
              })
              .catch(exception => {
                Logger.warn('Kiosk Trace', 'Payment success', {
                  options,
                  amountInCents,
                  exception,
                })
              })

            resolve({
              amountPaid: res.PaidAmount,
              tenderMediaId: res.TenderMediaId,
              tenderMediaDetails: res.TenderMediaDetails,
              hasClientReceipt: res.HasClientReceipt,
              hasMerchantReceipt: res.HasMerchantReceipt,
            })
          },
          res => {
            reject({
              error: res,
              message: `Une erreur est survenue`,
              amountInCents,
              id: options.id,
            })

            MBirdSdk.Trace.Transaction(false, options.id)
              .then(() => {
                // do nothing
              })
              .catch(exception => {
                Logger.warn('Kiosk Trace', 'Payment failure', {
                  options,
                  amountInCents,
                  exception,
                })
              })
          }
        )
        .catch(exception => {
          Logger.error('Kiosk Payment', 'Error', {
            options,
            amountInCents,
            exception,
          })
          reject({
            exception,
          })
        })
    })
  }
}

const AcrelecInteractor = new Acrelec()

export default AcrelecInteractor
