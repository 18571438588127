import { Services, Peripherals, KioskTypes } from 'pmt-modules/kioskInteractor/constants'

export default {
  order: {
    modes: [0],
    hasPager: false,
    pagerCaptureMode: [
      // 'NUMERIC_PAD',
      // 'SCANNER_2D',
      'CONTACTLESS',
    ],
  },
  front: {
    kiosk: KioskTypes.ACRELEC,
    services: [Services.CONNECTIVITY],
    peripherals: [Peripherals.PAYMENT, Peripherals.PRINTER, Peripherals.SCANNER],
    home: 'HOME',
    confirmDisplayType: 'DEFAULT',
    theme: {
      logo: {
        path: null,
        display: true,
      },
      background: {
        path: null,
        display: true,
      },
    },
  },
  texts: {
    ORDER__LOGIN__MARKETING__OPTIN: {
      fr: "J'accepte de recevoir des offres promotionnelles de l'enseigne",
      en: 'I accept to receive special offers from the store',
      de: 'Ich akzeptiere Angebote von uns erhalten',
    },
  },
}
