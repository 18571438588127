import { ModalType, createShowDialogAction, createHideDialogAction } from 'pmt-modules/dialog'
import { createAction } from 'pmt-modules/redux'

export const KioskActions = {
  RESET: 'KIOSK::RESET',
  SET_INACTIVITY_TIMEOUT: 'KIOSK::INACTIVITY_TIMEOUT::SET',
  SET_INACTIVITY_TIMEOUT_INSTANCE: 'KIOSK::INACTIVITY_TIMEOUT::SET_INSTANCE',
  SET_LOCALE: 'KIOSK::SET_LOCALE',
}

export const setInactivityTimeout = (callback, timer) =>
  createAction(KioskActions.SET_INACTIVITY_TIMEOUT, { callback, timer })
export const setInactivityTimeoutInstance = instance =>
  createAction(KioskActions.SET_INACTIVITY_TIMEOUT_INSTANCE, { instance })

export const showInactivityDialog = () => createShowDialogAction(ModalType.INACTIVITY)

export const hideInactivityDialog = () => createHideDialogAction(ModalType.INACTIVITY)

export const showPrinterDisabledDialog = () => createShowDialogAction(ModalType.PRINTER_DISABLED)

export const hidePrinterDisabledDialog = () => createHideDialogAction(ModalType.PRINTER_DISABLED)

export const resetKiosk = (options = {}) => createAction(KioskActions.RESET, { options })

export const setLocale = locale => createAction(KioskActions.SET_LOCALE, { locale })
