import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'

import invariant from 'invariant'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import isFunction from 'lodash/isFunction'

import { getFrontSettings } from 'pmt-modules/appConfig'
import { getFlowType, setFlowType } from 'pmt-modules/frontFlow'
import KioskSettingsContainer from 'pmt-modules/kioskSettings/components/KioskSettingsContainer'
import { __VERSION_GIT_HASH__ } from 'pmt-modules/environment'
import { isFetchingI18nPhrases } from 'pmt-modules/i18n'
import { Services } from 'pmt-modules/kioskInteractor/constants'
import {
  sharingListenMessages,
  fetchKioskConnectivity,
  fetchKioskRegistration,
  getKioskPeripheralsStatus,
} from 'pmt-modules/kioskInteractor/actions'
import { getConfigForRoute } from 'pmt-modules/routing'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { getLocale as getKioskLocale, setLocale } from 'pmt-modules/kiosk'
import {
  getDataKioskConnectivity,
  getDataKioskPeripheralsStatus,
  getDataKioskRegistration,
  getErrorKioskConnectivity,
  getErrorKioskPeripheralsStatus,
  getErrorKioskRegistration,
  isFetchingKioskConnectivity,
  isFetchingKioskPeripheralsStatus,
  isFetchingKioskRegistration,
  isListeningSharingMessage,
} from 'pmt-modules/kioskInteractor/selectors'
import { isLoggedIn } from 'pmt-modules/authRestaurant'
import { getLocale } from 'pmt-modules/i18n'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import LoadingPage from 'pmt-ui/LoadingPage'
import Tooltip from 'pmt-ui/Tooltip'
import withWidth from 'pmt-ui/utils/withWidth'
import { withStyles } from 'pmt-ui/styles'

import Logger from 'pmt-utils/logger'

import InactivityContainer from '../Inactivity'
import './style.scss'

const getRouteConfig = ({ route }) => getConfigForRoute(route)

const styles = theme => ({
  root: {
    width: '100%',
    height: '100% !important',
    zIndex: 1,
    overflow: 'hidden',
    display: 'flex',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  version: {
    position: 'absolute',
    width: 100,
    height: 100,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.tooltip,
  },
  popper: {
    left: '100px !important',
    opacity: 0.5,
  },
})

class KioskPage extends React.Component {
  constructor(props) {
    super(props)

    const {
      route,
      redirectTo,
      dataKioskConnectivity,
      isFetchingKioskConnectivity,
      fetchKioskConnectivity,
    } = props

    invariant(!isNil(route), 'route prop is mandatory on KioskPage')
    const routeConfig = cloneDeep(getRouteConfig(props))
    invariant(!isNil(routeConfig), 'routeConfig not defined for route')
    this.routeConfig = routeConfig
    this.authRequired = routeConfig.props.authRequired

    // define flowType regarding route config
    if (this.routeConfig.props.flowType !== props.flowType) {
      props.setFlowType(this.routeConfig.props.flowType)
    }

    // verify we are authorized, otherwise redirect to login
    if (this.shouldAskForAuth()) {
      redirectTo(getRoute('LOGIN'))
      return
    }

    // Connectivity is mandatory check if kiosk is up and running
    if (!this.isErrorPage() && dataKioskConnectivity === null && !isFetchingKioskConnectivity) {
      fetchKioskConnectivity()
    }

    window.addEventListener(
      'touchstart',
      e => {
        if (e.touches.length > 1) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      { passive: false }
    )
  }

  componentWillReceiveProps(nextProps) {
    // if any error on connectivity or registration is catched, go straight to error page
    if (this.hasConnectivityServiceEnabled() && nextProps.errorKioskConnectivity !== null) {
      this.redirectToErrorPage('connectivity unavailable')
    } else if (this.hasRegistrationServiceEnabled() && nextProps.errorKioskRegistration !== null) {
      this.redirectToErrorPage('registration unavailable')
    } else if (
      this.hasPeripheralStatusServiceEnabled() &&
      nextProps.errorKioskPeripheralsStatus !== null
    ) {
      this.redirectToErrorPage('peripherals unavailable')
    } else if (
      !this.isErrorPage() &&
      !this.isLoginPage() &&
      nextProps.dataKioskConnectivity !== null
    ) {
      this.checkSharingMessages(nextProps)
      this.checkRegistration(nextProps)
      this.checkPeripheralStatus(nextProps)
    }

    // kiosk has refresh, we need to set locale back
    if (getLocale() !== nextProps.locale) {
      nextProps.setLocale(nextProps.locale)
    }
  }

  hasConnectivityServiceEnabled = () =>
    this.props.frontSettings.services.indexOf(Services.CONNECTIVITY) !== -1

  hasSharingMessageServiceEnabled = () =>
    this.props.frontSettings.services.indexOf(Services.SHARING_LISTEN_MESSAGES) !== -1

  hasRegistrationServiceEnabled = () =>
    this.props.frontSettings.services.indexOf(Services.REGISTRATION) !== -1

  hasPeripheralStatusServiceEnabled = () =>
    this.props.frontSettings.services.indexOf(Services.PERIPHERALS_STATUS) !== -1

  shouldAskForAuth = () => this.authRequired && !this.props.isLoggedIn

  isLoginPage = () => this.routeConfig.definition.name === getRoute('LOGIN').name

  isErrorPage = () => this.routeConfig.definition.name === getRoute('KIOSK__ERROR').name

  redirectToErrorPage = message => {
    if (!this.isErrorPage()) {
      Logger.error('Kiosk internal error', message, this.props)
      this.props.redirectTo(getRoute('KIOSK__ERROR'))
    }
  }

  checkSharingMessages = props => {
    if (this.hasSharingMessageServiceEnabled() && !props.isListeningSharingMessage) {
      props.sharingListenMessages()
    }
  }

  checkRegistration = props => {
    if (!this.hasRegistrationServiceEnabled()) {
      return
    }

    if (!props.isFetchingKioskRegistration && props.dataKioskRegistration === null) {
      props.fetchKioskRegistration()
    }
  }

  checkPeripheralStatus = props => {
    if (!this.hasPeripheralStatusServiceEnabled()) {
      return
    }

    if (!props.isFetchingKioskPeripheralsStatus && props.dataKioskPeripheralsStatus === null) {
      props.getKioskPeripheralsStatus()
    }

    if (!props.isFetchingKioskPeripheralsStatus && props.dataKioskPeripheralsStatus !== null) {
      Object.values(props.frontSettings.peripherals).forEach(peripheral => {
        if (!props.dataKioskPeripheralsStatus[peripheral].status) {
          Logger.warning('Kiosk internal error', 'peripheral error', props)
        }
      })
    }
  }

  renderChildren(childrenProps) {
    return isFunction(this.props.children)
      ? this.props.children(childrenProps)
      : React.cloneElement(this.props.children, childrenProps)
  }

  render() {
    const {
      classes,
      dataKioskConnectivity,
      dataKioskRegistration,
      isFetchingI18nPhrases,
      isFetching,
    } = this.props

    if (this.shouldAskForAuth()) {
      return null
    }

    return (
      <div className={classes.root}>
        {/* handle inactivity on kiosk */}
        <InactivityContainer />

        <div className={classes.content}>
          {this.authRequired &&
          this.routeConfig.definition.name !== getRoute('KIOSK__ERROR').name ? (
            <KioskSettingsContainer restaurantId={this.props.restaurantId}>
              {({ isFetchingGetKioskSettings, kioskSettings }) => {
                if (isFetching || kioskSettings === null) {
                  return <LoadingPage show />
                }

                return (
                  <React.Fragment>
                    <LoadingBlockWrapper show={isFetchingI18nPhrases} />
                    {this.renderChildren({
                      dataKioskConnectivity,
                      dataKioskRegistration,
                      isFetchingGetKioskSettings,
                      kioskSettings,
                    })}
                    {find(
                      [
                        getRoute('HOME'),
                        getRoute('KIOSK__TOP_UP__HOME'),
                        getRoute('KIOSK__ORDER__HOME'),
                        getRoute('KIOSK__CHECKOUT__HOME'),
                      ],
                      route => route.name === this.routeConfig.definition.name
                    ) && (
                      <Tooltip
                        title={<div>{__VERSION_GIT_HASH__}</div>}
                        className={classes.version}
                        classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                        placement="left"
                      >
                        <div />
                      </Tooltip>
                    )}
                  </React.Fragment>
                )
              }}
            </KioskSettingsContainer>
          ) : (
            this.renderChildren()
          )}
        </div>
      </div>
    )
  }
}

KioskPage.defaultProps = {}

KioskPage.propTypes = {
  /**
   * The props.route of the page
   */
  route: PropTypes.object.isRequired,

  /**
   * The page content Component.
   * the view to display when not loading
   *
   * Can be a function that will received:
   * - isFetchingRestaurantsGroup: boolean
   * - restaurantsGroup: the selectedRGroup
   * - domain: the selected domain
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

const mapStateToProps = (state, props) => ({
  dataKioskConnectivity: getDataKioskConnectivity(state),
  dataKioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  dataKioskRegistration: getDataKioskRegistration(state),
  errorKioskConnectivity: getErrorKioskConnectivity(state),
  errorKioskPeripheralsStatus: getErrorKioskPeripheralsStatus(state),
  errorKioskRegistration: getErrorKioskRegistration(state),
  frontSettings: getFrontSettings(state),
  flowType: getFlowType(state),
  locale: getKioskLocale(state),
  isFetchingI18nPhrases: isFetchingI18nPhrases(state),
  isFetchingKioskConnectivity: isFetchingKioskConnectivity(state),
  isFetchingKioskPeripheralsStatus: isFetchingKioskPeripheralsStatus(state),
  isFetchingKioskRegistration: isFetchingKioskRegistration(state),
  isListeningSharingMessage: isListeningSharingMessage(state),
  isLoggedIn: isLoggedIn(state),
  restaurantId: getRestaurantId(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      redirectTo,
      fetchKioskConnectivity,
      fetchKioskRegistration,
      getKioskPeripheralsStatus,
      setFlowType,
      setLocale,
      sharingListenMessages,
    }
  ),
  withStyles(styles),
  withWidth()
)(KioskPage)
