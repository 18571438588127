import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'

import { getItemList, deleteCartDiffItemList, hideCartDiffDialog } from 'pmt-modules/cartDiff'

import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import Button from 'pmt-ui/Button'
import { DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import ContentClear from 'pmt-ui/svg-icons/content/clear'
import { withStyles } from 'pmt-ui/styles'

import ProductContent from './ProductContent'

const styles = theme => ({
  title: {
    clear: 'both',
    color: theme.palette.primary.main,
  },
  itemContainer: {
    width: '100%',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },
  crossContainer: {
    float: 'left',
    width: 60,
    height: 30,
    marginTop: 2,
    lineHeight: '30px',
    color: theme.pmt.colors.red800,
  },
  cross: {
    width: 30,
    height: 30,
  },
  quantityContainer: {
    float: 'left',
    width: 30,
    height: 30,
    lineHeight: '30px',
    color: theme.pmt.colors.grey600,
  },
  itemNameContainer: {
    float: 'left',
    width: 'calc(100% - 90px)',
  },
  itemName: {
    marginLeft: theme.spacing(0.5),
    lineHeight: '30px',
  },
  itemOptions: {
    color: theme.pmt.colors.grey500,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingBottom: 0,
    marginTop: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  updateButton: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginTop: theme.spacing(5),
    lineHeight: '32px',
    textTransform: 'initial',
  },
  grey500: {
    color: theme.pmt.colors.grey500,
  },
  footer: {
    padding: theme.spacing(3),
    background: theme.pmt.colors.greyBackground,
  },
})

class CartDiffItemsDialog extends React.PureComponent {
  handleRemoveItemsFromCart = () => {
    const { deleteCartDiffItemList, hideCartDiffDialog } = this.props

    deleteCartDiffItemList()
    hideCartDiffDialog()
  }

  render() {
    const { itemList, classes } = this.props

    return (
      <React.Fragment>
        <DialogTitle>
          <TypographyCustom type="327" component="p" className={classes.title}>
            {tr('kiosk.order.dialog.cart_diff.title')}
          </TypographyCustom>
        </DialogTitle>

        <DialogContent>
          <div className="u-marginTop20">
            {itemList.map((item, index) => (
              <div className={classes.itemContainer} key={index}>
                <div className={classes.crossContainer}>
                  <ContentClear className={classes.cross} />
                </div>
                <TypographyCustom type="244" className={classes.quantityContainer}>
                  x{item.quantity}
                </TypographyCustom>
                <div className={classes.itemNameContainer}>
                  <TypographyCustom type="244" className={classes.itemName}>
                    {item.name}
                  </TypographyCustom>
                  <TypographyCustom type="244" className={classes.itemOptions}>
                    {item.isProduct && <ProductContent fromMenu={false} product={item} />}

                    {item.isMenu && (
                      /* menu products */
                      <div className="u-marginLeft10">
                        {item.parts.reduce((products, part) => {
                          const partProducts = part.products.map(
                            product =>
                              product.isValid &&
                              product.quantity > 0 && (
                                <div>
                                  <span className={classes.grey500}>{product.name}</span>
                                  <ProductContent fromMenu product={product} />
                                </div>
                              )
                          )
                          return products.concat(partProducts)
                        }, [])}
                      </div>
                    )}
                  </TypographyCustom>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
        <div className={classes.footer}>
          <TypographyCustom type="244">{tr('kiosk.order.dialog.cart_diff.info')}</TypographyCustom>
          <Grid container>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                className={classNames('u-floatRight u-marginLeft20', classes.updateButton)}
                onClick={this.handleRemoveItemsFromCart}
              >
                <TypographyCustom type="287" skipColor>
                  {tr('kiosk.order.dialog.cart_diff.update')}
                </TypographyCustom>
              </Button>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

CartDiffItemsDialog.DialogRootProps = {
  disableBackdropClick: true,
}

CartDiffItemsDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 766,
    padding: theme.spacing(3),
  },
})

const mapStateToProps = (state, ownProps) => ({
  itemList: getItemList(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      hideCartDiffDialog,
      deleteCartDiffItemList,
    }
  ),
  withStyles(styles)
)(CartDiffItemsDialog)
