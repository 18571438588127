import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { getOrderProperties, resetOrderFrontFull, setPaymentMethod } from 'pmt-modules/orderFront'
import { resetKiosk } from 'pmt-modules/kiosk'
import { nfcStop, scanStop } from 'pmt-modules/kioskInteractor/actions'
import {
  getErrorKioskPayment,
  getDataKioskPeripheralsStatus,
} from 'pmt-modules/kioskInteractor/selectors'
import { getOrderData } from 'pmt-modules/kioskOrder'
import { getErrorOrderPost } from 'pmt-modules/orderPost'
import { makeGetCurrentOrderSettings } from 'pmt-modules/orderSettings'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-699
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b557fe3c42e98852fd06b3c
 */
@withAppConfig
@withRestaurant
class OrderPaymentModePage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (props.orderAppConfig.hasPager) {
      props.nfcStop()
      props.scanStop()
    }

    // orderData is empty
    // it means entities have expired, kiosk has refreshed, we can't proceed go back to home
    if (isEmpty(props.orderData) && isNil(props.orderProperties.restaurantId)) {
      props.resetOrderFrontFull()
      props.resetKiosk()
      props.redirectTo(getRoute('KIOSK__ORDER__HOME'))
    }
  }

  handleSelectMethod = (paymentMethod) => {
    EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
      paymentMode: paymentMethod,
    })
    this.props.setPaymentMethod(paymentMethod)
  }

  handleGoToCart = () => {
    this.props.redirectTo(getRoute('KIOSK__ORDER__CART'))
  }

  render() {
    const {
      frontAppConfig,
      route,
      currentOrderSettings,
      isFetchingRestaurant,
      kioskPeripheralsStatus,
      orderProperties,
      orderPostError,
      paymentError,
      restaurant,
    } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            currentOrderSettings={currentOrderSettings}
            kioskSettings={kioskSettings}
            kioskPeripheralsStatus={kioskPeripheralsStatus}
            orderPostError={orderPostError}
            totalCartPriceAndFees={orderProperties.totalCartPriceAndFees}
            restaurant={restaurant}
            onSelectMethod={this.handleSelectMethod}
            onGoToCart={this.handleGoToCart}
            paymentError={paymentError}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state) => {
  const orderProperties = getOrderProperties(state)
  const getCurrentOrderSettings = makeGetCurrentOrderSettings()

  return {
    orderData: getOrderData(state),
    orderProperties,
    currentOrderSettings: getCurrentOrderSettings(state, { orderMode: orderProperties.mode }),
    kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
    orderPostError: getErrorOrderPost(state),
    paymentError: getErrorKioskPayment(state),
  }
}

const mapDispatchToProps = {
  resetOrderFrontFull,
  resetKiosk,
  setPaymentMethod,
  redirectTo,
  nfcStop,
  scanStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPaymentModePage)
