import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let QrCode = props => (
  <SvgIcon {...props}>
    <g id="qr-code" transform="translate(5.000000, 0.000000)">
      <path d="M12.1749256,0 L1.82507444,0 C0.818717226,0 0,0.832267251 0,1.8551755 L0,22.1448245 C0,23.1677796 0.81876334,24 1.82507444,24 L12.1749256,24 C13.1812828,24 14,23.1677327 14,22.1448245 L14,1.8551755 C14,0.832267251 13.1812828,0 12.1749256,0 Z M13.3069448,22.1448714 C13.3069448,22.7793257 12.7991317,23.2955611 12.1749256,23.2955611 L1.82507444,23.2955611 C1.20086827,23.2955611 0.693055153,22.7793257 0.693055153,22.1448714 L0.693055153,20.9941816 L13.3069448,20.9941816 L13.3069448,22.1448714 Z M13.3069448,2.25441065 L11.4818704,2.25441065 C11.290449,2.25441065 11.1353198,2.41214534 11.1353198,2.60667697 C11.1353198,2.8012086 11.290449,2.95894328 11.4818704,2.95894328 L13.3069448,2.95894328 L13.3069448,20.2896959 L0.693055153,20.2896959 L0.693055153,2.9588964 L9.79541622,2.9588964 C9.9868376,2.9588964 10.1419669,2.80116172 10.1419669,2.60663009 C10.1419669,2.41209846 9.9868376,2.25436378 9.79541622,2.25436378 L0.693055153,2.25436378 L0.693055153,1.85512862 C0.693055153,1.22067426 1.20086827,0.704438876 1.82507444,0.704438876 L12.1749256,0.704438876 C12.7991317,0.704438876 13.3069448,1.22067426 13.3069448,1.85512862 L13.3069448,2.25441065 Z" id="Shape"/>
      <path d="M7.6273865,21.8666667 L6.1976135,21.8666667 C6.01250956,21.8666667 5.8625,22.0059732 5.8625,22.1777778 C5.8625,22.3495823 6.01250956,22.4888889 6.1976135,22.4888889 L7.6273865,22.4888889 C7.81249044,22.4888889 7.9625,22.3495823 7.9625,22.1777778 C7.9625,22.0059732 7.81249044,21.8666667 7.6273865,21.8666667 Z" id="Shape"/>
      <path d="M7.06835938,1.06666667 L7.01914062,1.06666667 C6.81524139,1.06666667 6.65,1.20597324 6.65,1.37777778 C6.65,1.54958232 6.81524139,1.68888889 7.01914062,1.68888889 L7.06835938,1.68888889 C7.27225861,1.68888889 7.4375,1.54958232 7.4375,1.37777778 C7.4375,1.20597324 7.27225861,1.06666667 7.06835938,1.06666667 Z" id="Shape"/>
      <path d="M8.55585938,1.06666667 L8.50664062,1.06666667 C8.30274139,1.06666667 8.1375,1.20597324 8.1375,1.37777778 C8.1375,1.54958232 8.30274139,1.68888889 8.50664062,1.68888889 L8.55585938,1.68888889 C8.75975861,1.68888889 8.925,1.54958232 8.925,1.37777778 C8.925,1.20597324 8.75975861,1.06666667 8.55585938,1.06666667 Z" id="Shape"/>
      <path d="M5.58085937,1.06666667 L5.53164062,1.06666667 C5.32774139,1.06666667 5.1625,1.20597324 5.1625,1.37777778 C5.1625,1.54958232 5.32774139,1.68888889 5.53164062,1.68888889 L5.58085937,1.68888889 C5.78475861,1.68888889 5.95,1.54958232 5.95,1.37777778 C5.95,1.20597324 5.78475861,1.06666667 5.58085937,1.06666667 Z" id="Shape"/>
      <path d="M6.21843327,6.75555556 L2.18156673,6.75555556 C1.99151736,6.75555556 1.8375,6.91496246 1.8375,7.11155641 L1.8375,11.2884436 C1.8375,11.4850375 1.99151736,11.6444444 2.18156673,11.6444444 L6.21843327,11.6444444 C6.40848264,11.6444444 6.5625,11.4850375 6.5625,11.2884436 L6.5625,7.11155641 C6.56245422,6.91491509 6.40848264,6.75555556 6.21843327,6.75555556 Z M5.87436653,10.9324427 L2.52558768,10.9324427 L2.52558768,7.46750989 L5.87436653,7.46750989 L5.87436653,10.9324427 Z" id="Shape"/>
      <path d="M5.16646471,7.91111111 L3.32103529,7.91111111 C3.12989856,7.91111111 2.975,8.06851516 2.975,8.26263902 L2.975,10.137361 C2.975,10.3314848 3.12989856,10.4888889 3.32103529,10.4888889 L5.16646471,10.4888889 C5.35760144,10.4888889 5.5125,10.3314848 5.5125,10.137361 L5.5125,8.26263902 C5.51245395,8.06846838 5.35760144,7.91111111 5.16646471,7.91111111 Z M4.82042943,9.78583307 L3.66702452,9.78583307 L3.66702452,8.61412015 L4.82042943,8.61412015 L4.82042943,9.78583307 Z" id="Shape"/>
      <path d="M6.21843327,12.3555556 L2.18156673,12.3555556 C1.99151736,12.3555556 1.8375,12.5149625 1.8375,12.7115564 L1.8375,16.8884436 C1.8375,17.0850375 1.99151736,17.2444444 2.18156673,17.2444444 L6.21843327,17.2444444 C6.40848264,17.2444444 6.5625,17.0850375 6.5625,16.8884436 L6.5625,12.7115564 C6.56245422,12.5149151 6.40848264,12.3555556 6.21843327,12.3555556 Z M5.87436653,16.5324427 L2.52558768,16.5324427 L2.52558768,13.0675099 L5.87436653,13.0675099 L5.87436653,16.5324427 Z" id="Shape"/>
      <path d="M5.16646471,13.6 L3.32103529,13.6 C3.12989856,13.6 2.975,13.757404 2.975,13.9515279 L2.975,15.8262499 C2.975,16.0203737 3.12989856,16.1777778 3.32103529,16.1777778 L5.16646471,16.1777778 C5.35760144,16.1777778 5.5125,16.0203737 5.5125,15.8262499 L5.5125,13.9515279 C5.51245395,13.7573573 5.35760144,13.6 5.16646471,13.6 Z M4.82042943,15.474722 L3.66702452,15.474722 L3.66702452,14.303009 L4.82042943,14.303009 L4.82042943,15.474722 Z" id="Shape"/>
      <path d="M11.7309333,12.3555556 L7.69406673,12.3555556 C7.50401736,12.3555556 7.35,12.5149625 7.35,12.7115564 L7.35,16.8884436 C7.35,17.0850375 7.50401736,17.2444444 7.69406673,17.2444444 L11.7309333,17.2444444 C11.9209826,17.2444444 12.075,17.0850375 12.075,16.8884436 L12.075,12.7115564 C12.0749542,12.5149151 11.9209826,12.3555556 11.7309333,12.3555556 Z M11.3868665,16.5324427 L8.03808768,16.5324427 L8.03808768,13.0675099 L11.3868665,13.0675099 L11.3868665,16.5324427 Z" id="Shape"/>
      <path d="M10.7664647,13.6 L8.92103529,13.6 C8.72989856,13.6 8.575,13.757404 8.575,13.9515279 L8.575,15.8262499 C8.575,16.0203737 8.72989856,16.1777778 8.92103529,16.1777778 L10.7664647,16.1777778 C10.9576014,16.1777778 11.1125,16.0203737 11.1125,15.8262499 L11.1125,13.9515279 C11.112454,13.7573573 10.9576014,13.6 10.7664647,13.6 Z M10.4204294,15.474722 L9.26702452,15.474722 L9.26702452,14.303009 L10.4204294,14.303009 L10.4204294,15.474722 Z" id="Shape"/>
      <path d="M11.7310248,9.41357476 L11.3640203,9.41357476 L10.2401148,9.41357476 L10.2401148,8.60669468 L10.9970158,8.60669468 C11.1870652,8.60669468 11.3410825,8.44728932 11.3410825,8.25069728 L11.3410825,7.11155296 C11.3410825,6.91496091 11.1870652,6.75555556 10.9970158,6.75555556 C10.8069664,6.75555556 10.6529491,6.91496091 10.6529491,7.11155296 L10.6529491,7.89469987 L9.89604804,7.89469987 C9.70599867,7.89469987 9.55198131,8.05410523 9.55198131,8.25069728 L9.55198131,9.41357476 L9.13910123,9.41357476 L9.13910123,8.25069728 C9.13910123,8.05410523 8.98508386,7.89469987 8.7950345,7.89469987 L7.69406673,7.89469987 C7.50401736,7.89469987 7.35,8.05410523 7.35,8.25069728 C7.35,8.44728932 7.50401736,8.60669468 7.69406673,8.60669468 L8.45096776,8.60669468 L8.45096776,9.76957216 C8.45096776,9.9661642 8.60498513,10.1255696 8.7950345,10.1255696 L11.0199078,10.1255696 L11.0199078,10.9324496 L9.50605996,10.9324496 L9.50605996,10.9087165 C9.50605996,10.7121244 9.3520426,10.5527191 9.16199323,10.5527191 C8.97194386,10.5527191 8.81792649,10.7121244 8.81792649,10.9087165 L8.81792649,11.288447 C8.81792649,11.4850391 8.97194386,11.6444444 9.16199323,11.6444444 L11.3639288,11.6444444 C11.5539781,11.6444444 11.7079955,11.4850391 11.7079955,11.288447 L11.7079955,10.1255696 L11.7309333,10.1255696 C11.9209826,10.1255696 12.075,9.9661642 12.075,9.76957216 C12.075,9.57298011 11.9210742,9.41357476 11.7310248,9.41357476 Z" id="Shape"/>
      <path d="M8.0576087,10.8444444 L7.6923913,10.8444444 C7.50326738,10.8444444 7.35,10.983751 7.35,11.1555556 C7.35,11.3273601 7.50326738,11.4666667 7.6923913,11.4666667 L8.0576087,11.4666667 C8.24673262,11.4666667 8.4,11.3273601 8.4,11.1555556 C8.4,10.983751 8.24673262,10.8444444 8.0576087,10.8444444 Z" id="Shape"/>
      <path d="M7.65625,9.33333333 C7.48708916,9.33333333 7.35,9.48907981 7.35,9.68115942 L7.35,10.0521739 C7.35,10.2442535 7.48708916,10.4 7.65625,10.4 C7.82541084,10.4 7.9625,10.2442535 7.9625,10.0521739 L7.9625,9.68115942 C7.9625,9.48907981 7.82541084,9.33333333 7.65625,9.33333333 Z" id="Shape"/>
      <path d="M8.0576087,6.75555556 L7.6923913,6.75555556 C7.50326738,6.75555556 7.35,6.89486213 7.35,7.06666667 C7.35,7.23847121 7.50326738,7.37777778 7.6923913,7.37777778 L8.0576087,7.37777778 C8.24673262,7.37777778 8.4,7.23847121 8.4,7.06666667 C8.4,6.89482073 8.24673262,6.75555556 8.0576087,6.75555556 Z" id="Shape"/>
      <path d="M9.25585938,6.75555556 L9.20664062,6.75555556 C9.00274139,6.75555556 8.8375,6.89486213 8.8375,7.06666667 C8.8375,7.23847121 9.00274139,7.37777778 9.20664062,7.37777778 L9.25585938,7.37777778 C9.45975861,7.37777778 9.625,7.23847121 9.625,7.06666667 C9.625,6.89482073 9.45975861,6.75555556 9.25585938,6.75555556 Z" id="Shape"/>
    </g>
  </SvgIcon>
)
QrCode = pure(QrCode)
QrCode.displayName = 'QrCode'
QrCode.pmtName = 'SvgIcon'

export default QrCode
