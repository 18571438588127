import isNil from 'lodash/isNil'
import get from 'lodash/get'

import { logoutRestaurant } from 'pmt-modules/authRestaurant'
import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { getFrontSettings } from 'pmt-modules/appConfig'
import { resetDialogAction } from 'pmt-modules/dialog'
import { GetKioskSettingsAction, RefreshKioskSettingsAction } from 'pmt-modules/kioskSettings'
import { getDataGetKioskSettings } from 'pmt-modules/kioskSettings'
import { GetRestaurantAction, setRestaurant } from 'pmt-modules/restaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getDefaultLocale, setLocale } from 'pmt-modules/i18n'

import { getQueryParam } from 'pmt-utils/url'

import { KioskActions, setInactivityTimeoutInstance, setLocale as setKioskLocale } from './actions'
import { getLocale as getKioskLocale, getInactivityTimeoutInstance } from './selectors'

export const getKioskSettingsMiddleware = createMiddleware(
  [GetKioskSettingsAction.SUCCESS, RefreshKioskSettingsAction.SUCCESS],
  ({ action, dispatch, getState }) => {
    if (action.response.order.locales.length === 1) {
      const locale = action.response.order.locales[0].substring(0, 2)
      const currentLocale = getKioskLocale(getState())
      if (currentLocale !== locale) {
        dispatch(setKioskLocale(locale))
      }
    }
  }
)

const getRestaurantFailure = createMiddleware(
  GetRestaurantAction.FAILURE,
  ({ action, dispatch }) => {
    // we weren't able to fetch restaurant
    // eg: { "code" : "1006", "message" : "invalid api consumer for restaurant" }
    // typically, restaurant changed its apiConsumer/credentials so we logout
    // which redirect to login page
    if (get(action, 'error.response.status') === 400) {
      return dispatch(logoutRestaurant())
    }
  }
)

const getRestaurantSuccess = createMiddleware(
  GetRestaurantAction.SUCCESS,
  ({ action, dispatch }) => {
    dispatch(setRestaurant(action.response))
  }
)

const resetKioskMiddleware = createMiddleware(
  [KioskActions.RESET],
  ({ action, dispatch, getState }) => {
    // clean current inactivity timeout instance before its reset in reducer
    clearTimeout(getInactivityTimeoutInstance(getState()))

    if (action.options.clean) {
      const l = getQueryParam('l')
      const kioskSettings = getDataGetKioskSettings(getState())

      // first, check if there is only one locale defined in back office
      if (kioskSettings && kioskSettings.order.locales.length === 1) {
        dispatch(setKioskLocale(kioskSettings.order.locales[0].substring(0, 2)))
      } else {
        // reset locale defined in query param
        !isNil(l) ? dispatch(setKioskLocale(l)) : dispatch(setKioskLocale(getDefaultLocale()))
      }
    }

    const frontSettings = getFrontSettings(getState())
    dispatch(redirectTo(getRoute(frontSettings.home)))

    dispatch(resetDialogAction())
  }
)

const setLocaleMiddleware = createMiddleware([KioskActions.SET_LOCALE], ({ action }) => {
  setLocale(action.locale)
})

const setInactivityTimeoutMiddleware = createMiddleware(
  [KioskActions.SET_INACTIVITY_TIMEOUT],
  ({ action, dispatch, getState }) => {
    clearTimeout(getInactivityTimeoutInstance(getState()))

    if (action.callback && action.timer) {
      const instance = setTimeout(action.callback, action.timer)
      dispatch(setInactivityTimeoutInstance(instance))
    }
  }
)

export const kioskMiddlewares = [
  getKioskSettingsMiddleware,
  getRestaurantFailure,
  getRestaurantSuccess,
  resetKioskMiddleware,
  setLocaleMiddleware,
  setInactivityTimeoutMiddleware,
]
