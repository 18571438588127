import { tr } from 'pmt-modules/i18n'
import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import DialogContainer from 'pmt-ui/DialogContainer'
import { TypographyCustom } from 'pmt-ui/Typography'

import NumericPad from 'app/components/NumericPad'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  title: {
    padding: theme.spacing(3, 0, 0),
    textAlign: 'center',
  },
  pagerId: {
    minHeight: 80,
    marginBottom: theme.spacing(5),
    color: theme.palette.primary.main,
    overflowWrap: 'break-word',
  },
  buttons: {
    marginLeft: theme.spacing(4),
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    '&:disabled': {
      border: '3px solid white',
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
}))

const NumericPadDialog = ({ button, onSubmit }) => {
  const [number, setNumber] = useState('')

  const classes = useStyles()

  return (
    <DialogContainer
      title={
        <TypographyCustom type="407" className={classes.title}>
          {tr('kiosk.keypad.dialog.title')}
        </TypographyCustom>
      }
      button={button}
      actions={({ onClose }) => (
        <React.Fragment>
          <Button onClick={onClose} color="secondary" className={classes.buttons}>
            <TypographyCustom type="247" skipColor>
              {tr('global.cancel')}
            </TypographyCustom>
          </Button>
          <Button
            onClick={() => {
              if (!isEmpty(number)) {
                onSubmit(number)
                onClose()
              }
            }}
            color="primary"
            variant="contained"
            className={classes.buttons}
            disabled={isEmpty(number)}
          >
            <TypographyCustom type="247" skipColor>
              {tr('global.validate')}
            </TypographyCustom>
          </Button>
        </React.Fragment>
      )}
    >
      {() => (
        <div className={classes.root}>
          <TypographyCustom type="807" align="center" className={classes.pagerId}>
            {number}
          </TypographyCustom>

          <NumericPad
            onSelect={digit => setNumber(`${number}${digit}`)}
            onErase={() => setNumber(number.slice(0, -1))}
          />
        </div>
      )}
    </DialogContainer>
  )
}

export default NumericPadDialog
