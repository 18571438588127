import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { findOnArray } from 'pmt-utils/array'

import { orderProductFormatter } from './format'

const getState = (state, props) => state.entities.orderProduct
const getCatalogDataOnProps = (state, props) => props.catalog

export const makeGetSelectedProduct = () =>
  createSelector([getState, getCatalogDataOnProps], (orderProductState, catalogData) => {
    if (isNil(orderProductState) || isNil(catalogData)) {
      return null
    }

    const selectedProduct = orderProductState.get('selectedProduct')

    if (isNil(selectedProduct)) {
      return null
    }

    const selectedProductId = selectedProduct.get('id')
    if (isNil(selectedProductId)) {
      return null
    }

    return findOnArray(catalogData.products, product => product.id === selectedProductId)
  })

export const getOrderProduct = createSelector([getState], orderProductState => {
  const orderProduct = orderProductState.get('orderProduct')

  if (isNil(orderProduct)) {
    return null
  }

  return orderProductFormatter(orderProduct.toJS())
})

export const getViewOptions = createSelector([getState], orderProductState => {
  const viewOptionsState = orderProductState.get('viewOptions')

  if (isNil(viewOptionsState)) {
    return null
  }

  return viewOptionsState.toJS()
})
