import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hidePostOrderBackHomeDialog } from 'pmt-modules/kioskOrder'
import { resetKiosk } from 'pmt-modules/kiosk'

import Button from 'pmt-ui/Button'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: theme.spacing(3),
  },
  dialogActions: {
    marginTop: theme.spacing(6),
    justifyContent: 'center',
  },
  cancelButton: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    marginRight: theme.spacing(7),
  },
  deleteButton: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
})

class PostOrderErrorBackHomeDialog extends React.PureComponent {
  handleHideDialog = () => {
    this.props.hidePostOrderBackHomeDialog()
  }

  handleHideDialogAndRedirectToHomePage = () => {
    this.handleHideDialog()
    this.props.resetKiosk()
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <TypographyCustom type="327" skipColor>
            {tr('kiosk.order.dialog.post_order_errror.title')}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TypographyCustom type="244">
            {tr('kiosk.order.dialog.post_order_errror.body')}
          </TypographyCustom>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleHideDialog}
            className={classes.cancelButton}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.dialog.post_order_errror.button.cancel')}
            </TypographyCustom>
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleHideDialogAndRedirectToHomePage}
            className={classes.deleteButton}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.dialog.post_order_errror.button.confirm')}
            </TypographyCustom>
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
PostOrderErrorBackHomeDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

PostOrderErrorBackHomeDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 760,
    padding: theme.spacing(6),
  },
})

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    hidePostOrderBackHomeDialog,
    resetKiosk,
  })
)(PostOrderErrorBackHomeDialog)
