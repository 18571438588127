import React from 'react'

import { withAppConfig } from 'pmt-modules/appConfig'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 *
 */
@withAppConfig
@withRestaurant
class ScanPage extends React.PureComponent {
  render() {
    const { frontAppConfig, isFetchingRestaurant, orderAppConfig, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            kioskSettings={kioskSettings}
            orderAppConfig={orderAppConfig}
            restaurant={restaurant}
          />
        )}
      </KioskPage>
    )
  }
}

export default ScanPage
