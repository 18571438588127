import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getFrontSettings, withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import {
  withCatalog,
  selectCategory,
  resetParentCategories,
  refreshCatalog,
} from 'pmt-modules/catalog'
import { getItemListFromCart } from 'pmt-modules/cart'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import KioskPage from '../../../components/KioskPage'
import View from './View'

import moment from 'moment'

import { REFRESH_CATALOG_DELAY } from 'pmt-modules/kioskOrder/constants'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
@withCatalog
class OrderCategoryPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (isNil(props.orderProperties.mode)) {
      props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
    } else {
      const { restaurant, catalog, isFetchingCatalog, orderProperties } = props
      const { mode, locale } = orderProperties
      if (
        restaurant &&
        !isFetchingCatalog &&
        moment().valueOf() - catalog?.dueDate >= REFRESH_CATALOG_DELAY
      ) {
        // refresh catalog after some time to be sure that it is up to date
        // this is used when kiosk has been idle on home over a few minutes, or over night
        props.refreshCatalog(restaurant.id, null, mode, {
          locale,
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isNil(nextProps.catalogError)) {
      this.props.redirectTo(getRoute(this.props.frontSettings.home))
    }
  }

  handleOnCategoryClick = category => {
    const { selectCategory, resetParentCategories, redirectTo } = this.props

    EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
      category,
    })
    resetParentCategories()
    selectCategory(category)
    redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: category.id,
    })
  }

  render() {
    const { frontAppConfig, route, restaurant, catalog, orderProperties, itemList } = this.props

    return (
      <KioskPage route={route}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            isFetching={!restaurant || !catalog}
            kioskSettings={kioskSettings}
            restaurant={restaurant}
            catalog={catalog}
            mode={orderProperties.mode}
            itemList={itemList}
            handleOnCategoryClick={this.handleOnCategoryClick}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  frontSettings: getFrontSettings(state),
  orderProperties: getOrderProperties(state),
  itemList: getItemListFromCart(state),
})

export default connect(
  mapStateToProps,
  {
    selectCategory,
    resetParentCategories,
    redirectTo,
    refreshCatalog,
  }
)(OrderCategoryPage)
