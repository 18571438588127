import isEmpty from 'lodash/isEmpty'

import { createFormatter } from 'pmt-utils/format'

import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings/constants'
import { IrlPaymentMethod } from 'pmt-modules/payment'

export const formatOrderData = (data, orderProperties) => {
  if (isEmpty(data)) {
    return null
  }

  if (orderProperties.payment.method === PaymentMethodsAllowed.CREDIT_CARD) {
    data = {
      ...data,
      irlPayment: {
        amount: orderProperties.totalCartPriceAndFees,
        type: IrlPaymentMethod.CB,
      },
    }
  } else {
    data = {
      ...data,
      irlPayment: null,
    }
  }

  return data
}

export const formatOrderKioskData = createFormatter(formatOrderData)
