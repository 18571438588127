import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getFrontSettings } from 'pmt-modules/appConfig'
import { isFetchingOrderPost } from 'pmt-modules/orderPost'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal,
    textAlign: 'center',
    flex: 1,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  contentGridContainer: {
    marginTop: theme.spacing(20),
  },
  loadingText: {
    marginTop: theme.spacing(6),
  },
  loadingBlock: {
    background: 'transparent',
  },
  circularProgress: {
    color: theme.pmt.colors.white,
  },
  tip: {
    margin: `${theme.spacing(50)}px auto 0`,
    maxWidth: theme.spacing(119),
    lineHeight: `${theme.spacing(7)}px`,
  },
})

@withRestaurant
class LoadingPostOrder extends React.PureComponent {
  render() {
    const { classes, frontAppConfig, isFetchingOrderPost, restaurant } = this.props

    return (
      isFetchingOrderPost && (
        <div className={classes.root}>
          <div className={classes.logoContainer}>
            {restaurant && (
              <img className={classes.logo} src={frontAppConfig.theme.logo.path || restaurant.logo} alt={restaurant.name} />
            )}
          </div>
          <Grid container className={classes.contentGridContainer}>
            <Grid item xs={12}>
              <div>
                <LoadingBlock
                  show
                  size={200}
                  classes={{
                    loadingBlock: classes.loadingBlock,
                    circularProgress: classes.circularProgress,
                  }}
                />
                <TypographyCustom type="247" className={classes.loadingText} align="center" skipColor>
                  {tr('kiosk.order.page.payment.printing_in_progress')}
                </TypographyCustom>

                <CustomTextsContainer>
                  {({ texts }) =>
                    !isNil(texts) &&
                    !isNil(texts.KIOSK__ORDER__PAYMENT__TIP) && (
                      <TypographyCustom type="484" align="center" className={classes.tip} skipColor>
                        {texts.KIOSK__ORDER__PAYMENT__TIP}
                      </TypographyCustom>
                    )
                  }
                </CustomTextsContainer>
              </div>
            </Grid>
          </Grid>
        </div>
      )
    )
  }
}

const mapStateToProps = state => ({
  frontAppConfig: getFrontSettings(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
})

export default compose(connect(mapStateToProps, {}), withStyles(styles))(LoadingPostOrder)
