import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { orderMenuFormatter } from './format'

const getState = (state, props) => state.entities.orderMenu

export const makeGetSelectedMenu = () =>
  createSelector([getState], orderMenuState => {
    if (isNil(orderMenuState)) {
      return null
    }

    const selectedMenu = orderMenuState.get('selectedMenu')

    if (isNil(selectedMenu)) {
      return null
    }

    return selectedMenu.toJS()
  })

export const getOrderMenu = createSelector([getState], orderMenuState => {
  const orderMenu = orderMenuState.get('orderMenu')

  if (isNil(orderMenu)) {
    return null
  }

  return orderMenuFormatter(orderMenu.toJS(), {
    // for products
    isMenuMode: true,
  })
})

export const getViewOptions = createSelector([getState], orderMenuState => {
  const viewOptionsState = orderMenuState.get('viewOptions')

  if (isNil(viewOptionsState)) {
    return null
  }

  return viewOptionsState.toJS()
})

export const getPartSelectedCategory = createSelector([getState], orderMenuState => {
  const selectedCategoryState = orderMenuState.get('selectedCategory')

  if (isNil(selectedCategoryState)) {
    return null
  }

  return selectedCategoryState.toJS()
})

export const getPartParentCategories = createSelector([getState], orderMenuState => {
  const parentCategoriesState = orderMenuState.get('parentCategories')

  if (isNil(parentCategoriesState)) {
    return []
  }

  return parentCategoriesState.toJS()
})

export const getPartParentCategory = createSelector([getState], orderMenuState => {
  const parentCategoriesState = orderMenuState.get('parentCategories')

  if (isNil(parentCategoriesState)) {
    return []
  }

  const parentCategories = parentCategoriesState.toJS()
  return !isEmpty(parentCategories) ? parentCategories.pop() : parentCategories
})
