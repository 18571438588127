import createMiddleware from 'pmt-modules/redux/createMiddleware'

import PaymentErrorsList from 'pmt-modules/api/errors/PaymentErrorsList'
import { PaymentStatus } from 'pmt-modules/payment/constants'

import { GetAsyncPaymentAction, getAsyncPayment } from './actions'
import { AsyncPaymentPendingStatus, AsyncPaymentCanceledResponseStatus } from './constants'

function getTimeout(asyncPaymentRetry) {
  // 2..10: every 2 seconds
  if (asyncPaymentRetry < 5) {
    return 2000
  }
  // 10..35: every 5 seconds
  if (asyncPaymentRetry < 5 + 5) {
    return 5000
  }
  // 35..125: 10 seconds
  if (asyncPaymentRetry < 5 + 5 + 9) {
    return 10000
  }
  // other: every 20 seconds
  return 20000
}

/**
 * We get the payment after an async transaction in order to verify its status
 * on success, we redirect to payment confirmation page
 */
const getAsyncPaymentSuccess = createMiddleware(
  GetAsyncPaymentAction.SUCCESS,
  ({ action, dispatch, next }) => {
    if (!action.response.isFinalized) {
      setTimeout(() => {
        const retry = action.data.retry + 1
        dispatch(getAsyncPayment(action.data.paymentId, action.data, retry))
      }, getTimeout(action.data.retry))
      return createMiddleware.STOP_PROPAGATION
    }

    // async payment is finalized

    // check if objet is a payment or contains a payment with status failed
    const statusFailed = [PaymentStatus.FAILED, PaymentStatus.REFUND_FAILED, PaymentStatus.REFUNDED]
    if (action.response.payment && statusFailed.includes(action.response.payment.status)) {
      next(action)

      const defaultError = {
        code: AsyncPaymentCanceledResponseStatus,
        message: 'async payment canceled',
        localizedMessage: PaymentErrorsList[AsyncPaymentCanceledResponseStatus],
        response: {
          ok: false,
          status: AsyncPaymentCanceledResponseStatus,
        },
      }

      const errorWithoutRefundData = action.response.payment?.errorModel || defaultError;

      dispatch({
        type: GetAsyncPaymentAction.FAILURE,
        data: action.data,
        error: {
          ...errorWithoutRefundData,
          refunded:  action.response.payment.status === PaymentStatus.REFUNDED
        },
      })
      return createMiddleware.STOP_PROPAGATION
    }
  }
)

/**
 * the async payment data verification has failed, retry
 */
const getAsyncPaymentFailure = createMiddleware(
  GetAsyncPaymentAction.FAILURE,
  ({ action, dispatch }) => {
    if (action.error && AsyncPaymentPendingStatus.indexOf(action.error.code) >= 0) {
      setTimeout(() => {
        dispatch(getAsyncPayment(action.data.paymentId, action.data, action.data.retry + 1))
      }, getTimeout(action.data.retry))
      return createMiddleware.STOP_PROPAGATION
    }
  }
)

export const AsyncPaymentMiddlewares = [getAsyncPaymentSuccess, getAsyncPaymentFailure]
