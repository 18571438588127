import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { getRoute } from 'pmt-modules/routing'

import Button from 'pmt-ui/Button'
import ButtonLink from 'pmt-ui/ButtonLink'
import Price from 'pmt-ui/Price'
import CreditCard from 'pmt-ui/svg-icons/action/credit-card'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${theme.pmt.colors.grey91}`,
    padding: `${theme.spacing(7)}px 0`,
  },
  labels: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(8),
  },
  labelPayment: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    color: theme.pmt.colors.grey91,
  },
  labelPaymentIcon: {
    width: 55,
    height: 55,
    marginRight: theme.spacing(2),
  },
  labelTotal: {
    marginRight: theme.spacing(4),
  },
  totalPrice: {
    lineHeight: '55px',
  },
  totalButton: {
    padding: theme.spacing(3),
    lineHeight: 1,
  },
  mergeButton: {
    color: theme.palette.text.primary,
    padding: theme.spacing(3),
    lineHeight: 1,
  },
})

const CheckFooter = ({ classes, check, isFetchingCheck, onScanToMerge, ui }) => (
  <div className={classes.root}>
    <div className={classes.labels}>
      <TypographyCustom type="284" skipColor className={classes.labelPayment}>
        <CreditCard className={classes.labelPaymentIcon} />
        {tr('kiosk.checkout.page.check.payment_method', {
          total: check.outstandingBalanceFormatted,
        })}
      </TypographyCustom>

      <TypographyCustom type="324" className={classes.labelTotal}>
        {tr('kiosk.checkout.page.check.total')}
      </TypographyCustom>

      <TypographyCustom type="407" className={classes.totalPrice}>
        <Price value={check.outstandingBalanceFormatted} />
      </TypographyCustom>
    </div>
    <Button
      variant="contained"
      onClick={onScanToMerge}
      className={classes.mergeButton}
      disabled={isFetchingCheck || ui.isScanning}
    >
      <TypographyCustom type="327" skipColor>
        {tr('kiosk.checkout.page.check.merge')}
      </TypographyCustom>
    </Button>
    <br />
    <ButtonLink
      variant="contained"
      color="primary"
      className={classes.totalButton}
      to={getRoute('KIOSK__CHECKOUT__PAYMENT')}
      params={{ checkId: check.id }}
      disabled={isFetchingCheck || ui.isScanning}
    >
      <TypographyCustom type="327" skipColor>
        {tr('kiosk.checkout.page.check.pay', {
          total: check.outstandingBalanceFormatted,
        })}
      </TypographyCustom>
    </ButtonLink>
  </div>
)

export default withStyles(styles)(CheckFooter)
