import Immutable from 'immutable'

import {
  CheckAction,
  GetCheckWithCodeAction,
  GetCheckWithTableNumberAction,
  GetCheckWithPosCheckIdAction,
  GetRefreshCheckAction,
  GetCheckAction,
  MergeCheckWithCodeAction,
} from './actions'

export * from './actions'
export * from './selectors'

/**
 *
 *
 */
const CHECK_DATA = {
  check: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
}

export const checkReducer = (state = Immutable.fromJS(CHECK_DATA), action) => {
  switch (action.type) {
    case CheckAction.RESET:
      return state.merge(
        Immutable.fromJS({
          ...CHECK_DATA,
        })
      )

    case GetCheckWithCodeAction.REQUEST:
    case GetCheckWithTableNumberAction.REQUEST:
    case GetCheckWithPosCheckIdAction.REQUEST:
    case GetCheckAction.REQUEST:
      return state.merge({
        check: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetRefreshCheckAction.REQUEST:
    case MergeCheckWithCodeAction.REQUEST:
      return state.merge({
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetCheckWithCodeAction.SUCCESS:
    case GetCheckWithTableNumberAction.SUCCESS:
    case GetCheckWithPosCheckIdAction.SUCCESS:
    case GetRefreshCheckAction.SUCCESS:
    case GetCheckAction.SUCCESS:
    case MergeCheckWithCodeAction.SUCCESS:
      return state.merge({
        check: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetCheckWithCodeAction.FAILURE:
    case GetCheckWithTableNumberAction.FAILURE:
    case GetCheckWithPosCheckIdAction.FAILURE:
    case GetRefreshCheckAction.FAILURE:
    case GetCheckAction.FAILURE:
      return state.merge({
        check: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    case MergeCheckWithCodeAction.FAILURE:
      return state.merge({
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}
