import React from 'react'
import { connect } from 'react-redux'

import { getErrorUserAccounts, isFetchingUserAccounts } from 'pmt-modules/restaurantUserAccount'
import { scanStart, sharingListenMessageBadgeIdStart } from 'pmt-modules/kioskInteractor/actions'
import { getSharingMessage, isAliveSharingMessage } from 'pmt-modules/kioskInteractor/selectors'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
class TopUpHomePage extends React.PureComponent {
  constructor(props) {
    super(props)

    props.scanStart()
    props.sharingListenMessageBadgeIdStart()
  }

  handleErrorRetry = () => {
    this.props.scanStart()
    this.props.sharingListenMessageBadgeIdStart()
    this.props.hideErrorDialog()
  }

  render() {
    const { isFetchingRestaurant, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {() => (
          <View
            restaurant={restaurant}
            isAliveSharingMessage={this.props.isAliveSharingMessage}
            isFetchingUserAccounts={this.props.isFetchingUserAccounts}
            errorUserAccounts={this.props.errorUserAccounts}
            onErrorRetry={this.handleErrorRetry}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    isAliveSharingMessage: isAliveSharingMessage(state),
    sharingMessage: getSharingMessage(state),
    isFetchingUserAccounts: isFetchingUserAccounts(state),
    errorUserAccounts: getErrorUserAccounts(state),
  }
}

export default connect(
  mapStateToProps,
  {
    hideErrorDialog,
    scanStart,
    sharingListenMessageBadgeIdStart,
  }
)(TopUpHomePage)
