import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import { getKioskPrinter } from '../../config'

class Printer {
  constructor() {
    const Builder = getKioskPrinter()
    this.builder = new Builder()
  }

  DEFAULT_CHAR_SIZE = 1

  renderRestaurantInfo(restaurant) {
    this.builder.addText(restaurant.name, { bold: true, align: 'center' })
    this.builder.addText(restaurant.address.street, { align: 'center' })
    this.builder.addText(`${restaurant.address.postCode} ${restaurant.address.city}`, {
      align: 'center',
    })

    if (!isEmpty(restaurant.legal) && !isEmpty(restaurant.legal.siren)) {
      this.builder.addText(`SIRET ${restaurant.legal.siren}`, { align: 'center' })
    }

    this.builder.addFeedLine()

    const createdDate = `${moment().format('DD/MM/YYYY')} - ${moment().format('HH:mm')}`
    this.builder.addText(createdDate, { align: 'center' })

    this.builder.addFeedLine()
  }

  formatBankReceipt(content = '') {
    return this.builder.printPayment(content)
  }

  renderPayment(separator = true, content = '') {
    if (separator) {
      this.builder.addText(`______________________________________`, { align: 'center' })
    }
    this.builder.printPayment(content)
  }
}

export default Printer
