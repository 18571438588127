import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { getQueryParam } from 'pmt-utils/url'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

const formatValueAdditionalPrice = value => {
  value.additionalPriceFormatted = formatPriceWithCurrency(value.additionalPrice)
  value.isOutOfStock = value.stock === 0
  // add way to tests locked items: we allow to add them to cart if the testLockedBeforePayment query param is
  value.disableAdd =
    value.isOutOfStock === true && getQueryParam('testLockedBeforePayment') !== 'true'
  return value
}

const formatOptionValue = createFormatter(formatValueAdditionalPrice)

export const formatOptionValues = createListFormatter(formatOptionValue)
