import { tr } from 'pmt-modules/i18n'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { setPagerId } from 'pmt-modules/orderFront'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core//Divider'

import ButtonLink from 'pmt-ui/ButtonLink'
import { TypographyCustom } from 'pmt-ui/Typography'

import NumericPad from 'app/components/NumericPad'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pagerId: {
    minHeight: 80,
    marginBottom: theme.spacing(10),
    color: theme.palette.primary.main,
    overflowWrap: 'break-word',
  },
  divider: {
    backgroundColor: theme.pmt.colors.grey91,
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: 86,
  },
  buttonLabel: {
    textTransform: 'initial',
  },
}))

const WithPadOnlyView = ({ setPagerId }) => {
  const [pagerId, setPagerIdState] = useState('')

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TypographyCustom type="807" align="center" className={classes.pagerId}>
          {pagerId}
        </TypographyCustom>

        <NumericPad
          onSelect={digit => setPagerIdState(`${pagerId}${digit}`)}
          onErase={() => setPagerIdState(pagerId.slice(0, -1))}
        />
      </div>

      <Divider className={classes.divider} />

      <ButtonLink
        variant="contained"
        color="primary"
        disabled={isEmpty(pagerId)}
        classes={{ root: classes.button, label: classes.buttonLabel }}
        to={'KIOSK__ORDER__PAYMENT__MODE'}
        onClick={() => {
          setPagerId(pagerId)
        }}
      >
        <TypographyCustom type="327" skipColor>
          {tr('kiosk.order.page.cart.submit')}
        </TypographyCustom>
      </ButtonLink>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = { setPagerId }

export default connect(mapStateToProps, mapDispatchToProps)(WithPadOnlyView)
