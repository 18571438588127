import RestaurantApi from '../api/calls/RestaurantApi'

import { createApiCallAction, createApiEnumAction, createRestaurantBasic } from '../api/utils'
import createAction from '../redux/createAction'

//
//
//

export const AuthRestaurantAction = createApiEnumAction('AUTH::RESTAURANT::AUTHENTICATE')

/**
 *
 * @param  {string} resturantId
 * @param  {string} apiConsumer
 * @param  {string} password
 * @param  {string} props     additional props that can be used by the middlewares.
 *                            - redirectTo: will redirect to the given url after the login success
 */
export const authenticateRestaurant = (restaurantId, password, apiConsumer, props) => {
  const authorization = createRestaurantBasic(restaurantId, password)

  return createApiCallAction(
    AuthRestaurantAction,
    RestaurantApi.authRestaurant(restaurantId, authorization, apiConsumer),
    {
      restaurantId,
      password,
      props,
      apiConsumer,
      authorization,
    }
  )
}

//
//
//

export const LOGOUT_RESTAURANT = 'AUTH::RESTAURANT::LOGOUT'

export const logoutRestaurant = () => createAction(LOGOUT_RESTAURANT, {})
