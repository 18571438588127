import ApiEndpoints from '../config/ApiEndpoints'

const RestaurantUserAccountApi = {
  getUserAccounts: (restaurantId, query) => {
    const defaultQueryParams = {
      loadUserData: true,
      loadEnabledUserAccountsOnly: true,
    }

    return {
      endpoint: ApiEndpoints.RESTAURANT_USER_ACCOUNTS,
      type: 'GET',
      params: {
        restaurantId,
      },
      query: {
        ...defaultQueryParams,
        ...query,
      },
    }
  },
}

export default RestaurantUserAccountApi
