import React from 'react'
import { connect } from 'react-redux'

import { RestaurantUserAccountsContainer } from 'pmt-modules/restaurantUserAccount'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
class TopUpPaymentConfirmationPage extends React.PureComponent {
  static INACTIVITY_TIMEOUT = 5000

  constructor(props) {
    super(props)

    this.inactivityTimeout = setTimeout(() => {
      this.props.redirectTo(getRoute('HOME'))
      clearTimeout(this.inactivityTimeout)
      this.inactivityTimeout = null
    }, TopUpPaymentConfirmationPage.INACTIVITY_TIMEOUT)
  }

  render() {
    const { isFetchingRestaurant, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {() => (
          <RestaurantUserAccountsContainer
            restaurantId={restaurant.id}
            userAccountId={this.props.routeParams.userAccountId}
            forceUpdate={true}
          >
            {({ userAccount, userAccounts, isFetchingUserAccounts }) =>
              isFetchingUserAccounts || userAccount === null ? (
                <LoadingBlockWrapper show />
              ) : (
                <View restaurant={restaurant} userAccount={userAccount} />
              )
            }
          </RestaurantUserAccountsContainer>
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default connect(
  mapStateToProps,
  {
    redirectTo,
  }
)(TopUpPaymentConfirmationPage)
