import React from 'react'
import PropTypes from 'prop-types'
import shallowEqual from 'recompose/shallowEqual'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import clsx from 'clsx'

import Select from '../Select'
import { MenuItem } from '../Menu'
import TextField from '../TextField'
import { InputLabel } from 'pmt-ui/Input'
import { FormControl } from 'pmt-ui/Form'

import { withStyles } from 'pmt-ui/styles'

import Flag from 'pmt-utils/country/flag'
import {
  getCountryCodes,
  getCountryDialCodeFromCountryCode,
  formatPhoneNumberAsYouType,
} from 'pmt-utils/phone'

const styles = theme => ({
  root: {
    flexDirection: 'row',
    height: 48,
  },
  phoneNumberRoot: {
    marginTop: 16,
    flexGrow: 1,
  },
  label: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 1.5px) scale(0.75)', // TODO: use theme?
    transformOrigin: 'top left',
  },
  flagIcon: {
    width: 19,
    height: 15,
    marginRight: theme.spacing(1),
    borderRadius: 2,
  },
  selectedValue: {
    position: 'absolute',
    top: `calc(50% - 9px)`,
    display: 'flex',
    height: '100%',
    width: '100%',
    // TODO: remove after migrating to TypoGraphy
    fontSize: 16,
    paddingTop: 2,
  },
  // New CSS from massive material update
  selectRoot: {
    position: 'relative',
    width: 95,
  },
})

/**
 * Check if a value is valid to be displayed inside an input.
 *
 * @param The value to check.
 * @returns True if the string provided is valid, false otherwise.
 */
const isValid = value => {
  return (
    value !== '' &&
    value !== undefined &&
    value !== null &&
    !(Array.isArray(value) && value.length === 0)
  )
}

class PhoneField extends React.Component {
  static propTypes = {
    /**
     * The value of the text field.
     */
    value: PropTypes.any,
  }

  escapeSetFormattedNumber = false // allow us to skip infinite loop but setting right phone number and skip error display while there's none

  state = {
    phoneNumber: '',
    countryCode: 'FR',
    countryDialCode: '+33',
  }

  componentDidMount() {
    const { formattedPhoneNumber, hasValue } = !isNil(this.props.value)
      ? this.formatPhoneNumber(this.props.value)
      : { formattedPhoneNumber: '', hasValue: false }
    this.setState({
      hasValue,
      phoneNumber: formattedPhoneNumber,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { formattedPhoneNumber, hasValue } = !isNil(nextProps.value)
      ? this.formatPhoneNumber(nextProps.value)
      : { formattedPhoneNumber: '', hasValue: false }

    if (this.state.phoneNumber !== formattedPhoneNumber || this.state.hasValue !== hasValue) {
      this.setState(
        {
          hasValue,
          phoneNumber: formattedPhoneNumber,
        },
        () => {
          // on certain devices (old samsung), cursor "jumps" on previous char
          // we position it at the end on setState callback
          // setTimeout is necessary to ensure it's working
          if (!isNil(this.input)) {
            const cursorPosition = this.input.selectionStart
            setTimeout(
              () => this.input && this.input.setSelectionRange(cursorPosition, cursorPosition),
              0
            )
          }
        }
      )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState)
  }

  getCountryCodeFromPhoneNumber(phoneNumber) {
    return getCountryCodes()
      .filter(countryCode => phoneNumber.indexOf(countryCode.dial_code) === 0)
      .pop()
  }

  formatPhoneNumber(phoneNumber, skipSpaces = false) {
    if (isEmpty(phoneNumber)) {
      return { formattedPhoneNumber: '', hasValue: false }
    }

    const countryCodeObject = this.getCountryCodeFromPhoneNumber(phoneNumber)
    const countryDialCode = countryCodeObject.dial_code || '+33'
    const countryCode = countryCodeObject.code || 'FR'

    this.setState({
      countryCode: countryCode, // TODO: set default countryDialCode depending on ISO
      countryDialCode: countryDialCode,
    })

    phoneNumber = phoneNumber.replace(countryDialCode, '')

    // clean leading zero but keep this information in order to print it later
    let hasLeadingZero = false
    if (countryDialCode === '+33') {
      hasLeadingZero = phoneNumber.match(/^[0]+/) || false
      phoneNumber = phoneNumber.replace(/^[0]+/, '')
    }

    // format phone number without space or dash
    // this should return something like +XX YYYYY  on success
    // and +XXYYY on error
    const cleanedPhoneNumber = `${countryDialCode}${phoneNumber}`.replace(/[ -]/g, '')
    phoneNumber = formatPhoneNumberAsYouType(cleanedPhoneNumber, countryCode)
    const isFormatted = phoneNumber.indexOf(' ') !== -1

    // when user try to put too many digits
    // we are not able to format phone number,
    // we try again but without last char
    if (!isFormatted) {
      phoneNumber = phoneNumber.replace(countryDialCode, '')
      phoneNumber = phoneNumber.substr(0, phoneNumber.length - 1)
      phoneNumber = formatPhoneNumberAsYouType(
        `${countryDialCode}${phoneNumber}`.replace(/[ -]/g, ''),
        countryCode
      )
    }

    // finally, we remove country code to get only phone number
    phoneNumber = phoneNumber.replace(new RegExp(`\\${countryDialCode} ?`, 'g'), '')
    phoneNumber = hasLeadingZero ? `0${phoneNumber}` : phoneNumber

    if (skipSpaces) {
      phoneNumber = phoneNumber.split(' ').join('')
    }

    const hasValue = phoneNumber !== '' || hasLeadingZero

    return { formattedPhoneNumber: phoneNumber, hasValue }
  }

  handleCountryCodeChange = (event, countryCode) => {
    const countryDialCode = getCountryDialCodeFromCountryCode(countryCode)

    this.setState(
      {
        countryCode,
        countryDialCode,
      },
      () => {
        const { formattedPhoneNumber } = this.formatPhoneNumber(
          `${this.state.countryDialCode}${this.state.phoneNumber}`
        )
        this.props.onChange(`${this.state.countryDialCode}${formattedPhoneNumber}`)
      }
    )
  }

  handleInputChange = event => {
    this.escapeSetFormattedNumber = false

    if (!this.props.hasOwnProperty('value')) {
      this.setState({ hasValue: isValid(event.target.value) })
    }

    if (this.props.onChange) {
      // we need a phone number that does not contain any spaces
      // the "true" parameter is here to skip spaces in formatted number
      const { formattedPhoneNumber } = this.formatPhoneNumber(
        `${this.state.countryDialCode}${event.target.value}`,
        true
      )

      if (!isEmpty(formattedPhoneNumber)) {
        this.props.onChange(`${this.state.countryDialCode}${formattedPhoneNumber}`)
      } else {
        this.props.onChange(null)
      }
    }
  }

  render() {
    const { classes, className, displayFlag, label, required } = this.props

    try {
      const selectElement = (
        <Select
          name="selectCountryCode"
          // current value
          value={this.state.countryCode}
          // display of the current value
          renderValue={() => (
            <div className={classes.selectedValue}>
              {displayFlag && (
                <Flag className={classes.flagIcon} country={this.state.countryCode} />
              )}
              {this.state.countryDialCode}
            </div>
          )}
          autoWidth={true}
          onChange={event => this.handleCountryCodeChange(event, event.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '100%',
              },
            },
          }}
          classes={{
            root: classes.selectRoot,
          }}
        >
          {getCountryCodes().map(({ name, dial_code, code }) => (
            <MenuItem key={code} value={code}>
              {displayFlag && <Flag className={classes.flagIcon} country={code} />}
              {name}
              &nbsp;&nbsp;
              {dial_code}
            </MenuItem>
          ))}
        </Select>
      )

      return (
        <div>
          <FormControl className={clsx(className, classes.root)}>
            <InputLabel htmlFor="phone" className={classes.label} error={this.props.error}>
              {label}
              {required ? ' *' : ''}
            </InputLabel>

            {selectElement}

            <TextField
              id="phone"
              type="tel"
              classes={{
                root: classes.phoneNumberRoot,
              }}
              inputRef={elem => (this.input = elem)}
              onChange={event => this.handleInputChange(event)}
              value={this.state.phoneNumber}
              error={this.props.error}
              placeholder={this.props.placeholder}
            />
          </FormControl>
          {!isEmpty(this.props.helperText) && (
            <p className="colorError u-fontSize12 u-marginTop8">{this.props.helperText}</p>
          )}
        </div>
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export default withStyles(styles)(PhoneField)
