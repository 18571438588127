import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'
import { addToArray } from 'pmt-utils/array'

// we format products in a menu part recursively
// so we format products inside all subcategories of all parts
const recursiveFormatProduct = (partCategory, formatProduct) => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories = partCategory.categories.map(partCategory => {
      return recursiveFormatProduct(partCategory, formatProduct)
    })
  }

  if (!isNil(partCategory.products)) {
    partCategory.products = partCategory.products.map(product => {
      product = formatProduct(product)
      return product
    })
  }

  return partCategory
}

export const mergeProductsAndMenus = (object, formatProduct = false, formatMenu = false) => {
  let items = []
  // pre-allocate items length
  items.length =
    (object.products ? object.products.length : 0) + (object.menus ? object.menus.length : 0)

  if (object.isCategory) {
    if (!isNil(object.products) && formatProduct) {
      each(object.products, product => {
        product = formatProduct(product)
        items.push(product)
      })
    }

    if (!isNil(object.menus) && formatMenu) {
      each(object.menus, menu => {
        menu = formatMenu(menu)
        items.push(menu)
      })
    }

    // order items by position value
    object.items = items.sort((a, b) => a.position - b.position)

    // then we order them by their availability
    const itemsAvailable = object.items.filter(item => item.available)
    const itemsUnavailable = object.items.filter(item => !item.available)
    // warn: we modify itemsAvailable reference here.
    object.items = addToArray(itemsAvailable, itemsUnavailable)
  } else {
    // if it is a menu part
    if (!isNil(object.products) && formatProduct) {
      object = recursiveFormatProduct(object, formatProduct)
    }

    let productsAvailable = []
    let productsUnavailable = []
    if (!isNil(object.products)) {
      productsAvailable = object.products.filter(item => item.available)
      productsUnavailable = object.products.filter(item => !item.available)
    }

    // warn: we modify productsAvailable reference here.
    object.products = addToArray(productsAvailable, productsUnavailable)
  }

  return object
}
