import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'

import { addToCartFromShortcut } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { openMenu } from 'pmt-modules/orderMenu'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { withStyles } from 'pmt-ui/styles'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import Price from 'pmt-ui/Price'
import StateMachine from 'pmt-ui/StateMachine'
import ActionInfo from 'pmt-ui/svg-icons/action/info'
import NavigationCheck from 'pmt-ui/svg-icons/navigation/check'

const styles = theme => ({
  root: {
    position: 'relative',
    width: 250,
    margin: `0 ${theme.spacing(1) + theme.spacing(0.5)}px ${theme.spacing(4)}px`,
    boxShadow: '2px 2px 4px 0px rgba(158,158,158,1)',
  },
  rootSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  rootDisabled: {
    opacity: 0.3,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 125,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  unavailableLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    paddingTop: theme.spacing(7),
    backgroundColor: 'rgba(38, 38, 38, 0.75)',
    color: theme.pmt.colors.white,
    zIndex: 100,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `0 ${theme.spacing(2)}px`,
  },
  textContainerUnavailable: {
    opacity: 0.6,
  },
  name: {
    flex: 1,
    ...theme.pmt.appConfig.itemCardContentName,
  },
  placeholderContainer: {
    backgroundColor: theme.pmt.colors.grey100,
  },
  placeholder: {
    maxWidth: 125,
    maxHeight: 125,
    opacity: 0.3,
  },
  priceContainer: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    textAlign: 'center',

    '& > div': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  price: {
    float: 'none',
    margin: '0 auto',
  },
  iconInfoContainer: {
    position: 'absolute',
    top: -35,
    right: -35,
    width: 70,
    height: 70,
    background: theme.pmt.colors.greyEF,
    transform: 'rotate(0.125turn)',
    zIndex: 90,
  },
  iconInfo: {
    position: 'absolute',
    bottom: 4,
    right: 24,
    width: 20,
    height: 20,
    transform: 'rotate(-0.125turn)',
    color: theme.pmt.colors.grey91,
  },
  iconSelectedContainer: {
    position: 'absolute',
    bottom: -35,
    right: -35,
    width: 70,
    height: 70,
    background: theme.palette.primary.main,
    transform: 'rotate(0.125turn)',
    zIndex: 90,
  },
  iconSelected: {
    position: 'absolute',
    bottom: 25,
    right: 45,
    width: 20,
    height: 20,
    transform: 'rotate(-0.125turn)',
    color: theme.pmt.colors.white,
  },
  extraInfos: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing(1),
  },
  tagCartModifier: {
    color: theme.palette.primary.main,
    background: theme.pmt.colors.greyEF,
    padding: theme.spacing(1),
    borderRadius: 5,
    marginTop: theme.spacing(1),
  },
  tagMenu: {
    padding: theme.spacing(1),
    background: theme.pmt.colors.greyEF,
    color: theme.pmt.colors.grey700,
    textTransform: 'uppercase',
    borderRadius: 5,
  },
  addOverlay: {
    position: 'absolute',
    top: theme.spacing(8),
    left: theme.spacing(10),
    width: 90,
    height: 90,
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    zIndex: 10,
  },
  addIcon: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
  },
})

const State = {
  DEFAULT: 'DEFAULT',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

const ItemCard = ({
  item,
  restaurant,
  category,
  nameType,
  openMenu,
  addToCartFromShortcut,
  onClick,
  onClickInfo,
  redirectTo,
  price,
  hasCartModifier,
  basePrice,
  additionalPrice,
  selectedLayout,
  disabledLayout,
  classes,
  ...otherProps
}) => (
  <StateMachine
    states={State}
    default={State.DEFAULT}
    transitions={[
      {
        state: State.ADDING_TO_CART,
        to: State.DEFAULT,
        duration: 1000,
      },
    ]}
  >
    {({ currentState, transitionTo }) => (
      <Card
        className={clsx(classes.root, {
          [classes.rootSelected]: selectedLayout,
          [classes.rootDisabled]: disabledLayout,
        })}
        {...otherProps}
        onClick={e => {
          if (onClick) {
            onClick(e)
          } else if (item.available) {
            if (item.isComplexItem) {
              if (item.isProduct) {
                EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                  item,
                  category,
                  restaurant,
                })
                redirectTo(getRoute('KIOSK__ORDER__PRODUCT'), {
                  productId: item.id,
                })
              } else if (item.isMenu) {
                EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
                  item,
                  category,
                  restaurant,
                })
                openMenu(item, {
                  restaurantId: restaurant.id,
                })
              }
            } else {
              if (!item.hasSuggestion) {
                EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
                  item,
                  category,
                  restaurant,
                })
              }
              transitionTo(State.ADDING_TO_CART)
              addToCartFromShortcut(item)
            }
          }
        }}
      >
        <CardContent className={classes.content}>
          {currentState === State.ADDING_TO_CART && (
            <TypographyCustom type="207" align="center" className={classes.addOverlay}>
              <NavigationCheck className={classes.addIcon} />
              {tr('global.order.added')}
            </TypographyCustom>
          )}
          <div
            className={clsx(classes.imageContainer, {
              [classes.placeholderContainer]: isEmpty(item.image),
            })}
          >
            {!isEmpty(item.image) ? (
              <div
                style={{
                  backgroundImage: `url('${!isEmpty(item.image) ? item.image : restaurant.logo}')`,
                }}
                className={classes.image}
              />
            ) : (
              <img
                src={restaurant.logo}
                className={clsx(classes.image, classes.placeholder)}
                alt={item.name}
              />
            )}
            {!item.available && (
              <TypographyCustom type="167" align="center" className={classes.unavailableLayout}>
                {tr('global.order.unavailable')}
              </TypographyCustom>
            )}
          </div>
          <div
            className={classes.iconInfoContainer}
            onClick={e => {
              if (onClickInfo) {
                onClickInfo(e)
              } else {
                e.stopPropagation()

                if (item.isProduct) {
                  EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
                    item,
                    category,
                    restaurant,
                  })
                  redirectTo(getRoute('KIOSK__ORDER__PRODUCT'), {
                    productId: item.id,
                  })
                } else if (item.isMenu) {
                  EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
                    item,
                    category,
                    restaurant,
                  })
                  openMenu(item, {
                    restaurantId: restaurant.id,
                  })
                }
              }
            }}
          >
            <ActionInfo className={classes.iconInfo} />
          </div>
          {selectedLayout && (
            <div className={classes.iconSelectedContainer}>
              <NavigationCheck className={classes.iconSelected} />
            </div>
          )}
          {(item.isMenu || item.hasCartModifier) && (
            <div className={classes.extraInfos}>
              {item.isMenu && (
                <TypographyCustom type="107" className={classes.tagMenu}>
                  {tr('global.order.menu')}
                </TypographyCustom>
              )}
              {item.hasCartModifier &&
                item.cartModifiers.list.map((cartModifier, index) => (
                  <React.Fragment key={index}>
                    <TypographyCustom type="107" className={classes.tagCartModifier}>
                      {cartModifier.tag}
                    </TypographyCustom>
                  </React.Fragment>
                ))}
            </div>
          )}
          <div
            className={clsx(classes.textContainer, {
              [classes.textContainerUnavailable]: !item.available,
            })}
          >
            <TypographyCustom type={nameType || '327'} align="center" className={classes.name}>
              {item.name}
            </TypographyCustom>
            <div className={classes.price}>
              {price && (
                <TypographyCustom component="div" type="287" className={classes.priceContainer}>
                  <Price value={price} hasCartModifier={hasCartModifier} baseValue={basePrice} />
                </TypographyCustom>
              )}
            </div>
            {additionalPrice && (
              <TypographyCustom type="287" className={classes.priceContainer}>
                <Price value={`+${additionalPrice}`} classes={{ finalValue: classes.price }} />
              </TypographyCustom>
            )}
          </div>
        </CardContent>
      </Card>
    )}
  </StateMachine>
)

ItemCard.defaultProps = {
  // used to display a check when item is selected (menu context)
  selectedLayout: false,
  // used to show a disabled item
  disabledLayout: false,
}

const mapStateToProps = () => ({})

export default compose(
  connect(mapStateToProps, {
    addToCartFromShortcut,
    openMenu,
    redirectTo,
  }),
  withStyles(styles)
)(ItemCard)
