import moment from 'moment'
import isEmpty from 'lodash/isEmpty'

import { Peripherals } from 'pmt-modules/kioskInteractor/constants'

import { existsOnArray } from 'pmt-utils/array'
import { getAmountInCents } from 'pmt-utils/currency'

class Local {
  /* defined as long as kioskPage INACTIVITY_TIMEOUT to avoid conflicts */
  static SCAN_DURATION = 10
  static READ_DURATION = 10

  static PaymentError = {
    PERIPHERAL_NOT_CONFIGURED: 'PERIPHERAL_NOT_CONFIGURED',
    PERIPHERAL_BUSY: 'PERIPHERAL_BUSY',
    PERIPHERAL_DEAD_LOCK: 'PERIPHERAL_DEAD_LOCK',
    INVALID_AMOUNT: 'INVALID_AMOUNT',
    USER_CANCELLED_TRANSACTION: 'USER_CANCELLED_TRANSACTION',
    UNKNOWN: 'UNKNOWN',
  }

  static AUTHORIZED_APPS = ['fr.paymytable.mag-card-reader']

  /**
   * since Local scan is for a fixed duration, we keep listening to the scanner while
   * isScanning is set to true, until scanStop is called
   * If we receive a message from Local scanner while isScanning is set to false, we ignore it.
   */
  isScanning = false

  /**
   * since Local NFCReader is for a fixed duration, we keep listening to the NFCReader while
   * isNFCReading is set to true, until nfcStop is called
   * If we receive a message from Local NFC reader while isNFCReading is set to false, we ignore it.
   */
  isNFCReading = false

  /**
   * Peripherals should always be formatted like this
   * peripheralType : {
   *   status: bool,
   *   details,
   * }
   * peripheral type are these (to be completed) : see Peripherals constants
   *  - nfc
   *  - payment
   *  - printer
   *  - scanner
   */
  static formatPeripherals() {
    return {
      [Peripherals.NFC]: {
        status: true,
        details: {},
      },
      [Peripherals.PAYMENT]: {
        status: true,
        details: {},
      },
      [Peripherals.PRINTER]: {
        status: true,
        details: {},
      },
      [Peripherals.SCANNER]: {
        status: true,
        details: {},
      },
    }
  }

  static isAuthorizedApp(identifier) {
    return existsOnArray(Local.AUTHORIZED_APPS, v => v === identifier)
  }

  /**
   * Returns the list of peripheral set on current kiosk according to current kiosk profile.
   * Retrieves the current status of all peripherals as it is known by Core. This call is NOT time
   * consuming as the states are retrieved from the memory.
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#peripherals-status-details
   */
  getPeripheralsStatus() {
    return new Promise((resolve, reject) => {
      resolve(Local.formatPeripherals())
    })
  }

  /**
   * To see if the JS SDK is correctly initialized you can check it with the following code
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#initialization
   */
  isConnected() {
    return new Promise((resolve, reject) => {
      resolve({
        isConnected: true,
      })
    })
  }

  /**
   * Performs a print action based on the provided TAG content. TAG content must be in the Local
   * TAG printer language format (detailed on specific document).
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#print-tag-content
   */
  print(content) {
    return new Promise((resolve, reject) => {
      console.log(content)
      resolve({
        printed: true,
      })
    })
  }

  /**
   * Send command to register an Apps to the local network.
   * An App can register under a Context that is optionally.
   * To have an App available on the network and found through the discover search, call discover
   * method, it has to register itself as discoverable through the register call.
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#register
   */
  register() {
    return new Promise((resolve, reject) => {
      resolve({
        isRegistered: true,
      })
    })
  }

  /**
   * Performs a scan action for a maximum number of seconds.
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#scan
   */
  scan() {
    return new Promise((resolve, reject) => {
      this.isScanning = true

      /**
       * Since Local scan listen for a fixed duration, we need to re-run the Local scan after
       * the end of the duration if we still are in scanning mode (isScanning set to true).
       */
      const runScan = () => {
        if (this.isScanning) {
          const result = ''
          if (!isEmpty(result)) {
            resolve({
              scannedData: result,
            })
          } else {
            // Local scan duration ended (timeout), we re-run the scan
            runScan()
          }
        }
      }

      runScan()
    })
  }

  /**
   * Stop scanning state
   */
  scanStop() {
    this.isScanning = false
  }

  /**
   * Performs a nfc read action for a maximum number of seconds.
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#nfcreader
   */
  nfcRead() {
    return new Promise((resolve, reject) => {
      this.isNFCReading = true

      /**
       * since Local NFCReader is for a fixed duration, we need to re-run the Local read after
       * the end of the duration if we still are in reading mode (isNFCReading set to true).
       */
      const runNfcRead = () => {
        // If we receive a message from Local scanner while isNFCReading is set to false, ignore it
        const result = ''
        if (this.isNFCReading) {
          if (!isEmpty(result)) {
            resolve({
              readData: result,
            })
          } else {
            // Local scan duration ended (timeout), we re-run the scan
            runNfcRead()
          }
        }
      }

      runNfcRead()
    })
  }

  /**
   * Stop reading state
   */
  nfcStop() {
    this.isNFCReading = false
  }

  /**
   * Triggers when a new message is received from local network
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#sharingonnewmessage
   */
  onNewMessage(success) {
    const data = {}
    // console.table(data)
    const message = {
      source: {
        // ip: data.Source.EntityIp,
        // identifier: data.Source.AppIdentifier,
        // context: data.Source.Context,
      },
      // message: data.Message,
      receptionData: moment().format(),
      rawData: data,
    }
    success(message)
  }

  /**
   * Send command to perform a payment transaction.
   * The App must have access to payment peripheral to be able to perform payment attempts.
   * During the pay call, the App may receive payment progress callbacks.
   * https://developer.Local.com/resources/sdk/jssdk/js_sdk_3.0.0.html#pay
   */
  pay(amount, options) {
    const amountInCents = getAmountInCents(amount)

    return new Promise((resolve, reject) => {
      resolve({
        amountPaid: amountInCents, //res.PaidAmount,
        // tenderMediaId: res.TenderMediaId,
        // tenderMediaDetails: res.TenderMediaDetails,
        // hasClientReceipt: res.HasClientReceipt,
        // hasMerchantReceipt: res.HasMerchantReceipt,
      })
    })
  }
}

const LocalInteractor = new Local()

export default LocalInteractor
