import {
  createApiCallAction,
  createApiEnumAction,
} from '../api/utils'

import RestaurantUserAccountApi from '../api/calls/RestaurantUserAccountApi'
import { GENERATED_API_ERROR } from '../api/constants'

export const FetchRestaurantUserAccountsAction = createApiEnumAction('RESTAURANT::USER_ACCOUNTS::GET')

export const fetchRestaurantUserAccounts = (restaurantId, query, options) => createApiCallAction(
  FetchRestaurantUserAccountsAction,
  RestaurantUserAccountApi.getUserAccounts(restaurantId, query),
  {
    restaurantId,
    query,
    options,
  }
)

/**
 * Dispatched on fetchRestaurantUserAccountSuccessMiddleware
 */
export const fetchRestaurantUserAccountsFailure = () => ({
  type: FetchRestaurantUserAccountsAction.FAILURE,
  error: {
    code: GENERATED_API_ERROR,
    message: `Le compte utilisateur n'a pas pu être reconnu. Merci de réessayer.`,
  },
})
