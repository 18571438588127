import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isNull from 'lodash/isNull'

import {
  fetchRestaurantUserAccounts,
} from '../../actions'

import {
  getDataUserAccounts,
  isFetchingUserAccounts,
} from '../../selectors'

class RestaurantUserAccountsContainer extends React.PureComponent {

  constructor(props) {
    super(props)

    if (isNull(props.userAccounts) || props.forceUpdate) {
      this.loadRestaurantUserAccounts(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (nextProps.userAccountId !== props.userAccountId) {
      this.loadRestaurantUserAccounts(nextProps)
    }
  }

  loadRestaurantUserAccounts(props) {
    if (!props.isFetchingUserAccounts) {
      props.fetchRestaurantUserAccounts(
        props.restaurantId,
        {
          userAccountId: props.userAccountId,
        }
      )
    }
  }

  render() {
    const {
      isFetchingUserAccounts,
      userAccounts,
      userAccountId,
      children,
      ...otherProps
    } = this.props

    return children({
      isFetchingUserAccounts,
      userAccounts,
      // in most cases, a user only have one user account
      userAccount: userAccounts && userAccounts.length > 0 ? userAccounts[0] : null,
      userAccountId,
      ...otherProps
    })
  }
}

RestaurantUserAccountsContainer.propTypes = {
  forceUpdate: PropTypes.bool,
  fetchRestaurantUserAccounts: PropTypes.func.isRequired,
  isFetchingUserAccounts: PropTypes.bool,
  userAccounts: PropTypes.array,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  return {
    userAccounts: getDataUserAccounts(state, props),
    isFetchingUserAccounts: isFetchingUserAccounts(state, props),
  }
}

export default connect(mapStateToProps, {
  fetchRestaurantUserAccounts,
})(RestaurantUserAccountsContainer)
