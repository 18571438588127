import React from 'react'

import ArrowDown from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'
import { withStyles } from 'pmt-ui/styles'

import './style.scss'

const styles = theme => ({
  arrow: {
    width: 150,
    height: 150,
    color: theme.palette.primary.main,
  },
})

const ArrowBounce = ({ classes }) => (
  <div
    className="arrow_bounce"
  >
    <div className="arrow bounce">
      <ArrowDown
        className={classes.arrow}
      />
    </div>
    <div className="arrow arrow2 bounce">
      <ArrowDown
        className={classes.arrow}
      />
    </div>
  </div>
)

export default withStyles(styles)(ArrowBounce)
