import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { createMiddleware } from '../redux'

import { redirectTo } from 'pmt-modules/routing'

import {
  FetchRestaurantUserAccountsAction,
  fetchRestaurantUserAccountsFailure,
} from './actions'

/**
 * Api returns an empty array if no user account is found.
 * We want to consider no account found as an error, so we dispatch a failure
 */
const fetchRestaurantUserAccountSuccessMiddleware = createMiddleware(
  FetchRestaurantUserAccountsAction.SUCCESS,
  ({ dispatch, action, next }) => {
    if (isEmpty(action.response)) {
      return next(fetchRestaurantUserAccountsFailure())
    } else {
      if (action.data.options && action.data.options.redirectTo && !isNil(action.response[0])) {
        dispatch(redirectTo(
          action.data.options.redirectTo,
          {
            userAccountId: action.response[0].id
          }
        ))
      }
    }
  }
)

export const restaurantUserAccountMiddlewares =  [
  fetchRestaurantUserAccountSuccessMiddleware,
]
