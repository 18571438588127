import React from 'react'

import AlertError from 'pmt-ui/svg-icons/alert/error'
import Button from 'pmt-ui/Button'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import HardwareTpe from 'pmt-ui/svg-icons/hardware/tpe'
import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import ArrowBounce from '../../../components/ArrowBounce'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  tpe: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(20),
    width: 600,
    height: 600,
  },
  useLabel: {
    marginTop: theme.spacing(20),
    fontWeight: 700,
    width: '50%',
    marginLeft: '25%',
  },
  button: {
    minWidth: 340,
    fontSize: 40,
    padding: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  errorTitle: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
})

const View = ({ classes, onCancel, onErrorRetry, paymentError, restaurant }) => (
  <div className={classes.root}>
    {paymentError && (
      <ErrorBlock
        mode={ErrorBlock.Mode.DIALOG}
        error={paymentError}
        options={{
          title: (
            <Typography
              variant="title"
              color="primary"
              classes={{
                root: classes.errorTitle,
              }}
            >
              <AlertError /> Erreur lors du paiement
            </Typography>
          ),
          actions: (
            <div>
              <Button onClick={onCancel} color="primary">
                Annuler
              </Button>
              <Button onClick={onErrorRetry} color="primary" variant="contained" autoFocus>
                Réessayer
              </Button>
            </div>
          ),
        }}
      />
    )}

    <img src={restaurant.logo} alt={restaurant.name} />

    <Typography variant="display3">Paiement</Typography>

    <HardwareTpe className={classes.tpe} />

    <Typography variant="display2" className={classes.useLabel}>
      Utilisez le lecteur CB pour finaliser la transaction
    </Typography>

    <ArrowBounce />
  </div>
)

export default withStyles(styles)(View)
