import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import forEach from 'lodash/forEach'

import { editItem } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { getOrderPreview } from 'pmt-modules/orderPreview'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import LoyaltyIcon from '@material-ui/icons/Loyalty'

import CartModifierTag from 'app/components/CartModifierTag'
import Chip from 'app/components/Chip'
import { existsOnArray } from 'pmt-utils/array'

import ProductContent from './ProductContent'

const styles = theme => ({
  nameContainer: {
    flex: 1,
    margin: `0 ${theme.spacing(4)}px`,
  },
  itemName: {
    marginBottom: theme.spacing(1),
    lineHeight: '44px', // align with quantity buttons
  },
  productName: {
    color: theme.pmt.colors.grey91,
    marginBottom: theme.spacing(1),
  },
  cartModifierContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  cartModifierChip: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: 4,
  },
  loyalty: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
})

const ItemName = ({ classes, item, editItem, fromRoute, restaurant, withEdit, orderPreview }) => {
  const modifiersFromCart = orderPreview?.modifiers || []

  let hasComplexModifier = false
  forEach(item.modifierIds, modifierId => {
    if (existsOnArray(modifiersFromCart, ({ id, isComplex }) => id === modifierId && isComplex)) {
      hasComplexModifier = true
    }
  })

  return (
    <div
      className={classes.nameContainer}
      onClick={() => {
        if (withEdit) {
          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DETAIL, {
            item,
          })
          editItem(item, {
            restaurantId: restaurant.id,
            fromRoute,
          })
        }
      }}
    >
      <TypographyCustom type="244" className={classes.itemName}>
        {hasComplexModifier && <LoyaltyIcon className={classes.loyalty} />}
        {item.name}
      </TypographyCustom>
      <React.Fragment>
        {item.isProduct && <ProductContent product={item} />}
        {item.isMenu && (
          /* menu products */
          <TypographyCustom component="div" type="244">
            {item.parts.reduce((products, part) => {
              const partProducts = part.selectedProducts.map((product, index) => (
                <div key={index}>
                  <div className={classes.productName}>{product.name}</div>
                  <ProductContent fromMenu product={product} />
                </div>
              ))

              products.push(partProducts)
              return products
            }, [])}
          </TypographyCustom>
        )}
        {item.hasCartModifier && (
          <div className={classes.cartModifierContainer}>
            {item.cartModifiers.list.map((cartModifier, index) => (
              <CartModifierTag
                cartModifier={cartModifier}
                chipElement={
                  <Chip
                    key={index}
                    label={cartModifier.tag}
                    classes={{ root: classes.cartModifierChip }}
                  />
                }
                key={index}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    </div>
  )
}

const mapStateToProps = state => ({
  orderPreview: getOrderPreview(state),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editItem,
  })
)(ItemName)
