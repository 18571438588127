import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import NavigationChevronRight from 'pmt-ui/svg-icons/navigation/chevron-right'

const styles = theme => ({
  root: {
    width: 250,
    margin: `0 ${theme.spacing(1) + theme.spacing(0.5)}px ${theme.spacing(4)}px`,
    boxShadow: '2px 2px 4px 0px rgba(158,158,158,1)',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 !important',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  image: {},
  unavailableLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,0.6)',
  },
  unavailableText: {
    color: theme.pmt.colors.white,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    textAlign: 'center',
  },
  unavailableTextContainer: {
    opacity: 0.5,
  },
  name: {
    flex: 1,
  },
  placeholder: {
    opacity: 0.3,
  },
  link: {
    display: 'inline-block',
    margin: '0 auto',
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    borderRadius: 5,
    textAlign: 'center',
  },
  icon: {
    width: 16,
    height: 16,
    marginBottom: -4,
  },
})

const CategoryCard = ({
  category,
  restaurant,
  nameType,
  displayLink,
  onClick,
  classes,
  ...otherProps
}) => (
  <Card
    className={classes.root}
    {...otherProps}
    onClick={(e) => {
      e.stopPropagation()
      if (category.available) {
        onClick(category)
      }
    }}
  >
    <CardContent className={classes.content}>
      <div className={classes.imageContainer}>
        <img
          src={!isEmpty(category.image) ?
            category.image : restaurant.logo
          }
          alt={category.name}
          className={classNames(classes.image, {
            [classes.placeholder]: isEmpty(category.image),
          })}
        />
        {!category.available && displayLink && (
          <div className={classes.unavailableLayout}>
            <TypographyCustom type="167" align="center" className={classes.unavailableText}>
              {tr('global.order.unavailable')}
            </TypographyCustom>
          </div>
        )}
      </div>
      <div className={classNames(classes.textContainer, {
        [classes.unavailableTextContainer]: !category.available && displayLink,
      })}>
        <TypographyCustom type={nameType || "327"} align="center" className={classes.name}>
          {category.name}
        </TypographyCustom>
        {displayLink && (
          <TypographyCustom
            type="167"
            className={classes.link}
          >
            {tr('kiosk.order.page.category.category.more')} <NavigationChevronRight className={classes.icon} />
          </TypographyCustom>
        )}
      </div>
    </CardContent>
  </Card>
)

export default withStyles(styles)(CategoryCard)
