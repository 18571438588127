import ApiEndpoints from '../config/ApiEndpoints'

const KioskApi = {
  getKioskSettings: restaurantId => {
    return {
      type: 'GET',
      params: {
        restaurantId,
      },
      endpoint: ApiEndpoints.KIOSK_SETTINGS,
    }
  },
}

export default KioskApi
