import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classnames from 'classnames'
import indexOf from 'lodash/indexOf'

import { OrderMode } from 'pmt-modules/order'

import Button, { ButtonLoading } from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import CardLanguages from '../../components/CardLanguages'
import GlobalErrorBlock from '../../components/GlobalErrorBlock'

const styles = theme => ({
  root: {
    position: 'relative',
    background: theme.palette.primary.main,
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  darkLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.25,
    zIndex: 1,
  },
  overContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  logo: {
    alignItem: 'center',
  },
  logoPicture: {
    margin: '270px auto 164px',
    width: 'auto',
    maxHeight: 450,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    width: theme.spacing(81),
    background: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:disabled': {
      backgroundColor: theme.pmt.colors.greyEF,
    },
    color: theme.palette.primary.main,
    lineHeight: '42px',
    height: theme.spacing(20),
    marginBottom: theme.spacing(9),
  },
  languages: {
    position: 'absolute',
    bottom: theme.spacing(9),
  },
  lowercase: {
    textTransform: 'lowercase',
  },
})

const View = ({
  catalogError,
  classes,
  frontAppConfig,
  isFetchingRestaurant,
  kioskPeripheralsStatus,
  kioskSettings,
  restaurant,
  onClickOrder,
  onClickTopUp,
}) => (
  <div
    className={classes.root}
    style={{
      backgroundImage: `url(${kioskSettings.order.backgroundImage})`,
    }}
  >
    <div className={classes.darkLayout} />
    <div className={classes.overContainer}>
      <GlobalErrorBlock
        frontAppConfig={frontAppConfig}
        catalogError={catalogError}
        kioskPeripheralsStatus={kioskPeripheralsStatus}
        kioskSettings={kioskSettings}
        restaurant={restaurant}
      />

      {frontAppConfig.theme.logo.display && (
        <div className={classes.logo}>
          <img
            className={classes.logoPicture}
            src={frontAppConfig.theme.logo.path || restaurant.logo}
            alt={restaurant.name}
          />
        </div>
      )}

      <div
        className={classnames(
          classes.buttonsContainer,
          !frontAppConfig.theme.logo.display && 'u-flex1'
        )}
      >
        <ButtonLoading
          isLoading={isFetchingRestaurant}
          variant="contained"
          className={classes.buttons}
          onClick={onClickOrder}
        >
          <TypographyCustom align="center" type="407" skipColor>
            {tr('global.order.order')}
            <br />
            <span className={classes.lowercase}>
              {kioskSettings.order.modes.length > 0 && (
                <React.Fragment>
                  {indexOf(kioskSettings.order.modes, OrderMode.ON_SITE) !== -1 &&
                    tr('kiosk.home.order.mode.0')}
                  {kioskSettings.order.modes.length > 1 && ` ${tr('kiosk.home.order.mode.or')} `}
                  {indexOf(kioskSettings.order.modes, OrderMode.TAKE_AWAY) !== -1 &&
                    tr('kiosk.home.order.mode.1')}
                </React.Fragment>
              )}
            </span>
          </TypographyCustom>
        </ButtonLoading>
        <Button
          variant="contained"
          className={classes.buttons}
          onClick={onClickTopUp}
          disabled={!kioskPeripheralsStatus || !kioskPeripheralsStatus.payment.status}
        >
          <TypographyCustom align="center" type="407" skipColor>
            {tr('kiosk.home.top-up')}
          </TypographyCustom>
        </Button>
      </div>

      <CardLanguages className={classes.languages} locales={kioskSettings.order.locales} />
    </div>
  </div>
)

export default withStyles(styles)(View)
