import React from 'react'
import { connect } from 'react-redux'

import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo } from 'pmt-modules/routing'

import KioskPage from '../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
class ErrorPage extends React.PureComponent {
  render() {
    const { restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={false}>
        <View restaurant={restaurant} />
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default connect(mapStateToProps, {
  redirectTo,
})(ErrorPage)
