import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import values from 'lodash/values'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import {
  addOrderMenuToCart,
  addOrderProductToCart,
  addToCartFromShortcut,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { hideSuggestionDialog } from 'pmt-modules/suggestion'
import { openProduct } from 'pmt-modules/orderProduct'
import {
  openMenu,
  isMenuAutofillable,
  getFilledMenu,
  createOrderMenuFromMenu,
} from 'pmt-modules/orderMenu'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { SuggestionActionType, getSuggestion } from 'pmt-modules/suggestion'

import Button from 'pmt-ui/Button'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'

import ItemCard from '../../../../components/ItemCard'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    color: theme.palette.primary.main,
    padding: theme.spacing(6, 5),
  },
  dialogContent: {
    // here to hide the scroll when it is "displayed"
    width: 'calc(100% + 24px)',
    marginLeft: '-12px',
    padding: theme.spacing(0, 5),
  },
  dialogActions: {
    cursor: 'pointer',
    justifyContent: 'center',
    margin: 0,
    padding: theme.spacing(6, 5),
    zIndex: 1,
  },
  actionTypeContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
  },
  actionTypeLabel: {
    flex: '100% 0 0',
    marginBottom: theme.spacing(3),
    '&>:first-child': {
      fontWeight: 700,
    },
  },
  noThanks: {
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'inherit',
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '&:last-child': {
      marginBottom: 0,
    },
    '& $itemCardRoot:nth-child(3n + 1)': {
      marginLeft: 0,
    },
    '& $itemCardRoot:nth-child(3n)': {
      marginRight: 0,
    },
  },
  itemCardRoot: {
    width: 250,
    height: 230,
  },
  itemCardName: {
    minHeight: 45,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '22px',
    color: theme.pmt.colors.grey26,
  },
  priceContainerSamePrice: {
    fontSize: 24,
  },
})

/**
 * https://paymytable.atlassian.net/browse/PP-698
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b50951b94d8f6ef1ab316bc
 */
@withRestaurant
class SuggestionDialog extends React.PureComponent {
  getItemSuggestedPrice(item, actionType) {
    let price = ''

    if (this.isSamePrice(item, actionType)) {
      price = tr('kiosk.order.page.suggestions.same_price')
      return price
    }

    if (actionType === SuggestionActionType.REPLACE) {
      price = `${item.suggestedPrice > 0 ? '+' : ''}`
    }

    price += item.suggestedPriceFormatted

    return price
  }

  isSamePrice = (item, actionType) =>
    item.suggestedPrice === 0 && actionType === SuggestionActionType.REPLACE

  handleClickItemCard = (item, actionType, options = {}) => {
    if (item.available) {
      if (actionType === SuggestionActionType.COMPOSE) {
        this.handleHideDialogAndAddItem()
      } else {
        this.props.hideSuggestionDialog()
      }

      if (item.isComplexItem || options.isInfo) {
        this.handleOpenItem(item, actionType, {
          restaurantId: this.props.restaurant.id,
          skipSuggestion: true,
        })
      } else {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          item,
          restaurant: this.props.restaurant,
        })
        this.props.addToCartFromShortcut(item)
      }
    }
  }

  handleOpenItem = (item, actionType, options) => {
    const {
      addOrderMenuToCart,
      setSuspendedDatas,
      hideSuggestionDialog,
      openMenu,
      openProduct,
      restaurant,
    } = this.props

    // we set suspended item here so we can add it if the user cancel on product/menu view
    setSuspendedDatas(this.props.orderItem, actionType)
    hideSuggestionDialog()

    if (item.isProduct) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_DETAIL, {
        item,
        restaurant,
      })
      openProduct(item, options)
    } else if (item.isMenu) {
      if (actionType === SuggestionActionType.REPLACE && isMenuAutofillable(item)) {
        const filledMenu =
          item.isOrderMenu === true
            ? getFilledMenu(item)
            : getFilledMenu(createOrderMenuFromMenu(item))

        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
          item,
          restaurant,
        })
        addOrderMenuToCart(filledMenu, { skipSuggestion: true })
        setSuspendedDatas(null, null)
      } else {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_DETAIL, {
          item,
          restaurant,
        })
        openMenu(item, options)
      }
    }
  }

  handleHideDialogAndAddItem = () => {
    this.props.hideSuggestionDialog()

    if (this.props.orderItem.isProduct) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderProductToCart(this.props.orderItem, { skipSuggestion: true })
    } else if (this.props.orderItem.isMenu) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderMenuToCart(this.props.orderItem, { skipSuggestion: true })
    }
  }

  getItemsByActionType = (suggestions, actionType) =>
    suggestions.filter(({ item, action }) => action === actionType && item.available)

  render() {
    const { classes, orderItem, restaurant } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <TypographyCustom type="327" skipColor align="center">
            {!isNil(orderItem.suggestedIntroduction) && !isEmpty(orderItem.suggestedIntroduction)
              ? orderItem.suggestedIntroduction
              : tr('kiosk.order.page.suggestions')}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {values(SuggestionActionType).map(actionType => {
            const items = this.getItemsByActionType(orderItem.suggestions, actionType)
            return (
              items.length > 0 && (
                <Grid
                  spacing={0}
                  key={`actionType-${actionType}-label`}
                  container
                  className={classes.actionTypeContainer}
                  justify="center"
                >
                  <TypographyCustom type={244} className={classes.actionTypeLabel} align="center">
                    <React.Fragment>
                      <span>{tr(`kiosk.order.page.suggestions.action_type.${actionType}`)}</span>
                      &nbsp;
                      {tr(`kiosk.order.page.suggestions.action_type.your_by.${actionType}`, {
                        name: orderItem.name,
                      })}
                    </React.Fragment>
                  </TypographyCustom>
                  <div className={classes.cardContainer}>
                    {items.map(({ item }) => (
                      <ItemCard
                        key={`item-${item.id}`}
                        item={item}
                        restaurant={restaurant}
                        nameType="204"
                        price={this.getItemSuggestedPrice(item, actionType)}
                        classes={{
                          root: classes.itemCardRoot,
                          name: classes.itemCardName,
                          priceContainer: classNames({
                            [classes.priceContainerSamePrice]: this.isSamePrice(item, actionType),
                          }), // if is same price
                        }}
                        onClickInfo={e => {
                          e.stopPropagation()
                          this.handleClickItemCard(item, actionType, { isInfo: true })
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          this.handleClickItemCard(item, actionType)
                        }}
                      />
                    ))}
                  </div>
                </Grid>
              )
            )
          })}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleHideDialogAndAddItem}
            className={classes.noThanks}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.page.suggestions.no_thanks')}
            </TypographyCustom>
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
SuggestionDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

SuggestionDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 900,
    overflowX: 'hidden',
  },
})

const mapStateToProps = (state, props) => ({
  orderItem: getSuggestion(state, props),
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    setSuspendedDatas,
    hideSuggestionDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
  })
)(SuggestionDialog)
