import { getAsyncPaymentBrowserInfo } from 'pmt-utils/browser'
import { createFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { formatItemsMap } from './item'

// /!\ We format UserCheck here not really a transaction/payment.

const formatAmount = payment => {
  payment.amount = Number(payment.amount || 0)
  payment.amountFormatted = formatPriceWithCurrency(payment.amount)

  if (!payment.tips && !payment.tip) {
    payment.tips = 0
  }
  if (!payment.tips) {
    payment.tips = payment.tip || 0
  } else {
    payment.tip = payment.tips || 0
  }
  payment.totalWithTipFormatted = formatPriceWithCurrency(payment.amount + payment.tips)

  if (payment.share) {
    payment.share.amountPerPersonFormatted = formatPriceWithCurrency(payment.share.amountPerPerson)
  }

  payment.paymentType = payment.type

  return payment
}

/**
 * Data neeeded for 3DS v2
 *
 * https://www.notion.so/paymytable/Mangopay-3DS2-543d705dd4534a908b3dc1a64882e7a6.
 */
const formatBrowserInfo = data => ({
  ...data,
  browserInfo: getAsyncPaymentBrowserInfo(),
})

export const formatPayment = createFormatter(formatAmount, formatItemsMap, formatBrowserInfo)

export const formatPaymentMade = createFormatter(paymentMade => {
  return {
    ...paymentMade,
    totalWithTipFormatted: formatPriceWithCurrency(paymentMade.amount + paymentMade.tips),
  }
})
