import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { selectMode } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
class OrderModePage extends React.PureComponent {
  handleOnSelectMode = mode => {
    EventManager.dispatch(EventManager.Events.ON_MODE_SELECT, {
      mode,
    })
    this.props.selectMode(mode)
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATEGORY'))
  }

  render() {
    const { frontAppConfig, route, restaurant } = this.props
    return (
      <KioskPage route={route} isFetching={!restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            restaurant={restaurant}
            kioskSettings={kioskSettings}
            handleOnSelectMode={this.handleOnSelectMode}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authRestaurantId: getRestaurantId(state),
})

export default connect(mapStateToProps, {
  redirectTo,
  selectMode,
})(OrderModePage)
