import React from 'react'
import { matchPath } from 'react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getFrontSettings } from 'pmt-modules/appConfig'
import {
  getInactivityTimeoutInstance,
  getIsListeningInactivity,
  setInactivityTimeout,
  showInactivityDialog,
} from 'pmt-modules/kiosk'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import { getInactivityTimeout } from './constants'

class InactivityContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (props.inactivityTimeout === null && !props.isListeningInactivity) {
      document.addEventListener('touchstart', this.setInactivityTimeout)
    }
  }

  setInactivityTimeout = () => {
    this.props.setInactivityTimeout(() => {
      const routes = [
        getRoute('LOGIN'),
        getRoute('KIOSK__ERROR'),
        getRoute('KIOSK__TOP_UP__PAYMENT'),
        getRoute('KIOSK__ORDER__PAYMENT__MODE'),
        getRoute('KIOSK__ORDER__PAYMENT__CREDIT_CARD'),
        getRoute('KIOSK__ORDER__POST__ERROR'),
        getRoute('KIOSK__ORDER__CONFIRM'),
        getRoute('KIOSK__CHECKOUT__PAYMENT'),
        getRoute('KIOSK__CHECKOUT__CONFIRM'),
        getRoute(this.props.frontSettings.home),
      ]

      let hasMatched = false
      routes.forEach(route => {
        if (
          !isNil(
            matchPath(window.location.pathname, {
              path: route.path,
              exact: true,
              strict: false,
            })
          )
        ) {
          hasMatched = true
        }
      })

      // we don't want to redirect user while he's paying
      // payment timeout will be handled by kiosk payment system
      if (!hasMatched) {
        this.props.showInactivityDialog()
      }
    }, getInactivityTimeout())
  }

  render() {
    return null
  }
}

const mapStateToProps = state => ({
  frontSettings: getFrontSettings(state),
  inactivityTimeout: getInactivityTimeoutInstance(state),
  isListeningInactivity: getIsListeningInactivity(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      redirectTo,
      setInactivityTimeout,
      showInactivityDialog,
    }
  )
)(InactivityContainer)
