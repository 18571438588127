import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { getItemListFromCart } from 'pmt-modules/cart'
import { refreshCatalog } from 'pmt-modules/catalog'
import { displayCartDiffItemsDialog } from 'pmt-modules/dialog'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { PostOrderPreviewAction } from 'pmt-modules/orderPreview'
import { createMiddleware } from 'pmt-modules/redux'

import { findOnArray } from 'pmt-utils/array'

import { setCartDiffItemList } from './actions'

const isItemUnavailable = (item, errorList) => {
  const error = findOnArray(errorList, errorItem => errorItem.itemId === item.id)
  if (!isNull(error)) {
    item.unavailabilityReasons = error.reasons
    return true
  }

  // options values
  let isOptionValueUnavailable = false
  forEach(item.optionsSelected, option => {
    forEach(option.values, value => {
      if (isItemUnavailable(value, errorList)) {
        isOptionValueUnavailable = true
      }
    })
  })

  return isOptionValueUnavailable
}

// checking new cart from order preview
// if an item is missing, we warn the user with the dialog
// and refreshing the catalog in background
const postOrderPreviewSuccessMiddleware = createMiddleware(
  PostOrderPreviewAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const availability = action.response.verifications.availability
    if (!availability.succeeded) {
      const itemListFromCart = getItemListFromCart(getState())
      const errorList = availability.errorList
      const unavailableItems = itemListFromCart.filter(cartItem => {
        // menu
        let isMenuUnavailable = false
        if (cartItem.isMenu) {
          forEach(cartItem.selectedProducts, item => {
            if (isItemUnavailable(item, errorList)) {
              isMenuUnavailable = true
            }
          })

          return isMenuUnavailable
        }

        return isItemUnavailable(cartItem, errorList)
      })

      dispatch(setCartDiffItemList(unavailableItems))

      if (!isEmpty(unavailableItems)) {
        dispatch(displayCartDiffItemsDialog())

        const restaurantId = action.response.order.restaurantId
        const { dueDate, locale, mode } = getOrderProperties(getState())
        dispatch(
          refreshCatalog(restaurantId, dueDate, mode, {
            locale,
          })
        )
      }
    }
  }
)

export const cartDiffMiddlewares = [postOrderPreviewSuccessMiddleware]
