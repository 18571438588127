import ApiEndpoints from '../config/ApiEndpoints'
import PaymentErrorsList from '../errors/PaymentErrorsList'

const AsyncPaymentApi = {
  /**
   * Retrieve a payment and ask to update its status.
   * This call is made after an async payment is made.
   * options authorization is used for user light calls
   */
  getAsyncPayment: (paymentId, options = {}, retry) => {
    let headers = {}
    if (options.authorization) {
      headers.authorization = options.authorization
    }

    return {
      endpoint: ApiEndpoints.GET_ASYNC_PAYMENT,
      type: 'GET',
      params: {
        paymentId,
      },
      query: {
        retry,
      },
      headers,
      errorHandler: PaymentErrorsList,
    }
  },
}

export default AsyncPaymentApi
