import isEmpty from 'lodash/isEmpty'

import { formatPrice } from 'pmt-utils/currency'

import Printer from './'

class TopUpTicketPrinter extends Printer {
  format(restaurant, topUpAmount, userAccount, options = {}) {
    this.builder.addText(`Rechargement`, { bold: true, align: 'center', size: 3 })
    this.builder.addFeedLine()

    this.renderRestaurantInfo(restaurant)

    this.renderUserInfo(userAccount)

    this.renderTopUpInfo(topUpAmount, userAccount)

    if (!isEmpty(options.kioskId)) {
      this.builder.addFeedLine(1)
      this.builder.addText(`Kiosk N° ${options.kioskId}`)
    }

    this.builder.addFeedLine(1)
    this.builder.addText(`Mode : Carte bancaire`)
    if (!isEmpty(options.id)) {
      this.builder.addText(`Transaction N° ${options.id}`)
    }
    this.builder.addText(`Id : ${userAccount.id}_${userAccount.user.id}`)

    this.renderPayment()

    this.builder.addFeedLine(3)
    this.builder.addCut()

    const message = this.builder.toString()

    console.log(message)
    return message
  }

  renderUserInfo(userAccount) {
    this.builder.addText(`${userAccount.user.fullName}`, { bold: true })
    this.builder.addFeedLine()
  }

  renderTopUpInfo(topUpAmount, userAccount) {
    let amountBefore = formatPrice(userAccount.amount)
    let amountBeforeLabel = 'Solde avant le rechargement'

    amountBeforeLabel += `${this.builder.generateSpaces(
      this.builder.getItemsTableColSize('COL_AMOUNT')
    )}`
    amountBeforeLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(amountBefore, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${amountBefore} €`
    this.builder.addText(amountBeforeLabel, { align: 'right' })

    let amount = formatPrice(topUpAmount)
    let amountLabel = 'Montant du rechargement'

    amountLabel += `${this.builder.generateSpaces(this.builder.getItemsTableColSize('COL_AMOUNT'))}`
    amountLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(amount, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${amount} €`
    this.builder.addText(amountLabel, { align: 'right' })

    let amountAfter = formatPrice(userAccount.amount + topUpAmount)
    let amountAfterLabel = 'Solde après le rechargement'

    amountAfterLabel += `${this.builder.generateSpaces(
      this.builder.getItemsTableColSize('COL_AMOUNT')
    )}`
    amountAfterLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(amountAfter, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${amountAfter} €`
    this.builder.addText(amountAfterLabel, { align: 'right', bold: true })
  }
}

export default TopUpTicketPrinter
