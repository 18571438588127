import { ModalType, createShowDialogAction, createHideDialogAction } from 'pmt-modules/dialog'

/**
 * @param {*} product
 * @param {*} options
 *  - restaurantId: required since it is need by withMenu used on the ProductDialog page.
 *
 * Handle by the middleware of the `openProduct` action
 */
export const showProductDialog = (product, options) =>
  createShowDialogAction(ModalType.PRODUCT_VIEW, {
    product,
    viewOptions: options,
    // put in the root for withMenu
    restaurantId: options.restaurantId,
  })

export const hideProductDialog = () => createHideDialogAction(ModalType.PRODUCT_VIEW)

/**
 * @param {*} product
 * @param {*} options
 *  - restaurantId: required since it is need by withMenu used on the ProductDialog page.
 *
 * Handle by the middleware of the `openProduct` action
 */
export const showLooseCartDialog = () => createShowDialogAction(ModalType.LOOSE_CART_VIEW, {})

export const hideLooseCartDialog = () => createHideDialogAction(ModalType.LOOSE_CART_VIEW)

export const showPostOrderBackHomeDialog = () =>
  createShowDialogAction(ModalType.POST_ORDER_ERROR_BACK_HOME)

export const hidePostOrderBackHomeDialog = () =>
  createHideDialogAction(ModalType.POST_ORDER_ERROR_BACK_HOME)
