import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hidePrinterDisabledDialog, resetKiosk, setInactivityTimeout } from 'pmt-modules/kiosk'

import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  content: {
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  caption: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
  },
  button: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'inherit',
    lineHeight: 1,
  },
})

/**
 * https://paymytable.atlassian.net/browse/PP-873
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5ba372538ec30635ad5b5956
 */
class PrinterDisabledDialog extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    this.props.hidePrinterDisabledDialog()
    this.props.setInactivityTimeout(() => {
      this.props.resetKiosk()
    }, 10000)
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.content}>
        <TypographyCustom type="327" align="left">
          {tr('kiosk.order.page.confirm.dialog.printer_disabled.title')}
        </TypographyCustom>
        <TypographyCustom type="244" align="left" className={classes.caption}>
          {tr('kiosk.order.page.confirm.dialog.printer_disabled.caption')}
        </TypographyCustom>
        <Button
          variant="contained"
          color="primary"
          onClick={this.handleSubmit}
          className={classes.button}
        >
          <TypographyCustom type="247" skipColor>
            {tr('kiosk.order.page.confirm.dialog.printer_disabled.submit')}
          </TypographyCustom>
        </Button>
      </div>
    )
  }
}

// Dialog root properties
PrinterDisabledDialog.DialogRootProps = {
  // we don't want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

PrinterDisabledDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 766
  },
})

const mapStateToProps = (state, props) => ({})
const mapDispatchToProps = { hidePrinterDisabledDialog, resetKiosk, setInactivityTimeout }

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(
  PrinterDisabledDialog,
)
