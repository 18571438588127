import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import {
  getErrorKioskPayment,
  getDataKioskPeripheralsStatus,
  sendPayment,
} from 'pmt-modules/kioskInteractor'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'

import KioskPage from '../../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-699
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b557fe3c42e98852fd06b3c
 */
@withAppConfig
@withRestaurant
class OrderPaymentCreditCardPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.sendPayment()
  }

  sendPayment() {
    this.props.sendPayment(this.props.orderProperties.totalCartPriceAndFees)
  }

  handleCancel = () => {
    this.props.hideErrorDialog()
    this.props.redirectTo(getRoute('KIOSK__ORDER__PAYMENT__MODE'))
  }

  handleErrorRetry = () => {
    this.sendPayment()
    this.props.hideErrorDialog()
  }

  render() {
    const {
      route,
      frontAppConfig,
      isFetchingRestaurant,
      kioskPeripheralsStatus,
      orderProperties,
      paymentError,
      restaurant,
    } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            kioskPeripheralsStatus={kioskPeripheralsStatus}
            kioskSettings={kioskSettings}
            onCancel={this.handleCancel}
            onErrorRetry={this.handleErrorRetry}
            paymentError={paymentError}
            totalCartPriceAndFees={orderProperties.totalCartPriceAndFeesFormatted}
            restaurant={restaurant}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  orderProperties: getOrderProperties(state),
  paymentError: getErrorKioskPayment(state),
})

const mapDispatchToProps = { hideErrorDialog, sendPayment, redirectTo }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPaymentCreditCardPage)
