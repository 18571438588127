import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { duplicateItemOnCart, removeFromCart, deleteFromCart, editItem } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Price from 'pmt-ui/Price'
import StateMachine from 'pmt-ui/StateMachine'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import ContentRemove from 'pmt-ui/svg-icons/content/remove'
import ActionDelete from 'pmt-ui/svg-icons/action/delete'
import NavigationCheck from 'pmt-ui/svg-icons/navigation/check'

import SquareIcon from '../SquareIcon'

const styles = theme => ({
  root: {
    position: 'relative',
    width: 315,
    height: 170,
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    background: theme.pmt.colors.white,
  },
  dataSection: {
    float: 'left',
    width: 200,
  },
  actionSection: {
    float: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: 40,
    height: '100%',
  },
  itemName: {
    minHeight: 21,
    maxHeight: 42,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '20px',
  },
  itemNameSmall: {
    maxHeight: 21,
    '-webkit-line-clamp': 1,
  },
  itemOptions: {
    minHeight: 18,
    maxHeight: 55,
    overflow: 'hidden',
    display: '-webkit-box',
    marginTop: theme.spacing(0.5),
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '18px',
    color: theme.pmt.colors.grey91,
  },
  itemOptionsWithReclaimLater: {
    '-webkit-line-clamp': 2,
  },
  priceContainer: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  quantity: {
    textAlign: 'center',
  },
  addOverlay: {
    position: 'absolute',
    top: 'calc(50% - 45px)',
    left: 'calc(50% - 45px)',
    width: 90,
    height: 90,
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    zIndex: 10,
  },
  addIcon: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
  },
})

const State = {
  DEFAULT: 'DEFAULT',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

const ProductOptions = ({ options }) => (
  <React.Fragment>
    {options.map((option, indexOptions) =>
      option.values.map(
        (value, indexOption) =>
          value.quantity > 0 && (
            <React.Fragment key={indexOption}>
              {value.name}
              {(indexOptions + 1 < options.length || indexOption + 1 < option.values.length) &&
                ', '}
            </React.Fragment>
          )
      )
    )}
  </React.Fragment>
)

const MenuProducts = ({ products }) => (
  <React.Fragment>
    {products.map(
      (product, index) =>
        product.quantity > 0 && (
          <React.Fragment key={index}>
            {product.name}
            {index + 1 < products.length && ', '}
          </React.Fragment>
        )
    )}
  </React.Fragment>
)

class ItemFooterRender extends React.PureComponent {
  constructor(props) {
    super(props)

    if (props.last && props.transition) {
      props.transitionTo(State.ADDING_TO_CART)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.last && this.props.transition && !prevProps.transition) {
      this.props.transitionTo(State.ADDING_TO_CART)
    }
  }

  render() {
    const {
      item,
      duplicateItemOnCart,
      restaurant,
      removeFromCart,
      deleteFromCart,
      editItem,
      currentState,
      classes,
    } = this.props

    return (
      <div
        className={classes.root}
        onClick={() => {
          EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DETAIL, {
            item,
          })
          editItem(item, {
            restaurantId: restaurant.id,
          })
        }}
      >
        {currentState === State.ADDING_TO_CART && (
          <TypographyCustom type="207" align="center" className={classes.addOverlay}>
            <NavigationCheck className={classes.addIcon} />
            {tr('global.order.added')}
          </TypographyCustom>
        )}
        <div className={classes.dataSection}>
          <TypographyCustom
            type="204"
            className={classNames(classes.itemName, {
              [classes.itemNameSmall]:
                (item.optionsSelected && !isEmpty(item.optionsSelected)) ||
                (item.selectedProducts && !isEmpty(item.selectedProducts)),
            })}
          >
            {item.name}
          </TypographyCustom>
          {item.isProduct && (
            <React.Fragment>
              {!isEmpty(item.optionsSelected) && (
                <TypographyCustom
                  type="164"
                  className={classNames(classes.itemOptions, {
                    [classes.itemOptionsWithReclaimLater]: item.reclaimLater,
                  })}
                >
                  <ProductOptions options={item.optionsSelected} />
                </TypographyCustom>
              )}
              {item.reclaimLater && (
                <TypographyCustom type="164" className={classes.itemOptions}>
                  {tr('kiosk.order.product.reclaimLater.label')}
                </TypographyCustom>
              )}
            </React.Fragment>
          )}
          {item.isMenu &&
            !isEmpty(item.selectedProducts) && (
              <TypographyCustom type="164" className={classes.itemOptions}>
                <MenuProducts products={item.selectedProducts} />
              </TypographyCustom>
            )}
          <TypographyCustom type="287" className={classes.priceContainer}>
            <Price value={item.totalPriceWithQuantityFormatted} />
          </TypographyCustom>
        </div>
        <div className={classes.actionSection}>
          <SquareIcon
            primaryColor
            icon={<ContentAdd />}
            onClick={e => {
              e.stopPropagation()
              EventManager.dispatch(EventManager.Events.ON_CART_ITEM_INCREASE, {
                item,
              })
              duplicateItemOnCart(item)
            }}
          />
          <TypographyCustom type="207" className={classes.quantity}>
            {item.quantity}
          </TypographyCustom>
          <SquareIcon
            icon={item.quantity === 1 ? <ActionDelete /> : <ContentRemove />}
            onClick={e => {
              e.stopPropagation()
              if (item.quantity === 1) {
                EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DELETE, {
                  item,
                  restaurant,
                })
                deleteFromCart(item)
              } else {
                EventManager.dispatch(EventManager.Events.ON_CART_ITEM_DECREASE, {
                  item,
                })
                removeFromCart(item)
              }
            }}
          />
        </div>
      </div>
    )
  }
}

class ItemFooter extends React.PureComponent {
  render() {
    return (
      <StateMachine
        states={State}
        default={State.DEFAULT}
        transitions={[
          {
            state: State.ADDING_TO_CART,
            to: State.DEFAULT,
            duration: 1000,
          },
        ]}
      >
        {({ currentState, transitionTo }) => (
          <ItemFooterRender
            {...this.props}
            currentState={currentState}
            transitionTo={transitionTo}
          />
        )}
      </StateMachine>
    )
  }
}

const mapStateToProps = (state, props) => ({})

export default compose(
  connect(
    mapStateToProps,
    {
      duplicateItemOnCart,
      removeFromCart,
      deleteFromCart,
      editItem,
    }
  ),
  withStyles(styles)
)(ItemFooter)
