import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import NavigationChevronLeft from 'pmt-ui/svg-icons/navigation/chevron-left'

const styles = theme => ({
  root: {
    width: 230,
    height: 230,
    padding: theme.spacing(3),
    margin: `0 ${theme.spacing(1) + theme.spacing(0.5)}px ${theme.spacing(4)}px`,
    boxShadow: '2px 2px 4px 0px rgba(158,158,158,1)',
  },
  content: {
    padding: '0 !important',
  },
  backLink: {
    marginLeft: -theme.spacing(1.5),
    height: 36,
    lineHeight: '36px',
    color: theme.palette.primary.main,
  },
  icon: {
    float: 'left',
    width: 36,
    height: 36,
  },
  hr: {
    borderTop: `1px solid ${theme.pmt.colors.grey91}`,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  category: {
    overflow: 'hidden',
    minHeight: 28,
    maxHeight: 84,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '26px',
  },
})

const ParentCategoryCard = ({ category, parentCategory, onClick, classes }) => (
  <Card className={classes.root}>
    <CardContent className={classes.content}>
      <TypographyCustom
        type="207"
        className={classes.backLink}
        onClick={e => {
          e.stopPropagation()
          onClick(parentCategory)
        }}
      >
        <NavigationChevronLeft className={classes.icon} /> Retour
      </TypographyCustom>
      <hr className={classes.hr} />
      <TypographyCustom type="247" className={classes.category}>
        {category.name}
      </TypographyCustom>
    </CardContent>
  </Card>
)

export default withStyles(styles)(ParentCategoryCard)
