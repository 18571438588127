import React from 'react'
import PropTypes from 'prop-types'

import { DialogActions, DialogContent, DialogContentText, DialogTitle } from 'pmt-ui/Dialog'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    width: 600,
    padding: theme.spacing(2),
  },
  title: {
    '& h2': {
      display: 'flex',
      alignItems: 'flex-end',
      color: `${theme.palette.primary.main}`,
      textAlign: 'left',
    },
  },
})

const DialogError = ({ classes, errorMessage, options }) => (
  <div className={classes.root}>
    {options.title && (
      <DialogTitle
        classes={{
          root: classes.title,
        }}
        disableTypography={typeof options.title !== 'string'}
      >
        {options.title}
      </DialogTitle>
    )}
    <DialogContent>
      <DialogContentText>
        {options.contentComponent
          ? React.cloneElement(options.contentComponent, {}, errorMessage)
          : errorMessage}
      </DialogContentText>
    </DialogContent>
    {options.actions && <DialogActions>{options.actions}</DialogActions>}
  </div>
)

// Dialog root properties
DialogError.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

DialogError.propTypes = {
  /**
   * options can contain :
   *   - title: dialog title
   *   - actions: dialog actions
   */
  options: PropTypes.object,
}

export default withStyles(styles)(DialogError)
