import isEmpty from 'lodash/isEmpty'

import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'

import { formatOptionValues } from './optionValue'

const formatValues = option => {
  option.hasImageValue = option.values.some(o => !isEmpty(o.image))
  return option
}

const formatOption = createFormatter(
  formatValues,
  createSubObjectFormatter('values', formatOptionValues)
)

export const formatOptions = createListFormatter(formatOption)
