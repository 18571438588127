import { findOnArray } from 'pmt-utils/array'

import areSameMenu from './areSameMenu'

// return a menu set in cart
export const getMenuFromCart = (itemCartList, menu) =>
  findOnArray(itemCartList, itemFromList => areSameMenu(itemFromList, menu))

export const getMenuFromCartWithOrderId = (itemCartList, orderId) =>
  findOnArray(itemCartList, itemFromList => itemFromList.orderId === orderId)
