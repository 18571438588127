import repeat from 'lodash/repeat'

class LocalPrinterBuilder {
  data = ''

  static DEFAULT_CHAR_SIZE = 1

  static ItemsTableColSize = {
    COL_QTY: 3,
    COL_PRODUCT: 20,
    COL_UNIT: 6,
    COL_TOTAL: 6,
    COL_VAT: 5,
  }

  static MaxCharPerSize = {
    SIZE_1: 40,
    SIZE_2: 38,
    SIZE_3: 36,
    SIZE_4: 32,
  }

  /**
   * Default font options for addText method
   *  - bold: boolean whether to bold text or not
   *  - align: string left/center/right to align text
   *  - size: number 1-4 font size of text
   */
  static DefaultFontOptions = {
    bold: false,
    align: 'left',
    size: 1,
  }

  addText(text = '', options = {}) {
    let extra = ''
    let spaces = 0
    let str = text

    options = { ...LocalPrinterBuilder.DefaultFontOptions, ...options }

    // If text length superior to maximum chars printable by line, we cut text in two and
    // recursively call addText method with the remaining part of text
    if (text.length * options.size > LocalPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`]) {
      str = text.substr(
        0,
        LocalPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`] / options.size
      )
      extra = text.substr(
        (LocalPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`] - text.length * options.size) /
          options.size
      )
    }

    if (options.align === 'center') {
      spaces = this.getSpaceToAlignCenter(str, options.size)
    } else if (options.align === 'right') {
      spaces = this.getSpaceToAlignRight(str, options.size)
    }

    if (options.bold) {
      str = this.bold(str)
    }

    if (options.size) {
      str = this.size(str, options.size)
    }

    this.data += `${this.generateSpaces(spaces)}${str}\n`

    if (extra) {
      this.addText(extra, options)
    }
  }

  addTextRaw(text = '') {
    this.data += text
  }

  getSpaceToAlignCenter(str, size = 1) {
    return this.getSpacesLeft(str, size) / 2
  }

  getSpaceToAlignRight(str, size = 1) {
    return this.getSpacesLeft(str, size)
  }

  getSpacesLeft(
    str,
    size = 1,
    lineLength = LocalPrinterBuilder.MaxCharPerSize[`SIZE_${LocalPrinterBuilder.DEFAULT_CHAR_SIZE}`]
  ) {
    return size === 1 ? lineLength - str.length : lineLength - str.length * size
  }

  size(str, size = LocalPrinterBuilder.DEFAULT_CHAR_SIZE) {
    return size === LocalPrinterBuilder.DEFAULT_CHAR_SIZE
      ? str
      : `<@Magnification${size}>${str}<@MagnificationOff>`
  }

  bold(str) {
    return `<@BoldOn>${str}<@BoldOff>`
  }

  addCut() {
    this.addText('<@PartialCut>')
  }

  printPayment() {
    this.addText('<@PaymentCustomerReceipt>')
  }

  addFeedLine(nbLines = 1) {
    for (let i = 0; i < nbLines; i++) {
      this.addText()
    }
  }

  fillWithSpaces(nb, width) {
    return this.generateSpaces(width - nb)
  }

  /**
   * Lodash repeat seems to be the fastest solution
   * String.prototype.repeat() requires a polyfill
   * see :
   *   - https://jsperf.com/repeating-character
   *   - https://jsperf.com/repeating-char
   */
  generateSpaces(nb) {
    return repeat(' ', nb)
  }

  toString() {
    return this.data
  }

  getItemsTableColSize(key) {
    return LocalPrinterBuilder.ItemsTableColSize[key]
  }
}

export default LocalPrinterBuilder
