import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { hideDietaryInfoDialog } from 'pmt-modules/catalog'
import { getAllergensOptions } from 'pmt-modules/user/constants'

import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Button from 'pmt-ui/Button'
import { withStyles } from 'pmt-ui/styles'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import Table, { TableBody, TableCell, TableHead, TableRow } from 'pmt-ui/Table'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 35px)',
    color: theme.palette.primary.main,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  dialogActions: {
    cursor: 'pointer',
    justifyContent: 'center',
    margin: 0,
    padding: `${theme.spacing(6)}px ${theme.spacing(5)}px`,
    zIndex: 1,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  allergen: {
    color: theme.pmt.colors.grey91,
  },
  allergenActive: {
    color: theme.palette.primary.main,
  },
  tableCell: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
    width: 90,
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
      width: 55,
      minWidth: 55,
    },
  },
  cellRow: {
    minWidth: 300,
    [theme.breakpoints.down('sm')]: {
      minWidth: 200,
    },
  },
  close: {
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'inherit',
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
})

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.pmt.colors.grey26,
    color: theme.palette.common.white,
  },
  body: {
    backgroundColor: theme.pmt.colors.greyEF,
    fontSize: 12,
    minWidth: 300,
  },
}))(TableCell)

/**
 * @specs N/A
 */
class DietaryInfoDialog extends React.PureComponent {
  constructor(props) {
    super(props)

    // set static var for better performance and clean allergens without icon
    this.allergens = getAllergensOptions().filter(
      a => props.item.formattedAllergens.indexOf(a.value) >= 0
    )
  }

  renderItem = ({ classes, item, depth = 2 }) => (
    <Fragment>
      {/* item */}
      {this.renderRow({ item, classes, depth })}

      {/* options */}
      {(item.options || []).map(
        option =>
          option.hasAllergens && (
            <Fragment>
              {option.values && (
                <Fragment>
                  <TableRow style={{ height: 36 }}>
                    <CustomTableCell
                      component="th"
                      scope="row"
                      colSpan={this.allergens.length + 1}
                      style={{
                        paddingLeft: 4 * (depth + 1) * 3,
                      }}
                    >
                      {option.name}
                    </CustomTableCell>
                  </TableRow>
                  {(option.values || []).map(v =>
                    this.renderRow({ item: v, classes, depth: depth + 2 })
                  )}
                </Fragment>
              )}
            </Fragment>
          )
      )}

      {/* parts */}
      {item.parts &&
        (item.parts || []).map(
          part =>
            part.hasAllergens && (
              <Fragment>
                <TableRow key={`parts-${part.id}`} style={{ height: 36 }}>
                  <CustomTableCell component="th" scope="row" colSpan={this.allergens.length + 1}>
                    {part.name}
                  </CustomTableCell>
                </TableRow>
                {(part.products || []).map(product =>
                  this.renderItem({ item: product, classes, depth: depth + 1 })
                )}

                {/* part categories */}
                {this.renderPartCategory({
                  classes,
                  categories: part.categories,
                  depth: depth + 1,
                })}
              </Fragment>
            )
        )}
    </Fragment>
  )

  renderPartCategory = ({ classes, categories, depth = 2 }) =>
    (categories || []).map(category => (
      <Fragment>
        {(category.products || []).map(item => this.renderItem({ item, classes, depth }))}
        {(category.categories || []).map(subCategory =>
          this.renderPartCategory({ categories: subCategory.categories, classes, depth })
        )}
      </Fragment>
    ))

  renderRow = ({ classes, item, depth = 2 }) =>
    !isEmpty(get(item, 'dietaryInfo.allergens', [])) && (
      <TableRow key={item.id}>
        <TableCell
          component="th"
          scope="row"
          className={classes.cellRow}
          style={{ paddingLeft: 4 * depth * 3 }}
        >
          {item.name}
        </TableCell>
        {this.allergens.map(a => (
          <TableCell key={`${item.id}-${a.value}`} classes={{ root: classes.tableCell }}>
            <div
              className={classNames(classes.allergen, {
                [classes.allergenActive]:
                  item.dietaryInfo && (item.dietaryInfo.allergens || []).indexOf(a.value) >= 0,
              })}
            >
              {a.icon}
            </div>
          </TableCell>
        ))}
      </TableRow>
    )

  render() {
    const { classes, item, hideDietaryInfoDialog } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="287" skipColor className={classes.dialogTitle}>
            {tr('kiosk.order.dialog.dietary_info.title')}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={hideDietaryInfoDialog}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.wrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell />
                  {this.allergens.map(a => (
                    <CustomTableCell classes={{ root: classes.tableCell }}>
                      {a.label}
                    </CustomTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{this.renderItem({ item, classes })}</TableBody>
            </Table>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            color="primary"
            variant="outlined"
            onClick={hideDietaryInfoDialog}
            className={classes.close}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.dialog.dietary_info.close')}
            </TypographyCustom>
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

DietaryInfoDialog.DialogStyles = theme => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = () => ({})

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      hideDietaryInfoDialog,
    }
  )
)(DietaryInfoDialog)
