import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { setLocale } from 'pmt-modules/kiosk'

import Card, { CardContent } from 'pmt-ui/Card'
import { withStyles } from 'pmt-ui/styles'

import Flag from 'pmt-utils/country/flag'

const styles = theme => ({
  flagsContainer: {
    padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
  },
  flags: {
    display: 'inline-block',
    marginRight: theme.spacing(6),
    '&:last-child': {
      marginRight: 0,
    },
  },
  flag: {
    height: theme.spacing(7),
  },
})

const CardLanguages = ({ classes, className, locales = [], setLocale }) =>
  locales.length > 1 && (
    <Card className={classnames(className, classes.root)}>
      <CardContent className={classes.flagsContainer}>
        {locales.map(l => (
          <div
            key={l}
            className={classes.flags}
            onClick={e => {
              e.stopPropagation()
              setLocale(l.substring(0, 2))
            }}
          >
            <Flag className={classes.flag} country={l.slice(-2)} />
          </div>
        ))}
      </CardContent>
    </Card>
  )

const mapStateToProps = (state, props) => ({})

export default compose(
  connect(mapStateToProps, {
    setLocale,
  }),
  withStyles(styles)
)(CardLanguages)
