import createAction from '../redux/createAction'

export const OrderMenuAction = {
  SELECT_MENU: 'ORDER_MENU_ACTION::SELECT_MENU',
  EDIT_MENU: 'ORDER_MENU_ACTION::EDIT_MENU',
  SELECT_PRODUCT: 'ORDER_MENU_ACTION::SELECT_PRODUCT',
  UNSELECT_PRODUCT: 'ORDER_MENU_ACTION::UNSELECT_PRODUCT',
  EDIT_PRODUCT: 'ORDER_MENU_ACTION::EDIT_PRODUCT',
  UPDATE_PRODUCT: 'ORDER_MENU_ACTION::UPDATE_PRODUCT',
  ADD_PRODUCT_ITEM: 'ORDER_MENU_ACTION::ADD_PRODUCT_ITEM',
  ADD_PRODUCT: 'ORDER_MENU_ACTION::ADD_PRODUCT',
  SET_CURRENT_PART: 'ORDER_MENU_ACTION::SET_CURRENT_PART',
  SET_SELECTED_PART: 'ORDER_MENU_ACTION::SET_SELECTED_PART',
  SET_PART_SELECTED_CATEGORY: 'ORDER_MENU_ACTION::SET_SELECTED_CATEGORY',
  SET_PART_PARENT_CATEGORIES: 'ORDER_MENU_ACTION::SET_PARENT_CATEGORIES',
}

/**
 *
 * @param {*} menu
 * @param {*} options
 *    - restaurantId {string}
 */
export const selectMenu = (menu, options) =>
  createAction(OrderMenuAction.SELECT_MENU, {
    menu,
    options: {
      redirectToMenuPage: false,
      isEditMode: false,
      ...options,
    },
  })

/**
 *
 * @param {*} menu
 * @param {*} options
 *    - restaurantId {string}
 *    - fromRoute
 */
export const editMenu = (orderMenu, options) =>
  createAction(OrderMenuAction.EDIT_MENU, {
    orderMenu,
    // note: putting orderMenu as menu is a a trick, but it works for now
    // To change: retrieve the menu and put in on the action on cart middlewares
    // openEditItemMiddleware
    menu: orderMenu,
    options: {
      redirectToMenuPage: true,
      isEditMode: true,
      ...options,
    },
  })

/**
 *
 * @param {object} menu
 * @param {object} options
 *      - isMenuMode {boolean}
 *      - restaurantId {string} required.
 *      - orderMenu {object}
 *      - isEditMode {boolean}
 */
export const openMenu = (menu, options) =>
  createAction(OrderMenuAction.SELECT_MENU, {
    menu,
    options: {
      redirectToMenuPage: true,
      ...options,
    },
  })

//
//
//

/**
 * @param {*} part
 * @param {*} menu
 * @param {*} options
 *    - restaurantId {string}
 */
export const selectMenuProduct = (part, partCategory, product, options) =>
  createAction(OrderMenuAction.SELECT_PRODUCT, {
    part,
    partCategory,
    product,
    options,
  })

/**
 * @param {*} part
 * @param {*} menu
 * @param {*} options
 *    - restaurantId {string}
 */
export const unselectMenuProduct = (part, partCategory, orderProduct, options) =>
  createAction(OrderMenuAction.UNSELECT_PRODUCT, {
    part,
    partCategory,
    orderProduct,
    options,
  })

/**
 * @param {*} options
 *    - restaurantId {string}
 */
export const editMenuProduct = (part, partCategory, orderProduct, options) =>
  createAction(OrderMenuAction.EDIT_PRODUCT, {
    part,
    partCategory,
    orderProduct,
    options,
  })

export const addProductItemToMenu = (part, partCategory, item, options) =>
  createAction(OrderMenuAction.ADD_PRODUCT_ITEM, {
    part,
    partCategory,
    item,
    options,
  })

export const addOrderProductToMenu = (orderProduct, partId, partCategoryId) =>
  createAction(OrderMenuAction.ADD_PRODUCT, {
    orderProduct,
    partId,
    partCategoryId,
  })

export const updateOrderProductToMenu = (orderProduct, partId, partCategoryId) =>
  createAction(OrderMenuAction.UPDATE_PRODUCT, {
    orderProduct,
    partId,
    partCategoryId,
  })

/**
 * Define the part currently displayed for the user
 */
export const setCurrentPart = part =>
  createAction(OrderMenuAction.SET_CURRENT_PART, {
    part,
  })

export const setPartSelectedCategory = category =>
  createAction(OrderMenuAction.SET_PART_SELECTED_CATEGORY, {
    category,
  })

export const resetPartSelectedCategory = () =>
  createAction(OrderMenuAction.SET_PART_SELECTED_CATEGORY, {
    category: null,
  })

/**
 *
 * @param {*} parentCategories
 */
export const setPartParentCategories = parentCategories =>
  createAction(OrderMenuAction.SET_PART_PARENT_CATEGORIES, {
    parentCategories,
  })
export const resetPartParentCategories = () =>
  createAction(OrderMenuAction.SET_PART_PARENT_CATEGORIES, {
    parentCategories: [],
  })
