import { tr } from 'pmt-modules/i18n'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import Printer from './'
import { formatPrice } from 'pmt-utils/currency/index'

class CheckoutTicketPrinter extends Printer {
  formatBankReceipt() {
    this.renderPayment(false)

    this.builder.addFeedLine(3)
    this.builder.addCut()

    const message = this.builder.toString()

    console.log(message)
    return message
  }

  format(restaurant, check, options = {}) {
    this.check = check
    this.options = options

    this.builder.addText(tr('global.printer.acrelec.payment'), {
      bold: true,
      align: 'center',
      size: 3,
    })
    this.builder.addFeedLine()

    this.renderRestaurantInfo(restaurant)

    this.renderCheckoutInfo(check)

    this.renderCustomText()

    this.builder.addFeedLine(1)

    this.builder.addCut()

    const message = this.builder.toString()

    console.log(message)
    return message
  }

  renderCheckoutInfo() {
    let quantityLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.quantity'),
        1,
        this.builder.getItemsTableColSize('COL_QTY')
      )
    )}${tr('global.printer.acrelec.quantity')}`

    let productLabel = `${tr('global.printer.acrelec.product')}${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.product'),
        1,
        this.builder.getItemsTableColSize('COL_PRODUCT')
      )
    )}`

    let unitLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.unit_price'),
        1,
        this.builder.getItemsTableColSize('COL_UNIT')
      )
    )}${tr('global.printer.acrelec.unit_price')}`

    let totalLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.total'),
        1,
        this.builder.getItemsTableColSize('COL_TOTAL')
      )
    )}${tr('global.printer.acrelec.total')}`

    this.builder.addText(`${quantityLabel} ${productLabel} ${unitLabel} ${totalLabel}`)

    forEach(this.check.entries, entry => {
      this.renderEntry(entry)
    })

    this.builder.addFeedLine()
    this.renderTotal()
    this.builder.addFeedLine()
  }

  renderEntry({ name, quantity, unitPrice, price }) {
    this.renderItemTitle(name, quantity, formatPrice(unitPrice), formatPrice(price))
  }

  renderItemTitle(name, quantity, unit, total) {
    quantity = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        quantity.toString(),
        1,
        this.builder.getItemsTableColSize('COL_QTY')
      )
    )}${quantity}`
    name = this.getProductFormatted(name)
    unit = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(unit, 1, this.builder.getItemsTableColSize('COL_UNIT'))
    )}${unit}`
    total = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(total, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${total}`

    this.builder.addText(`${quantity} ${name} ${unit} ${total}  `)
  }

  getProductFormatted(name, margin = 0) {
    let productLabel = `${this.builder.generateSpaces(margin)}${name.substr(
      0,
      this.builder.getItemsTableColSize('COL_PRODUCT') - margin
    )}`
    productLabel =
      name.length > productLabel.length
        ? `${productLabel.substr(0, productLabel.length - 1)}…`
        : productLabel
    productLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(productLabel, 1, this.builder.getItemsTableColSize('COL_PRODUCT'))
    )}`

    return productLabel
  }

  renderTotal() {
    let strTotal = formatPrice(this.check.total)
    let strTotalLabel = tr('global.printer.acrelec.total_order')
    strTotalLabel += `${this.builder.generateSpaces(
      this.builder.getItemsTableColSize('COL_VAT')
    )}   `
    strTotalLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(strTotal, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${strTotal} €`
    this.builder.addText(strTotalLabel, { align: 'right', bold: true })

    if (!isNil(this.check) && !isNil(this.check.tax)) {
      forEach(this.check.tax, tax => {
        let vatLabel = tr('global.printer.acrelec.vat')
        // tax name is a free field, we clean a potential vat wording in it
        const taxName = tax.name
          .toLowerCase()
          .replace('tva', '')
          .trim()
        vatLabel += `${this.builder.generateSpaces(
          this.builder.getSpacesLeft(taxName, 1, this.builder.getItemsTableColSize('COL_VAT'))
        )}`
        vatLabel += taxName
        vatLabel += `${this.builder.generateSpaces(
          this.builder.getSpacesLeft(
            formatPrice(tax.amoutOfTax),
            1,
            this.builder.getItemsTableColSize('COL_TOTAL')
          )
        )}${formatPrice(tax.amoutOfTax)} €`

        this.builder.addText(vatLabel, { align: 'right', bold: true })
      })
    }
  }

  renderCustomText() {
    const text = get(this.options.kioskSettings, 'order.ticketSettings.customText', '')
    if (!isEmpty(text)) {
      this.builder.addTextRaw(this.options.kioskSettings.order.ticketSettings.customText)
    }
  }
}

export default CheckoutTicketPrinter
