import React from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import { connect } from 'react-redux'
import invariant from 'invariant'

import {
  fetchCheck,
  getCheck,
  getCheckError,
  isFetchingCheck,
  fetchCheckWithCode,
  fetchCheckWithPosCheckId,
  fetchCheckWithTableNumber,
} from '../check'
import { getPayment } from '../payment/selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the check and pass it to the children
 *
 * Requirements:
 * - restaurantId
 * - checkId
 *
 * see `withCheck`
 */
class CheckContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (
      isNil(props.check) ||
      props.forceReload ||
      (!isNil(props.check) &&
        ((props.checkId && parseInt(props.checkId) !== props.check.id) ||
          (props.tableNumber && props.tableNumber !== props.check.tableNumber) ||
          (props.posCheckId && props.posCheckId !== props.check.posCheckId)))
    ) {
      this.loadCheck(props, true)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props

    if (
      ((nextProps.checkId && nextProps.checkId !== props.checkId) ||
        (nextProps.tableNumber && nextProps.tableNumber !== props.tableNumber) ||
        (nextProps.posCheckId && nextProps.posCheckId !== props.posCheckId) ||
        (nextProps.code && nextProps.code !== props.code)) &&
      (!props.check || props.check.id)
    ) {
      this.loadCheck(nextProps)
    }
  }

  loadCheck(props, forceReload = false) {
    if (!props.isFetchingCheck || forceReload) {
      if (props.posCheckId) {
        props.fetchCheckWithPosCheckId(props.restaurantId, props.posCheckId)
      } else if (props.tableNumber) {
        props.fetchCheckWithTableNumber(props.restaurantId, props.tableNumber)
      } else if (props.checkId) {
        props.fetchCheck(props.restaurantId, props.checkId)
      } else if (props.code) {
        props.fetchCheckWithCode(props.restaurantId, props.code)
      } else {
        invariant(false, 'Invalid identifier to fetch check.')
      }
    }
  }

  render() {
    const {
      CheckWrappedComponent,
      check,
      checkError,
      isFetchingCheck,
      children,
      payment,
      ...otherProps
    } = this.props

    const childrenProps = {
      check,
      checkError,
      reloadCheck: () => this.loadCheck(this.props),
      isFetchingCheck,
      payment,
    }

    if (isNil(CheckWrappedComponent)) {
      return children(childrenProps)
    }

    return <CheckWrappedComponent {...childrenProps} {...otherProps} />
  }
}

CheckContainer.defaultProps = {
  check: null,
  checkId: null,
  tableNumber: null,
  posCheckId: null,
  code: null,
}

CheckContainer.propTypes = {
  check: PropTypes.object,
}

const mapStateToProps = state => ({
  check: getCheck(state),
  checkError: getCheckError(state),
  isFetchingCheck: isFetchingCheck(state),
  payment: getPayment(state),
})

export default connect(
  mapStateToProps,
  {
    fetchCheck,
    fetchCheckWithCode,
    fetchCheckWithPosCheckId,
    fetchCheckWithTableNumber,
  }
)(CheckContainer)
