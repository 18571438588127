import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: 1,
    padding: theme.spacing(1),
    textAlign: 'center',
    background: theme.pmt.colors.white,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  noBorder: {
    border: 'none',
  },
})

const Chip = ({ label, noBorder, classes, otherProps }) => (
  <TypographyCustom
    type="207"
    className={classNames(classes.root, {
      [classes.noBorder]: noBorder,
    })}
    component="span"
    {...otherProps}
  >
    {label}
  </TypographyCustom>
)

Chip.defaultProps = {
  noBorder: false,
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
}

export default withStyles(styles)(Chip)
