export default {
  defaultLocale: 'fr',
  locales: [
    'fr',
    'en',
    'de',
    'es',
    'it',
  ],
  calendar: true,
}
