import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import { formatOrder } from 'pmt-modules/order/format'

import { formatVerifications } from './format'

const getState = state => state.entities.orderPreview

export const getSendDate = createSelector([getState], state => state.get('sendDate'))
export const getModificationDate = createSelector([getState], state =>
  state.get('modificationDate')
)

export const isFetchingOrderPreview = createSelector([getState], state => state.get('isFetching'))

export const getOrderPreview = createSelector([getState], state => {
  if (isNil(state)) {
    return null
  }
  const order = state.get('order', null)
  return order ? formatOrder(order) : null
})

export const getOrderPreviewError = createSelector([getState], state => {
  if (isNil(state)) {
    return null
  }
  return state.get('error', null)
})

export const getOrderPreviewVerifications = createSelector([getState], state => {
  if (isNil(state)) {
    return null
  }
  const verifications = state.get('verifications', null)
  return verifications ? formatVerifications(verifications) : null
})
