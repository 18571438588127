import React from 'react'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'

import { withAppConfig } from 'pmt-modules/appConfig'
import { nfcStart, scanStart, nfcStop, scanStop } from 'pmt-modules/kioskInteractor/actions'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'

import View from './View'
import { compose } from 'recompose';

/**
 * https://paymytable.atlassian.net/browse/PP-967
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875a2af8eb101376fac9
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875a5d5b584c8c2d4a8d
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875adf29ce0aa9e5b850
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875c4859890a3e48710b
 */
@withAppConfig
@withRestaurant
class CheckoutCheckPage extends React.PureComponent {
  constructor(props) {
    super(props)

    props.scanStop()
    props.nfcStop()
  }

  handleScanToMerge = () => {
    this.props.updateUI({
      isScanning: true,
    })

    this.props.scanStart()
    this.props.nfcStart()
  }

  render() {
    const { frontAppConfig, restaurant, route, routeParams, ui, updateUI } = this.props

    return (
      <KioskPage route={route} isFetching={!restaurant}>
        <View
          code={routeParams.code}
          frontAppConfig={frontAppConfig}
          onScanToMerge={this.handleScanToMerge}
          restaurant={restaurant}
          ui={ui}
          updateUI={updateUI}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = { nfcStart, scanStart, nfcStop, scanStop }

export default compose(
  ui({
    state: {
      isScanning: false,
      isSuccess: null,
    },
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CheckoutCheckPage)
