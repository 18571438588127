import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isNil from 'lodash/isNil'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import IconOrderMode from 'pmt-ui/Icon/IconOrderMode'

const styles = theme => ({
  root: {},
  modeContainer: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    float: 'left',
    fontSize: 110,
  },
  modeLabel: {
    display: 'inline-block',
    height: 110,
    lineHeight: '110px',
    // it is to center the text + icon, to compensate the padding initiated by the icon
    paddingRight: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  colorMain: {
    color: theme.palette.primary.main,
  },
  colorGrey500: {
    color: theme.pmt.colors.grey500,
  },
})

export const TYPE_COLORS = {
  MAIN: 'Main',
  GREY500: 'Grey500',
}

const IconLabelMode = ({ mode, type, color, classes }) => (
  <TypographyCustom type={type || "407"} className={classNames(classes.modeContainer, classes[`color${color}`], classes.root)}>
    {!isNil(mode) && (
      <IconOrderMode type={mode} classes={{ root: classes.icon }} />
    )}
    <span className={classes.modeLabel}>{tr(`kiosk.order.page.mode.label.${mode}`)}</span>
  </TypographyCustom>
)

export default withStyles(styles)(IconLabelMode)
