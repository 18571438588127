import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import { resetKiosk } from 'pmt-modules/kiosk'
import { getDataKioskPeripheralsStatus } from 'pmt-modules/kioskInteractor/selectors'
import { getDataGetKioskSettings, refreshKioskSettings } from 'pmt-modules/kioskSettings'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { refreshRestaurant } from 'pmt-modules/restaurant'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-967
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875ced47f75de88751a4
 */
@withAppConfig
@withRestaurant
class CheckoutHomePage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.props.resetKiosk({ clean: true })
  }

  componentWillReceiveProps(nextProps) {
    // once we receive kioskSettings, we can reset kiosk
    // this is used when we load the app for the first time and mandatory to init catalog
    if (!this.props.kioskSettings && nextProps.kioskSettings) {
      this.props.resetKiosk({ clean: true })
    }
  }

  handleRedirection = () => {
    this.props.refreshRestaurant(this.props.restaurant.id, {
      restaurant: this.props.restaurant,
    })
    this.props.refreshKioskSettings(this.props.restaurant.id)

    if (this.props.restaurant.valided && this.props.restaurant.orderSettings.isCurrentlyOpen) {
      this.props.redirectTo(getRoute('KIOSK__CHECKOUT__SCAN'))
    }
  }

  render() {
    const {
      frontAppConfig,
      route,
      isFetchingRestaurant,
      restaurant,
      kioskPeripheralsStatus,
    } = this.props

    return (
      <KioskPage route={route} isFetching={!restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            isFetchingRestaurant={isFetchingRestaurant}
            restaurant={restaurant}
            kioskPeripheralsStatus={kioskPeripheralsStatus}
            kioskSettings={kioskSettings}
            handleRedirection={this.handleRedirection}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authRestaurantId: getRestaurantId(state),
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  kioskSettings: getDataGetKioskSettings(state, props),
})

export default connect(mapStateToProps, {
  redirectTo,
  refreshKioskSettings,
  refreshRestaurant,
  resetKiosk,
})(CheckoutHomePage)
