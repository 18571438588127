import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let PaymentCreditCard = props => (
  <SvgIcon {...props}>
    <g id="card" transform="translate(5.000000, 2.000000)" fillRule="nonzero">
      <path
        d="M8.29303757,0 L1.68340921,0 C0.772245143,0 0.030982754,0.74765625 0.030982754,1.66667969 L0.030982754,13.6666797 C0.030982754,14.5856641 0.772245143,15.3333594 1.68340921,15.3333594 L7.63209798,15.3333594 L7.63209798,14.6666797 L1.68340921,14.6666797 C1.13679597,14.6666797 0.691961082,14.2180078 0.691961082,13.6666797 L0.691961082,1.66667969 C0.691961082,1.11535156 1.13679597,0.666679687 1.68340921,0.666679687 L8.2930763,0.666679687 C8.83968954,0.666679687 9.28452443,1.11535156 9.28452443,1.66667969 L9.28452443,11.3333594 L9.94546403,11.3333594 L9.94546403,1.66667969 C9.94546403,0.74765625 9.20420164,0 8.29303757,0 Z"
        id="Shape"
      />
      <path
        d="M13.1151159,8.87867188 L9.87308057,4.79199219 L9.35652061,5.20867188 L12.598556,9.295 C13.0192631,9.825 13.250588,10.4910156 13.2502782,11.17 L13.2502782,19.3333203 L8.415342,19.3333203 C6.70807607,19.3333203 5.31873192,17.9323047 5.31873192,16.21 L5.31873192,15 L4.65775359,15 L4.65775359,16.21 C4.65775359,18.2996484 6.34356397,20 8.415342,20 L13.5807867,20 C13.7635463,20 13.9112565,19.8510156 13.9112565,19.6666797 L13.9112565,11.1703516 C13.9116051,10.3410156 13.6290424,9.52699219 13.1151159,8.87867188 Z"
        id="Shape"
      />
      <path
        d="M5.64788495,7.86101563 C5.87986832,7.86769531 6.09570193,7.965 6.25464346,8.135 L10.6960212,12.895 L11.177532,12.4380078 L6.7354958,7.67765625 C6.45490824,7.37796875 6.07517586,7.20632813 5.66670698,7.19464844 C5.25393924,7.18066406 4.86959817,7.33230469 4.57611404,7.61199219 C4.57247356,7.61566406 4.54602204,7.64097656 4.54273012,7.64464844 C3.97795324,8.21597656 3.93992191,9.11265625 4.45516511,9.73199219 L7.30162818,13.1219922 L7.30162818,14.1533203 C7.30127962,15.9953125 8.44973285,17.6663281 10.1599809,18.3123047 L10.3919642,17.6879688 C8.9384858,17.1389844 7.96225795,15.7186328 7.96256777,14.1533203 L7.96256777,13 C7.96256777,12.9213281 7.93479948,12.8446484 7.88425886,12.7843359 L4.96049383,9.30199219 C4.66801663,8.95066406 4.68951092,8.4403125 5.02660328,8.1 C5.19511073,7.93933594 5.41520447,7.85964844 5.64788495,7.86101563 Z"
        id="Shape"
      />
      <rect id="Rectangle-path" x="7.30158945" y="0.666679687" width="1" height="8.33332031" />
      <rect id="Rectangle-path" x="5.64920172" y="11" width="1" height="4" />
      <rect id="Rectangle-path" x="5.64920172" y="0.333320313" width="1" height="7.33332031" />
      <rect id="Rectangle-path" x="1.68340921" y="1.66667969" width="1" height="4.33332031" />
      <rect id="Rectangle-path" x="3.33579694" y="1.66667969" width="1" height="2.66667969" />
      <path
        d="M3.99677526,11 L2.01387901,11 C1.83146805,11 1.68340921,11.1489844 1.68340921,11.3333203 L1.68340921,13.3333203 C1.68340921,13.5176563 1.83146805,13.6666406 2.01387901,13.6666406 L3.99677526,13.6666406 C4.17918623,13.6666406 4.32724506,13.5176563 4.32724506,13.3333203 L4.32724506,11.3333203 C4.32724506,11.1489844 4.17918623,11 3.99677526,11 Z M3.66630546,13 L2.34438754,13 L2.34438754,11.6666797 L3.66630546,11.6666797 L3.66630546,13 Z"
        id="Shape"
      />
    </g>
  </SvgIcon>
)
PaymentCreditCard = pure(PaymentCreditCard)
PaymentCreditCard.displayName = 'PaymentCreditCard'
PaymentCreditCard.muiName = 'SvgIcon'

export default PaymentCreditCard
