import isNull from 'lodash/isNull'
import forOwn from 'lodash/forOwn'
import Immutable from 'immutable'

import {
  GetAsyncPaymentAction,
  isAsyncPaymentRedirectToPspResponse,
} from 'pmt-modules/asyncPayment'

import { UserCreditCardAction } from 'pmt-modules/creditCard/actions'
import { generateIdempotencyKey } from 'pmt-utils/idempotency'
import { createSimpleReducer } from 'pmt-modules/redux'

import {
  PostPaymentAction,
  PostIrlPaymentAction,
  PostUserLightPaymentAction,
  SelectPaymentTypeAction,
  PaymentAction,
  PaymentItemSelection,
  SharePaymentAction,
  PostPaymentEmailAction,
} from './actions'

import {
  GetCheckWithTableNumberAction,
  GetRefreshCheckAction,
  GetCheckAction,
  GetCheckWithCodeAction,
  MergeCheckWithCodeAction,
} from '../check'

import { PaymentType } from '../../global/constants'
import { GetAppConfigAction } from '../../appConfig'

export * from './actions'
export * from './selectors'

const CHECK = Immutable.fromJS({
  outstandingBalance: 0,
})

export const getDefaultShare = () => ({
  enabled: false, // to know if we are in share mode or not for an item

  // the dividend
  divider: 1,
  defaultDivider: 1,

  // the divisor
  multiplier: 1,
  defaultMultiplier: 1,

  //
  dividerIsMinimum: true,
  multiplierIsMinimum: true,
  multiplierIsMaximum: true,
})

export const getDefaultChoose = () => ({
  ...getDefaultShare(),
  divider: 2,
  defaultDivider: 2,
})

const PAYMENT = () => ({
  type: PaymentType.ALL,
  amount: 0,
  tips: 0,

  idempotencyKey: generateIdempotencyKey(),

  // replace the array of entry `entries`
  items: {},

  cardId: null,

  // the associated check
  check: CHECK,

  // front data to handle sharing by division
  share: getDefaultShare(),

  /**
   * true if the requesting client can handle 3D Secure verification.
   */
  supportAsyncPayment: false,
})

/**
 *
 *
 */
const PAYMENT_DATA = {
  payment: PAYMENT(),
  isFetching: false,
  lastUpdated: null,
  error: null,
}

/**
 * An item is a payment's entry information.
 * An entry is the check's item information.
 */
const createItem = entry => ({
  quantity: 0,
  id: entry.id,
  max: entry.quantity,
  name: entry.name,
  unitPrice: entry.price / entry.quantity,
  absoluteTotal: entry.price,
  total: 0,
  share: getDefaultChoose(),
})

const itemsPath = ['payment', 'items']

const ceilToCent = exactValue => {
  return Number(Math.ceil(exactValue + 'e2') + 'e-2')
}

const ceilWithRoundingValue = (exactValue, roundingValue) => {
  // we must ceil, and not use exact nor round, because if we do, and everyone split the check, we
  // will have some cents remaining. With ceil, the last to pay will pay less.
  return Math.ceil(exactValue / roundingValue) * roundingValue
}

// this method is used to add share data on payment (check not null) or an item directly (share only)
const updateShareData = ({ check, share, roundingValue }) => {
  if (isNull(share)) {
    return null
  }
  // verify we are not under the minimum
  share.divider = Math.max(share.defaultDivider, share.divider)
  share.multiplier = Math.max(share.defaultMultiplier, share.multiplier)

  // at maximum, we multiplier the number of "divider"
  const maxMultiplier = share.divider
  share.multiplier = Math.min(maxMultiplier, share.multiplier)

  // update data
  share.dividerIsMinimum = share.defaultDivider === share.divider
  share.multiplierIsMinimum = share.defaultMultiplier === share.multiplier
  share.multiplierIsMaximum = maxMultiplier === share.multiplier

  // we add share data on the payment if check is not null.
  // Otherwise we are adding share data on an item
  if (check) {
    check.tips = check.tips || 0
    const checkTotal = check.total ? check.total - check.tips : 0
    const exactValuePerPerson = checkTotal / share.divider
    const amountPerPersonAsNumber = roundingValue
      ? ceilWithRoundingValue(exactValuePerPerson, roundingValue)
      : ceilToCent(exactValuePerPerson)
    share.amountPerPerson = amountPerPersonAsNumber.toFixed(2)
    // set the check total on share information. Used by the API to verify we have an up to date
    // check when wanting to split
    share.total = checkTotal
  }

  return share
}

const getItemOnState = (state, itemId) => {
  const stateItems = state.getIn(itemsPath)
  const items = stateItems.toJS()

  return items[itemId]
}

const createItemOnState = (state, entry) => {
  const stateItems = state.getIn(itemsPath)
  const items = stateItems.toJS()
  const item = items[entry.id] || createItem(entry)

  // set object
  return state.setIn(itemsPath, stateItems.set(entry.id, item))
}

const removeItemOnState = (state, entry) => {
  const stateItems = state.getIn(itemsPath)
  let items = stateItems.toJS()
  delete items[entry.id]

  return state.setIn(itemsPath, items)
}

const updateItem = (item, roundingValue) => {
  if (item.share.enabled) {
    item.share = updateShareData(item)
    item.quantity = item.share.divider / item.share.multiplier

    // we share for the total quantity of the entry (* item.max)
    item.total = (item.unitPrice * item.max) / item.quantity
    if (roundingValue) {
      item.total = ceilWithRoundingValue(item.total, roundingValue)
    } else {
      item.total = ceilToCent(item.total)
    }
  } else {
    // reset the share data
    item.share = getDefaultShare()
    // calculate total price
    item.total = item.unitPrice * item.quantity
  }

  return item
}

const updateItemOnState = (state, item) => {
  const stateItems = state.getIn(itemsPath)

  return state.setIn(
    itemsPath,
    stateItems.set(item.id, updateItem(item, state.getIn(['payment', 'roundingValue'])))
  )
}

const increaseItemQuantity = (state, itemId) => {
  const item = getItemOnState(state, itemId)

  if (item.share.enabled) {
    // pass from sharing to quantity
    item.share.enabled = false
    item.quantity = 0
  } else {
    // change quantity
    item.quantity++
  }

  // the max has reached, we pass it to 0
  if (item.quantity > item.max) {
    item.quantity = 0
  }

  return updateItemOnState(state, item)
}

/**
 * This function have to be called each time the payment on the state change.
 * It will calculate payment information with the new payment state:
 * - calculate payment's amount
 * - update data for each payment type
 */
const refreshPaymentInfo = payment => {
  let total = 0
  switch (payment.type) {
    case PaymentType.ENTRIES:
      // the total is the added total of each item
      forOwn(payment.items, (item, itemKey) => {
        if (item.share.enabled) {
          let itemPrice = item.unitPrice * item.max * (item.share.multiplier / item.share.divider)
          // to be coherent with the displayed prices (aka ceiled value for each line)
          // the total is the sum of the ceiled values (and not the ceiled nor rounded value of the sum)
          total += payment.roundingValue
            ? ceilWithRoundingValue(itemPrice, payment.roundingValue)
            : ceilToCent(itemPrice)
        } else {
          total += item.unitPrice * item.max
        }
      })

      break

    case PaymentType.SHARE:
      payment.share = updateShareData(payment)

      // calculate total and round to upper cent
      // we split the original check total, without the tips
      payment.check.tips = payment.check.tips || 0
      const checkTotal =
        payment.check && payment.check.total ? payment.check.total - payment.check.tips : 0
      const exactTotal = (checkTotal / payment.share.divider) * payment.share.multiplier
      if (payment.roundingValue) {
        total = ceilWithRoundingValue(exactTotal, payment.roundingValue)
      } else {
        total = ceilToCent(exactTotal)
      }

      break

    case PaymentType.ALL:
      // we wants to pay the remaining total
      total = payment.check.outstandingBalance
      console.log('current outstanding balance', total)
      break

    default:
      console.error(`not handle  type ${payment.type}`)
  }

  payment.amount = total

  // update items
  // TODO: remove selected items that are no longer in the entry
  forOwn(payment.items, (item, itemKey) => {
    payment.items[itemKey] = updateItem({ ...item }, payment.roundingValue)
  })

  // we considered there was new data enough to refreshPaymentInfo
  // so we consider it's not the same payment, and generate a new idempotency key
  // like that, changing tips value or splitting the check differently will allow to resubmit the payment
  payment.idempotencyKey = generateIdempotencyKey()

  return Immutable.fromJS(payment)
}

const refreshPaymentInfoState = state => {
  const payment = state.get('payment').toJS()
  return state.setIn(['payment'], refreshPaymentInfo(payment))
}

export const paymentReducer = (state = Immutable.fromJS(PAYMENT_DATA), action) => {
  switch (action.type) {
    // change id empotency when we change the card
    case UserCreditCardAction.SELECT_CARD:
      const currentCreditCardId = state.getIn(['payment', 'cardId'])
      const newCreditCardId = action.creditCard.id

      if (currentCreditCardId !== newCreditCardId) {
        const newIdempotencyKey = generateIdempotencyKey()
        state = state
          .setIn(['payment', 'idempotencyKey'], newIdempotencyKey)
          .setIn(['payment', 'cardId'], action.creditCard.id)
      }
      return state

    case PaymentAction.RESET:
      return state.merge(
        Immutable.fromJS({
          ...PAYMENT_DATA,
          payment: {
            ...PAYMENT(),
            // we keep the roundingValue (from apiConsumer) on payment so we have it when we need to do calculation
            roundingValue: state.get('payment').get('roundingValue'),
          },
          // keep data from the payment to be displayed on the confirm page.
          // the rest of the data is reset above, before we display the confirm page, in order to
          // have a clean payment data for the next payment flow.
          paymentMade: {
            amount: Number(state.get('payment').get('amount')) || 0,
            tips: state.get('payment').get('tips') || state.get('payment').get('tip') || 0,
          },
        })
      )

    //
    // check
    //
    case GetCheckWithTableNumberAction.SUCCESS:
    case GetRefreshCheckAction.SUCCESS:
    case GetCheckAction.SUCCESS:
    case GetCheckWithCodeAction.SUCCESS:
    case MergeCheckWithCodeAction.SUCCESS:
      console.log('check received', action.response)
      state = state.setIn(['payment', 'check'], action.response)
      return refreshPaymentInfoState(state)

    //
    // PaymentType
    //

    case SelectPaymentTypeAction.ALL:
      state = state.mergeIn(['payment'], {
        type: PaymentType.ALL,
        amount: state.getIn(['payment', 'check', 'total']),
        items: {},
      })
      return refreshPaymentInfoState(state)

    case SelectPaymentTypeAction.SHARE:
      state = state.mergeIn(['payment'], {
        type: PaymentType.SHARE,
        items: {},
      })
      return refreshPaymentInfoState(state)

    case SelectPaymentTypeAction.ENTRIES:
      state = state.mergeIn(['payment'], {
        type: PaymentType.ENTRIES,
        share: getDefaultShare(),
      })
      return refreshPaymentInfoState(state)

    //
    // Payment
    //

    case PaymentAction.SELECT_TIPS:
      state = state.setIn(['payment', 'tips'], action.tips)
      // trick API
      state = state.setIn(['payment', 'tip'], action.tips)
      return refreshPaymentInfoState(state)

    //
    //
    //

    case PaymentItemSelection.ADD_ITEM:
      state = createItemOnState(state, action.entry)
      state = increaseItemQuantity(state, action.entry.id)
      return refreshPaymentInfoState(state)

    case PaymentItemSelection.REMOVE_ITEM:
      state = removeItemOnState(state, action.entry)
      return refreshPaymentInfoState(state)

    //
    // SHARING ITEM
    //
    case PaymentItemSelection.ITEM_SHARE_UPDATE:
      const increaseItemShareUpdate = (state, itemId, share) => {
        const item = getItemOnState(state, itemId)
        // change quantity
        item.share.divider = share.divider
        item.share.multiplier = share.multiplier
        item.share.enabled = true
        return updateItemOnState(state, item)
      }
      state = createItemOnState(state, action.entry)
      state = increaseItemShareUpdate(state, action.entry.id, action.share)
      return refreshPaymentInfoState(state)

    //
    // SHARING CHECK
    //

    case SharePaymentAction.INCREASE_DIVIDER:
      return refreshPaymentInfoState(
        state
          .updateIn(['payment', 'share', 'divider'], value => value + 1)
          .setIn(['payment', 'share', 'enabled'], true)
      )

    case SharePaymentAction.DECREASE_DIVIDER:
      return refreshPaymentInfoState(
        state
          .updateIn(['payment', 'share', 'divider'], value => value - 1)
          .setIn(['payment', 'share', 'enabled'], true)
      )

    case SharePaymentAction.INCREASE_MULTIPLIER:
      return refreshPaymentInfoState(
        state
          .updateIn(['payment', 'share', 'multiplier'], value => value + 1)
          .setIn(['payment', 'share', 'enabled'], true)
      )

    case SharePaymentAction.DECREASE_MULTIPLIER:
      return refreshPaymentInfoState(
        state
          .updateIn(['payment', 'share', 'multiplier'], value => value - 1)
          .setIn(['payment', 'share', 'enabled'], false)
      )

    // storing roundingValue (from apiConsumer) on payment so we have it when we need to do calculation
    case GetAppConfigAction.SUCCESS:
      state = state.setIn(
        ['payment', 'roundingValue'],
        action.response?.settings?.front?.display?.roundingValue
      )
      return refreshPaymentInfoState(state)

    default:
      return state
  }
}

/**
 *
 *
 */
const POST_PAYMENT_DATA = {
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
}

export const postPaymentReducer = (state = Immutable.fromJS(POST_PAYMENT_DATA), action) => {
  switch (action.type) {
    case PostPaymentAction.RESET:
      return state.merge(
        Immutable.fromJS({
          ...POST_PAYMENT_DATA,
          data: {
            // we keep some data to display confirm page
            id: state.get('data')?.get('id'), // needed for the download receipt button
          },
        })
      )

    case GetAsyncPaymentAction.REQUEST:
      return state.merge({
        isFetching: true,
      })

    case PostPaymentAction.REQUEST:
    case PostIrlPaymentAction.REQUEST:
    case PostUserLightPaymentAction.REQUEST:
      return state.merge({
        data: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case PostPaymentAction.SUCCESS:
    case PostIrlPaymentAction.SUCCESS:
    case PostUserLightPaymentAction.SUCCESS:
      return state.merge({
        data: action.response,
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetAsyncPaymentAction.SUCCESS:
      return state.merge({
        data: {
          ...action.response.linkedObject, // linkedObject is the payment
        },
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetAsyncPaymentAction.FAILURE:
    case PostPaymentAction.FAILURE:
    case PostIrlPaymentAction.FAILURE:
    case PostUserLightPaymentAction.FAILURE:
      // if 3DSecure response, the error contains the order
      if (isAsyncPaymentRedirectToPspResponse(action)) {
        return state.merge({
          data: action.error.body,
          isFetching: false,
          error: null,
        })
      }
      return state.merge({
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}

export const postPaymentEmailReducer = createSimpleReducer(PostPaymentEmailAction)
