import React from 'react'

import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    lineHeight: '44px', // align with quantity buttons
  },
})

const CartContent = ({ classes, item }) => (
  <TypographyCustom type="367" className={classes.root}>
    <Price value={item.totalPriceWithQuantityFormatted} />
  </TypographyCustom>
)

export default withStyles(styles)(CartContent)
