import Immutable from 'immutable'
import { combineReducers } from 'redux'

import { createSimpleReducer } from '../redux'

import {
  KioskConnectivtyAction,
  KioskGetPeripheralsStatusAction,
  KioskMessagesAction,
  KioskNfcActions,
  KioskPaymentAction,
  KioskPrintAction,
  KioskRegistrationAction,
  KioskScanAction,
} from './actions'

export * from './actions'
export * from './middlewares'
export * from './selectors'

const DEFAULT_SCAN_STATE = Immutable.fromJS({})

/**
 * Scan reducer
 * Handle received data from barcode/QR code scanner
 */
const kioskScanReducer = (state = DEFAULT_SCAN_STATE, action) => {
  switch (action.type) {
    case KioskScanAction.RECEIVED_DATA:
      return state.merge({
        data: action.data,
      })

    default:
      return state
  }
}

const DEFAULT_NFC_STATE = Immutable.fromJS({})

/**
 * Nfc reader reducer
 * Handle received data from nfc reader
 */
const kioskNfcReducer = (state = DEFAULT_NFC_STATE, action) => {
  switch (action.type) {
    case KioskNfcActions.START:
      return state.merge({
        data: null,
        error: null,
      })
    case KioskNfcActions.STOP:
      return state.merge({
        error: null,
      })
    case KioskNfcActions.READ_DATA:
      return state.merge({
        data: action.data,
        error: null,
      })
    case KioskNfcActions.FAILURE:
      return state.merge({
        data: null,
        error: action.error,
      })

    default:
      return state
  }
}

const DEFAULT_SHARING_STATE = Immutable.fromJS({
  // data received (other than heartbeat/status) currently badge
  data: null,
  // heartbeat informations with last update date
  heartbeat: null,
  isListening: false,
  isListeningBadgeId: false,
  // contains magCardReaderConnected status
  status: null,
})

/**
 * Sharing message reducer
 */
const kioskSharingMessageReducer = (state = DEFAULT_SHARING_STATE, action) => {
  switch (action.type) {
    case KioskMessagesAction.LISTEN:
      return state.merge({
        isListening: true,
      })

    case KioskMessagesAction.RECEIVED_BADGE:
      return state.merge({
        data: action.data,
      })

    case KioskMessagesAction.RECEIVED_HEARTBEAT:
      return state.merge({
        heartbeat: action.data,
      })

    case KioskMessagesAction.RECEIVED_STATUS:
      return state.merge({
        status: action.data,
      })

    case KioskMessagesAction.START_LISTEN_BADGE_ID:
      return state.merge({
        isListeningBadgeId: true,
      })

    case KioskMessagesAction.STOP_LISTEN_BADGE_ID:
      return state.merge({
        isListeningBadgeId: false,
      })

    default:
      return state
  }
}

export const kioskInteractorReducer = combineReducers({
  connectivity: createSimpleReducer(KioskConnectivtyAction),
  nfc: kioskNfcReducer,
  print: createSimpleReducer(KioskPrintAction),
  payment: createSimpleReducer(KioskPaymentAction, {
    [KioskPaymentAction.RESET]: state => state.merge({ data: null, error: null }),
  }),
  peripherals: createSimpleReducer(KioskGetPeripheralsStatusAction),
  registration: createSimpleReducer(KioskRegistrationAction),
  scanner: kioskScanReducer,
  sharingMessage: kioskSharingMessageReducer,
})
