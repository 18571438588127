import React from 'react'
import isNil from 'lodash/isNil'

import { tr } from 'pmt-modules/i18n'
import { RoutingContainer } from 'pmt-modules/routing'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { TypographyCustom } from 'pmt-ui/Typography'

import { makeStyles } from '@material-ui/core/styles'

import BackTo from 'app/components/BackTo'
import OrderHeader from 'app/components/OrderHeader'

import WithPadOnlyView from './components/WithPadOnlyView'
import WithScanView from './components/WithScanView'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
  },
  backTo: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
    height: 'calc(100% - 64px)', // theme spacing 8-^
  },
  caption: {
    lineHeight: '1.2',
    marginTop: theme.spacing(8),
    whiteSpace: 'pre-line',
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
}))

const View = ({ frontAppConfig, kioskSettings, orderAppConfig, restaurant }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <OrderHeader
        frontAppConfig={frontAppConfig}
        restaurant={restaurant}
        kioskSettings={kioskSettings}
      />
      <RoutingContainer>
        {({ redirectTo, getRoute }) => (
          <BackTo
            text={tr('kiosk.order.back_to_cart')}
            handleOnClick={() => redirectTo(getRoute('KIOSK__ORDER__CART'))}
            classes={{ root: classes.backTo }}
          />
        )}
      </RoutingContainer>
      <div className={classes.content}>
        <div className={classes.root}>
          <CustomTextsContainer>
            {({ texts }) =>
              !isNil(texts?.KIOSK__ORDER__SCAN__CAPTION) ? (
                <TypographyCustom
                  type="407"
                  align="center"
                  className={classes.caption}
                  dangerouslySetInnerHTML={{
                    __html: texts.KIOSK__ORDER__SCAN__CAPTION,
                  }}
                />
              ) : (
                <TypographyCustom type="407" align="center" className={classes.caption}>
                  {tr('kiosk.order.page.scan.caption.1')}
                  <span>{tr('kiosk.order.page.scan.caption.2')}</span>
                  {tr('kiosk.order.page.scan.caption.3')}
                </TypographyCustom>
              )
            }
          </CustomTextsContainer>
          {orderAppConfig.hasPagerCaptureScanner2D || orderAppConfig.hasPagerCaptureContactless ? (
            <WithScanView orderAppConfig={orderAppConfig} frontAppConfig={frontAppConfig} />
          ) : (
            <WithPadOnlyView />
          )}
        </div>
      </div>
    </div>
  )
}

export default View
