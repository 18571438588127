import React from 'react'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'

import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import Price from 'pmt-ui/Price'
import NavigationCheck from 'pmt-ui/svg-icons/navigation/check'

import { makeStyles } from 'pmt-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    boxShadow: '2px 2px 4px 0px rgba(158,158,158,1)',
  },
  rootSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  rootDisabled: {
    opacity: 0.3,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 125,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `0 ${theme.spacing(2)}px`,
  },
  name: {
    flex: 1,
    ...theme.pmt.appConfig.itemCardContentName,
  },
  placeholderContainer: {
    backgroundColor: theme.pmt.colors.grey100,
  },
  placeholder: {
    maxWidth: 125,
    maxHeight: 125,
    opacity: 0.3,
  },
  priceContainer: {
    height: 24,
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    lineHeight: '24px',
    textAlign: 'center',
  },
  price: {
    float: 'none',
    margin: '0 auto',
  },
  iconSelectedContainer: {
    position: 'absolute',
    bottom: -35,
    right: -35,
    width: 70,
    height: 70,
    background: theme.palette.primary.main,
    transform: 'rotate(0.125turn)',
    zIndex: 90,
  },
  iconSelected: {
    position: 'absolute',
    bottom: 25,
    right: 45,
    width: 20,
    height: 20,
    transform: 'rotate(-0.125turn)',
    color: theme.pmt.colors.white,
  },
}))

const OptionValueCard = ({ checked, value, disabled, restaurant, onClick }) => {
  const classes = useStyles()

  return (
    <Card
      className={clsx(classes.root, {
        [classes.rootSelected]: checked,
        [classes.rootDisabled]: disabled,
      })}
      onClick={onClick}
    >
      <CardContent className={classes.content}>
        <div
          className={clsx(classes.imageContainer, {
            [classes.placeholderContainer]: isEmpty(value.image),
          })}
        >
          {!isEmpty(value.image) ? (
            <div style={{ backgroundImage: `url('${value.image}')` }} className={classes.image} />
          ) : (
            <img
              src={restaurant.logo}
              className={clsx(classes.image, classes.placeholder)}
              alt={value.name}
            />
          )}
        </div>
        {checked && (
          <div className={classes.iconSelectedContainer}>
            <NavigationCheck className={classes.iconSelected} />
          </div>
        )}
        <div className={classes.textContainer}>
          <TypographyCustom type="207" align="center" className={classes.name}>
            {value.name}
          </TypographyCustom>
          {value.additionalPrice > 0 && (
            <TypographyCustom type="247" className={classes.priceContainer}>
              <Price
                value={`+${value.additionalPriceFormatted}`}
                classes={{ finalValue: classes.price }}
              />
            </TypographyCustom>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default OptionValueCard
