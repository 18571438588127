import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    height: 275,
    background: theme.pmt.colors.white,
    zIndex: 1000,
  },
  logoContainer: {
    position: 'relative',
    float: 'left',
    width: '18.5%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  logo: {
    maxWidth: '80%',
    maxHeight: '80%',
  },
  restaurantNameContainer: {
    background: theme.pmt.colors.greyEF,
    padding: theme.spacing(5),
    display: 'flex',
    flex: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  restaurantName: {
    marginBottom: theme.spacing(1),
  },
  externalAd: {
    float: 'left',
    width: '81.5%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  ad: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  hr: {
    position: 'absolute',
    bottom: 0,
    width: '80%',
    margin: '0 auto',
    borderTop: `2px solid ${theme.pmt.colors.greyEF}`,
  },
})

const OrderHeader = ({ frontAppConfig, kioskSettings, restaurant, classes }) => (
  <div className={classes.root}>
    <div className={classes.logoContainer}>
      <img src={frontAppConfig.theme.logo.path || restaurant.logo} alt="restaurant-logo" className={classes.logo} />
      <hr className={classes.hr} />
    </div>
    {kioskSettings.order.advertisingImage ? (
      <div className={classes.externalAd}>
        <img src={kioskSettings.order.advertisingImage} alt="restaurant-ad" className={classes.ad} />
      </div>
    ) : (
      <div className={classes.restaurantNameContainer}>
        <TypographyCustom type="324" skipColor className={classes.restaurantName}>
          {tr('kiosk.header.your_restaurant')}
        </TypographyCustom>
        <TypographyCustom type="407" skipColor>
          {restaurant.name}
        </TypographyCustom>
      </div>
    )}
  </div>
)


export default withStyles(styles)(OrderHeader)
