import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import isNil from 'lodash/isNil'

import { getInactivityTimeoutInstance, resetKiosk, setInactivityTimeout } from 'pmt-modules/kiosk'
import { printContent } from 'pmt-modules/kioskInteractor'
import CheckoutTicketPrinter from 'pmt-modules/kioskInteractor/utils/printer/CheckoutTicket'
import { getDataGetKioskSettings } from 'pmt-modules/kioskSettings'
import { getCheck } from 'pmt-modules/payment/check'
import { getDataPostPaymentEmail, getPostPayment } from 'pmt-modules/payment/payment/selectors'

import Button from 'pmt-ui/Button'
import Divider from 'pmt-ui/Divider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import ConfirmChoiceStepEmail from './ConfirmChoiceStepEmail'
import ConfirmChoiceStepFinal from './ConfirmChoiceStepFinal'
import Logger from 'pmt-utils/logger/index'

const styles = theme => ({
  root: {
    width: 735,
    margin: 'auto',
    marginTop: theme.spacing(11),
    marginBottom: theme.spacing(11),
    background: theme.pmt.colors.greyEF,
    padding: theme.spacing(6),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  button: {
    width: '100%',
    height: 126,
    lineHeight: 1.1,
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    border: `3px solid ${theme.palette.primary.main}`,
    whiteSpace: 'pre-line',
  },
  divider: {
    background: theme.palette.text.primary,
    marginTop: theme.spacing(4),
  },
  modal: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.pmt.colors.white,
    display: 'flex',
    flexDirection: 'column',
  },
})

class ConfirmChoices extends React.Component {
  static Choices = {
    EMAIL: 'EMAIL',
    EMAIL_FINAL: 'EMAIL_FINAL',
    FINAL: 'FINAL',
  }

  constructor(props) {
    super(props)

    this.state = { isPrinterBusy: false }

    if (!props.payment || !props.payment.id) {
      Logger.error('Kiosk Self-Checkout', 'Missing payment on confirm page', {
        props,
      })
      this.printCheckReceiptAndSelectFinalStep()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.dataPostPaymentEmail && nextProps.dataPostPaymentEmail) {
      this.handleSelectStep(ConfirmChoices.Choices.EMAIL_FINAL)
    }
  }

  printCheckReceipt = () => {
    const printer = new CheckoutTicketPrinter()
    const { check, kioskSettings, printContent, restaurant } = this.props

    printContent(
      printer.format(restaurant, check, {
        kioskSettings,
      })
    )
  }

  printCheckReceiptAndSelectFinalStep = () => {
    this.setState({
      isPrinterBusy: true,
    })

    // printer might be busy from previous bank receipt
    setTimeout(() => {
      this.setState({
        isPrinterBusy: false,
      })
      this.printCheckReceipt()
      this.handleSelectStep(ConfirmChoices.Choices.FINAL)
    }, 2000)
  }

  handleSelectStep = step => {
    if (step === ConfirmChoices.Choices.FINAL || step === ConfirmChoices.Choices.EMAIL_FINAL) {
      this.props.setInactivityTimeout(() => {
        this.props.resetKiosk()
      }, 15000)
    }

    this.props.updateUI({
      step,
    })
  }

  render() {
    const { classes, frontAppConfig, onGoBackHome, onGoToScan, restaurant, ui } = this.props

    return (
      <div className={classes.root}>
        {isNil(ui.step) ? (
          <Fragment>
            <TypographyCustom type="407" align="center" className={classes.title}>
              {tr('kiosk.checkout.page.confirm.choices.title')}
            </TypographyCustom>

            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleSelectStep(ConfirmChoices.Choices.EMAIL)}
              className={classes.button}
            >
              <TypographyCustom type="327" skipColor align="center">
                {tr('kiosk.checkout.page.confirm.choices.ticket_by_email')}
              </TypographyCustom>
            </Button>
            <Divider className={classes.divider} />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.handleSelectStep(ConfirmChoices.Choices.FINAL)}
              className={classes.button}
            >
              <TypographyCustom type="327" skipColor align="center">
                {tr('kiosk.checkout.page.confirm.choices.no_ticket')}
              </TypographyCustom>
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.printCheckReceiptAndSelectFinalStep}
              className={classes.button}
            >
              {this.state.isPrinterBusy ? (
                <LoadingBlockWrapper show={true} />
              ) : (
                <TypographyCustom type="327" skipColor align="center">
                  {tr('kiosk.checkout.page.confirm.choices.print_ticket')}
                </TypographyCustom>
              )}
            </Button>
          </Fragment>
        ) : (
          <div className={classes.modal}>
            {ui.step === ConfirmChoices.Choices.EMAIL && (
              <ConfirmChoiceStepEmail frontAppConfig={frontAppConfig} restaurant={restaurant} />
            )}
            {(ui.step === ConfirmChoices.Choices.FINAL ||
              ui.step === ConfirmChoices.Choices.EMAIL_FINAL) && (
              <ConfirmChoiceStepFinal
                frontAppConfig={frontAppConfig}
                onGoBackHome={onGoBackHome}
                onGoToScan={onGoToScan}
                restaurant={restaurant}
                withEmail={ui.step === ConfirmChoices.Choices.EMAIL_FINAL}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  check: getCheck(state),
  dataPostPaymentEmail: getDataPostPaymentEmail(state),
  inactivityTimeout: getInactivityTimeoutInstance(state),
  kioskSettings: getDataGetKioskSettings(state),
  payment: getPostPayment(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      printContent,
      resetKiosk,
      setInactivityTimeout,
    }
  ),
  ui({
    state: {
      step: null,
    },
  }),
  withStyles(styles)
)(ConfirmChoices)
