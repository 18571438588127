import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import isNil from 'lodash/isNil'

import { createFormatter } from 'pmt-utils/format'

import { mergeSuggestedProductsAndMenus } from '../utils/suggestion'

import { formatItemsCartModifiers } from './cartModifiers'

const recursiveFlattenSuggestions = (category, o) => {
  if (!isEmpty(category.categories)) {
    category.categories.forEach(c => recursiveFlattenSuggestions(c, o))
  }

  category.items = category.items.map(item => {
    let suggestedItems = mergeSuggestedProductsAndMenus(item.productsSuggested, item.menusSuggested)

    item.suggestedItems = suggestedItems.filter(suggestedItem => suggestedItem.available)
    item.hasSuggestion = !isNil(item.suggestedItems) && !isEmpty(item.suggestedItems)

    if (item.hasSuggestion && !find(o.suggestions, { id: item.id })) {
      o.suggestions.push({
        id: item.id,
        name: item.name,
        suggestedItems: formatItemsCartModifiers(item.suggestedItems),
      })
    }
    return item
  })

  return {
    category,
    suggestions: o.suggestions,
  }
}

const formatSuggestion = catalog => {
  let o = {
    category: {},
    suggestions: [],
  }

  catalog.categories = catalog.categories.map(category => {
    o = recursiveFlattenSuggestions(category, o)
    return o.category
  })

  catalog.suggestions = o.suggestions

  return catalog
}

export const formatSuggestions = createFormatter(formatSuggestion)
