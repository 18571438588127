import { findOnArray } from 'pmt-utils/array'
import areSameProduct from './areSameProduct'

export { default as createOrderProductFromProduct } from './createOrderProductFromProduct'

export { default as areSameProduct } from './areSameProduct'

// return a product set in cart
export const getProductFromCart = (itemCartList, product) =>
  findOnArray(itemCartList, itemFromList => areSameProduct(itemFromList, product))

export const getProductFromCartWithOrderId = (itemCartList, orderId) =>
  findOnArray(itemCartList, itemFromList => itemFromList.orderId === orderId)
