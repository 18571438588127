import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let HardwareKeyboardArrowDown = props => (
  <SvgIcon {...props}>
    <path d="M8.12,9.29L12,13.17l3.88-3.88c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.59,4.59 c-0.39,0.39-1.02,0.39-1.41,0l-4.59-4.59c-0.39-0.39-0.39-1.02,0-1.41l0,0C7.09,8.91,7.73,8.9,8.12,9.29z" />
  </SvgIcon>
)
HardwareKeyboardArrowDown = pure(HardwareKeyboardArrowDown)
HardwareKeyboardArrowDown.displayName = 'HardwareKeyboardArrowDown'
HardwareKeyboardArrowDown.pmtName = 'SvgIcon'

export default HardwareKeyboardArrowDown
