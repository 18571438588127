import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionHome from 'pmt-ui/svg-icons/action/home'

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    background: theme.pmt.colors.grey4A,
    textTransform: 'none',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  rootExpanded: {
    display: 'block',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  iconContainer: {
    display: 'flex',
  },
  iconContainerExpanded: {
    display: 'inline-block',
    float: 'none',
    marginBottom: theme.spacing(3),
  },
  icon: {
    width: 40,
    height: 42,
    color: theme.pmt.colors.white,
  },
  text: {
    color: theme.pmt.colors.white,
    height: 46,
    lineHeight: '46px',
    marginLeft: theme.spacing(1),
  },
  textExpanded: {
    display: 'block',
    float: 'none',
    height: 'auto',
    lineHeight: 'initial',
    marginLeft: 0,
  },
})

const BackHomeButton = ({ expandedVersion, classes, ...otherProps }) => (
  <Button
    variant="contained"
    className={classNames(classes.root, {
      [classes.rootExpanded]: expandedVersion,
    })}
    {...otherProps}
  >
    <React.Fragment>
      <div className={classNames(classes.iconContainer, {
        [classes.iconContainerExpanded]: expandedVersion,
      })}>
        <ActionHome className={classes.icon} />
      </div>
      <TypographyCustom
        type="207"
        className={classNames(classes.text, {
          [classes.textExpanded]: expandedVersion,
        })}
        align="center"
        component="div"
      >
        {expandedVersion ? tr('kiosk.order.button.home_expanded') : tr('kiosk.order.button.home')}
      </TypographyCustom>
    </React.Fragment>
  </Button>
)

BackHomeButton.propTypes = {
  expandedVersion: PropTypes.bool.isRequired,
}

export default withStyles(styles)(BackHomeButton)
