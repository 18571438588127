import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { getCatalogFormatted } from 'pmt-modules/catalog/selectors'

import { formatSuggestion } from './format'

const getSuggestionState = state => (isNil(state) ? null : state.entities.suggestion)

export const getSuggestion = createSelector(
  [getSuggestionState, getCatalogFormatted],
  (suggestionState, formattedCatalog) => {
    if (isNil(suggestionState) || isNil(formattedCatalog)) {
      return null
    }

    const orderItem = suggestionState.get('orderItem')
    if (isNil(orderItem)) {
      return null
    }

    return formatSuggestion(orderItem.toJS(), {
      catalog: formattedCatalog,
    })
  }
)
