import { createSimpleSelector } from 'pmt-modules/redux'

import { formatOrder } from 'pmt-modules/order/format'

const getOrderPost = state => state.entities.orderPost

const orderPostSelectors = createSimpleSelector(getOrderPost, formatOrder)

export const isFetchingOrderPost = orderPostSelectors.isFetching
export const getDataOrderPost = orderPostSelectors.getData
export const getErrorOrderPost = orderPostSelectors.getError
