import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { withForm, FormType } from 'pmt-modules/form'

import TextField from 'pmt-ui/TextField'
import PasswordField from 'pmt-ui/PasswordField'

const LoginFormView = withForm(FormType.RESTAURANT_LOGIN)(
  ({ formData, formErrors, onChange }) => (
    <div>
      <TextField
        type="text"
        label="Restaurant Id"
        fullWidth
        value={formData.restaurantId}
        onChange={event =>
          onChange({ ...formData, restaurantId: event.target.value })
        }
        error={
          !isNil(formData.restaurantId) &&
          !isEmpty(formErrors.restaurantId.message)
        }
        helperText={
          !isNil(formData.restaurantId) &&
          !isEmpty(formErrors.restaurantId.message)
            ? formErrors.restaurantId.message
            : false
        }
      />
      <TextField
        type="text"
        label="API Consumer"
        className="u-marginTop10"
        fullWidth
        value={formData.apiConsumer}
        onChange={event =>
          onChange({ ...formData, apiConsumer: event.target.value })
        }
        error={
          !isNil(formData.apiConsumer) &&
          !isEmpty(formErrors.apiConsumer.message)
        }
        helperText={
          !isNil(formData.apiConsumer) &&
          !isEmpty(formErrors.apiConsumer.message)
            ? formErrors.apiConsumer.message
            : false
        }
      />
      <PasswordField
        label="Mot de passe"
        className="u-marginTop10"
        value={formData.password}
        onChange={event =>
          onChange({ ...formData, password: event.target.value })
        }
        error={
          !isNil(formData.password) && !isEmpty(formErrors.password.message)
        }
        helperText={
          !isNil(formData.password) && !isEmpty(formErrors.password.message)
            ? formErrors.password.message
            : false
        }
        fullWidth
      />
    </div>
  )
)

export default LoginFormView
