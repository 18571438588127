import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import each from 'lodash/each'
import sortBy from 'lodash/sortBy'

import { findOnArray } from 'pmt-utils/array'

import { CatalogItemType } from 'pmt-modules/catalog/constants'
import { areSameProduct } from 'pmt-modules/orderProduct/utils'
import { getPartSelectedProducts } from 'pmt-modules/orderMenu/utils/category'

export const areMenuModifiersEqual = (menuToCheck, menuToCompare) => {
  return isEqual(sortBy(menuToCheck.modifierIds), sortBy(menuToCompare.modifierIds))
}

// check if products contained in parts are equal
export const areMenuPartProductsEqual = (partToCheck, partToCompare) => {
  if (partToCheck.nbChosenValues !== partToCompare.nbChosenValues) {
    return false
  }

  const toCheckValues = getPartSelectedProducts(partToCheck)
  const toCompareValues = getPartSelectedProducts(partToCompare)

  if (toCompareValues.length !== toCheckValues.length) {
    return false
  }

  const isEqual = isEqualWith(
    toCheckValues,
    toCompareValues,
    (productsToCheck, productsToCompare) => {
      //
      // verify each option value
      //
      let i = 0
      let length = productsToCheck.length
      while (i < length) {
        const productToCheck = productsToCheck[i]
        const productToCompare = productsToCompare[i]
        const productIsEqual = areSameProduct(productToCheck, productToCompare)

        if (!productIsEqual) {
          return false
        }
        ++i
      }
      return true
    }
  )

  return isEqual
}

// check if menu parts are equal
export const areMenuPartsEqual = (menuToCheck, menuToCompare) => {
  if (menuToCheck.parts.length !== menuToCompare.parts.length) {
    return false
  }

  let haveSameParts = true
  each(menuToCheck.parts, partToCheck => {
    let partToCompare = findOnArray(
      menuToCompare.parts,
      partToCompare => partToCheck.id === partToCompare.id
    )

    if (!partToCompare) {
      haveSameParts = false
      return false
    }

    // in orderPreviewMode, we have the selectedProducts data
    haveSameParts = areMenuPartProductsEqual(partToCheck, partToCompare)
    if (!haveSameParts) {
      haveSameParts = false
      return false
    }
  })

  return haveSameParts
}

/**
 * check if a menu (not from the cart) exists in cart
 *
 * /!\ The data could not be the same from where the call is made (cart or order preview)
 * For example, from the cart, we do not have the selectedProducts
 */
const areSameMenu = (menuToCheck, menuToCompare) => {
  if (menuToCheck.type !== CatalogItemType.MENU) {
    return false
  }

  if (menuToCheck.id !== menuToCompare.id) {
    return false
  }

  return (
    areMenuPartsEqual(menuToCheck, menuToCompare) &&
    areMenuModifiersEqual(menuToCheck, menuToCompare)
  )
}

export default areSameMenu
