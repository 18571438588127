// TODO: I18n
// TODO: list errors
const TopUpErrors = {
  POST: {
    119: 'global.top_up.errors.failed_too_many_times',
    125: 'global.top_up.errors.failed_try_again',
    126: 'global.top_up.errors.transaction_refused',
    127: 'global.top_up.errors.transaction_refused',
    128: 'global.top_up.errors.transaction_refused',
    129: 'global.top_up.errors.transaction_refused',
    130: 'global.top_up.errors.card_expired',
    131: 'global.top_up.errors.card_inactive',
    132: 'global.top_up.errors.card_inactive',
    133: 'global.top_up.errors.max_attempts',
    134: 'global.top_up.errors.max_amount',
    135: 'global.top_up.errors.max_uses',
    136: 'global.top_up.errors.debit_limit',
    137: 'global.top_up.errors.amount',
    138: 'global.top_up.errors.transaction_refused',
    // default: 'Erreur inconnue',
  },
}

export default TopUpErrors
