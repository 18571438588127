import isNil from 'lodash/isNil'

import { OrderMode } from 'pmt-modules/order/constants'

import { millisecondsToDays } from 'pmt-utils/date'

/**
 * Return settings for orderMode received
 */
export const getOrderSettingsForMode = (
  orderMode,
  onSiteSettings,
  takeAwaySettings,
  deliverySettings
) => {
  switch (orderMode) {
    case OrderMode.ON_SITE:
      return onSiteSettings
    case OrderMode.TAKE_AWAY:
      return takeAwaySettings
    case OrderMode.DELIVERY:
      return deliverySettings
    default:
      return null
  }
}

/**
 * Return maxTimeAhead as days for a specific mode
 */
export const getMaxTimeAheadAsDaysOnMode = (
  mode,
  onSiteSettings,
  takeAwaySettings,
  deliverySettings
) => {
  return getMaxTimeAheadAsDays(
    getOrderSettingsForMode(mode, onSiteSettings, takeAwaySettings, deliverySettings)
  )
}

/**
 * Return maxTimeAhead in days
 */
export const getMaxTimeAheadAsDays = modeSettings => {
  if (isNil(modeSettings) || !modeSettings.enabled) {
    return null
  }

  return millisecondsToDays(modeSettings.maxTimeAhead)
}
