import { createAction } from '../redux'

import { ModalType, createHideDialogAction } from '../dialog'

/**
 * /!\ Any new action should be added on OrderFrontActionsToResetIdEmpotency, to reset the idempotency
 */
export const CartAction = {
  DISPLAY_CART: 'CART::DISPLAY',
  //
  // cart modififers actions
  // see cartModifierActions below
  //
  ADD_TO_CART_FROM_SHORTCUT: 'CART::ADD_TO_CART_FROM_SHORTCUT',
  ADD_ORDER_PRODUCT_TO_CART: 'CART::ADD_ORDER_PRODUCT_TO_CART',
  UPDATE_ORDER_PRODUCT_ON_CART: 'CART::UPDATE_ORDER_PRODUCT_ON_CART',
  ADD_ORDER_MENU_TO_CART: 'CART::ADD_ORDER_MENU_TO_CART',
  UPDATE_ORDER_MENU_ON_CART: 'CART::UPDATE_ORDER_MENU_ON_CART',
  REMOVE_FROM_CART: 'CART::REMOVE_FROM_CART',
  DELETE_FROM_CART: 'CART::DELETE_FROM_CART',
  DUPLICATE_ITEM_ON_CART: 'CART::DUPLICATE_ITEM_ON_CART',

  //
  // setters
  //
  SET_MODIFICATION_DATE: 'CART::SET_MODIFICATION_DATE',
  SET_MINIMUM_PRICE: 'CART::SET_MINIMUM_PRICE',
  UPDATE_COMMENT: 'CART::UPDATE_COMMENT',
  SET_CART: 'CART::SET_CART',
  SET_SUSPENDED_DATAS: 'CART::SET_SUSPENDED_DATAS',

  //
  //
  //
  EDIT_ITEM: 'CART::EDIT_ITEM',

  //
  //
  //
  RESET_CART: 'CART::RESET',
}

/**
 * List the cart actions that must trigger an idempotency reset.
 */
export const CartActionsToResetIdEmpotency = [
  CartAction.ADD_TO_CART_FROM_SHORTCUT,
  CartAction.ADD_ORDER_PRODUCT_TO_CART,
  CartAction.UPDATE_ORDER_PRODUCT_ON_CART,
  CartAction.ADD_ORDER_MENU_TO_CART,
  CartAction.UPDATE_ORDER_MENU_ON_CART,
  CartAction.REMOVE_FROM_CART,
  CartAction.DELETE_FROM_CART,
  CartAction.DUPLICATE_ITEM_ON_CART,

  // CartAction.UPDATE_COMMENT,
  CartAction.SET_CART,

  CartAction.EDIT_ITEM,

  CartAction.RESET_CART,
]

/**
 * Array of all cart actions that leads to a cart modifications.
 * Used for the catchCartActionMiddleware.
 */
export const cartModifierActions = [
  CartAction.DISPLAY_CART,
  CartAction.ADD_TO_CART_FROM_SHORTCUT,
  CartAction.ADD_ORDER_PRODUCT_TO_CART,
  CartAction.ADD_ORDER_MENU_TO_CART,
  CartAction.UPDATE_ORDER_PRODUCT_ON_CART,
  CartAction.UPDATE_ORDER_MENU_ON_CART,
  CartAction.REMOVE_FROM_CART,
  CartAction.DELETE_FROM_CART,
  CartAction.DUPLICATE_ITEM_ON_CART,
  CartAction.SET_SUSPENDED_DATAS,
]

export const displayCart = () => createAction(CartAction.DISPLAY_CART)

export const addToCartFromShortcut = item =>
  createAction(CartAction.ADD_TO_CART_FROM_SHORTCUT, {
    item,
  })

export const addOrderProductToCart = (orderProduct, options) =>
  createAction(CartAction.ADD_ORDER_PRODUCT_TO_CART, {
    orderProduct,
    options,
  })

export const updateOrderProductOnCart = orderProduct =>
  createAction(CartAction.UPDATE_ORDER_PRODUCT_ON_CART, {
    orderProduct,
  })

export const addOrderMenuToCart = (orderMenu, options) =>
  createAction(CartAction.ADD_ORDER_MENU_TO_CART, {
    orderMenu,
    options,
  })

export const updateOrderMenuOnCart = orderMenu =>
  createAction(CartAction.UPDATE_ORDER_MENU_ON_CART, {
    orderMenu,
  })

export const duplicateItemOnCart = item =>
  createAction(CartAction.DUPLICATE_ITEM_ON_CART, {
    item,
  })

export const removeFromCart = item =>
  createAction(CartAction.REMOVE_FROM_CART, {
    item,
  })

export const deleteFromCart = item =>
  createAction(CartAction.DELETE_FROM_CART, {
    item,
  })

export const setModificationDate = date =>
  createAction(CartAction.SET_MODIFICATION_DATE, {
    date,
  })

export const setMinimumPrice = minimumPrice =>
  createAction(CartAction.SET_MINIMUM_PRICE, {
    minimumPrice,
  })

export const updateComment = comment =>
  createAction(CartAction.UPDATE_COMMENT, {
    comment,
  })

export const setSuspendedDatas = (suspendedItem, suspendedActionType) =>
  createAction(CartAction.SET_SUSPENDED_DATAS, {
    suspendedItem,
    suspendedActionType,
  })

export const setCart = ({ itemList }) =>
  createAction(CartAction.SET_CART, {
    itemList,
  })

/**
 * @param {*} options
 *    - restaurantId {string}
 *    - fromRoute {Route} the route from where we edit the item. We will redirect to this route after
 *      the edit is done.
 */
export const editItem = (item, options) =>
  createAction(CartAction.EDIT_ITEM, {
    item,
    options,
  })

export const hideDeleteCartDialog = () => createHideDialogAction(ModalType.DELETE_CART)

export const resetCart = (doOrderPreview = true) =>
  createAction(CartAction.RESET_CART, {
    doOrderPreview,
  })
