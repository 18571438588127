import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { withAppConfig } from 'pmt-modules/appConfig'
import { getErrorCatalog } from 'pmt-modules/catalog'
import { getDataKioskPeripheralsStatus } from 'pmt-modules/kioskInteractor/selectors'
import { resetKiosk } from 'pmt-modules/kiosk'
import { getDataGetKioskSettings, refreshKioskSettings } from 'pmt-modules/kioskSettings'
import { selectMode } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { refreshRestaurant } from 'pmt-modules/restaurant'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import Logger from 'pmt-utils/logger'

import RoutingEnum from '../../config/routingEnum'
import KioskPage from '../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-660
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b50951d89fd09d0b8d09cd7
 */
@withAppConfig
@withRestaurant
class HomePage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.props.resetKiosk({ clean: true })

    if (props.frontAppConfig.home) {
      if (!getRoute(props.frontAppConfig.home)) {
        Logger.error('Setup', `route ${props.frontAppConfig.home} doesn't exist for Kiosk`)
        // skip a redirection if home from appConfig is the same as the URL of this page
      } else if (props.frontAppConfig.home !== RoutingEnum.HOME.name) {
        this.props.redirectTo(getRoute(props.frontAppConfig.home))
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // once we receive kioskSettings, we can reset kiosk
    // this is used when we load the app for the first time and mandatory to init catalog
    if (!this.props.kioskSettings && nextProps.kioskSettings) {
      this.props.resetKiosk({ clean: true })
    }
  }

  handleClickOrder = modes => {
    this.props.refreshRestaurant(this.props.restaurant.id, {
      restaurant: this.props.restaurant,
    })
    this.props.refreshKioskSettings(this.props.restaurant.id, {
      modes,
    })

    if (
      this.props.restaurant.valided &&
      !isEmpty(modes) &&
      this.props.restaurant.orderSettings.isCurrentlyOpen
    ) {
      if (modes.length === 1) {
        this.props.selectMode(modes[0])
        this.props.redirectTo(getRoute('KIOSK__ORDER__CATEGORY'))
      } else {
        this.props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
      }
    } else {
      if (!this.props.restaurant.valided) {
        console.warn(`restaurant disabled`)
      }
      if (isEmpty(modes)) {
        console.warn(`no modes`)
      }
      if (!this.props.restaurant.orderSettings.isCurrentlyOpen) {
        console.warn(`not currently opened`)
      }
    }
  }

  handleClickTopUp = () => {
    this.props.redirectTo(getRoute('KIOSK__TOP_UP__LOGIN'))
  }

  render() {
    const {
      catalogError,
      isFetchingRestaurant,
      kioskPeripheralsStatus,
      restaurant,
      frontAppConfig,
    } = this.props

    return (
      <KioskPage route={this.props.route} isFetching={!restaurant}>
        {({ kioskSettings }) => (
          <View
            catalogError={catalogError}
            isFetchingRestaurant={isFetchingRestaurant}
            restaurant={restaurant}
            frontAppConfig={frontAppConfig}
            kioskPeripheralsStatus={kioskPeripheralsStatus}
            kioskSettings={kioskSettings}
            onClickOrder={() => this.handleClickOrder(kioskSettings.order.modes)}
            onClickTopUp={this.handleClickTopUp}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authRestaurantId: getRestaurantId(state),
  catalogError: getErrorCatalog(state),
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  kioskSettings: getDataGetKioskSettings(state, props),
})

export default connect(mapStateToProps, {
  redirectTo,
  refreshKioskSettings,
  refreshRestaurant,
  resetKiosk,
  selectMode,
})(HomePage)
