/**
 * Transform our menu data to be saved on our store:
 * - remove formatted data that can lead to infinite loops (reference chain)
 * - remove formatted data that takes too much memory
 *
 * Note: A better/generic solution could be to add and `__store_ignore_[KEY]` to our object format
 * data, and patch immutable.js to handle it. e.g on part data: `__store_ignore_nextPart`
 * Or: create an utils that takes the paths to remove.
 */
const transformOrderMenuForStore = orderMenu => {
  const cartMenu = orderMenu

  cartMenu.parts = cartMenu.parts.map(part => {
    delete part.nextPart
    return part
  })

  return cartMenu
}

export default transformOrderMenuForStore
