import { createSimpleReducer } from '../redux'

import { FetchRestaurantUserAccountsAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './components'
export * from './middlewares'

export const restaurantUserAccountsReducer = createSimpleReducer(FetchRestaurantUserAccountsAction)
