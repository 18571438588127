import { tr } from 'pmt-modules/i18n'
import React from 'react'

import moment from 'moment'
import Divider from 'pmt-ui/Divider'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import ErrorOutline from 'pmt-ui/svg-icons/action/error-outline'
import ActionHome from 'pmt-ui/svg-icons/action/home'
import { TypographyCustom } from 'pmt-ui/Typography'

import { ItemsContainer } from '../../../components/Cart'
import IconLabelMode, { TYPE_COLORS } from '../../../components/IconLabelMode'

const styles = theme => ({
  root: {
    flex: 1,
    background: theme.pmt.colors.greyEF,
    padding: theme.spacing(8),
    position: 'relative',
  },
  errorIcon: {
    color: theme.pmt.colors.red800,
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.pmt.colors.red800,
  },
  caption: {
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.red800,
  },
  staff: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  cartContent: {
    background: theme.pmt.colors.white,
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
    height: 820,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  cartTitle: {
    marginTop: theme.spacing(4),
  },
  cartPayment: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7),
    color: theme.pmt.colors.grey91,
  },
  cartDetail: {
    flex: 1,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  divider: {
    backgroundColor: theme.pmt.colors.grey91,
    marginBottom: theme.spacing(4),
  },
  modeAndTotalContainer: {
    display: 'flex',
  },
  modeContainer: {
    justifyContent: 'flex-start',
    flex: 1,
    marginLeft: -26,
  },
  icon: {
    fontSize: 90,
  },
  modeLabel: {
    height: 90,
    lineHeight: '90px',
  },
  cartTotal: {
    display: 'flex',
    lineHeight: '90px',

    '& > div:first-child': {
      marginRight: theme.spacing(6),
    },
  },
  payment: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  homeIcon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    position: 'absolute',
    bottom: theme.spacing(8),
    right: theme.spacing(8),
  },
})

const View = ({ classes, itemList, mode, onBackHome, restaurant, totalCartPrice }) => (
  <div className={classes.root}>
    <ErrorOutline className={classes.errorIcon} />
    <TypographyCustom type="407" className={classes.title} skipColor>
      {tr('kiosk.order.page.order_post_error.title')}
    </TypographyCustom>
    <TypographyCustom type="324" className={classes.caption} skipColor>
      {tr('kiosk.order.page.order_post_error.caption')}
    </TypographyCustom>

    <TypographyCustom type="324" className={classes.staff}>
      {tr('kiosk.order.page.order_post_error.staff')}
    </TypographyCustom>

    <div className={classes.cartContent}>
      <TypographyCustom type="407" className={classes.cartTitle}>
        {tr('kiosk.order.page.order_post_error.cart_title', {
          time: moment().format('HH:mm'),
        })}
      </TypographyCustom>
      <TypographyCustom type="324" className={classes.cartPayment} skipColor>
        {tr('kiosk.order.page.order_post_error.cart_payment')}
      </TypographyCustom>

      <div className={classes.cartDetail}>
        <ItemsContainer itemList={itemList} restaurant={restaurant} />
      </div>

      <Divider className={classes.divider} />

      <div className={classes.modeAndTotalContainer}>
        <IconLabelMode
          type="327"
          mode={mode}
          color={TYPE_COLORS.MAIN}
          classes={{
            root: classes.modeContainer,
            icon: classes.icon,
            modeLabel: classes.modeLabel,
          }}
        />
        <div className={classes.cartTotal}>
          <TypographyCustom type="324" skipColor>
            {tr('kiosk.order.page.cart.total')}
          </TypographyCustom>
          <TypographyCustom type="407" skipColor>
            <Price value={totalCartPrice} />
          </TypographyCustom>
        </div>
      </div>
    </div>

    <TypographyCustom type="324" className={classes.payment}>
      {tr('kiosk.order.page.order_post_error.payment')}
    </TypographyCustom>
    <TypographyCustom type="324">{tr('kiosk.order.page.order_post_error.leave')}</TypographyCustom>

    <ActionHome className={classes.homeIcon} onClick={onBackHome} />
  </div>
)

export default withStyles(styles)(View)
