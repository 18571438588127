import Immutable from 'immutable'

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { findOnArray, updateArrayObject } from 'pmt-utils/array'

import { createReducer } from '../redux'

import { OrderProductAction } from './actions'
import { createOrderProductFromProduct } from './utils'
import { orderProductModifier } from './modifiers'

export * from './actions'
export * from './modifiers'
export * from './selectors'
export * from './utils'

const DEFAULT_STATE = Immutable.fromJS({
  orderProduct: null,
  viewOptions: null,
})

//
// state modifiers
//

const updateOrderProduct = (state, orderProductUpdaterFunc) => {
  return state.updateIn(['orderProduct'], orderProductState => {
    return Immutable.fromJS(orderProductModifier(orderProductUpdaterFunc(orderProductState.toJS())))
  })
}

/**
 * Allow to update an option value.
 * This function search for the option value, give it to the valueUpdatedFunc, and
 * save the updated date returned on the state
 *
 * @param {*} state
 * @param {*} action  - option
 *                    - value
 * @param {*} valueUpdaterFunc (option, value). Can modify option and value by reference
 */
const updateOptionValue = (state, action, valueUpdaterFunc) =>
  updateOrderProduct(state, orderProduct => {
    let options = orderProduct.options
    if (isEmpty(options)) {
      // TODO: error
      return
    }

    const option = findOnArray(options, action.option.id)
    const value = findOnArray(option.values, action.value.id)

    // option and value will be modified by reference ont the func
    valueUpdaterFunc(option, value)

    option.values = updateArrayObject(option.values, value, value.id)

    orderProduct.options = updateArrayObject(options, option, option.id)

    return orderProduct
  })

//
// reducer functions
//

const selectProduct = (state, action) => {
  if (isNil(action.product)) {
    return state
  }

  return state
    .set('selectedProduct', Immutable.fromJS(action.product))
    .set(
      'orderProduct',
      Immutable.fromJS(
        action.product.isOrderProduct === true
          ? action.product // note: here product is already the orderProduct
          : createOrderProductFromProduct(action.product, action.options)
      )
    )
    .set('viewOptions', Immutable.fromJS(action.viewOptions))
}

const selectOptionValue = (state, action) => {
  return updateOptionValue(state, action, (option, value) => {
    if (option.quantityMax === 1 && value.quantity === 0) {
      // When the max is 1 and there is another value potentially selected
      // we set the quantity to the value and remove the other selected values
      option.values = option.values.map(value => {
        value.quantity = 0
        return value
      })
      value.quantity = 1
    } else if (option.nbChosenValues < option.quantityMax) {
      value.quantity += 1
    }
  })
}

const unselectOptionValue = (state, action) => {
  return updateOptionValue(state, action, (option, value) => {
    if (value.quantity > 0) {
      value.quantity -= 1
    }
  })
}

//
//
//

const updateComment = (state, action) =>
  updateOrderProduct(state, orderProduct => {
    orderProduct.comment = action.comment
    return orderProduct
  })

//
//
//

const setReclaimLater = (state, action) =>
  updateOrderProduct(state, orderProduct => {
    orderProduct.reclaimLater = action.isChecked
    return orderProduct
  })

//
//
//

export const orderProductReducer = createReducer(DEFAULT_STATE, {
  [OrderProductAction.SELECT_PRODUCT]: selectProduct,
  [OrderProductAction.SELECT_OPTION_VALUE]: selectOptionValue,
  [OrderProductAction.SET_RECLAIM_LATER]: setReclaimLater,
  [OrderProductAction.UNSELECT_OPTION_VALUE]: unselectOptionValue,
  [OrderProductAction.UPDATE_COMMENT]: updateComment,
})
