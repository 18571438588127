import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

import { TypographyCustom } from 'pmt-ui/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(4),
    background: theme.pmt.colors.greyEF,
    '&:last-child': {
      marginBottom: 0,
    },
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}))

const ReclaimLater = ({ orderProduct, setReclaimLater }) => {
  const classes = useStyles()

  if (!orderProduct.reclaimLaterEnabled) {
    return null
  }

  return (
    <div className={classes.root}>
      <TypographyCustom type="324" className={classes.header}>
        {tr('kiosk.order.product.reclaimLater.title')}
      </TypographyCustom>

      <FormControlLabel
        value={orderProduct.reclaimLater}
        control={
          <Checkbox
            color="primary"
            checked={orderProduct.reclaimLater}
            onChange={e => setReclaimLater(e.target.checked)}
          />
        }
        label={
          <TypographyCustom type="244" className={classes.label}>
            {tr('kiosk.order.product.reclaimLater.label')}
          </TypographyCustom>
        }
      />
    </div>
  )
}

export default ReclaimLater
