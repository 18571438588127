import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { withAppConfig } from 'pmt-modules/appConfig'
import { getErrorCatalog } from 'pmt-modules/catalog'
import { getDataKioskPeripheralsStatus } from 'pmt-modules/kioskInteractor/selectors'
import { resetKiosk } from 'pmt-modules/kiosk'
import { getDataGetKioskSettings, refreshKioskSettings } from 'pmt-modules/kioskSettings'
import { selectMode } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { refreshRestaurant } from 'pmt-modules/restaurant'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b509522dae3184e07c5c2ed
 */
@withAppConfig
@withRestaurant
class OrderHomePage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.props.resetKiosk({ clean: true })
  }

  componentWillReceiveProps(nextProps) {
    // once we receive kioskSettings, we can reset kiosk
    // this is used when we load the app for the first time and mandatory to init catalog
    if (!this.props.kioskSettings && nextProps.kioskSettings) {
      this.props.resetKiosk({ clean: true })
    }
  }

  handleRedirection = modes => {
    this.props.refreshRestaurant(this.props.restaurant.id, {
      restaurant: this.props.restaurant,
    })
    this.props.refreshKioskSettings(this.props.restaurant.id, {
      modes,
    })

    if (
      this.props.restaurant.valided &&
      !isEmpty(modes) &&
      this.props.restaurant.orderSettings.isCurrentlyOpen
    ) {
      if (modes.length === 1) {
        this.props.selectMode(modes[0])
        this.props.redirectTo(getRoute('KIOSK__ORDER__CATEGORY'))
      } else {
        this.props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
      }
    }
  }

  render() {
    const {
      frontAppConfig,
      route,
      catalogError,
      isFetchingRestaurant,
      restaurant,
      kioskPeripheralsStatus,
    } = this.props

    return (
      <KioskPage route={route} isFetching={!restaurant}>
        {({ kioskSettings }) => (
          <View
            frontAppConfig={frontAppConfig}
            catalogError={catalogError}
            isFetchingRestaurant={isFetchingRestaurant}
            restaurant={restaurant}
            kioskPeripheralsStatus={kioskPeripheralsStatus}
            kioskSettings={kioskSettings}
            handleRedirection={this.handleRedirection}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  authRestaurantId: getRestaurantId(state),
  catalogError: getErrorCatalog(state),
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  kioskSettings: getDataGetKioskSettings(state, props),
})

export default connect(mapStateToProps, {
  redirectTo,
  refreshKioskSettings,
  refreshRestaurant,
  resetKiosk,
  selectMode,
})(OrderHomePage)
