//
// This module store global configuration and data for the kiosk,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { GetAppConfigAction } from 'pmt-modules/appConfig/actions'
import { getDefaultLocale } from 'pmt-modules/i18n'

import { KioskActions } from './actions'

export * from './actions'
export * from './middlewares'
export * from './selectors'

const DEFAULT = {
  // bool to know whether we are listening to inactivity and thus
  // add an event listener on touchstart action
  isListeningInactivity: false,
  inactivityTimeoutInstance: null,
  locale: getDefaultLocale(),
}

export const kioskReducer = (state = Immutable.fromJS(DEFAULT), action) => {
  // console.log('ACTION : ', action.type)
  switch (action.type) {
    // we want to clean isListeningInactivity value for every refresh of our app
    // but persist reducer will restore previously set isListeningInactivity value
    // so we use appConfig success, which is done only once, at every page load
    case GetAppConfigAction.SUCCESS:
      return state.merge({
        isListeningInactivity: false,
      })
    case KioskActions.SET_INACTIVITY_TIMEOUT_INSTANCE:
      return state.merge({
        isListeningInactivity: true,
        inactivityTimeoutInstance: action.instance,
      })

    case KioskActions.SET_LOCALE:
      return state.merge({
        locale: action.locale,
      })

    case KioskActions.RESET:
      return state.merge({
        inactivityTimeoutInstance: null,
      })

    default:
      return state
  }
}
