import Immutable from 'immutable'

import { createReducer } from 'pmt-modules/redux'
import { GetAsyncPaymentAction } from 'pmt-modules/asyncPayment'

import { PostOrderAction } from './actions'
import { isAsyncPaymentRedirectToPspResponse } from 'pmt-modules/asyncPayment/utils'

export * from './actions'
export * from './selectors'

const DEFAULT_STATE = Immutable.fromJS({
  data: null,
  isFetching: false,
  error: null,
})

const handlePostOrderRequest = state =>
  state.merge({
    data: null,
    isFetching: true,
    error: null,
  })

const handleFinalizeAsyncPaymentOrderRequest = state =>
  state.merge({
    isFetching: true,
  })

const handlePostOrderSuccess = (state, action) =>
  state.merge({
    data: action.response,
    isFetching: false,
    error: null,
  })

const handlePostOrderAsyncPaymentSuccess = (state, action) => {
  if (action.response.isFinalized) {
    state.merge({
      data: {
        ...action.response.linkedObject, // linkedObject is the order
        payment: action.response.payment, // keep payment on order data
      },
      isFetching: false,
      error: null,
    })
  }
  return state
}

const handlePostOrderFailure = (state, action) => {
  if (isAsyncPaymentRedirectToPspResponse(action)) {
    // receive a 303, which contains the order data, with the psp redirect data.
    // but a 303 is considered as an error in our architecture
    return state.merge({
      data: action.error.body,
      isFetching: false,
      error: null,
    })
  } else {
    // not a 303, we received a real error.
    return state.merge({
      data: null,
      isFetching: false,
      error: action.error,
    })
  }
}

const handlePostOrderReset = state =>
  state.merge({
    data: null,
    isFetching: false,
    error: null,
  })

export const orderPostReducer = createReducer(DEFAULT_STATE, {
  [PostOrderAction.REQUEST]: handlePostOrderRequest,
  [GetAsyncPaymentAction.REQUEST]: handleFinalizeAsyncPaymentOrderRequest,
  [PostOrderAction.SUCCESS]: handlePostOrderSuccess,
  [GetAsyncPaymentAction.SUCCESS]: handlePostOrderAsyncPaymentSuccess,
  [PostOrderAction.FAILURE]: handlePostOrderFailure,
  [GetAsyncPaymentAction.FAILURE]: handlePostOrderFailure,
  [PostOrderAction.RESET]: handlePostOrderReset,
})
