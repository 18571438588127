import { generateUUID } from 'pmt-utils/uuid'
import cloneDeep from 'lodash/cloneDeep'
import { pureFunction } from 'pmt-utils/pure'

import transformOrderProductForApi from './transformOrderProductForApi'
import transformOrderMenuForApi from './transformOrderMenuForApi'

const explodeItem = item => {
  const itemList = []
  for (let i = 0; i < item.quantity; i++) {
    // TODO: deep copy ?
    const duplicateItem = cloneDeep(item)
    // baseOrderId contains the orderId that we set on our front cart.
    // we change the orderId to be unique, but want to keep a trace of its orderId on the cart,
    // for the reconciliation
    duplicateItem.baseOrderId = duplicateItem.orderId
    // generate a new orderId for the duplicate items
    if (i !== 0) {
      duplicateItem.orderId = generateUUID()
    }
    itemList.push(duplicateItem)
  }

  return itemList
}

const explodeItems = items =>
  items.reduce((res, item) => {
    // a product have a quantity > 1, we need to de-duplicate it
    const devidedProducts = explodeItem(item)
    return res.concat(devidedProducts)
  }, [])

const transformProducts = products =>
  explodeItems(products).map(product => transformOrderProductForApi(product))

const transformMenus = menus =>
  explodeItems(menus).map(menu => transformOrderMenuForApi(menu))

export const transformCartItemsForApi = pureFunction(itemsListParam => {
  const itemsList = cloneDeep(itemsListParam)
  const cart = {
    products: transformProducts(itemsList.filter(item => item.isProduct)),
    menus: transformMenus(itemsList.filter(item => item.isMenu)),
  }

  return cart
})

export const transformCartForApi = pureFunction(cart => {
  const newCart = {
    ...cart,
    ...transformCartItemsForApi(cart.itemList),
  }

  // remove data that has been spread but we don't want
  delete newCart.itemList
  return newCart
})
