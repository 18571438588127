import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    '& .st0': {
      fill: '#FFFFFF',
    },
    '& .st1': {
      fill: '#AE1221',
    },
    '& .st2': {
      fill: '#3DA933',
    },
  },
})

let CardReader = props => (
  <SvgIcon clsx={props.classes.root} {...props}>
    <path d="M7.2,8.1c0-1.7,0-3.4,0-5.1c0-1.1,0.6-1.7,1.7-1.7c1.9,0,3.9,0,5.8,0c0.8,0,1.4,0.4,1.6,1.2c0,0.2,0,0.3,0,0.5  c0,0.6,0,1.3,0,2c0,0.2,0,0.3,0.2,0.3c1,0.2,2,0.4,3,0.7C20.2,6,20.7,6.4,20.9,7c0.4,1.1,0.8,2.2,1.1,3.3c0.3,1.1,0.6,2.2,1.3,3.2  c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.1,0.2,0.3,0.1,0.5c-1,2-2.5,3.1-4.8,3.3c-0.2,0-0.4-0.1-0.5-0.3c-0.5-0.8-1.1-1.4-1.9-1.9  c-0.5-0.3-0.9-0.6-1.3-0.9c0,0-0.1,0-0.2,0c-0.3,0-0.5,0.1-0.7,0.1c-1.9,0-3.7,0-5.6,0c-0.7,0-1.2-0.3-1.5-0.9  c-0.1-0.2-0.2-0.5-0.2-0.8C7.2,11.5,7.2,9.8,7.2,8.1z M15.6,7.3c0-0.1,0-0.2,0-0.3c0-1.4,0-2.7,0-4.1c0-0.7-0.3-1-0.9-1  c-1.9,0-3.9,0-5.8,0C8.2,2,8,2.3,8,2.9c0,3.4,0,6.9,0,10.4c0,0.7,0.3,0.9,1,0.9c1.9,0,3.7,0,5.6,0c0.1,0,0.1,0,0.3,0  c0-0.1,0-0.1-0.1-0.1c-0.4-0.6-0.5-1.2-0.6-1.9c0-0.4-0.1-0.9-0.3-1.2c-0.3-0.6-0.6-1.2-1-1.8c-0.4-0.7-0.7-1.5-0.6-2.3  c0.1-1,1-1.4,1.9-0.9c0.4,0.2,0.7,0.5,1,0.8C15.2,6.8,15.4,7,15.6,7.3z M16.4,5.8c0,0.5,0,0.9,0,1.4c0,0.2,0.1,0.2,0.2,0.3  c0.5,0.2,1,0.4,1.4,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.3,0.3,0.7,0.3,1c0,0.1,0,0.3,0,0.4c-0.1,0.2-0.1,0.3-0.3,0.5  c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.2-0.7,0.1c-0.4-0.2-0.7-0.6-1-1c-0.3-0.6-0.6-1.2-0.9-1.8c-0.4-0.8-0.9-1.4-1.6-1.8  c-0.3-0.2-0.5-0.1-0.7,0.2c-0.1,0.3-0.1,0.7,0,1c0.1,0.6,0.5,1.1,0.8,1.6c0.6,1,1.1,2,1.1,3.1c0,0.3,0.1,0.5,0.2,0.8  c0.3,0.7,0.8,1.2,1.5,1.6c1,0.6,2,1.3,2.7,2.3c0,0.1,0.2,0.1,0.2,0.1c1.7-0.1,2.9-1,3.7-2.5c0-0.1,0-0.2,0-0.3  c-0.4-0.6-0.8-1.2-1-1.8c-0.4-0.9-0.6-1.8-0.9-2.8c-0.3-0.9-0.6-1.9-1-2.8c-0.1-0.3-0.3-0.4-0.6-0.5C18.6,6.2,17.5,6,16.4,5.8z   M17.5,10.3c0.2-0.4,0.5-0.7,0.3-1.1c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1-0.1-0.1c-0.4-0.2-0.8-0.3-1.1-0.5c0,0.2-0.1,0.4,0,0.6  C16.6,9.4,17,9.9,17.5,10.3z" />
    <path d="M0,12.3C0,9,0,5.7,0,2.3c0-0.5,0.2-0.7,0.7-0.7c1.6,0,3.1,0,4.7,0c0.5,0,0.7,0.2,0.7,0.7c0,6.7,0,13.3,0,20  c0,0.5-0.2,0.7-0.7,0.7c-1.6,0-3.2,0-4.8,0C0.2,23,0,22.8,0,22.3C0,19,0,15.6,0,12.3z M4.6,12.3c0-3.3,0-6.6,0-9.9  c0-0.2-0.1-0.3-0.3-0.3c-1.2,0-2.3,0-3.5,0c-0.2,0-0.3,0-0.3,0.3c0,6.6,0,13.1,0,19.7c0,0.2,0.1,0.3,0.3,0.3c1.2,0,2.3,0,3.5,0  c0.3,0,0.3,0,0.3-0.3C4.6,18.9,4.6,15.6,4.6,12.3z M5.2,2.3c0,6.7,0,13.4,0,20.1c0.1,0,0.2,0,0.3,0c0-6.7,0-13.4,0-20.1  C5.4,2.3,5.3,2.3,5.2,2.3z" />
    <path d="M9.8,19.5c0-0.9,0-1.8,0-2.6c0-0.2,0-0.5,0.2-0.6c0.2-0.1,0.4,0,0.5,0.2s0.1,0.4,0.1,0.6c0,1.7,0,3.3,0,5  c0,0.2-0.1,0.4-0.2,0.6c0,0.1-0.3,0.1-0.3,0.1c-0.1,0-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.3,0-0.4C9.8,21.2,9.8,20.3,9.8,19.5z" />
    <path d="M11.7,17.8c0-0.4,0-0.8,0-1.2c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.5,0.2,0.5,0.4c0,0.8,0,1.6,0,2.4c0,0.3-0.5,0.5-0.7,0.3  c-0.1,0-0.1-0.2-0.1-0.2C11.7,18.7,11.7,18.3,11.7,17.8L11.7,17.8z" />
    <path
      class="st0"
      d="M11,6.6c0-1.1,0-2.2,0-3.3c0-0.1,0-0.2,0-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.4,0.3c0,0.1,0,0.2,0,0.3  c0,2.2,0,4.3,0,6.5c0,0.1,0,0.2,0,0.3c0,0.2,0.2,0.3,0.4,0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3C11,8.7,11,7.6,11,6.6z   M9.5,5.1c0-0.5,0-1,0-1.6c0-0.1,0-0.3,0-0.4c0-0.2-0.2-0.3-0.3-0.3C8.9,2.7,8.8,2.8,8.8,3c0,0.1,0,0.1,0,0.2c0,1.2,0,2.4,0,3.6  c0,0.1,0,0.2,0,0.2C8.8,7.2,9,7.3,9.1,7.3c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3C9.5,6.2,9.5,5.6,9.5,5.1z"
    />
    <path
      class="st0"
      d="M4,4.9c0-0.2,0-0.4,0-0.5C4,4.2,3.9,4,3.7,4S3.4,4.2,3.4,4.4c0,0.4,0,0.7,0,1.1c0,0.2,0.1,0.3,0.3,0.3  C3.9,5.8,4,5.7,4,5.5C4,5.3,4,5.1,4,4.9z M3.4,3.2c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3  c0-0.1,0-0.1,0-0.2c0-0.3-0.1-0.4-0.3-0.4C3.6,2.7,3.4,2.9,3.4,3.2z"
    />
    <path d="M11,6.6c0,1.1,0,2.2,0,3.3c0,0.1,0,0.2,0,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3  c0-2.2,0-4.3,0-6.5c0-0.1,0-0.2,0-0.3c0-0.2,0.2-0.3,0.4-0.3C10.8,2.8,11,2.8,11,3c0,0.1,0,0.2,0,0.3C11,4.4,11,5.5,11,6.6z" />
    <path d="M9.5,5.1c0,0.6,0,1.1,0,1.7c0,0.1,0,0.2,0,0.3c0,0.2-0.2,0.3-0.3,0.3C9,7.3,8.8,7.2,8.8,7c0-0.1,0-0.1,0-0.2  c0-1.2,0-2.4,0-3.6c0-0.1,0-0.1,0-0.2c0-0.2,0.2-0.3,0.4-0.3s0.3,0.1,0.3,0.3c0,0.1,0,0.3,0,0.4C9.5,4,9.5,4.5,9.5,5.1z" />
    <path
      class="st1"
      d="M4,4.9c0,0.2,0,0.4,0,0.5c0,0.2-0.1,0.4-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.4,0-0.7,0-1.1C3.4,4.2,3.5,4,3.7,4  C3.9,4,4,4.2,4,4.4C4,4.5,4,4.7,4,4.9z"
    />
    <path
      class="st2"
      d="M3.4,3.2c0-0.3,0.1-0.4,0.3-0.4c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.3-0.3,0.3  c-0.2,0-0.3-0.1-0.3-0.3C3.4,3.3,3.4,3.2,3.4,3.2z"
    />
  </SvgIcon>
)

CardReader = pure(CardReader)
CardReader.displayName = 'CardReader'
CardReader.pmtName = 'SvgIcon'

export default withStyles(styles)(CardReader)
