import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

import { orderProductsModifier } from 'pmt-modules/orderProduct/modifiers'

import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { truncatePrice } from 'pmt-utils/currency'

import { getNbChosenValuesValuesFromPart } from '../utils/category'

// get price from every subcategories in part
const recursiveGetPartsTotalPrice = (partCategory, partPrice) => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories.forEach(partCategory => {
      partPrice = recursiveGetPartsTotalPrice(partCategory, partPrice)
    })
  }

  if (!isNil(partCategory.products)) {
    partCategory.products.forEach(product => {
      if (product.quantity > 0) {
        partPrice += product.totalPriceWithQuantity
      }
    })
  }

  return partPrice
}

const getPartsTotalPrice = parts => {
  let price = 0

  parts.forEach(part => {
    let partPrice = 0
    partPrice = recursiveGetPartsTotalPrice(part, partPrice)
    part.totalPrice = partPrice
    price += partPrice
  })

  return price
}

//
//
//

const formatValuesQuantity = part => {
  part.nbChosenValues = getNbChosenValuesValuesFromPart(part)
  part.hasReachedMaxQuantity = part.nbChosenValues === part.max
  part.hasReachedMinQuantity = part.nbChosenValues >= part.min

  // a part is valid if
  // - she is not mandatory
  // - she is mandatory and hasReachedMinQuantity
  part.isValid = false
  if (!part.mandatory) {
    part.isValid = true
  } else {
    // part is mandatory
    if (part.hasReachedMinQuantity) {
      part.isValid = true
    }
  }

  return part
}

/**
 * Set orderMenu.isValid to true if all the parts are valid.
 */
const isValidModifier = orderMenu => {
  // a product isValid if all its parts are valid
  let isValid = true
  if (!isUndefined(orderMenu.parts)) {
    orderMenu.parts.forEach(part => {
      if (!part.isValid) {
        isValid = false
        // quit forEach
        return false
      }
    })
  } else {
    isValid = false
  }
  orderMenu.isValid = isValid

  return orderMenu
}

const totalPriceModifierForModifiedObject = orderMenu => {
  orderMenu.totalPrice = truncatePrice(orderMenu.price)

  // add parts additional prices, the API does not set the real price on 'price'.
  // it set the price without taking account of the additional prices.
  let partsTotalAdditionalPrice = getPartsTotalPrice(orderMenu.parts)

  orderMenu.totalPrice += partsTotalAdditionalPrice

  orderMenu.totalPriceWithQuantity = orderMenu.totalPrice * (orderMenu.quantity || 0)

  return orderMenu
}

const totalPriceModifier = (orderMenu, props) => {
  if (props.isOrderPreviewMode === true) {
    // note: unit price is the price of the product on the catalog
    // the price can be set differently on the order preview since it contains
    // the real price of the product (with discounts, etc)
    // we know there is a discount when the basePrice is not equals to the price

    // unitPrice = normal price of the product, without any modifiers (front value)
    // basePrice = normal price of the product, without any modifiers (api value)
    // price = price of the product, once the modifiers are applied

    return totalPriceModifierForModifiedObject(orderMenu)
  }

  // not in order preview mode

  // the order product has been modified by the API, we don't calculate the totalPrice
  // but take the totalPrice set on reconciliation
  // TODO: what to do if we edit the product and modify additionalPrice ?
  if (orderMenu.hasBeenModifiedByApi === true) {
    return totalPriceModifierForModifiedObject(orderMenu)
  }

  orderMenu.totalPrice = truncatePrice(orderMenu.price)

  let partsTotalPrice = getPartsTotalPrice(orderMenu.parts)
  orderMenu.totalPrice += partsTotalPrice

  orderMenu.totalPriceWithQuantity = orderMenu.totalPrice * (orderMenu.quantity || 0)

  return orderMenu
}

// apply modifiers to every products in every subcategories in part
const recursiveOrderProductsModifier = (partCategory, props) => {
  if (!isNil(partCategory.categories) && !isEmpty(partCategory.categories)) {
    partCategory.categories = partCategory.categories.map(category => {
      return recursiveOrderProductsModifier(category, props)
    })
  }

  if (!isNil(partCategory.products)) {
    partCategory.products = orderProductsModifier(partCategory.products, props)
  }

  return partCategory
}

export const partModifier = createFormatter(recursiveOrderProductsModifier, formatValuesQuantity)

const partsModifier = createListFormatter(partModifier)

//
// Props: None
//
export const orderMenuModifier = createFormatter(
  createSubObjectFormatter('parts', partsModifier),
  isValidModifier,
  totalPriceModifier
)
