import { createAction } from '../redux'

export const OrderSettingsAction = {
  SET_ORDER_SETTINGS: 'ORDER_SETTINGS::SET_ORDER_SETTINGS',
  RESET_ORDER_SETTINGS: 'ORDER_SETTINGS::RESET_ORDER_SETTINGS',
}

export const setOrderSettings = orderSettings =>
  createAction(OrderSettingsAction.SET_ORDER_SETTINGS, {
    orderSettings,
  })

export const resetOrderSettings = () =>
  createAction(OrderSettingsAction.RESET_ORDER_SETTINGS, {})
