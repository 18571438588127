import React from 'react'
import { connect } from 'react-redux'

import { redirectTo, getRoute } from 'pmt-modules/routing'
import { RestaurantUserAccountsContainer } from 'pmt-modules/restaurantUserAccount'
import { scanStop, sharingListenMessageBadgeIdStop } from 'pmt-modules/kioskInteractor/actions'
import { TopUpContainer } from 'pmt-modules/topUp'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'

import UserAccount from './components/UserAccount'
import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
class TopUpAccountPage extends React.PureComponent {
  constructor(props) {
    super(props)

    // hide modal just in case
    props.hideErrorDialog()

    props.scanStop()
    props.sharingListenMessageBadgeIdStop()
  }

  handleCancel = () => {
    this.props.redirectTo(getRoute('HOME'))
  }

  handleValidate = () => {
    this.props.redirectTo(getRoute('KIOSK__TOP_UP__PAYMENT'), {
      userAccountId: this.props.routeParams.userAccountId,
    })
  }

  render() {
    const { isFetchingRestaurant, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {() => (
          <RestaurantUserAccountsContainer
            restaurantId={restaurant.id}
            userAccountId={this.props.routeParams.userAccountId}
          >
            {({ userAccount, userAccounts, isFetchingUserAccounts }) => (
              <UserAccount
                userAccount={userAccount}
                userAccounts={userAccounts}
                isFetchingUserAccounts={isFetchingUserAccounts}
              >
                {userAccount !== null && (
                  <TopUpContainer userId={userAccount.user.id} userAccount={userAccount}>
                    {({ topUp, topUpIsInit, topUpError }) => (
                      <View
                        restaurant={restaurant}
                        topUp={topUp}
                        topUpIsInit={topUpIsInit}
                        topUpError={topUpError}
                        user={userAccount.user}
                        userAccount={userAccount}
                        onCancel={this.handleCancel}
                        onValidate={this.handleValidate}
                      />
                    )}
                  </TopUpContainer>
                )}
              </UserAccount>
            )}
          </RestaurantUserAccountsContainer>
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(
  mapStateToProps,
  {
    hideErrorDialog,
    redirectTo,
    scanStop,
    sharingListenMessageBadgeIdStop,
  }
)(TopUpAccountPage)
