import React from 'react'

import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  thankyou: {
    marginTop: theme.spacing(20),
  },
  newCredit: {
    marginTop: theme.spacing(30),
  },
  amount: {
    marginTop: theme.spacing(10),
  },
  bye: {
    marginTop: theme.spacing(40),
  },
})

const View = ({ classes, restaurant, userAccount }) => (
  <div className={classes.root}>
    <img src={restaurant.logo} alt={restaurant.name} />

    <Typography variant="display3" className={classes.thankyou} color="primary">
      Merci pour votre rechargement
    </Typography>

    <Typography variant="display2" className={classes.newCredit}>
      Votre nouveau solde
    </Typography>

    <Typography variant="display3" className={classes.amount}>
      {userAccount.amountFormatted}
    </Typography>

    <Typography variant="display3" color="primary" className={classes.bye}>
      À bientôt
    </Typography>
  </div>
)

export default withStyles(styles)(View)
