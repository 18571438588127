export const KIOSK_CALL = 'KIOSK::CALL'

export const KioskTypes = {
  ACRELEC: 'ACRELEC',
  BFAST: 'BFAST',
  NIXDORF: 'NIXDORF',
  DEMO: 'DEMO',
}

export const Services = {
  CONNECTIVITY: 'CONNECTIVITY',
  SHARING_LISTEN_MESSAGES: 'SHARING_LISTEN_MESSAGES',
  REGISTRATION: 'REGISTRATION',
  PERIPHERALS_STATUS: 'PERIPHERALS_STATUS',
}

export const Peripherals = {
  NFC: 'nfc',
  PAYMENT: 'payment',
  PRINTER: 'printer',
  SCANNER: 'scanner',
}

/**
 * Enumeration of the different type of messages that this application can use.
 */
export const MessageType = {
  /**
   * Heartbeat message sent every 30 seconds by default, to let the AppFriends knows
   * that our service application is still running.
   */
  HEARTBEAT: 'HEARTBEAT',

  /**
   * Message sent when a badge has been scanned on the magnetic card reader.
   *
   * data:
   * - `badge` string, the scanned badge id. A badge always begin with `;` and end with '?'
   *
   */
  BADGE: 'BADGE',

  /**
   * Send informations about the current status of the service.
   *
   * data:
   * - `magCardReaderConnected` boolean, true if a magnetic card reader has been connected to
   */
  STATUS: 'STATUS',
}
