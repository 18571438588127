import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import { formatDate, formatHour } from 'pmt-utils/date'

import BackHomeButton from '../../../components/BackHomeButton'
import { ConfirmDefault, ConfirmChoices } from './components'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(6),
    maxWidth: 230,
    maxHeight: 230,
  },
  check: {
    marginTop: theme.spacing(3),
  },
  checkSolved: {
    marginBottom: theme.spacing(8),
  },
  checkBalance: {
    color: theme.palette.primary.main,
  },
  paidAt: {
    margin: 'auto',
    marginBottom: theme.spacing(9),
    maxWidth: 860,
    color: theme.pmt.colors.grey91,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandedBackHomeButton: {
    width: 500,
    lineHeight: 1,
    border: `3px solid ${theme.palette.text.primary}`,
    background: theme.palette.text.primary,
    margin: `${theme.spacing(6)}px auto ${theme.spacing(10)}px`,
    padding: `${theme.spacing(3)}px 0`,
    display: 'flex',
    textTransform: 'uppercase',

    '&:focus, &:hover': {
      background: theme.palette.text.primary,
    },
  },
  iconContainerExpanded: {
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
  textExpanded: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '32px',
  },
})

const View = ({ classes, frontAppConfig, onGoBackHome, check, restaurant, onGoToScan }) => (
  <div className={classes.root}>
    {frontAppConfig.theme.logo.display && (
      <div className={classes.logoContainer}>
        <img
          src={frontAppConfig.theme.logo.path || restaurant.logo}
          className={classes.logo}
          alt="logo"
        />
      </div>
    )}

    <TypographyCustom type="607" align="center">
      {tr('kiosk.checkout.page.confirm.thanks')}
    </TypographyCustom>

    <TypographyCustom type="604" align="center" className={classes.check}>
      {tr('kiosk.checkout.page.confirm.check')}
      <span className={classes.checkBalance}> {check.outstandingBalanceFormatted} </span>
    </TypographyCustom>
    <TypographyCustom type="604" align="center" className={classes.checkSolved}>
      {tr('kiosk.checkout.page.confirm.check_solved')}
    </TypographyCustom>

    <TypographyCustom type="404" align="center" className={classes.paidAt}>
      {tr('kiosk.checkout.page.confirm.paid_at', {
        date: formatDate(null, 'l'),
        hour: formatHour(null, ':'),
      })}
    </TypographyCustom>

    <div className={classes.content}>
      {frontAppConfig.confirmDisplayType === 'DEFAULT' && (
        <ConfirmDefault onGoToScan={onGoToScan} />
      )}

      {frontAppConfig.confirmDisplayType === 'CHOICES' && (
        <ConfirmChoices
          frontAppConfig={frontAppConfig}
          onGoBackHome={onGoBackHome}
          onGoToScan={onGoToScan}
          restaurant={restaurant}
        />
      )}
    </div>

    <BackHomeButton
      expandedVersion
      classes={{
        root: classes.expandedBackHomeButton,
        textExpanded: classes.textExpanded,
        iconContainerExpanded: classes.iconContainerExpanded,
      }}
      onClick={onGoBackHome}
    />
  </div>
)

export default withStyles(styles)(View)
