import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { truncatePrice } from 'pmt-utils/currency'

const getOptionsTotalAdditionalPrice = options => {
  let price = 0

  options.forEach(option => {
    option.values.forEach(option => {
      price += option.additionalPrice * option.quantity
    })
  })

  return price
}

//
//
//

const formatValuesQuantity = option => {
  option.nbChosenValues = option.values.reduce((nb, value) => nb + value.quantity, 0)
  option.hasReachedMaxQuantity = option.nbChosenValues === option.quantityMax
  option.hasReachedMinQuantity = option.nbChosenValues >= option.quantityMin

  // an option is valid if
  // - she is not mandatory
  // - she is mandatory and hasReachedMinQuantity
  option.isValid = false
  if (!option.mandatory) {
    option.isValid = true
  } else {
    // option isMandatory
    if (option.hasReachedMinQuantity) {
      option.isValid = true
    }
  }

  return option
}

/**
 * Set orderProduct.isValid to true if all the options are valid.
 */
const isValidModifier = orderProduct => {
  orderProduct.options = orderProduct.options || []

  // a product isValid if all its options are valid
  let isValid = true
  let hasMandatoryOptions = false
  orderProduct.options.forEach(option => {
    if (option.mandatory) {
      hasMandatoryOptions = true
    }

    if (!option.isValid) {
      isValid = false
      // quit forEach
      return false
    }
  })
  orderProduct.isValid = isValid
  orderProduct.hasMandatoryOptions = hasMandatoryOptions

  return orderProduct
}

/**
 * The modifier for an order product modified by the API.
 */
const totalPriceModifierForModifiedObject = orderProduct => {
  orderProduct.totalPrice = truncatePrice(orderProduct.price)

  // add options additional prices, the API does not set the real price on 'price'.
  // it set the price without taking account of the additional prices.
  let optionsTotalAdditionalPrice = getOptionsTotalAdditionalPrice(orderProduct.options)
  // Note: Options additional prices should have the same modifier as the product.
  // a 50% discount should apply to the additional price too.
  // TODO: how can we handle that on the front ?
  orderProduct.totalPrice += optionsTotalAdditionalPrice

  orderProduct.totalPriceWithQuantity = orderProduct.totalPrice * (orderProduct.quantity || 0)

  return orderProduct
}

const totalPriceModifier = (orderProduct, props) => {
  if (props.isOrderPreviewMode === true) {
    // note: unit price is the price of the product on the catalog
    // the price can be set differently on the order preview since it contains
    // the real price of the product (with discounts, etc)
    // we know there is a discount when the basePrice is not equals to the price

    // unitPrice = normal price of the product, without any modifiers (front value)
    // basePrice = normal price of the product, without any modifiers (api value)
    // price = price of the product, once the modifiers are applied

    return totalPriceModifierForModifiedObject(orderProduct)
  }

  // not in order preview mode

  // the order product has been modified by the API, we don't calculate the totalPrice
  // but take the totalPrice set on reconciliation
  // TODO: what to do if we edit the product and modify additionalPrice ?
  if (orderProduct.hasBeenModifiedByApi === true) {
    return totalPriceModifierForModifiedObject(orderProduct)
  }

  if (props.isMenuMode === true) {
    orderProduct.totalPrice = 0
    orderProduct.totalPrice += orderProduct.additionalPrice || 0
  } else {
    orderProduct.totalPrice = truncatePrice(orderProduct.unitPrice)
  }

  // add options additional prices
  let optionsTotalAdditionalPrice = getOptionsTotalAdditionalPrice(orderProduct.options)
  orderProduct.totalPrice += optionsTotalAdditionalPrice

  orderProduct.totalPriceWithQuantity = orderProduct.totalPrice * (orderProduct.quantity || 0)

  return orderProduct
}

export const optionModifier = createFormatter(formatValuesQuantity)

const optionsModifier = createListFormatter(optionModifier)

//
// Props:
//    - isMenuMode: product is for a menu
//
export const orderProductModifier = createFormatter(
  createSubObjectFormatter('options', optionsModifier),
  isValidModifier,
  totalPriceModifier
)

export const orderProductsModifier = createListFormatter(orderProductModifier)
