import {
  notEmpty,
  isGreaterThan,
} from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { getQueryParam } from 'pmt-utils/url'

export LoginFormView from './LoginFormView'

// in DEV environment, prefill form with the following data
const devData = {
  restaurantId: '16000106',
  apiConsumer: 'NTYzNzc5NjEwMjQwNjE0NDpGUEhfSFI4SVNxVGtrYnB0c2lERFk1Z3I=',
  password: 'toto42',
}

const defaultData = {
  restaurantId: getQueryParam('restaurantId'),
  apiConsumer: getQueryParam('apiConsumer'),
  password: getQueryParam('password'),
}

// TODO: I18n
const validationRules = {
  restaurantId: [
    [ notEmpty, 'Restaurant ID requis'],
  ],
  apiConsumer: [
    [ notEmpty, 'Api-Consumer requis'],
  ],
  password: [
    [ isGreaterThan(5), 'global.login.form.validation.password_length' ]
  ],
}

export default createForm(
  FormType.RESTAURANT_LOGIN,
  validationRules,
  defaultData,
  devData,
)
