import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BackHomeButton from '../../../../components/BackHomeButton'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(45),
    marginBottom: theme.spacing(6),
    maxWidth: 350,
    maxHeight: 350,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  email: {
    marginBottom: theme.spacing(10),
  },
  bye: {
    flex: 1,
    whiteSpace: 'pre-line',
  },
  scanButton: {
    width: 500,
    height: 98,
    lineHeight: 1,
    marginTop: theme.spacing(12),
    padding: `${theme.spacing(3)}px 0`,
    border: `3px solid ${theme.palette.primary.main}`,
  },
  expandedBackHomeButton: {
    width: 500,
    lineHeight: 1,
    border: `3px solid ${theme.palette.text.primary}`,
    background: theme.palette.text.primary,
    margin: `${theme.spacing(6)}px auto ${theme.spacing(10)}px`,
    padding: `${theme.spacing(3)}px 0`,
    display: 'flex',
    textTransform: 'uppercase',

    '&:focus, &:hover': {
      background: theme.palette.text.primary,
    },
  },
  iconContainerExpanded: {
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
  textExpanded: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '32px',
  },
})

const ConfirmChoiceStepFinal = ({
  classes,
  frontAppConfig,
  onGoBackHome,
  onGoToScan,
  restaurant,
  withEmail,
}) => (
  <div className={classes.root}>
    {frontAppConfig.theme.logo.display && (
      <div className={classes.logoContainer}>
        <img
          src={frontAppConfig.theme.logo.path || restaurant.logo}
          className={classes.logo}
          alt="logo"
        />
      </div>
    )}

    <div className={classes.content}>
      {withEmail && (
        <TypographyCustom type="404" className={classes.email} align="center">
          {tr('kiosk.checkout.page.confirm.choices.final.email')}
        </TypographyCustom>
      )}
      <TypographyCustom type="607" className={classes.bye} align="center">
        {tr('kiosk.checkout.page.confirm.choices.final.bye')}
      </TypographyCustom>
      <Button
        variant="outlined"
        color="primary"
        onClick={onGoToScan}
        className={classes.scanButton}
      >
        <TypographyCustom type="327" skipColor>
          {tr('kiosk.checkout.page.check.scan_again')}
        </TypographyCustom>
      </Button>
    </div>

    <BackHomeButton
      expandedVersion
      classes={{
        root: classes.expandedBackHomeButton,
        textExpanded: classes.textExpanded,
        iconContainerExpanded: classes.iconContainerExpanded,
      }}
      onClick={onGoBackHome}
    />
  </div>
)

export default withStyles(styles)(ConfirmChoiceStepFinal)
