import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '../../SvgIcon';

let CartIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 466">
    <path d="M477.091,139.909 L438.691,139.909 L410.764,47.982 C407.273,34.018 393.309,23.546 378.182,23.546 L349.091,23.546 L349.091,11.909 C349.091,4.927 344.436,0.273 337.455,0.273 L174.545,0.273 C167.563,0.273 162.909,4.928 162.909,11.909 L162.909,23.545 L144.291,23.545 C129.164,23.545 116.364,31.69 111.709,45.654 L73.309,139.909 L34.909,139.909 C15.127,139.909 0,155.036 0,174.818 L0,221.363 C0,241.145 15.127,256.272 34.909,256.272 L36.073,256.272 L55.855,434.308 C58.182,452.926 73.31,465.726 90.764,465.726 L421.237,465.726 C438.692,465.726 453.819,452.926 456.146,434.308 L475.928,256.272 L477.092,256.272 C496.874,256.272 512.001,241.145 512.001,221.363 L512.001,174.818 C512,155.036 496.873,139.909 477.091,139.909 Z M186.182,23.545 L325.818,23.545 L325.818,46.818 L186.182,46.818 L186.182,23.545 Z M489.891,221.364 L488.727,221.364 C488.727,228.346 484.072,233 477.091,233 L465.455,233 L93.091,233 C86.109,233 81.455,237.655 81.455,244.636 C81.455,251.617 86.11,256.272 93.091,256.272 L452.655,256.272 L432.873,431.981 C431.709,437.799 427.055,442.454 421.237,442.454 L90.764,442.454 C84.946,442.454 80.291,437.799 79.128,431.981 L58.182,243.473 C57.018,237.655 52.364,233 46.545,233 L34.909,233 C27.927,233 23.273,228.345 23.273,221.364 L23.273,174.819 C23.273,167.837 27.928,163.183 34.909,163.183 L81.454,163.183 L384,163.183 C390.982,163.183 395.636,158.528 395.636,151.547 C395.636,144.566 390.981,139.911 384,139.911 L98.909,139.911 L132.655,53.8 C134.982,49.145 138.473,46.818 144.291,46.818 L162.909,46.818 L162.909,58.454 C162.909,65.436 167.564,70.09 174.545,70.09 L337.454,70.09 C344.436,70.09 349.09,65.435 349.09,58.454 L349.09,46.818 L378.181,46.818 C382.836,46.818 387.49,50.309 389.817,54.963 L420.072,155.036 C421.236,159.691 425.89,163.181 431.708,163.181 L478.253,163.181 C485.235,163.181 489.889,167.836 489.889,174.817 L489.889,221.364 L489.891,221.364 Z" id="Shape"/>
    <path d="M326.482,316.492 L326.482,306.037 C326.482,301.895 323.125,298.537 318.982,298.537 C314.839,298.537 311.482,301.895 311.482,306.037 L311.482,316.492 C311.482,320.634 314.839,323.992 318.982,323.992 C323.125,323.992 326.482,320.634 326.482,316.492 Z" id="Shape"/>
    <path d="M200.518,316.492 L200.518,306.037 C200.518,301.895 197.161,298.537 193.018,298.537 C188.875,298.537 185.518,301.895 185.518,306.037 L185.518,316.492 C185.518,320.634 188.875,323.992 193.018,323.992 C197.16,323.992 200.518,320.634 200.518,316.492 Z" id="Shape"/>
    <path d="M256,340.02 C264.531,340.02 272.645,336.325 278.26,329.883 C280.982,326.761 280.657,322.023 277.533,319.301 C274.41,316.578 269.674,316.905 266.951,320.027 C264.186,323.2 260.194,325.02 256,325.02 C251.806,325.02 247.814,323.2 245.049,320.027 C242.326,316.904 237.588,316.579 234.467,319.301 C231.344,322.022 231.019,326.76 233.74,329.883 C239.355,336.326 247.469,340.02 256,340.02 Z" id="Shape" transform="translate(256.000372, 328.737182) scale(1, -1) translate(-256.000372, -328.737182) "/>
  </SvgIcon>
);
CartIcon = pure(CartIcon);
CartIcon.displayName = 'CartIcon';
CartIcon.muiName = 'SvgIcon';

export default CartIcon;
