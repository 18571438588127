import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import OrderHeader from '../../../components/OrderHeader'
import CatalogLayout from '../../../components/CatalogLayout'
import IconLabelMode, { TYPE_COLORS } from '../../../components/IconLabelMode'
import BackTo from '../../../components/BackTo'

import CategoriesCarousel from 'app/components/CategoriesCarousel'

import MenuView from './components/MenuView'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: theme.pmt.colors.white,
  },
  overContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
    textAlign: 'center',
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  contentGridContainer: {
    marginTop: theme.spacing(20),
  },
  loadingText: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  modeContainer: {
    marginTop: theme.spacing(50),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  categoryCard: {
    width: 200,
    height: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    // to forbid text to go under the selected left bar
    padding: '0 7px 0 0',
    boxShadow: 'none',
    borderLeft: `7px solid ${theme.pmt.colors.white}`,
  },
  selectedBar: {
    borderLeft: `7px solid ${theme.palette.primary.main}`,
  },
  categoriesContainer: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: theme.spacing(3),
    boxSizing: 'content-box',
    width: '100%',
  },
  categoryCardContent: {
    height: 'auto',
  },
  categoryCardImage: {
    maxWidth: 100,
    maxHeight: 100,
  },
  categoryCardName: {
    minHeight: 32,
    maxHeight: 72,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '32px',
  },
  categoryCardTextContainer: {
    display: 'block',
    padding: `0 ${theme.spacing(2)}px`,
  },
  productSection: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(4),
  },
  productSectionContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-start',
    height: '100%',
    overflow: 'hidden',
  },
})

const View = ({
  frontAppConfig,
  isFetching,
  kioskSettings,
  restaurant,
  mode,
  category,
  catalog,
  orderMenu,
  viewOptions,
  isFromCart,
  onCategoryClick,
  handleRedirectToPreviousPage,
  onReturnToPreviousPartCategory,
  onSelectCategory,
  onSelectOrderProduct,
  onUnselectOrderProduct,
  onAddToCartFromShortcut,
  onSubmitOrderMenu,
  resetPartCategory,
  showDietaryInfoDialog,
  classes,
}) => (
  <div className={classes.background}>
    {isFetching ? (
      <div className={classes.overContainer}>
        <div className={classes.logoContainer}>
          {restaurant && (
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          )}
        </div>
        <Grid container spacing={3} className={classes.contentGridContainer}>
          <Grid item xs={12}>
            <div>
              <LoadingBlock show size={200} />
              <TypographyCustom type="247" className={classes.loadingText} align="center">
                {tr('kiosk.order.page.category.loading')}
              </TypographyCustom>
            </div>
            <IconLabelMode
              mode={mode}
              color={TYPE_COLORS.MAIN}
              classes={{ root: classes.modeContainer }}
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div className={classes.content}>
        <OrderHeader
          frontAppConfig={frontAppConfig}
          restaurant={restaurant}
          kioskSettings={kioskSettings}
        />
        <CatalogLayout
          leftPart={
            <CategoriesCarousel
              catalog={catalog}
              category={category}
              onSelectCategory={onCategoryClick}
              restaurant={restaurant}
            />
          }
          rightPart={
            <div className={classes.productSection}>
              <div className={classes.productSectionContainer}>
                <BackTo
                  text={
                    isFromCart ? tr('kiosk.order.back_to_cart') : tr('kiosk.order.back_to_catalog')
                  }
                  handleOnClick={handleRedirectToPreviousPage}
                />
                <MenuView
                  orderMenu={orderMenu}
                  viewOptions={viewOptions}
                  category={category}
                  restaurant={restaurant}
                  onReturnToPreviousPartCategory={onReturnToPreviousPartCategory}
                  onSelectCategory={onSelectCategory}
                  onSelectOrderProduct={onSelectOrderProduct}
                  onUnselectOrderProduct={onUnselectOrderProduct}
                  onAddToCartFromShortcut={onAddToCartFromShortcut}
                  onSubmitOrderMenu={onSubmitOrderMenu}
                  resetPartCategory={resetPartCategory}
                  showDietaryInfoDialog={showDietaryInfoDialog}
                  classes={{ header: 'u-marginTop40' }}
                />
              </div>
            </div>
          }
        />
      </div>
    )}
  </div>
)

export default withStyles(styles)(View)
