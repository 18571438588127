import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { getRoute } from 'pmt-modules/routing'

import ButtonLink from 'pmt-ui/ButtonLink'
import { withStyles } from 'pmt-ui/styles'
import CartIcon from 'pmt-ui/svg-icons/pmt/CartIcon'
import { TypographyCustom } from 'pmt-ui/Typography'

import { ItemsContainer } from '../../../../components/Cart'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
  },
  itemsContainer: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  isEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartIcon: {
    width: 100,
    height: 89,
    color: theme.pmt.colors.grey91,
  },
  cartEmptyText: {
    marginTop: theme.spacing(3),
    color: theme.pmt.colors.grey91,
  },
  catalogButton: {
    marginTop: theme.spacing(12),
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'inherit',
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
})

const CartContent = ({ classes, itemList, fromRoute, restaurant }) => (
  <div className={classes.root}>
    {!isEmpty(itemList) ? (
      <div className={classes.itemsContainer}>
        <ItemsContainer itemList={itemList} restaurant={restaurant} fromRoute={fromRoute} withEdit />
      </div>
    ) : (
      <div className={classes.isEmptyContainer}>
        <CartIcon className={classes.cartIcon} />
        <TypographyCustom type="327" component="p" className={classes.cartEmptyText}>
          {tr('kiosk.order.footer.empty_cart')}
        </TypographyCustom>
        <ButtonLink
          variant="outlined"
          size="large"
          color="primary"
          classes={{ root: classes.catalogButton }}
          to={getRoute('KIOSK__ORDER__CATEGORY')}
        >
          <TypographyCustom type="247" skipColor>
            {tr('kiosk.order.page.cart.go_to_catalog')}
          </TypographyCustom>
        </ButtonLink>
      </div>
    )}
  </div>
)

export default withStyles(styles)(CartContent)
