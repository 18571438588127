import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import set from 'lodash/set'
import isEmpty from 'lodash/isEmpty'

import Button from 'pmt-ui/Button'
import { FormType, resetFormWithDefaultData, withForm } from 'pmt-modules/form'
import { Email, MarketingOptim } from 'pmt-modules/user/forms/elements/index'
import { getPostPayment, isFetchingPostPaymentEmail } from 'pmt-modules/payment/payment/selectors'
import { postPaymentEmail } from 'pmt-modules/payment/payment/actions'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock/'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `0 ${theme.spacing(20)}px`,
  },
  logo: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(9),
    maxWidth: 230,
    maxHeight: 230,
  },
  ticket: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  email: {
    marginTop: `${theme.spacing(10)}px !important`,
    marginBottom: theme.spacing(9),
    '& input': {
      padding: theme.spacing(4),
      textAlign: 'center',
      fontSize: '40px !important',
    },
    '& p': {
      fontSize: 40,
    },
  },
  marketingOptim: {
    '& span:last-child': {
      fontSize: 28,
      fontWeight: 400,
      marginLeft: theme.spacing(2),
      lineHeight: 1,
      color: theme.palette.text.primary,
      textAlign: 'left',
    },
    '& svg': {
      width: 45,
      height: 45,
    },
  },
  terms: {
    color: theme.pmt.colors.grey91,
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(5),

    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  submit: {
    width: '100%',
    padding: theme.spacing(3),
  },
})

class ConfirmChoiceStepEmail extends React.PureComponent {
  constructor(props) {
    super(props)

    props.resetFormWithDefaultData(FormType.CHECKOUT_PAYMENT_EMAIL)
  }

  render() {
    const {
      classes,
      formData,
      formErrors,
      frontAppConfig,
      formIsValid,
      isFetchingPostPaymentEmail,
      payment,
      restaurant,
      onChange,
      postPaymentEmail,
    } = this.props
    return (
      <div className={classes.root}>
        <LoadingBlockWrapper show={isFetchingPostPaymentEmail} />

        {frontAppConfig.theme.logo.display && (
          <div className={classes.logoContainer}>
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          </div>
        )}

        <TypographyCustom type="604" align="center">
          {tr('kiosk.checkout.page.confirm.choices.email.receive')}
          <span className={classes.ticket}>
            {` ${tr('kiosk.checkout.page.confirm.choices.email.ticket')}`}
          </span>
          <br />
          {tr('kiosk.checkout.page.confirm.choices.email.inbox')}
        </TypographyCustom>

        <form
          onSubmit={e => {
            e.preventDefault()
            postPaymentEmail(payment.id, formData)
          }}
        >
          <Email
            className={classes.email}
            label=""
            placeholder={tr('kiosk.checkout.page.confirm.choices.email.placeholder')}
            variant="outlined"
            value={formData.email}
            onChange={email => onChange({ ...formData, email })}
            validation={formErrors.email}
            required
          />

          <MarketingOptim
            className={classes.marketingOptim}
            value={formData.marketing.allowContact}
            onChange={isInputChecked =>
              onChange(set(formData, 'marketing.allowContact', isInputChecked))
            }
          />

          <TypographyCustom type="284" className={classes.terms}>
            {tr('kiosk.checkout.page.confirm.choices.email.terms.first')}
            {restaurant && !isEmpty(restaurant.legal.cguUrl) ? (
              <a href={restaurant.legal.cguUrl} target="_blank" rel="noopener noreferrer">
                {tr('kiosk.checkout.page.confirm.choices.email.terms.second')}
              </a>
            ) : (
              tr('kiosk.checkout.page.confirm.choices.email.terms.second')
            )}
          </TypographyCustom>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!formIsValid}
            className={classes.submit}
          >
            <TypographyCustom type="327" skipColor>
              {tr('kiosk.checkout.page.confirm.choices.email.submit')}
            </TypographyCustom>
          </Button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  payment: getPostPayment(state),
  isFetchingPostPaymentEmail: isFetchingPostPaymentEmail(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      postPaymentEmail,
      resetFormWithDefaultData,
    }
  ),
  withForm(FormType.CHECKOUT_PAYMENT_EMAIL),
  withStyles(styles)
)(ConfirmChoiceStepEmail)
