import { createAction } from 'pmt-modules/redux'
import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
// import { createTestApiCallAction } from 'pmt-modules/api/utils'
import PaymentApi from 'pmt-modules/api/calls/PaymentApi'
import { getRoute, createRedirectToAction } from 'pmt-modules/routing'
import { PaymentMethodsAllowed } from 'pmt-modules/payment/constants'

//
// PaymentType
//

/**
 * Actions to change type of payment
 */
export const SelectPaymentTypeAction = {
  /**
   * Wants to pay all the check
   */
  ALL: 'PAYMENT::SELECT_TYPE::ALL',

  /**
   * Wants to pay a part of the check
   */
  SHARE: 'PAYMENT::SELECT_TYPE::SHARE',

  /**
   * Wants to pay for the attached entries
   */
  ENTRIES: 'PAYMENT::SELECT_TYPE::ENTRIES',
}

export const setPaymentTypeAll = () => createAction(SelectPaymentTypeAction.ALL)
export const setPaymentTypeShare = () => createAction(SelectPaymentTypeAction.SHARE)
export const setPaymentTypeEntries = () => createAction(SelectPaymentTypeAction.ENTRIES)

//
// Payment
//

export const PaymentAction = {
  GET_PAYMENT: 'PAYMENT::GET_PAYMENT',
  SELECT_TIPS: 'PAYMENT::SELECT_TIPS',
  RESET: 'PAYMENT::RESET',
}

export const resetPayment = () => createAction(PaymentAction.RESET)

export const selectTips = tips => createAction(PaymentAction.SELECT_TIPS, { tips })

//
// Sharing
//

export const SharePaymentAction = {
  INCREASE_DIVIDER: 'PAYMENT::SHARE::INCREASE_DIVIDER',
  DECREASE_DIVIDER: 'PAYMENT::SHARE::DECREASE_DIVIDER',
  INCREASE_MULTIPLIER: 'PAYMENT::SHARE::INCREASE_MULTIPLIER',
  DECREASE_MULTIPLIER: 'PAYMENT::SHARE::DECREASE_MULTIPLIER',
}

export const increaseDivider = () => createAction(SharePaymentAction.INCREASE_DIVIDER)
export const decreaseDivider = () => createAction(SharePaymentAction.DECREASE_DIVIDER)
export const increaseMultiplier = () => createAction(SharePaymentAction.INCREASE_MULTIPLIER)
export const decreaseMultiplier = () => createAction(SharePaymentAction.DECREASE_MULTIPLIER)

//
// ItemSelection
//
export const PaymentItemSelection = {
  ADD_ITEM: 'PAYMENT::ITEM::ADD',
  REMOVE_ITEM: 'PAYMENT::ITEM::REMOVE',
  ITEM_SHARE: 'PAYMENT::SHARE::ITEM_SHARE',
  ITEM_SHARE_UPDATE: 'PAYMENT::ITEM::SHARE::UPDATE',
}

export const addEntryToPayment = entry => createAction(PaymentItemSelection.ADD_ITEM, { entry })

export const removeEntryFromPayment = entry =>
  createAction(PaymentItemSelection.REMOVE_ITEM, { entry })

export const shareEntryToPaymentUpdate = (entry, item, share) =>
  createAction(PaymentItemSelection.ITEM_SHARE_UPDATE, { entry, item, share })

export const redirectToPaymentUserPage = (restaurantId, checkId) =>
  createRedirectToAction(getRoute('PAYMENT_PAYMENT'), { restaurantId, checkId })

//
// PostPayment
//

export const PostPaymentAction = {
  ...createApiEnumAction('PAYMENT::POST::USER'),
  RESET: 'PAYMENT::POST::RESET',
}
export const PostIrlPaymentAction = createApiEnumAction('PAYMENT::POST::IRL_PAYMENT')
export const PostUserLightPaymentAction = createApiEnumAction('PAYMENT::POST::USER_LIGHT')

export const resetPostPayment = () => createAction(PostPaymentAction.RESET)

export const processPayment = (restaurantId, selectedCreditCard, payment, check) =>
  createApiCallAction(
    PostPaymentAction,
    PaymentApi.processPaymentv2(restaurantId, PaymentMethodsAllowed.CREDIT_CARD, payment, check, {
      selectedCreditCard,
    }),
    {
      restaurantId,
      card: selectedCreditCard,
      payment,
      check,
    }
  )

export const processPaymentv2 = (
  restaurantId,
  paymentMethod,
  payment,
  check,
  { selectedCreditCard }
) =>
  createApiCallAction(
    PostPaymentAction,
    PaymentApi.processPaymentv2(restaurantId, paymentMethod, payment, check, {
      selectedCreditCard,
    }),
    {
      restaurantId,
      selectedCreditCard,
      paymentMethod,
      payment,
      check,
    }
  )

// export const processPaymentv2 = (
//   restaurantId,
//   paymentMethod,
//   payment,
//   check,
//   { selectedCreditCard }
// ) =>
//   createTestApiCallAction(
//     PostPaymentAction,
//     true,
//     {
//       data: { ...payment, restaurantId: '19000205' }
//     }
//   )

export const processIrlPayment = (restaurant, check, payment) =>
  createApiCallAction(
    PostIrlPaymentAction,
    PaymentApi.processIrlPayment(restaurant, check, payment),
    {
      restaurant,
      check,
      payment,
    }
  )

export const GetPaymentAction = createApiEnumAction('PAYMENT::GET')

export const getPayment = paymentId =>
  createApiCallAction(GetPaymentAction, PaymentApi.get(paymentId), {
    paymentId,
  })

export const PostPaymentEmailAction = createApiEnumAction('PAYMENT::POST::PAYMENT_EMAIL')

export const postPaymentEmail = (paymentId, body) =>
  createApiCallAction(PostPaymentEmailAction, PaymentApi.postPaymentEmail(paymentId, body), {
    paymentId,
    body,
  })
