import React from 'react'
import isFunction from 'lodash/isFunction'

import Tooltip from '@material-ui/core/Tooltip'

const CartModifierTag = ({ cartModifier, chipElement, key }) => (
  <Tooltip key={key} title={cartModifier.name} placement="bottom">
    {isFunction(chipElement) ? chipElement(cartModifier.tag, key) : chipElement}
  </Tooltip>
)

export default CartModifierTag
