import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { generateUUID } from 'pmt-utils/uuid'
import { findOnArray } from 'pmt-utils/array'
import cloneDeep from 'lodash/cloneDeep'

import { getCardModifierIdsFromCartModifierList } from 'pmt-modules/catalog/utils'
import { createOrderProductFromProduct } from 'pmt-modules/orderProduct/utils'

import { orderMenuModifier } from '../modifiers'

// create every order product in a menu part, recursively
const recursiveCreateOrderProductFromProductInPart = (part, partCategory) => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories = partCategory.categories.map(partCategory => {
      return recursiveCreateOrderProductFromProductInPart(part, partCategory)
    })
  }

  if (!isNil(partCategory.products)) {
    partCategory.products = partCategory.products
      // do not add useless products
      .map(product =>
        createOrderProductFromProduct(product, {
          isMenuMode: true,
          partId: part.id,
          partCategoryId: partCategory.id,
        })
      )
  }

  partCategory.rootCategory = findOnArray(partCategory.categories, 'root')
  return partCategory
}

const createOrderMenuFromMenu = menu => {
  const { parts } = menu

  // spread to keep item formatted data
  const orderMenu = cloneDeep(menu)

  orderMenu.isOrderMenu = true

  orderMenu.orderId = generateUUID()

  orderMenu.grantable = false
  orderMenu.conditionForModifierIds = []
  orderMenu.modifierIds = []

  if (orderMenu.hasCartModifier) {
    orderMenu.modifierIds = getCardModifierIdsFromCartModifierList(orderMenu.cartModifiers.list)
  }

  // POS only
  orderMenu.categoryId = null
  orderMenu.categoryName = null

  // orderMenu.sequence = 0

  orderMenu.parts = (parts || []).map(part => {
    part = recursiveCreateOrderProductFromProductInPart(part, part)
    return part
  })

  const price = menu.price

  orderMenu.basePrice = menu.hasCartModifier ? menu.basePrice : price
  orderMenu.price = price
  orderMenu.unitPrice = price
  orderMenu.totalPriceWithQuantity = orderMenu.unitPrice
  orderMenu.quantity = 1

  // Define the part currently displayed for the user
  // orderMenu.currentPart = orderMenu.parts[0]

  return orderMenuModifier(orderMenu, {
    // for products
    isMenuMode: true,
  })
}

/*
 * formatted menu datas model to submit to cart
  {
    orderId: generateUUID(),
    id: "menu-id",
    name: "menu.name",
    isValid: true,
    parts: [
      {
        id: "part.id",
        name: "part.name",
        mandatory: true or false,
        additionalPrice: part.additionalPrice,
        min: part.min,
        max: part.max,
        nbChosenValues: 1,
        hasReachedMaxQuantity: true,
        hasReachedMinQuantity: true,
        isValid: true,
        products: [
          // see ./product.js
        ]
      },
      etc...
    ]
    availabilityHours: product.availabilityHours,
    unitPrice: unitPrice,
    quantity: quantity,
    type: menu.type,
  }
*/

export default createOrderMenuFromMenu
