import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  bottomButtonBar: {
    height: theme.pmt.bottomButtonBar.height,
  }
})

class BottomButtonBar extends React.Component {

  render() {
    const { children, classes, show } = this.props

    if (!show) {
      return (null)
    }

    return (
      <div
        className={`${classes.bottomButtonBar} u-fixed u-zIndexMetabar u-bottom0 u-left0 u-right0 u-backgroundColorWhite`}
      >
        {children}
      </div>
    )
  }
}

BottomButtonBar.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BottomButtonBar)
