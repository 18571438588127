import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { RestaurantUserAccountRemainingView } from 'pmt-modules/restaurantUserAccount'
import { TopUpChooseAmountView } from 'pmt-modules/topUp'

import BottomButtonBar from 'pmt-ui/BottomButtonBar'
import Button from 'pmt-ui/Button'
import Divider from 'pmt-ui/Divider'
import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  divider: {
    width: 300,
    margin: '25px auto 100px',
  },
  topUpAmounts: {
    marginTop: theme.spacing(12),
  },
  topUpLabel: {
    marginBottom: theme.spacing(12),
  },
  amounts: {
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    justifyContent: 'center !important',

    // override amount__circle class defined in AmountCircle via TopUpChooseAmountView component
    // for specific view needs
    '& .amount__circle': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  button: {
    minWidth: 340,
    fontSize: 40,
    padding: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
})

const View = ({
  classes,
  user,
  userAccount,
  topUp,
  topUpIsInit,
  topUpError,
  restaurant,
  onCancel,
  onValidate,
}) => (
  <div className={classes.root}>
    <img src={restaurant.logo} alt={restaurant.name} />

    <Typography variant="display3" className={classes.hello}>
      Bonjour <strong>{user.firstName}</strong>
    </Typography>

    <Divider className={classes.divider} />

    <RestaurantUserAccountRemainingView userId={user.id} restaurantId={restaurant.id} />

    <div className={classes.topUpAmounts}>
      <Typography variant="display3" className={classes.topUpLabel}>
        {tr('kiosk.top_up.selectable_amount', {
          context: '',
          desc: 'Label asking to select an amount on kiosk',
        })}
      </Typography>

      <TopUpChooseAmountView
        classes={{
          amounts: classes.amounts,
        }}
        userId={user.id}
        userAccount={userAccount}
        displayOtherAmount={false}
        amountsToPropose={[10, 20, 30, 40, 50, 70, 100]}
        amountSize={180}
      />

      <BottomButtonBar show>
        <Button variant="outlined" color="primary" className={classes.button} onClick={onCancel}>
          Annuler
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onValidate}
          disabled={!topUpIsInit || !topUp.amount}
        >
          Valider
        </Button>
      </BottomButtonBar>
    </div>
  </div>
)

export default withStyles(styles)(View)
