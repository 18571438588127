import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hideInactivityDialog } from 'pmt-modules/kiosk'
import { resetKiosk } from 'pmt-modules/kiosk'

import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  content: {
    padding: `${theme.spacing(10)}px ${theme.spacing(8)}px ${theme.spacing(5)}px`,
    textAlign: 'center',
  },
  caption: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(3),
    width: '100%',
    textTransform: 'inherit',
    lineHeight: 1,
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '0%',
    height: '100%',
    opacity: 0.7,
    background: theme.pmt.colors.white,
    zIndex: 1,
  },
  label: {
    zIndex: 2,
  },
})

/**
 * https://paymytable.atlassian.net/browse/PP-805
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b928e7fbcbb4577a76ed57c
 */
class InactivityDialog extends React.Component {
  constructor(props) {
    super(props)

    this.inactivityTimeout = null
  }

  state = {
    progress: 0,
  }

  componentDidMount() {
    this.inactivityTimeout = setInterval(this.handleProgress, 100)
  }

  handleProgress = () => {
    if (this.state.progress >= 100) {
      clearInterval(this.inactivityTimeout)
      this.handleRedirectToHome()
    } else {
      this.setState({
        progress: this.state.progress + 1,
      })
    }
  }

  handleRedirectToHome = () => {
    this.hideInactivityDialog()
    this.props.resetKiosk()
  }

  hideInactivityDialog = () => {
    this.props.hideInactivityDialog()
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.content}>
        <TypographyCustom type="407" align="center">
          {tr('kiosk.order.page.inactivity.title')}
        </TypographyCustom>
        <Button
          variant="contained"
          color="primary"
          onClick={this.hideInactivityDialog}
          className={classes.button}
        >
          <TypographyCustom type="327" skipColor>
            {tr('kiosk.order.page.inactivity.continue')}
          </TypographyCustom>
        </Button>
        <Button variant="contained" onClick={this.handleRedirectToHome} className={classes.button}>
          <div className={classes.progress} style={{ width: `${this.state.progress}%` }} />
          <TypographyCustom type="247" skipColor className={classes.label}>
            {tr('kiosk.order.button.home_expanded')}
          </TypographyCustom>
        </Button>
      </div>
    )
  }
}

// Dialog root properties
InactivityDialog.DialogRootProps = {
  // we don't want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

InactivityDialog.DialogStyles = theme => ({
  paper: {
    width: 621,
    maxHeight: 523,
  },
})

const mapStateToProps = (state, props) => ({})
const mapDispatchToProps = { hideInactivityDialog, resetKiosk }

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InactivityDialog)
