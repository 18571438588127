// DeliveryTimeType : defines if the delivery time is a fixed time or adaptable
// exmaples : a fixed 20min, no matter the period of the day
// an adaptable is like 10 min during the morning, 40min at lunch and 20 at diner
export const DeliveryTimeType = {
  FIXED: 0,
  ADAPTABLE: 1,
}

// TODO: remove from here
export { PaymentMethodsAllowed } from 'pmt-modules/payment/constants'
