import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { hideLooseCartDialog } from 'pmt-modules/kioskOrder'
import { resetKiosk } from 'pmt-modules/kiosk'

import Button from 'pmt-ui/Button'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  dialogTitle: {
    padding: 0,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: theme.spacing(3),
  },
  dialogActions: {
    marginTop: theme.spacing(6),
  },
  cancelButton: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    boxShadow: `inset 0px 0px 0px 3px ${theme.palette.primary.main}`,
  },
  deleteButton: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
  },
})

class LoseCartDialog extends React.PureComponent {
  handleHideDialog = () => {
    this.props.hideLooseCartDialog()
  }

  handleHideDialogAndRedirectToHomePage = () => {
    this.handleHideDialog()
    this.props.resetKiosk()
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <TypographyCustom type="327" skipColor>
            {tr('kiosk.order.dialog.lose_cart.title')}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TypographyCustom type="244">
            {tr('kiosk.order.dialog.lose_cart.body.0')}
            <br /><br />
            <strong>{tr('kiosk.order.dialog.lose_cart.body.1')}</strong>
          </TypographyCustom>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="outlined"
                onClick={this.handleHideDialog}
                className={classes.cancelButton}
              >
                <TypographyCustom type="247" skipColor>
                  {tr('kiosk.order.dialog.lose_cart.button.cancel')}
                </TypographyCustom>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleHideDialogAndRedirectToHomePage}
                className={classes.deleteButton}
              >
                <TypographyCustom type="247" skipColor>
                  {tr('kiosk.order.dialog.lose_cart.button.confirm')}
                </TypographyCustom>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
LoseCartDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

LoseCartDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 766,
    padding: theme.spacing(6),
  },
})

const mapStateToProps = (state, props) => ({})

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    hideLooseCartDialog,
    resetKiosk,
  })
)(LoseCartDialog)
