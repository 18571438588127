import React from 'react'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    flex: 1,
  },
  leftPart: {
    float: 'left',
    width: '18.5%',
    height: '100%',
    overflow: 'hidden',
    borderRight: `4px solid ${theme.pmt.colors.greyEF}`,
    boxSizing: 'content-box',
  },
  rightPart: {
    float: 'left',
    width: 'calc(81.5% - 4px)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

const CatalogLayout = ({ rightPart, leftPart, classes }) => (
  <div className={classes.root}>
    <div className={classes.leftPart}>
      {leftPart}
    </div>
    <div className={classes.rightPart}>
      {rightPart}
    </div>
  </div>
)

export default withStyles(styles)(CatalogLayout)
