import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'

import { getRoute } from 'pmt-modules/routing'
import { EventManager } from 'pmt-modules/event'

import { ButtonLoading } from 'pmt-ui/Button'
import ButtonLink from 'pmt-ui/ButtonLink'
import Divider from 'pmt-ui/Divider'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import IconLabelMode, { TYPE_COLORS } from '../../../../components/IconLabelMode'

const styles = theme => ({
  root: {},
  divider: {
    backgroundColor: theme.pmt.colors.grey91,
    marginBottom: theme.spacing(4),
  },
  modeAndTotalContainer: {
    display: 'flex',
    padding: `0 ${theme.spacing(4)}px 0`,
  },
  modeContainer: {
    justifyContent: 'flex-start',
    flex: 1,
  },
  icon: {
    fontSize: 90,
  },
  modeLabel: {
    height: 90,
    lineHeight: '90px',
  },
  cartTotal: {
    display: 'flex',
    alignItems: 'center',

    '& > div:first-child': {
      marginRight: theme.spacing(6),
    },
  },
  cartTotalEmtpy: {
    color: theme.pmt.colors.grey500,
  },
  button: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(7),
    width: '100%',
    height: 86,
  },
  buttonLabel: {
    textTransform: 'initial',
  },
})

const CartFooter = ({
  classes,
  isEmpty,
  isFetchingOrderPreview,
  itemList,
  mode,
  orderAppConfig,
  restaurant,
  totalCartPriceAndFees,
}) => {
  let submitCartLocation = getRoute('KIOSK__ORDER__PAYMENT__MODE')
  if (orderAppConfig.hasPager) {
    submitCartLocation = getRoute('KIOSK__ORDER__SCAN')
  }

  return (
    <div className={classes.root}>
      {!isEmpty && <Divider className={classes.divider} />}
      <div className={classes.modeAndTotalContainer}>
        <IconLabelMode
          type="327"
          mode={mode}
          color={isEmpty ? TYPE_COLORS.GREY500 : TYPE_COLORS.MAIN}
          classes={{
            root: classes.modeContainer,
            icon: classes.icon,
            modeLabel: classes.modeLabel,
          }}
        />
        <div
          className={classNames(classes.cartTotal, {
            [classes.cartTotalEmtpy]: isEmpty,
          })}
        >
          <TypographyCustom type="324">{tr('kiosk.order.page.cart.total')}</TypographyCustom>
          <TypographyCustom type="407">
            <Price value={totalCartPriceAndFees} />
          </TypographyCustom>
        </div>
      </div>
      <ButtonLoading
        component={<ButtonLink />}
        disabled={isEmpty}
        variant="contained"
        color="primary"
        classes={{
          root: classes.button,
          label: classes.buttonLabel,
        }}
        onClick={() => {
          if (!isFetchingOrderPreview) {
            EventManager.dispatch(EventManager.Events.ON_CART_SUBMIT, {
              items: itemList,
              restaurant,
            })
          }
        }}
        isLoading={isFetchingOrderPreview}
        to={submitCartLocation}
      >
        <TypographyCustom type="327" skipColor>
          {tr('kiosk.order.page.cart.submit')}
        </TypographyCustom>
      </ButtonLoading>
    </div>
  )
}

export default withStyles(styles)(CartFooter)
