import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import BackHomeButton from '../../../components/BackHomeButton'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  logo: {
    marginTop: theme.spacing(25),
    maxWidth: 600,
    maxHeight: 300,
  },
  thankyou: {
    margin: `${theme.spacing(12)}px auto ${theme.spacing(8)}px`,
    maxWidth: theme.spacing(102),
    lineHeight: `${theme.spacing(12)}px`
  },
  orderIdContainer: {
    display: 'inline-block',
    border: `${theme.spacing(1)}px solid ${theme.palette.primary.main}`,
    padding: `${theme.spacing(8)}px ${theme.spacing(9)}px`,
    borderRadius: 3,
  },
  orderId: {
    marginTop: theme.spacing(7),
  },
  help: {
    marginTop: theme.spacing(14),
    maxWidth: theme.spacing(106),
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: `${theme.spacing(5)}px`,
  },
  expandedBackHomeButton: {
    width: 'auto',
    margin: `${theme.spacing(31)}px auto`,
    padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
    display: 'flex',
  },
  iconContainerExpanded: {
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
  textExpanded: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '32px',
  },
})

const View = ({ classes, frontAppConfig, onGoBackHome, orderData, restaurant }) => (
  <div className={classes.root}>
    <img className={classes.logo} src={frontAppConfig.theme.logo.path || restaurant.logo} alt={restaurant.name} />

    <TypographyCustom type="807" align="center" className={classes.thankyou}>
      {tr('kiosk.order.page.confirm.thank_you')}
    </TypographyCustom>

    <div className={classes.orderIdContainer}>
      <TypographyCustom type="487" align="center">
        {tr('kiosk.order.page.confirm.order')}
      </TypographyCustom>
      <TypographyCustom type="2004" align="center" className={classes.orderId}>
        {orderData.truncOrderId}
      </TypographyCustom>
    </div>

    <TypographyCustom type="324" align="center" className={classes.help}>
      {tr('kiosk.order.page.confirm.help')}
    </TypographyCustom>

    <BackHomeButton
      expandedVersion
      classes={{
        root: classes.expandedBackHomeButton,
        textExpanded: classes.textExpanded,
        iconContainerExpanded: classes.iconContainerExpanded,
      }}
      onClick={onGoBackHome}
    />
  </div>
)

export default withStyles(styles)(View)
