import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import {
  addOrderMenuToCart,
  addOrderProductToCart,
  getSuspendedDatas,
  setSuspendedDatas,
  updateOrderProductOnCart,
} from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import { showDietaryInfoDialog, withCatalog, withCategory } from 'pmt-modules/catalog'
import { hideProductDialog } from 'pmt-modules/kioskOrder'
import { redirectTo } from 'pmt-modules/routing'
import {
  addOrderProductToMenu,
  updateOrderProductToMenu,
  getOrderMenu,
} from 'pmt-modules/orderMenu'
import { selectOptionValue, unselectOptionValue } from 'pmt-modules/orderProduct'
import SelectedProductContainer from 'pmt-modules/orderProduct/components/SelectedProductContainer'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { SuggestionActionType } from 'pmt-modules/suggestion'

import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
@withCatalog
@withCategory
class ProductDialog extends React.Component {
  handleClose = () => {
    if (!this.props.viewOptions.isMenuMode) {
      const {
        cartSuspendedDatas,
        addOrderProductToCart,
        addOrderMenuToCart,
        setSuspendedDatas,
      } = this.props

      // we check if we have suspended item to be added to cart when the user quit the product
      // we need to be in replace mode because the compose mode is already added
      if (
        !isNil(cartSuspendedDatas) &&
        cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
      ) {
        if (cartSuspendedDatas.suspendedItem.isProduct) {
          addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
        } else if (cartSuspendedDatas.suspendedItem.isMenu) {
          addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
        }
        setSuspendedDatas(null, null)
      }
    }

    this.props.hideProductDialog()
  }

  handleSelectOptionValue = (option, value) => {
    this.props.selectOptionValue(option, value)
  }

  handleUnselectOptionValue = (option, value) => {
    this.props.unselectOptionValue(option, value)
  }

  handleSubmitOrderProduct = (orderProduct, category) => {
    const {
      cartSuspendedDatas,
      viewOptions,
      updateOrderProductOnCart,
      updateOrderProductToMenu,
      addOrderProductToCart,
      addOrderProductToMenu,
      orderMenu,
      hideProductDialog,
      restaurant,
      setSuspendedDatas,
    } = this.props

    if (orderProduct.isValid) {
      if (viewOptions.isMenuMode === true) {
        // menu mode
        if (viewOptions.isEditMode === true) {
          updateOrderProductToMenu(orderProduct, viewOptions.partId, viewOptions.partCategoryId)
        } else {
          EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_PRODUCT, {
            category,
            item: orderProduct,
            menu: orderMenu,
            restaurant,
          })

          addOrderProductToMenu(orderProduct, viewOptions.partId, viewOptions.partCategoryId, {
            skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : true,
          })
        }
      } else {
        // product mode
        if (viewOptions.isEditMode === true) {
          updateOrderProductOnCart(orderProduct)
        } else {
          if (!orderProduct.hasSuggestion) {
            EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
              category,
              item: orderProduct,
              restaurant,
            })
          }
          addOrderProductToCart(orderProduct, {
            skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : false,
          })
        }

        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }
      }

      hideProductDialog()
    }
  }

  render() {
    const { catalog, viewOptions, restaurant, showDietaryInfoDialog } = this.props
    // viewOptions:
    // - isMenuMode {boolean}
    // - isEditMode {boolean}
    const { isMenuMode } = viewOptions

    return this.props.children({
      dialogContent: (
        <SelectedProductContainer catalog={catalog} isMenuMode={isMenuMode}>
          {({ orderProduct }) =>
            orderProduct ? (
              <View
                restaurant={restaurant}
                orderProduct={orderProduct}
                viewOptions={viewOptions}
                handleSelectOptionValue={this.handleSelectOptionValue}
                handleUnselectOptionValue={this.handleUnselectOptionValue}
                handleSubmitOrderProduct={this.handleSubmitOrderProduct}
                onClose={this.handleClose}
                showDietaryInfoDialog={() => showDietaryInfoDialog(orderProduct)}
              />
            ) : (
              <div />
            )
          }
        </SelectedProductContainer>
      ),
      onBackdropClick: () => this.handleClose(),
    })
  }
}

// Dialog root properties
ProductDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: false,
  isComplex: true,
}

ProductDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 875,
    width: 875,
    // fix weird bug on Safari
    zIndex: 100,
  },
})

const mapStateToProps = (state, props) => ({
  cartSuspendedDatas: getSuspendedDatas(state),
  orderMenu: getOrderMenu(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      hideProductDialog,
      selectOptionValue,
      unselectOptionValue,
      addOrderProductToCart,
      addOrderProductToMenu,
      addOrderMenuToCart,
      updateOrderProductToMenu,
      updateOrderProductOnCart,
      redirectTo,
      setSuspendedDatas,
      showDietaryInfoDialog,
    }
  )
)(ProductDialog)
