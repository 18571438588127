import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import RestaurantUserAccountsContainer from '../RestaurantUserAccountsContainer'
import View from './View'

const defaultBalanceLabel = 'global.user_account.my_credit'

const RestaurantUserAccountRemainingView = ({ userId, restaurantId, balanceLabel = defaultBalanceLabel }) => (
  <RestaurantUserAccountsContainer
    userId={userId}
    restaurantId={restaurantId}
  >
    {props =>
      props.isFetchingUserAccounts || props.userAccount === null
      ? (
        <LoadingBlockWrapper
          show
        />
      ) : (
        <View
          balanceLabel={tr(balanceLabel)}
          {...props}
        />
      )
    }
  </RestaurantUserAccountsContainer>
)

export default RestaurantUserAccountRemainingView
