import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import NavigationChevronLeft from 'pmt-ui/svg-icons/navigation/chevron-left'

const styles = theme => ({
  root: {},
  iconBackContainer: {
    float: 'left',
    display: 'inline',
    width: 45,
    height: 55,
    marginRight: theme.spacing(3),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    borderRadius: 3,
  },
  iconBack: {
    width: '100%',
    height: '100%',
  },
  backToCatalogLabel: {
    height: 55,
    lineHeight: '55px',
    color: theme.palette.primary.main,
  },
})

const BackTo = ({ text, handleOnClick, classes }) => (
  <div
    className={classes.root}
    onClick={handleOnClick}
  >
    <div className={classes.iconBackContainer}>
      <NavigationChevronLeft className={classes.iconBack} />
    </div>
    <TypographyCustom type="247" className={classes.backToCatalogLabel}>
      {text}
    </TypographyCustom>
  </div>
)

export default withStyles(styles)(BackTo)
