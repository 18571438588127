import React from 'react'
import { connect } from 'react-redux'

import { haveAuthCredentials } from '../../../auth'

/**
 * @specs N/A
 *
 * A HOC that fetch the current logged in user and pass it to the children.
 *
 * Requirements:
 * -
 *
 * see `withUserMe`
 *
 */
class AuthStateContainer extends React.Component {
  render() {
    const { haveAuthCredentials, AuthWrappedComponent, ...otherProps } = this.props

    if (AuthWrappedComponent) {
      return <AuthWrappedComponent haveAuthCredentials={haveAuthCredentials} {...otherProps} />
    }

    return this.props.children({
      haveAuthCredentials,
    })
  }
}

const mapStateToProps = (state, props) => {
  return {
    haveAuthCredentials: haveAuthCredentials(state),
  }
}

export default connect(mapStateToProps, {})(AuthStateContainer)
