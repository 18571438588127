import { LOCATION_CHANGE } from 'pmt-modules/routing'

import Immutable from 'immutable'

import {
  getDefaultBasicToken,
  getDefaultApiConsumer,
  getDefaultRestaurantId,
} from './utils'

import { AuthRestaurantAction } from './actions'

export * from './utils'
export * from './actions'
export * from './selectors'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({
  auth: getDefaultBasicToken(),
  apiConsumer: getDefaultApiConsumer(),
  restaurantId: getDefaultRestaurantId(),
  isFetching: false,
  error: null,

  // for Refresh.
  refreshSucceded: false,
})

export const authRestaurantReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case AuthRestaurantAction.REQUEST:
      return state.merge({
        isFetching: true,
        auth: null,
        error: null,
      })

    case AuthRestaurantAction.SUCCESS:
      return state.merge({
        isFetching: false,
        auth: action.data.authorization,
        apiConsumer: action.data.apiConsumer,
        restaurantId: action.data.restaurantId,
        refreshSucceded: true,
        error: null,
      })

    case AuthRestaurantAction.FAILURE:
      return state.merge({
        isFetching: false,
        auth: null,
        error: action.error,
      })

    default:
      return state
  }
}
