import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'pmt-ui/Button'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  squareContainer: {
    minWidth: 'auto',
    padding: theme.spacing(0.75),
    cursor: 'pointer',
  },
  borderPrimary: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  borderDefault: {
    border: `2px solid ${theme.pmt.colors.grey91}`,
    '&:focus,&:hover': {
      border: `2px solid ${theme.pmt.colors.grey91}`,
    },
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  colorDefault: {
    color: theme.pmt.colors.grey91,
  },
})

/**
 * @specs N/A
 */
class SquareIcon extends React.PureComponent {
  render() {
    const { icon, primaryColor, onClick, classes } = this.props

    return (
      <Button
        variant="outlined"
        color={primaryColor ? 'primary' : 'default'}
        className={classNames(classes.squareContainer, {
          [classes.borderPrimary]: primaryColor,
          [classes.borderDefault]: !primaryColor,
        })}
        onClick={onClick}
      >
        {React.cloneElement(icon, {
          className: classNames({
            [classes.colorPrimary]: primaryColor,
            [classes.colorDefault]: !primaryColor,
          }),
        })}
      </Button>
    )
  }
}

SquareIcon.defaultProps = {
  primaryColor: false,
}

SquareIcon.propTypes = {
  icon: PropTypes.object,
  primaryColor: PropTypes.bool,
}

export default withStyles(styles)(SquareIcon)
