import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'

import AlertError from 'pmt-ui/svg-icons/alert/error'
import Button from 'pmt-ui/Button'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import HardwareTpe from 'pmt-ui/svg-icons/hardware/tpe'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import LoadingPostPayment from './components/LoadingPostPayment'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  logo: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(6),
    maxWidth: 230,
    maxHeight: 230,
  },
  title: {
    marginTop: theme.spacing(9),
  },
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  leftToPay: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(16),
  },
  amount: {
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  tpe: {
    color: 'black',
    marginTop: theme.spacing(16),
    width: 400,
    height: 400,
  },
  tip: {
    margin: `${theme.spacing(18)}px auto`,
    maxWidth: 638,
    lineHeight: `${theme.spacing(7)}px`,
  },
  button: {
    minWidth: 340,
    fontSize: 40,
    padding: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
  errorTitle: {
    display: 'flex',
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: 32,
      marginRight: theme.spacing(1),
    },
  },
  errorContent: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  errorButtons: {
    marginLeft: theme.spacing(4),
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
})

const View = ({
  classes,
  check,
  frontAppConfig,
  onCancel,
  onErrorRetry,
  paymentKioskError,
  paymentError,
  restaurant,
}) => (
  <div className={classes.root}>
    <LoadingPostPayment />
    {(paymentError || paymentKioskError) && (
      <ErrorBlock
        mode={ErrorBlock.Mode.DIALOG}
        error={{
          localizedMessage:
            (paymentKioskError && tr('kiosk.checkout.page.payment.credit_card.error.message')) ||
            (paymentError && tr('kiosk.checkout.page.payment.credit_card.api_error.message')),
        }}
        options={{
          contentComponent: <TypographyCustom type="244" className={classes.errorContent} />,
          title: (
            <TypographyCustom type="327" className={classes.errorTitle} skipColor>
              <AlertError />
              {paymentKioskError && tr('kiosk.checkout.page.payment.credit_card.error.title')}
              {paymentError && tr('kiosk.checkout.page.payment.credit_card.api_error.title')}
            </TypographyCustom>
          ),
          actions: (
            <div className={classes.actions}>
              {paymentKioskError && (
                <Button
                  onClick={onCancel}
                  color="primary"
                  variant="outlined"
                  className={classes.errorButtons}
                >
                  <TypographyCustom type="247" skipColor>
                    {tr('kiosk.checkout.page.payment.credit_card.error.cancel')}
                  </TypographyCustom>
                </Button>
              )}
              <Button
                onClick={onErrorRetry}
                color="primary"
                variant="contained"
                className={classes.errorButtons}
                autoFocus
              >
                <TypographyCustom type="247" skipColor>
                  {tr('kiosk.checkout.page.payment.credit_card.error.try_again')}
                </TypographyCustom>
              </Button>
            </div>
          ),
        }}
      />
    )}

    <Fragment>
      {frontAppConfig.theme.logo.display && (
        <div className={classes.logoContainer}>
          <img
            src={frontAppConfig.theme.logo.path || restaurant.logo}
            className={classes.logo}
            alt="logo"
          />
        </div>
      )}
      <TypographyCustom type="407" align="center" className={classes.title}>
        {tr('kiosk.checkout.page.payment.credit_card.title')}
      </TypographyCustom>

      <TypographyCustom type="404" align="center" className={classes.leftToPay}>
        {tr('kiosk.checkout.page.payment.credit_card.left_to_pay')}
      </TypographyCustom>
      <TypographyCustom type="804" align="center" className={classes.amount}>
        {check.outstandingBalanceFormatted}
      </TypographyCustom>

      <HardwareTpe className={classes.tpe} />

      <TypographyCustom type="404" align="center" className={classes.tip}>
        {tr('kiosk.checkout.page.payment.credit_card.tip')}
      </TypographyCustom>
    </Fragment>
  </div>
)

export default withStyles(styles)(View)
