import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'

import { connect } from 'react-redux'

import { getPublicUrl } from 'pmt-modules/environment'
import { nfcStart, scanStart } from 'pmt-modules/kioskInteractor/actions'
import { getErrorKioskNfc } from 'pmt-modules/kioskInteractor/selectors'
import { setPagerId } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'

import ErrorIcon from '@material-ui/icons/Error'
import TouchAppIcon from '@material-ui/icons/TouchApp'

import NumericPadDialog from 'app/components/NumericPad/NumericPadDialog'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
  },
  scanRfid: {
    marginTop: theme.spacing(17),
    flex: 1,
  },
  errorTitle: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fontSize: 32,
      marginRight: theme.spacing(1),
    },
  },
  errorContent: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginLeft: theme.spacing(4),
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    textTransform: 'initial',
    border: `3px solid ${theme.palette.primary.main}`,
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
  caption: {
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,

    '& svg': {
      fontSize: 48,
      marginLeft: theme.spacing(1),
    },
  },
})

const ErrorView = ({ classes, canRetry, onCancel, onRetry }) => (
  <ErrorBlock
    mode={ErrorBlock.Mode.DIALOG}
    error={{
      localizedMessage: canRetry
        ? tr('kiosk.order.page.scan.nfc.error.message_retry')
        : tr('kiosk.order.page.scan.nfc.error.message'),
    }}
    options={{
      contentComponent: <TypographyCustom type="244" className={classes.errorContent} />,
      title: (
        <TypographyCustom type="327" className={classes.errorTitle} skipColor>
          <ErrorIcon /> {tr('kiosk.order.page.scan.nfc.error.title')}
        </TypographyCustom>
      ),
      actions: (
        <div className={classes.actions}>
          <Button
            onClick={onCancel}
            color="primary"
            className={classes.buttons}
            autoFocus={!canRetry}
          >
            <TypographyCustom type="247" skipColor>
              {tr('kiosk.order.button.home_expanded')}
            </TypographyCustom>
          </Button>
          {canRetry && (
            <Button
              onClick={onRetry}
              color="primary"
              variant="contained"
              className={classes.buttons}
              autoFocus
            >
              <TypographyCustom type="247" skipColor>
                {tr('global.retry')}
              </TypographyCustom>
            </Button>
          )}
        </div>
      ),
    }}
  />
)

class WithScanView extends React.PureComponent {
  constructor(props) {
    super(props)

    this.startDevice()
  }

  startDevice = () => {
    const { orderAppConfig, nfcStart, scanStart } = this.props

    if (orderAppConfig.hasPagerCaptureScanner2D) {
      scanStart()
    } else if (orderAppConfig.hasPagerCaptureContactless) {
      nfcStart()
    }
  }

  handleCancel = () => {
    this.props.hideErrorDialog()
    this.props.redirectTo(getRoute(this.props.frontAppConfig.home))
  }

  handleErrorRetry = () => {
    this.startDevice()
    this.props.hideErrorDialog()
  }

  handlePagerId = (pagerId) => {
    this.props.setPagerId(pagerId)
    this.props.redirectTo(getRoute('KIOSK__ORDER__PAYMENT__MODE'))
  }

  render() {
    const { classes, errorKioskNfc, orderAppConfig } = this.props

    return (
      <div className={classes.root}>
        {errorKioskNfc && (
          <ErrorView
            classes={classes}
            canRetry={errorKioskNfc.canRetry}
            onRetry={this.handleErrorRetry}
            onCancel={this.handleCancel}
          />
        )}
        <div className={classes.content}>
          <div className={classes.scanRfid}>
            <img src={getPublicUrl() + '/img/scan_rfid.png'} alt="scan rfid" />
            {orderAppConfig.hasPagerCaptureNumericPad && (
              <NumericPadDialog
                button={
                  <TypographyCustom type="327" className={classes.caption}>
                    {tr('kiosk.order.page.scan.use_pad')}
                    <TouchAppIcon />
                  </TypographyCustom>
                }
                onSubmit={this.handlePagerId}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  errorKioskNfc: getErrorKioskNfc(state),
})

export default compose(
  connect(mapStateToProps, {
    redirectTo,
    hideErrorDialog,
    getRoute,
    nfcStart,
    scanStart,
    setPagerId,
  }),
  withStyles(styles)
)(WithScanView)
