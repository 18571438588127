import { KioskTypes } from './constants'

let _kioskInteractor = null
let _kioskPrinter = null

/**
 * Kiosk module will be loaded regarding what has been set in app config
 * check getAppConfigSuccess middleware to see registering
 */
export const registerKioskInteractor = type => {
  if (process.env.__DEV__) {
    _kioskInteractor = require('./local')
    _kioskPrinter = require('./local/printer')
    return
  }
  switch (type) {
    case KioskTypes.ACRELEC:
      _kioskInteractor = require('./acrelec')
      _kioskPrinter = require('./acrelec/printer')
      break

    case KioskTypes.BFAST:
      _kioskInteractor = require('./bfast')
      _kioskPrinter = require('./bfast/printer')
      break

    case KioskTypes.NIXDORF:
      _kioskInteractor = require('./nixdorf')
      _kioskPrinter = require('./nixdorf/printer')
      break

    case KioskTypes.DEMO:
      _kioskInteractor = require('./local')
      _kioskPrinter = require('./local/printer')
      break

    default:
      break
  }
}

export const getKiosk = () => _kioskInteractor.default
export const getKioskPrinter = () => _kioskPrinter.default
