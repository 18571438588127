import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { FlowTypes, getFlowType } from 'pmt-modules/frontFlow'
import { Peripherals } from 'pmt-modules/kioskInteractor/constants'

import MessageBlock from 'pmt-ui/MessageBlock'
import ErrorOutline from 'pmt-ui/svg-icons/action/error-outline'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  messageBlock: {
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.8)',
    top: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
    color: theme.pmt.colors.red800,
    width: `calc(100% - ${theme.spacing(10)}px)`,
  },
  iconError: {
    height: 33,
    width: 33,
    marginRight: theme.spacing(1),
  },
  text: {
    lineHeight: 1.04,
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const hasKioskPeripheralsStatusError = (frontAppConfig, kioskPeripheralsStatus) => {
  if (!kioskPeripheralsStatus) {
    return true
  }

  let hasError = false
  Object.values(frontAppConfig.peripherals).forEach(peripheral => {
    if (!kioskPeripheralsStatus[peripheral].status) {
      hasError = true
    }
  })
  return hasError
}

const hasKioskPeripheralStatusError = (frontAppConfig, kioskPeripheralsStatus, peripheral) => {
  if (frontAppConfig.peripherals.indexOf(peripheral) > -1) {
    if (!kioskPeripheralsStatus) {
      return true
    }
    return !kioskPeripheralsStatus[peripheral].status
  }
  return false
}

class GlobalErrorBlock extends React.PureComponent {
  state = {
    displayOrderError: true,
  }

  constructor(props) {
    super(props)

    this.hideOrderError()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.displayOrderError && !this.state.displayOrderError) {
      this.setState({
        displayOrderError: true,
      })
    }

    if (this.state.displayOrderError) {
      this.hideOrderError()
    }
  }

  hideOrderError = () => {
    setTimeout(() => {
      this.setState({
        displayOrderError: false,
      })
    }, 10000)
  }

  render() {
    const {
      classes,
      catalogError,
      flowType,
      frontAppConfig,
      kioskPeripheralsStatus,
      kioskSettings,
      restaurant,
    } = this.props

    return (
      (hasKioskPeripheralsStatusError(frontAppConfig, kioskPeripheralsStatus) ||
        (this.state.displayOrderError &&
          (!restaurant.valided || !restaurant.orderSettings.isCurrentlyOpen)) ||
        isEmpty(kioskSettings.order.modes) ||
        !isNil(catalogError)) && (
        <div className={classes.messageBlock}>
          {hasKioskPeripheralStatusError(
            frontAppConfig,
            kioskPeripheralsStatus,
            Peripherals.PAYMENT
          ) && (
            <MessageBlock
              className={classes.text}
              icon={<ErrorOutline className={classes.iconError} />}
              text={
                <TypographyCustom type="284" skipColor>
                  {tr('kiosk.errors.tpe.disabled.home')}
                </TypographyCustom>
              }
            />
          )}
          {hasKioskPeripheralStatusError(
            frontAppConfig,
            kioskPeripheralsStatus,
            Peripherals.PRINTER
          ) && (
            <MessageBlock
              className={classes.text}
              icon={<ErrorOutline className={classes.iconError} />}
              text={
                <TypographyCustom type="284" skipColor>
                  {tr('kiosk.errors.printer.disabled.home')}
                </TypographyCustom>
              }
            />
          )}
          {hasKioskPeripheralStatusError(
            frontAppConfig,
            kioskPeripheralsStatus,
            Peripherals.SCANNER
          ) && (
            <MessageBlock
              className={classes.text}
              icon={<ErrorOutline className={classes.iconError} />}
              text={
                <TypographyCustom type="284" skipColor>
                  {tr('kiosk.errors.scanner.disabled.home')}
                </TypographyCustom>
              }
            />
          )}
          {hasKioskPeripheralStatusError(
            frontAppConfig,
            kioskPeripheralsStatus,
            Peripherals.NFC
          ) && (
            <MessageBlock
              className={classes.text}
              icon={<ErrorOutline className={classes.iconError} />}
              text={
                <TypographyCustom type="284" skipColor>
                  {tr('kiosk.errors.nfc.disabled.home')}
                </TypographyCustom>
              }
            />
          )}
          {flowType === FlowTypes.ORDER &&
            (!restaurant.valided ||
              !restaurant.hasFeatureOrder ||
              isEmpty(kioskSettings.order.modes) ||
              !restaurant.orderSettings.isCurrentlyOpen ||
              !isNil(catalogError)) &&
            this.state.displayOrderError && (
              <MessageBlock
                className={classes.text}
                icon={<ErrorOutline className={classes.iconError} />}
                text={
                  <TypographyCustom type="284" skipColor>
                    {tr('kiosk.errors.restaurant.closed')}
                  </TypographyCustom>
                }
              />
            )}
          {flowType === FlowTypes.CHECKOUT &&
            (!restaurant.valided || !restaurant.hasFeaturePayment) && (
              <MessageBlock
                className={classes.text}
                icon={<ErrorOutline className={classes.iconError} />}
                text={
                  <TypographyCustom type="284" skipColor>
                    {tr('kiosk.errors.restaurant.closed')}
                  </TypographyCustom>
                }
              />
            )}
        </div>
      )
    )
  }
}

const mapStateToProps = state => ({
  flowType: getFlowType(state),
})

export default compose(
  connect(
    mapStateToProps,
    {}
  ),
  withStyles(styles)
)(GlobalErrorBlock)
