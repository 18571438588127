import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '../../SvgIcon';

let Trash = (props) => (
  <SvgIcon {...props}>
    <path d="M490.667,85.333H448h-68.693l-7.019-28.075C363.883,23.552,333.717,0,298.965,0h-85.931    c-34.752,0-64.917,23.552-73.323,57.259l-7.019,28.075H64H21.333C9.536,85.333,0,94.891,0,106.667S9.536,128,21.333,128h22.485    l17.963,323.541C63.68,485.44,91.776,512,125.717,512h260.565c33.941,0,62.037-26.56,63.936-60.459L468.181,128h22.485    c11.797,0,21.333-9.557,21.333-21.333S502.464,85.333,490.667,85.333z M181.077,67.605c3.691-14.677,16.811-24.939,31.957-24.939    h85.931c15.147,0,28.267,10.261,31.957,24.939l4.416,17.728H176.661L181.077,67.605z M192,405.333    c0,11.776-9.536,21.333-21.333,21.333c-11.797,0-21.333-9.557-21.333-21.333V192c0-11.776,9.536-21.333,21.333-21.333    c11.797,0,21.333,9.557,21.333,21.333V405.333z M277.333,405.333c0,11.776-9.536,21.333-21.333,21.333    s-21.333-9.557-21.333-21.333V192c0-11.776,9.536-21.333,21.333-21.333s21.333,9.557,21.333,21.333V405.333z M362.667,405.333    c0,11.776-9.536,21.333-21.333,21.333c-11.797,0-21.333-9.557-21.333-21.333V192c0-11.776,9.536-21.333,21.333-21.333    c11.797,0,21.333,9.557,21.333,21.333V405.333z"/>
  </SvgIcon>
);
Trash = pure(Trash);
Trash.displayName = 'Trash';
Trash.muiName = 'SvgIcon';

export default Trash;
