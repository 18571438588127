import { formatUser } from 'pmt-modules/user/format'

import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

const formatAmount = userAccount => {
  userAccount.amountFormatted = formatPriceWithCurrency(userAccount.amount)
  return userAccount
}

export const formatUserAccount = createFormatter(
  formatAmount,
  createSubObjectFormatter('user', formatUser)
)

export const formatUserAccounts = createListFormatter(formatUserAccount)
