import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import withCheck from 'pmt-modules/payment/components/withCheck'
import { getInactivityTimeoutInstance, resetKiosk, setInactivityTimeout } from 'pmt-modules/kiosk'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { getRoute, redirectTo } from 'pmt-modules/routing'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-967
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875b3368160a3245af6a
 */
@withAppConfig
@withRestaurant
@withCheck
class OrderConfirmPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (isNil(props.check)) {
      props.resetKiosk()
    }
  }

  componentDidMount() {
    this.props.setInactivityTimeout(() => {
      this.props.resetKiosk()
    }, this.props.frontAppConfig.confirmDisplayType === 'DEFAULT' ? 15000 : 60000)
  }

  handleGoToScan = () => {
    this.props.setInactivityTimeout()
    this.props.resetKiosk()
    this.props.redirectTo(getRoute('KIOSK__CHECKOUT__SCAN'))
  }

  handleGoBackHome = () => {
    this.props.setInactivityTimeout()
    this.props.resetKiosk()
  }

  render() {
    const { frontAppConfig, route, check, restaurant } = this.props

    return (
      <KioskPage route={route} isFetching={!restaurant}>
        <View
          frontAppConfig={frontAppConfig}
          check={check}
          restaurant={restaurant}
          onGoBackHome={this.handleGoBackHome}
          onGoToScan={this.handleGoToScan}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  inactivityTimeout: getInactivityTimeoutInstance(state),
})

export default connect(
  mapStateToProps,
  {
    redirectTo,
    resetKiosk,
    setInactivityTimeout,
  }
)(OrderConfirmPage)
