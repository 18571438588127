import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Price from 'pmt-ui/Price'
import Button from 'pmt-ui/Button'
import ShoppingBasket from 'pmt-ui/svg-icons/action/shopping-basket'
import WheatIcon from 'pmt-ui/svg-icons/food/wheat'

import CartModifierTag from 'app/components/CartModifierTag'
import Chip from 'app/components/Chip'

import OptionsContainer from './OptionsContainer'

const styles = theme => ({
  header: {
    width: '100%',
    paddingBottom: theme.spacing(6),
  },
  productName: {
    flex: ' 0 0 100%',
  },
  productDescription: {
    marginTop: theme.spacing(3),
    flex: ' 0 0 100%',
  },
  allergen: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'flex-start',
    marginLeft: `-${theme.spacing(1)}px`,
    color: theme.palette.primary.main,
    alignItems: 'center',
    textDecoration: 'underline',
    '& svg': {
      marginRight: `0 !important`,
    },
  },
  buttonAdd: {
    width: 104,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    margin: `${theme.spacing(4)}px 0 0`,
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    transition: 'width .2s ease-in',
  },
  buttonAddValid: {
    width: 813,
    textAlign: 'center',
  },
  buttonIcon: {
    float: 'left',
    width: 40,
    height: 40,
  },
  buttonAddText: {
    color: theme.pmt.colors.white,
    textTransform: 'initial',
  },
  buttonText: {
    marginLeft: theme.spacing(3),
    minHeight: 34,
    maxHeight: 34,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    lineHeight: '34px',
  },
  priceContainer: {
    marginTop: theme.spacing(2),
  },
  price: {
    alignItems: 'baseline',
  },
  basePrice: {
    '& > hr': {
      top: 13,
    },
  },
  cartModifierContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  cartModifierChip: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: 4,
  },
})

const ProductView = ({
  orderProduct,
  restaurant,
  viewOptions,
  handleSelectOptionValue,
  handleUnselectOptionValue,
  handleSubmitOrderProduct,
  classes,
  showDietaryInfoDialog,
  setReclaimLater,
}) => (
  <React.Fragment>
    <div className={classes.header}>
      <div>
        <TypographyCustom type="487" className={classes.productName}>
          {orderProduct.name}
        </TypographyCustom>
        <TypographyCustom type="244" className={classes.productDescription}>
          {orderProduct.description}
        </TypographyCustom>
        {orderProduct.hasAllergens && (
          <TypographyCustom
            type="244"
            skipColor
            className={classes.allergen}
            onClick={showDietaryInfoDialog}
          >
            <WheatIcon className={classes.buttonIcon} />
            {tr('kiosk.order.dialog.dietary_info.title')}
          </TypographyCustom>
        )}

        <TypographyCustom type="487" className={classes.priceContainer}>
          <Price
            value={orderProduct.priceFormatted}
            hasCartModifier={orderProduct.hasCartModifier}
            baseValue={orderProduct.hasCartModifier ? orderProduct.basePriceFormatted : null}
            classes={{ root: classes.price, baseValue: classes.basePrice }}
          />
        </TypographyCustom>

        {orderProduct.hasCartModifier && (
          <div className={classes.cartModifierContainer}>
            {orderProduct.cartModifiers.list.map((cartModifier, index) => (
              <CartModifierTag
                cartModifier={cartModifier}
                chipElement={
                  <Chip
                    key={index}
                    label={cartModifier.tag}
                    classes={{ root: classes.cartModifierChip }}
                  />
                }
                key={index}
              />
            ))}
          </div>
        )}
      </div>
      <TypographyCustom type="327" skipColor>
        {({ className }) => (
          <Button
            variant="contained"
            color="primary"
            disabled={!orderProduct.isValid}
            classes={{
              root: classNames(classes.buttonAdd, {
                [classes.buttonAddValid]: orderProduct.isValid,
              }),
              label: classNames(className, classes.buttonAddText),
            }}
            onClick={() => {
              handleSubmitOrderProduct(orderProduct)
            }}
          >
            {viewOptions.isMenuMode ? (
              <React.Fragment>
                <ShoppingBasket className={classes.buttonIcon} />
                {orderProduct.isValid && (
                  <span className={classes.buttonText}>{`${tr('global.order.add_to_menu')}${
                    orderProduct.totalPrice > 0 ? ` (+${orderProduct.totalPriceFormatted})` : ''
                  }`}</span>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ShoppingBasket className={classes.buttonIcon} />
                {orderProduct.isValid && (
                  <span className={classes.buttonText}>
                    {!viewOptions.isEditMode
                      ? `${tr('global.order.add_to_cart')} (${orderProduct.totalPriceFormatted})`
                      : tr('global.order.update')}
                  </span>
                )}
              </React.Fragment>
            )}
          </Button>
        )}
      </TypographyCustom>
    </div>

    <OptionsContainer
      restaurant={restaurant}
      orderProduct={orderProduct}
      viewOptions={viewOptions}
      handleSelectOptionValue={handleSelectOptionValue}
      handleUnselectOptionValue={handleUnselectOptionValue}
      setReclaimLater={setReclaimLater}
    />
  </React.Fragment>
)

export default withStyles(styles)(ProductView)
