import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import {
  getPartSelectedCategory,
  getPartParentCategory,
  getCategoryFromPart,
  resetPartParentCategories,
  resetPartSelectedCategory,
} from 'pmt-modules/orderMenu'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Grid from 'pmt-ui/Grid'
import Button from 'pmt-ui/Button'
import NavigationCheck from 'pmt-ui/svg-icons/navigation/check'
import ContentClear from 'pmt-ui/svg-icons/content/clear'

import ItemCard from '../../../../components/ItemCard'
import CategoryCard from '../../../../components/CategoryCard'
import ParentCategoryCard from '../../../../components/ParentCategoryCard'

const styles = theme => ({
  root: {
    overflow: 'hidden',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
    background: theme.pmt.colors.greyEF,
  },
  name: {
    paddingLeft: theme.spacing(1),
  },
  itemContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  itemCardRoot: {
    width: 230,
    height: 230,
  },
  itemCardImageContainer: {
    height: 105,
  },
  itemCardPlaceholder: {
    maxWidth: 105,
    maxHeight: 105,
  },
  subCategoryImage: {
    maxWidth: 105,
    maxHeight: 105,
  },
  itemCardName: {
    minHeight: 45,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '22px',
    color: theme.pmt.colors.grey26,
  },
  quantitytTipContainer: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  quantityTip: {
    color: theme.pmt.colors.grey4A,
  },
  hr: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    borderTop: `1px solid ${theme.pmt.colors.grey91}`,
  },
  buttonOk: {
    float: 'right',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(2),
    border: `3px solid ${theme.pmt.colors.grey26}`,
    textTransform: 'initial',
  },
  validProductContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconValid: {
    float: 'left',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  valueNameChecked: {
    overflow: 'hidden',
    float: 'left',
    maxWidth: 'calc(100% - 40px)',
    minHeight: 28,
    maxHeight: 84,
    marginLeft: theme.spacing(1),
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    lineHeight: '26px',
  },
  change: {
    float: 'right',
    color: theme.palette.primary.main,
  },
  selectedItems: {
    overflow: 'hidden',
  },
  selectedItem: {
    float: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 230,
    height: 54,
    margin: `0 ${theme.spacing(1) + theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    background: theme.pmt.colors.white,
  },
  selectedItemName: {
    overflow: 'hidden',
    float: 'left',
    width: 'calc(100% - 30px)',
    minHeight: 20,
    maxHeight: 40,
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '18px',
  },
  selectedItemIcon: {
    float: 'right',
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
  },
})

const PartQuantity = ({ part, classes }) => (
  <div className={classes.quantitytTipContainer}>
    {part.nbChosenValues > 0 ? (
      <TypographyCustom component="span" type="204" className={classes.quantityTip}>
        {part.min === 1 && part.max === 1
          ? tr('kiosk.order.menu.part.product_selected')
          : tr('kiosk.order.menu.part.product_selected_value', {
              smart_count: part.nbChosenValues,
            })}
        {!part.hasReachedMaxQuantity && (
          <React.Fragment>
            &nbsp;
            {part.min !== part.max
              ? tr('kiosk.order.menu.part.on_maximum', { value: part.max })
              : tr('kiosk.order.menu.part.on', { value: part.max })}
          </React.Fragment>
        )}
      </TypographyCustom>
    ) : (
      <TypographyCustom component="span" type="204" className={classes.quantityTip}>
        {part.min >= 0 &&
          part.max !== part.min && (
            <React.Fragment>
              {tr('kiosk.order.menu.part.min_max_product_to_select.min', {
                min: part.min,
              })}
              {tr('kiosk.order.menu.part.min_max_product_to_select.max', {
                smart_count: part.max,
              })}
            </React.Fragment>
          )}
        {part.min === part.max && (
          <React.Fragment>
            {tr('kiosk.order.menu.part.min_product_to_select', { smart_count: part.min })}
          </React.Fragment>
        )}
      </TypographyCustom>
    )}
  </div>
)

class PartView extends React.PureComponent {
  constructor(props) {
    super(props)

    if (
      props.part.products.length === 1 &&
      !props.part.products[0].isComplexItem &&
      isEmpty(props.part.categories)
    ) {
      props.onAddToCartFromShortcut(props.part, props.part, props.part.products[0])
    }

    if (props.part.hasReachedMaxQuantity && props.part.nextPart) {
      props.updateUIParent({
        openedPart: props.part.nextPart.id,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(this.props.part, nextProps.part) &&
      nextProps.part.hasReachedMaxQuantity &&
      nextProps.isOpen
    ) {
      this.props.resetPartParentCategories()
      this.props.resetPartSelectedCategory()
      nextProps.updateUIParent({
        openedPart: get(nextProps, 'part.nextPart.id', null),
      })
    }
  }

  render() {
    const {
      part,
      category,
      restaurant,
      partSelectedCategory,
      partParentCategory,
      isOpen,
      onReturnToPreviousPartCategory,
      onSelectCategory,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onAddToCartFromShortcut,
      resetPartParentCategories,
      resetPartSelectedCategory,
      ui,
      updateUI,
      updateUIParent,
      classes,
    } = this.props

    let partCategory = part
    if (!isNil(partSelectedCategory)) {
      const categoryFromPart = getCategoryFromPart(part, partSelectedCategory)
      partCategory = !isNil(categoryFromPart) ? categoryFromPart : part
    }

    return (
      <div
        id={part.id}
        className={classes.root}
        onClick={() => {
          resetPartParentCategories()
          resetPartSelectedCategory()
          updateUI({
            showPartValue: true,
            modifyPartValue: true,
          })
          updateUIParent({
            openedPart: part.id,
          })
        }}
      >
        <TypographyCustom type="327" className={classes.name}>
          {part.name}
          {!isOpen &&
            part.nbChosenValues > 0 && (
              <TypographyCustom type="247" className={classes.change}>
                {tr('global.order.change')}
              </TypographyCustom>
            )}
        </TypographyCustom>
        <PartQuantity part={part} classes={classes} />
        {(part.nbChosenValues === part.max && !ui.modifyPartValue) ||
        !ui.showPartValue ||
        !isOpen ? (
          <Grid container spacing={3} className={classes.validProductContainer}>
            {part.selectedProducts.map((product, index) => (
              <Grid key={index} item xs={6}>
                <NavigationCheck className={classes.iconValid} />
                <TypographyCustom type="247" className={classes.valueNameChecked}>
                  {product.name}
                  {product.totalPrice > 0 && (
                    <React.Fragment>
                      &nbsp;(
                      {`+${product.totalPriceFormatted}`})
                    </React.Fragment>
                  )}
                </TypographyCustom>
              </Grid>
            ))}
          </Grid>
        ) : (
          isOpen && (
            <React.Fragment>
              {!isEmpty(part.selectedProducts) && (
                <div className={classes.selectedItems}>
                  {part.selectedProducts.map((selectedProduct, index) => (
                    <div key={index} className={classes.selectedItem}>
                      <TypographyCustom type="164" className={classes.selectedItemName}>
                        {selectedProduct.name}
                        {selectedProduct.totalPrice > 0 && (
                          <React.Fragment>
                            &nbsp;(
                            {`+${selectedProduct.totalPriceFormatted}`})
                          </React.Fragment>
                        )}
                      </TypographyCustom>
                      <ContentClear
                        className={classes.selectedItemIcon}
                        onClick={e => {
                          e.stopPropagation()
                          onUnselectOrderProduct(
                            part,
                            selectedProduct.partCategory,
                            selectedProduct
                          )
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className={classes.itemContainer}>
                {!isEmpty(partParentCategory) && (
                  <ParentCategoryCard
                    category={partCategory}
                    parentCategory={partParentCategory}
                    onClick={parentCategory => {
                      onReturnToPreviousPartCategory(parentCategory)
                    }}
                  />
                )}
                {!isNil(partCategory.categories) &&
                  partCategory.categories.map((category, index) => (
                    <CategoryCard
                      key={index}
                      category={category}
                      restaurant={restaurant}
                      nameType="207"
                      displayLink
                      onClick={() => {
                        onSelectCategory(category, partCategory)
                      }}
                      classes={{
                        root: classes.itemCardRoot,
                        image: classes.subCategoryImage,
                        name: classes.itemCardName,
                      }}
                    />
                  ))}
                {!isNil(partCategory.products) &&
                  partCategory.products.map((product, index) => (
                    <ItemCard
                      key={index}
                      item={product}
                      restaurant={restaurant}
                      category={category}
                      nameType="204"
                      additionalPrice={product.totalPrice > 0 ? product.totalPriceFormatted : false}
                      selectedLayout={product.quantity > 0}
                      disabledLayout={part.hasReachedMaxQuantity && product.quantity === 0}
                      classes={{
                        root: classes.itemCardRoot,
                        imageContainer: classes.itemCardImageContainer,
                        placeholder: classes.itemCardPlaceholder,
                        name: classes.itemCardName,
                      }}
                      onClick={e => {
                        e.stopPropagation()

                        if (product.available) {
                          if (product.quantity > 0) {
                            onUnselectOrderProduct(part, partCategory, product)
                          } else if (!part.hasReachedMaxQuantity) {
                            if (product.isComplexItem) {
                              if (product.isProduct) {
                                onSelectOrderProduct(part, partCategory, product)
                              }
                            } else {
                              onAddToCartFromShortcut(part, partCategory, product)
                            }
                          }
                        }
                      }}
                      onClickInfo={e => {
                        e.stopPropagation()
                        if (product.available) {
                          onSelectOrderProduct(part, partCategory, product)
                        }
                      }}
                    />
                  ))}
              </div>
              {((part.min < part.max &&
                part.nbChosenValues < part.max &&
                part.nbChosenValues > 0) ||
                part.min === 0) &&
                ui.showPartValue &&
                isOpen && (
                  <Grid item xs={12}>
                    <hr className={classes.hr} />
                    <TypographyCustom type="247">
                      {({ className }) => (
                        <Button
                          variant="outlined"
                          classes={{
                            root: classes.buttonOk,
                            label: className,
                          }}
                          onClick={e => {
                            e.stopPropagation()

                            updateUI({
                              showPartValue: false,
                            })
                            updateUIParent({
                              openedPart: get(part, 'nextPart.id', null),
                            })
                          }}
                        >
                          {part.nbChosenValues === 0
                            ? tr('global.order.no_thanks')
                            : tr('global.order.ok_all')}
                        </Button>
                      )}
                    </TypographyCustom>
                  </Grid>
                )}
            </React.Fragment>
          )
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  partSelectedCategory: getPartSelectedCategory(state),
  partParentCategory: getPartParentCategory(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      resetPartParentCategories,
      resetPartSelectedCategory,
    }
  ),
  ui({
    state: {
      showPartValue: true,
      modifyPartValue: false,
    },
  }),
  withStyles(styles)
)(PartView)
