import React from 'react'
import isNil from 'lodash/isNil'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import CategoryCard from 'app/components/CategoryCard'
import FixedVerticalCarousel from './FixedVerticalCarousel'
import InfiniteVerticalCarousel from './InfiniteVerticalCarousel'

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingRight: theme.spacing(3),
    boxSizing: 'content-box',
    width: '100%',
  },
  categoryCard: {
    width: 200,
    height: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    // to forbid text to go under the selected left bar
    padding: '0 7px 0 0',
    boxShadow: 'none',
    borderLeft: `7px solid ${theme.pmt.colors.white}`,
  },
  selectedBar: {
    borderLeft: `7px solid ${theme.palette.primary.main}`,
  },
  categoryCardContent: {
    height: 'auto',
  },
  categoryCardImage: {
    maxWidth: 100,
    maxHeight: 100,
  },
  categoryCardName: {
    minHeight: 32,
    maxHeight: 72,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '32px',
  },
  categoryCardTextContainer: {
    display: 'block',
    padding: theme.spacing(0, 2),
  },
}))

const CategoriesCarousel = ({ catalog, category, restaurant, onSelectCategory }) => {
  const classes = useStyles()

  // right now, one client doesn't want infinite carousel, so we take it off for everyone ¯\_(ツ)_/¯
  // minimum number of categories to display infinite carousel
  return true || catalog.categories.length < 6 ? (
    <FixedVerticalCarousel
      classes={classes}
      catalog={catalog}
      category={category}
      restaurant={restaurant}
      onSelectCategory={onSelectCategory}
    />
  ) : (
    <InfiniteVerticalCarousel
      elements={catalog.categories}
      renderComponent={(category, selected) => (
        <CategoryCard
          restaurant={restaurant}
          category={category}
          classes={{
            root: classes.categoryCard,
            content: classes.categoryCardContent,
            image: classes.categoryCardImage,
            name: clsx(classes.categoryCardName, {
              [classes.colorPrimary]: selected,
            }),
            textContainer: classes.categoryCardTextContainer,
          }}
          nameType="247"
          onClick={onSelectCategory}
        />
      )}
      elementHeight={250}
      isSelected={(e, ee) => !isNil(e) && !isNil(ee) && e.id === ee.id}
      selectedElement={category}
    />
  )
}

export default CategoriesCarousel
