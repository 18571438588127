import isNil from 'lodash/isNil'

import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { getFrontSettings } from 'pmt-modules/appConfig'
import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { FlowTypes, getFlowType } from 'pmt-modules/frontFlow'
import { PaymentType } from 'pmt-modules/global/constants'
import { KioskActions } from 'pmt-modules/kiosk'
import {
  KioskNfcActions,
  KioskPaymentAction,
  KioskScanAction,
  printContent,
} from 'pmt-modules/kioskInteractor'
import { getDataGetKioskSettings } from 'pmt-modules/kioskSettings'
import CheckoutTicketPrinter from 'pmt-modules/kioskInteractor/utils/printer/CheckoutTicket'
import { RefreshRestaurantAction, formatRestaurant } from 'pmt-modules/restaurant'
import {
  PostIrlPaymentAction,
  processIrlPayment,
  resetPayment,
  resetPostPayment,
} from 'pmt-modules/payment/payment/actions'
import { resetCheck, getCheck, mergeCheckWithCode } from 'pmt-modules/payment/check'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import Logger from 'pmt-utils/logger'

const scanNfcReadDataMiddleware = createMiddleware(
  [KioskNfcActions.READ_DATA, KioskScanAction.RECEIVED_DATA],
  ({ getState, dispatch, action }) => {
    const flowType = getFlowType(getState())

    if (flowType === FlowTypes.CHECKOUT) {
      let code = null
      switch (action.type) {
        case KioskNfcActions.READ_DATA:
          code = action.data.readData
          break
        case KioskScanAction.RECEIVED_DATA:
          code = action.data.scannedData
          break
        default:
      }

      const check = getCheck(getState())
      // a check has already been fetched, we are going to merge it with newly scanned
      if (!isNil(check)) {
        const restaurantId = getRestaurantId(getState())
        console.log(`merge check ${check.id}`, check.outstandingBalance)
        dispatch(mergeCheckWithCode(restaurantId, check.id, code))
      } else {
        dispatch(redirectTo(getRoute('KIOSK__CHECKOUT__CHECK__CODE'), { code }))
      }
    }
  }
)

const handleRedirectAfterRefresh = ({ dispatch, frontSettings, restaurant }) => {
  if (restaurant.isStatusEnabled && restaurant.hasFeaturePayment) {
    if (window.location.pathname === getRoute(frontSettings.home).path) {
      dispatch(redirectTo(getRoute('KIOSK__CHECKOUT__SCAN')))
    }
  } else {
    dispatch(redirectTo(getRoute(frontSettings.home)))
  }
}

const refreshRestaurantSuccess = createMiddleware(
  RefreshRestaurantAction.SUCCESS,
  ({ action, dispatch, getState }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.CHECKOUT) {
      const frontSettings = getFrontSettings(getState())

      handleRedirectAfterRefresh({
        dispatch,
        frontSettings,
        restaurant: formatRestaurant(action.response),
      })
    }
  }
)

const paymentSuccessMiddleware = createMiddleware(
  KioskPaymentAction.SUCCESS,
  ({ action, getState, dispatch }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.CHECKOUT) {
      Logger.info('PAYMENT', 'payment success', { action })

      const printer = new CheckoutTicketPrinter()
      const content = printer.formatBankReceipt()

      const { restaurant, payment, check } = action.options

      payment.type = PaymentType.IRL_CREDIT_CARD
      payment.externalId = action.options.id

      dispatch(printContent(content))
      dispatch(processIrlPayment(restaurant, check, payment))
    }
  }
)

const postPaymentMiddleware = createMiddleware(
  [PostIrlPaymentAction.FAILURE, PostIrlPaymentAction.SUCCESS],
  ({ action, dispatch, getState, next }) => {
    if (action.type === PostIrlPaymentAction.FAILURE) {
      Logger.error('Kiosk Self-Checkout', 'PostIrlPayment Failed', {
        action,
        state: getState(),
      })
    }

    const frontSettings = getFrontSettings(getState())
    const { restaurant, check } = action.data

    if (frontSettings.confirmDisplayType === 'DEFAULT') {
      const printer = new CheckoutTicketPrinter()
      const kioskSettings = getDataGetKioskSettings(getState())

      dispatch(
        printContent(
          printer.format(restaurant, check, {
            kioskSettings,
          })
        )
      )
    }

    next(action)

    return dispatch(
      redirectTo(getRoute('KIOSK__CHECKOUT__CONFIRM'), {
        checkId: check.id,
      })
    )
  }
)

const resetKioskMiddleware = createMiddleware([KioskActions.RESET], ({ dispatch, getState }) => {
  const flowType = getFlowType(getState())
  if (flowType === FlowTypes.CHECKOUT) {
    dispatch(resetCheck())
    dispatch(resetPayment())
    dispatch(resetPostPayment())
  }
})

export const kioskCheckoutMiddlewares = [
  paymentSuccessMiddleware,
  postPaymentMiddleware,
  refreshRestaurantSuccess,
  resetKioskMiddleware,
  scanNfcReadDataMiddleware,
]
