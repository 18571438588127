import React from 'react'

import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    background: theme.palette.primary.main,
  },
  logo: {
    alignItems: 'center',
    display: 'flex',
  },
  logoPicture: {
    margin: '0 auto',
    width: 'auto',
    maxHeight: 450,
  },
  infos: {
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'flex-end',

    '& p': {
      fontWeight: '700',
      margin: '0 auto 80px',
      fontSize: '48px',
      lineHeight: '68px',
      color: theme.palette.common.white,
    },
  },
})

const View = ({ classes, restaurant }) => (
  <div className={classes.root}>
    <div className={classes.logo}>
      {restaurant && (
        <img className={classes.logoPicture} src={restaurant.logo} alt={restaurant.name} />
      )}
    </div>
    <div className={classes.infos}>
      <Typography component="p">Maintenance en cours</Typography>
    </div>
  </div>
)

export default withStyles(styles)(View)
