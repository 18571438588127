import { SdkRoutingEnum, Route } from 'pmt-modules/routing'

const RoutingEnum = {
  ...SdkRoutingEnum,

  KIOSK__ERROR: new Route('KIOSK::ERROR', '/error'),

  /**
   * TOP UP
   */
  KIOSK__TOP_UP__HOME: new Route('KIOSK::TOP_UP::HOME', '/top-up'),
  KIOSK__TOP_UP__LOGIN: new Route('KIOSK::TOP_UP::LOGIN', '/top-up/login'),
  KIOSK__TOP_UP__ACCOUNT: new Route('KIOSK::TOP_UP::ACCOUNT', '/top-up/userAccount/:userAccountId'),
  KIOSK__TOP_UP__PAYMENT: new Route(
    'KIOSK::TOP_UP::PAYMENT',
    '/top-up/userAccount/:userAccountId/payment'
  ),
  KIOSK__TOP_UP__PAYMENT_CONFIRMATION: new Route(
    'KIOSK::TOP_UP::PAYMENT_CONFIRMATION',
    '/top-up/userAccount/:userAccountId/payment-confirmation'
  ),

  /**
   * ORDER
   */
  KIOSK__ORDER__HOME: new Route('KIOSK::ORDER::HOME', '/order'),
  KIOSK__ORDER__MODE: new Route('KIOSK::ORDER::MODE', '/order/mode'),
  KIOSK__ORDER__CATEGORY: new Route('KIOSK::ORDER::CATEGORY', '/order/category'),
  KIOSK__ORDER__CATALOG: new Route('KIOSK::ORDER::CATALOG', '/order/catalog/:categoryId'),
  KIOSK__ORDER__PRODUCT: new Route('KIOSK::ORDER::PRODUCT', '/order/catalog/product/:productId'),
  KIOSK__ORDER__MENU: new Route('KIOSK::ORDER::MENU', '/order/catalog/menu/:menuId'),

  KIOSK__ORDER__CART: new Route('KIOSK::ORDER::CART', '/order/cart'),
  KIOSK__ORDER__PAYMENT__MODE: new Route('KIOSK::ORDER::PAYMENT::MODE', '/order/payment/mode'),
  KIOSK__ORDER__PAYMENT__CREDIT_CARD: new Route(
    'KIOSK::ORDER::PAYMENT::CREDIT_CARD',
    '/order/payment/creditCard'
  ),

  KIOSK__ORDER__SCAN: new Route('KIOSK::ORDER::SCAN', '/order/scan'),

  KIOSK__ORDER__POST__ERROR: new Route('KIOSK::ORDER::POST::ERROR', '/order/post/error'),

  KIOSK__ORDER__CONFIRM: new Route('KIOSK::ORDER::CONFIRM', '/order/confirm'),

  /**
   * CHECKOUT
   */
  KIOSK__CHECKOUT__HOME: new Route('KIOSK::CHECKOUT::HOME', '/checkout'),
  KIOSK__CHECKOUT__SCAN: new Route('KIOSK::CHECKOUT::SCAN', '/checkout/scan'),
  KIOSK__CHECKOUT__CHECK__CODE: new Route('KIOSK::CHECKOUT::CHECK::CODE', '/checkout/check/:code'),
  KIOSK__CHECKOUT__PAYMENT: new Route('KIOSK::CHECKOUT::PAYMENT', '/checkout/:checkId/payment'),
  KIOSK__CHECKOUT__CONFIRM: new Route('KIOSK::CHECKOUT::CONFIRM', '/checkout/:checkId/confirm'),
}

export default RoutingEnum
