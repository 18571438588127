import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'

import { createFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { formatOrderMenuParts } from './part'

const totalPriceFormatter = (orderMenu, props) => {
  // Note: we use || 0 just in the following cases:
  // - menu has broken null info
  // - unit tests does not give the data since it is not required for the test, but required here
  //   to format

  orderMenu.totalPriceFormatted = formatPriceWithCurrency(orderMenu.totalPrice || 0)

  orderMenu.totalPriceWithQuantity =
    // re-use current totalPriceWithQuantity: the order preview can change the totalPrice (for
    // example for promotion), we need to use the order preview data.
    orderMenu.totalPriceWithQuantity ||
    // in some unit tests contexts, the totalPriceWithQuantity has not been calculated yet
    (orderMenu.totalPrice || 0) * (orderMenu.quantity || 0)

  orderMenu.totalPriceWithQuantityFormatted = formatPriceWithCurrency(
    orderMenu.totalPriceWithQuantity || 0
  )
  orderMenu.unitPriceFormatted = formatPriceWithCurrency(orderMenu.price || 0)

  orderMenu.unitPriceFormatted = formatPriceWithCurrency(orderMenu.unitPrice || 0)

  orderMenu.basePriceFormatted = formatPriceWithCurrency(orderMenu.basePrice || 0)

  orderMenu.priceFormatted = formatPriceWithCurrency(orderMenu.price || 0)

  return orderMenu
}

const formatOrderMenuSelectedProducts = orderMenu => {
  let selectedProducts = []
  orderMenu.parts.forEach(part => {
    if (part.selectedProducts) {
      selectedProducts = selectedProducts.concat(part.selectedProducts)
    }
  })
  orderMenu.selectedProducts = selectedProducts

  return orderMenu
}

const formatNextPart = orderMenu => {
  orderMenu.parts = orderMenu.parts || []
  orderMenu.parts = orderMenu.parts.map((part, index) => {
    let nextPart = null
    let lastPart = orderMenu.parts.length === index + 1
    orderMenu.parts.forEach((partFilter, indexFilter) => {
      if (index < indexFilter || lastPart) {
        if (
          !partFilter.hasReachedMaxQuantity &&
          (partFilter.nbChosenValues < partFilter.min || partFilter.min === 0) &&
          isNil(nextPart) &&
          partFilter.id !== part.id
        ) {
          nextPart = cloneDeep(partFilter)
        }
      }
    })

    part.nextPart = nextPart

    return part
  })

  return orderMenu
}

export const orderMenuFormatter = createFormatter(
  totalPriceFormatter,
  createSubObjectFormatter('parts', formatOrderMenuParts),
  formatOrderMenuSelectedProducts,
  formatNextPart
)
