import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import OrderHeader from '../../../components/OrderHeader'
import CatalogLayout from '../../../components/CatalogLayout'
import IconLabelMode, { TYPE_COLORS } from '../../../components/IconLabelMode'
import BackTo from '../../../components/BackTo'

import CategoriesCarousel from 'app/components/CategoriesCarousel'

import ProductView from './components/ProductView'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: theme.pmt.colors.white,
  },
  overContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
    textAlign: 'center',
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  contentGridContainer: {
    marginTop: theme.spacing(20),
  },
  loadingText: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  modeContainer: {
    marginTop: theme.spacing(50),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  selectedBar: {
    borderLeft: `7px solid ${theme.palette.primary.main}`,
  },
  productSection: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(4),
  },
  productSectionContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-start',
    height: '100%',
    overflow: 'hidden',
  },
})

const View = ({
  frontAppConfig,
  isFetching,
  kioskSettings,
  restaurant,
  mode,
  category,
  catalog,
  orderProduct,
  viewOptions,
  onCategoryClick,
  handleBackToCatalogClick,
  handleSelectOptionValue,
  handleUnselectOptionValue,
  handleSubmitOrderProduct,
  showDietaryInfoDialog,
  setReclaimLater,
  classes,
}) => (
  <div className={classes.background}>
    {isFetching ? (
      <div className={classes.overContainer}>
        <div className={classes.logoContainer}>
          {restaurant && (
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          )}
        </div>
        <Grid container spacing={3} className={classes.contentGridContainer}>
          <Grid item xs={12}>
            <div>
              <LoadingBlock show size={200} />
              <TypographyCustom type="247" className={classes.loadingText} align="center">
                {tr('kiosk.order.page.category.loading')}
              </TypographyCustom>
            </div>
            <IconLabelMode
              mode={mode}
              color={TYPE_COLORS.MAIN}
              classes={{ root: classes.modeContainer }}
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div className={classes.content}>
        <OrderHeader
          frontAppConfig={frontAppConfig}
          restaurant={restaurant}
          kioskSettings={kioskSettings}
        />
        <CatalogLayout
          leftPart={
            <CategoriesCarousel
              catalog={catalog}
              category={category}
              onSelectCategory={onCategoryClick}
              restaurant={restaurant}
            />
          }
          rightPart={
            <div className={classes.productSection}>
              <div className={classes.productSectionContainer}>
                <BackTo
                  text={tr('kiosk.order.back_to_catalog')}
                  handleOnClick={handleBackToCatalogClick}
                />
                <ProductView
                  restaurant={restaurant}
                  orderProduct={orderProduct}
                  viewOptions={viewOptions}
                  handleSelectOptionValue={handleSelectOptionValue}
                  handleUnselectOptionValue={handleUnselectOptionValue}
                  handleSubmitOrderProduct={handleSubmitOrderProduct}
                  classes={{ header: 'u-marginTop40' }}
                  showDietaryInfoDialog={showDietaryInfoDialog}
                  setReclaimLater={setReclaimLater}
                />
              </div>
            </div>
          }
        />
      </div>
    )}
  </div>
)

export default withStyles(styles)(View)
