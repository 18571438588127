import { createAction } from '../redux'

import {
  createApiCallAction,
  createApiEnumAction,
  // createTestApiCallAction,
} from '../api/utils'

import { ModalType, createShowDialogAction, createHideDialogAction } from '../dialog'

import UserCardApi from '../api/calls/UserCardApi'

export const FetchUserCreditCardsAction = createApiEnumAction('USER::CREDIT_CARDS::GET')

export const fetchUserCreditCards = (userId, storeId) =>
  createApiCallAction(FetchUserCreditCardsAction, UserCardApi.getUserCards(userId, storeId), {
    userId,
    storeId
  })

export const UserCreditCardAction = {
  SELECT_CARD: 'USER::CREDIT_CARDS::SELECT',
  UNSELECT_CARD: 'USER::CREDIT_CARDS::UNSELECT',
  RESET_CARD: 'USER::CREDIT_CARDS::RESET',

  DISPLAY_ADD_CREDIT_CARD: 'USER::CREDIT_CARDS::DISPLAY_ADD_CREDIT_CARD',

  RESET_POST_CARD_ERROR: 'USER::CREDIT_CARDS::RESET_POST_CREDIT_CARD_ERROR',
}

export const onSelectUserCard = (userId, creditCard) =>
  createAction(UserCreditCardAction.SELECT_CARD, { userId, creditCard })

export const onUnselectUserCard = (userId, creditCard) =>
  createAction(UserCreditCardAction.UNSELECT_CARD, { userId, creditCard })

export const resetUserCard = userId => createAction(UserCreditCardAction.RESET_CARD, { userId })

export const displayAddCreditCardDialog = userId =>
  createShowDialogAction(ModalType.ADD_CREDIT_CARD, {
    userId,
  })

export const hideAddCreditCardDialog = () => createHideDialogAction(ModalType.ADD_CREDIT_CARD)

export const PostUserCreditCardRegistrationTypeAction = {
  ...createApiEnumAction('USER::CREDIT_CARD_REGISTRATION_TYPE::POST'),
  RESET_ERROR: 'USER::CREDIT_CARD_REGISTRATION_TYPE::RESET_ERROR',
}

export const postUserCreditCardRegistrationType = (userId, restaurantId, data, options) =>
  createApiCallAction(
    PostUserCreditCardRegistrationTypeAction,
    UserCardApi.postMeUserCardRegistrationType(userId, restaurantId, data, options),
    {
      userId,
      restaurantId,
      data,
      options,
    }
  )

export const resetUserCreditCardRegistrationTypeError = userId =>
  createAction(PostUserCreditCardRegistrationTypeAction.RESET_ERROR, {})

export const SendUserCreditCardToPspAction = {
  ...createApiEnumAction('USER::CREDIT_CARD::SEND_TO_PSP'),
  SEND_SUCCESS: 'USER::CREDIT_CARD::SEND_TO_PSP::SEND_SUCCESS',
  SEND_FAILURE: 'USER::CREDIT_CARD::SEND_TO_PSP::SEND_FAILURE',
  SEND_CANCEL: 'USER::CREDIT_CARD::SEND_TO_PSP::SEND_CANCEL',
  SEND_RESET: 'USER::CREDIT_CARD::SEND_TO_PSP::SEND_RESET',
}

export const sendCreditCardToPsp = (
  userId,
  restaurantId,
  pspRegistrationId,
  creditCard,
  pspDatas,
  options
) =>
  createApiCallAction(SendUserCreditCardToPspAction, UserCardApi.sendCreditCardToPsp(pspDatas), {
    userId,
    restaurantId,
    pspRegistrationId,
    creditCard,
    pspDatas,
    options,
  })

export const resetUserCreditCardToPspSend = () =>
  createAction(SendUserCreditCardToPspAction.SEND_RESET, {})

export const throwUserCreditCardToPspSuccess = () =>
  createAction(SendUserCreditCardToPspAction.SEND_SUCCESS, {})

export const throwUserCreditCardToPspFailure = () =>
  createAction(SendUserCreditCardToPspAction.SEND_FAILURE, {})

export const throwUserCreditCardToPspCancel = () =>
  createAction(SendUserCreditCardToPspAction.SEND_CANCEL, {})

export const PostPspDatasAction = {
  ...createApiEnumAction('USER::CREDIT_CARD::POST_FINAL'),
  RESET_ERROR: 'USER::CREDIT_CARD::POST_FINAL::RESET_ERROR',
}

export const postPspDatas = (userId, restaurantId, pspRegistrationId, creditCard, data, options) =>
  createApiCallAction(
    PostPspDatasAction,
    UserCardApi.postPspDatas(restaurantId, pspRegistrationId, creditCard, data),
    {
      userId,
      pspRegistrationId,
      restaurantId,
      creditCard,
      data,
      options,
    }
  )

export const resetPspDatasError = () => createAction(PostPspDatasAction.RESET_ERROR)

export const DeleteUserCreditCardsAction = createApiEnumAction('USER::CREDIT_CARD::DELETE')

export const deleteCreditCard = (userId, creditCardsList, creditCard) =>
  createApiCallAction(
    DeleteUserCreditCardsAction,
    UserCardApi.deleteMeUserCard(userId, creditCard.id),
    {
      userId,
      creditCardsList,
      creditCard,
    }
  )
