import ApiEndpoints from '../config/ApiEndpoints'

import CheckErrors from '../errors/CheckErrors'

const CheckApi = {
  getCheckWithTableNumber: (restaurantId, tableNumber) => ({
    type: 'GET',
    params: {
      restaurantId,
      tableNumber,
    },
    query: {},
    endpoint: ApiEndpoints.GET_CHECK_TABLE_NUMBER,
    errorHandler: CheckErrors.GET_CHECK,
  }),

  getCheckWithPosCheckId: (restaurantId, posCheckId) => ({
    type: 'GET',
    params: {
      restaurantId,
      posCheckId,
    },
    query: {},
    endpoint: ApiEndpoints.GET_CHECK_POS_ID,
    errorHandler: CheckErrors.GET_CHECK,
  }),

  getCheckWithCode: (restaurantId, code) => ({
    type: 'GET',
    params: {
      restaurantId,
      code,
    },
    query: {},
    headers: {},
    endpoint: ApiEndpoints.GET_CHECK_CODE,
    errorHandler: CheckErrors.GET_CHECK_CODE,
  }),

  getCheck: (restaurantId, checkId) => ({
    type: 'GET',
    params: {
      restaurantId,
      checkId,
    },
    query: {},
    endpoint: ApiEndpoints.GET_CHECK,
    errorHandler: CheckErrors.GET_CHECK,
  }),

  putMergeChecks: (restaurantId, checkIdTarget, posCodeToMerge) => ({
    type: 'PUT',
    params: {
      restaurantId,
    },
    body: {
      posCodeToMerge,
      checkIdTarget,
    },
    headers: {},
    endpoint: ApiEndpoints.PUT_MERGE_CHECKS,
    errorHandler: CheckErrors.PUT_MERGE_CHECKS,
  }),
}

export default CheckApi
