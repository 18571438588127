import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { selectMode } from 'pmt-modules/orderFront'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import {
  withCatalog,
  withCategory,
  selectCategory,
  setParentCategories,
  resetParentCategories,
} from 'pmt-modules/catalog'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  getItemListFromCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { SuggestionActionType } from 'pmt-modules/suggestion'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
@withCatalog
@withCategory
class OrderCatalogPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.addSuspendedItem()
    if (isNil(props.orderProperties.mode)) {
      props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
    }
  }

  handleOnCategoryClick = category => {
    EventManager.dispatch(EventManager.Events.ON_CATALOG_CATEGORY_SELECT, {
      category,
    })
    this.props.resetParentCategories()
    this.props.selectCategory(category)
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: category.id,
    })
  }

  handleOnSubCategoryClick = category => {
    const parentCategories = this.props.parentCategories
    parentCategories.push(this.props.category)

    this.props.selectCategory(category)
    this.props.setParentCategories(parentCategories)
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: category.id,
    })
  }

  handleOnBackToPreviousCategoryClick = () => {
    const parentCategories = this.props.parentCategories
    const nextCategory = parentCategories.pop()

    this.props.setParentCategories(parentCategories)
    this.props.selectCategory(nextCategory)
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: nextCategory.id,
    })
  }

  handleOnParentCategoryClick = parentCategory => {
    let nextParentCategories = []
    let addParentCategories = true
    this.props.parentCategories.forEach(parentCategoryFromProps => {
      if (parentCategoryFromProps.id !== parentCategory.id && addParentCategories) {
        nextParentCategories.push(parentCategoryFromProps)
      } else {
        addParentCategories = false
      }
    })

    this.props.setParentCategories(nextParentCategories)
    this.props.selectCategory(parentCategory)
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: parentCategory.id,
    })
  }

  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  render() {
    const {
      appConfig,
      frontAppConfig,
      route,
      restaurant,
      orderProperties,
      category,
      catalog,
      itemList,
      parentCategories,
    } = this.props

    return (
      <KioskPage route={route}>
        {({ kioskSettings }) => (
          <View
            appConfig={appConfig}
            isFetching={!restaurant || !catalog}
            frontAppConfig={frontAppConfig}
            kioskSettings={kioskSettings}
            restaurant={restaurant}
            mode={orderProperties.mode}
            catalog={catalog}
            category={category}
            itemList={itemList}
            parentCategories={parentCategories}
            onCategoryClick={this.handleOnCategoryClick}
            onSubCategoryClick={this.handleOnSubCategoryClick}
            onBackToPreviousCategory={this.handleOnBackToPreviousCategoryClick}
            onParentCategoryClick={this.handleOnParentCategoryClick}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  cartSuspendedDatas: getSuspendedDatas(state),
  itemList: getItemListFromCart(state),
  orderProperties: getOrderProperties(state),
})

export default connect(
  mapStateToProps,
  {
    addOrderMenuToCart,
    addOrderProductToCart,
    redirectTo,
    resetParentCategories,
    selectCategory,
    selectMode,
    setParentCategories,
    setSuspendedDatas,
  }
)(OrderCatalogPage)
