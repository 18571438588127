import find from 'lodash/find'
import flattenDeep from 'lodash/flattenDeep'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { createOrderProductFromProduct, orderProductModifier } from 'pmt-modules/orderProduct'
import { createOrderMenuFromMenu, orderMenuModifier } from 'pmt-modules/orderMenu'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { createFormatter } from 'pmt-utils/format'

import { SuggestionActionType } from './constants'

const replaceProductWithProduct = (baseItem, itemToReplace) => {
  itemToReplace = createOrderProductFromProduct(itemToReplace)

  // if option exists on baseItem use it, else return itemToReplace option
  itemToReplace.options = itemToReplace.options.map(
    itemOption =>
      find(baseItem.options, baseItemOption => itemOption.id === baseItemOption.id) || itemOption
  )

  return orderProductModifier(itemToReplace)
}

const recursiveReplaceProductInSubCategory = (
  category,
  itemToReplace,
  baseItem,
  productsReplaced
) => {
  if (!isNil(category.categories) && !isEmpty(category.categories)) {
    category.categories = category.categories.map(category => {
      const result = recursiveReplaceProductInSubCategory(
        category,
        itemToReplace,
        baseItem,
        productsReplaced
      )
      productsReplaced = result.productsReplaced

      return result.category
    })
  }

  let products = []
  if (!isNil(category.products)) {
    products = category.products.map(product => {
      let quantity = product.quantity
      let options = product.options

      if (
        product.id === baseItem.id &&
        !find(productsReplaced, productReplaced => productReplaced.id === baseItem.id)
      ) {
        quantity = baseItem.quantity
        options = baseItem.options
        productsReplaced.push(baseItem)
      }

      return {
        ...product,
        comment: baseItem.comment,
        quantity,
        options,
      }
    })
  }

  return {
    category: {
      ...category,
      products,
    },
    productsReplaced,
  }
}

const replaceProductWithMenu = (baseItem, itemToReplace) => {
  itemToReplace = createOrderMenuFromMenu(itemToReplace)

  // if baseItem exists on itemToReplace, update product's quantity and options
  itemToReplace.parts = itemToReplace.parts.map(part => {
    const result = recursiveReplaceProductInSubCategory(part, itemToReplace, baseItem, [])
    return result.category
  })

  return orderMenuModifier(itemToReplace)
}

const recursiveReplaceProductFromMenuInSubCategory = (
  category,
  itemToReplace,
  baseItemProducts,
  maxQuantity,
  productsReplaced,
  productsAdded
) => {
  if (!isNil(category.categories) && !isEmpty(category.categories)) {
    category.categories = category.categories.map(category => {
      const result = recursiveReplaceProductFromMenuInSubCategory(
        category,
        itemToReplace,
        baseItemProducts,
        maxQuantity,
        productsReplaced,
        productsAdded
      )
      productsReplaced = result.productsReplaced
      productsAdded = result.productsAdded

      return result.category
    })
  }

  let products = []
  if (!isNil(category.products)) {
    products = category.products.map(product => {
      const baseItemPartProduct = find(
        baseItemProducts,
        baseItemPartProduct => product.id === baseItemPartProduct.id
      )

      // if baseItemPartProduct exists on itemToReplace, update product's quantity and options
      if (
        baseItemPartProduct &&
        baseItemPartProduct.quantity > 0 &&
        // checking that we don't add to much product to the part
        productsAdded.length < maxQuantity &&
        !find(productsReplaced, productReplaced => productReplaced.id === baseItemPartProduct.id)
      ) {
        product.comment = baseItemPartProduct.comment
        product.quantity = baseItemPartProduct.quantity
        product.options = baseItemPartProduct.options
        productsReplaced.push(baseItemPartProduct)
        productsAdded.push(baseItemPartProduct)
      }
      return product
    })
  }

  return {
    category: {
      ...category,
      products,
    },
    productsReplaced,
    productsAdded,
  }
}

const replaceMenuWithMenu = (baseItem, itemToReplace) => {
  let productsReplaced = []
  // skip adding multiple times the same product in different parts
  itemToReplace = createOrderMenuFromMenu(itemToReplace)

  // get every products from every parts from baseItem
  const baseItemProducts = flattenDeep(
    baseItem.parts.map(baseItemPart => [...baseItemPart.products])
  )

  itemToReplace.parts = itemToReplace.parts.map(part => {
    const result = recursiveReplaceProductFromMenuInSubCategory(
      part,
      itemToReplace,
      baseItemProducts,
      part.max,
      productsReplaced,
      []
    )

    productsReplaced = result.productsReplaced
    return result.category
  })

  return orderMenuModifier(itemToReplace)
}

const formatItemSuggetions = (orderItem, { catalog }) => {
  if (orderItem.hasSuggestion) {
    orderItem.suggestions = orderItem.suggestedItems.map(item => {
      // on replace action, merge orderItem product/options with suggested item's
      if (item.action === SuggestionActionType.REPLACE) {
        if (orderItem.isProduct && item.isProduct) {
          item = replaceProductWithProduct(orderItem, item)
        } else if (orderItem.isProduct && item.isMenu) {
          item = replaceProductWithMenu(orderItem, item)
        } else if (orderItem.isMenu && item.isMenu) {
          item = replaceMenuWithMenu(orderItem, item)
        }

        item.suggestedPrice = item.totalPrice - orderItem.totalPrice
        item.suggestedPriceFormatted = formatPriceWithCurrency(item.suggestedPrice)
      } else {
        item.suggestedPrice = item.price
        item.suggestedPriceFormatted = item.priceFormatted
      }

      item.isSuggestedItem = true

      return {
        item,
        action: item.action,
      }
    })

    orderItem.suggestions = orderItem.suggestions.filter(suggestion => !isNil(suggestion))
  }

  return orderItem
}

export const formatSuggestion = createFormatter(formatItemSuggetions)
