import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  makeGetCategory,
  makeGetFirstSelectableCategory,
  getSelectedCategory,
  getParentCategories,
} from 'pmt-modules/catalog/selectors'

class CategoryContainer extends React.PureComponent {
  render() {
    const {
      CategoryWrappedComponent,
      categoryId,
      category,
      selectedCategory,
      parentCategories,
      ...otherProps
    } = this.props

    return (
      <CategoryWrappedComponent
        categoryId={categoryId}
        category={category}
        selectedCategory={selectedCategory}
        parentCategories={parentCategories}
        {...otherProps}
      />
    )
  }
}

CategoryContainer.propTypes = {
  categoryId: PropTypes.string,
}
const mapStateToProps = (state, props) => {
  const getCategory = makeGetCategory()
  const getFirstSelectableCategory = makeGetFirstSelectableCategory()
  const parentCategories = getParentCategories(state)

  return {
    category: getCategory(state, { ...props, parentCategories }),
    firstSelectableCategory: getFirstSelectableCategory(state, props),
    selectedCategory: getSelectedCategory(state),
    parentCategories,
  }
}

export default connect(mapStateToProps, {})(CategoryContainer)
