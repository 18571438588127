import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  options: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.pmt.colors.grey91,
  },
})

const ProductContent = ({ product, fromMenu, classes }) => (
  <React.Fragment>
    {/* product options */}
    {product.options.map(option =>
      option.values.map(
        value =>
          value.quantity > 0 && (
            <TypographyCustom key={value.id} component="div" type="244" className={classes.options}>
              {value.name}
            </TypographyCustom>
          )
      )
    )}
    {/* reclaim later */}
    {product.reclaimLater && (
      <TypographyCustom component="div" type="244" className={classes.options}>
        {tr('kiosk.order.product.reclaimLater.label')}
      </TypographyCustom>
    )}
  </React.Fragment>
)

export default withStyles(styles)(ProductContent)
