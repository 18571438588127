import isEmpty from 'lodash/isEmpty'

import BfastSdk from '@paymytable/bfast-web-sdk'

import { Peripherals } from 'pmt-modules/kioskInteractor/constants'

import { getAmountInCents } from 'pmt-utils/currency'
import Logger from 'pmt-utils/logger'

/**
 * Bfast implementation
 */
class Bfast {
  static READ_DURATION = 10000
  static SCAN_DURATION = 10000

  /**
   * Peripherals should always be formatted like this
   * peripheralType : {
   *   status: bool,
   *   details,
   * }
   * peripheral type are these (to be completed) : see Peripherals constants
   *  - nfc
   *  - payment
   *  - printer
   *  - scanner
   */
  static formatPeripherals(data) {
    return {
      [Peripherals.NFC]: {
        status: data.nfcreader?.status || false,
        details: data.nfcreader,
      },
      [Peripherals.PAYMENT]: {
        status: data.payment?.status || false,
        details: data.payment,
      },
      [Peripherals.PRINTER]: {
        status: data.printer?.status || false,
        details: data.printer,
      },
      [Peripherals.SCANNER]: {
        status: data.scanner?.status || false,
        details: data.scanner,
      },
    }
  }

  /**
   * Returns the list of peripheral available on current kiosk.
   * Retrieves the current status of all peripherals.
   */
  getPeripheralsStatus() {
    return new Promise((resolve, reject) => {
      console.log('BFAST = PERIPHERALS')
      BfastSdk.getPeripheralsStatus()
        .then(result => {
          const peripherals = Bfast.formatPeripherals(result)
          console.log(
            'BFAST = PERIPHERALS = OK',
            JSON.stringify(result),
            JSON.stringify(peripherals)
          )
          resolve(peripherals)
        })
        .catch(exception => {
          console.log('BFAST = PERIPHERALS = KO ', exception)
          reject({
            exception,
          })
        })
    })
  }

  /**
   * To see if the JS SDK is correctly initialized
   */
  isConnected() {
    return new Promise(resolve => {
      resolve({
        isConnected: true,
      })
    })
  }

  /**
   * Performs a print action based on the provided content
   */
  print(content) {
    return new Promise((resolve, reject) => {
      console.log('BFAST = PRINT = ', content)

      content.print()

      resolve({
        printed: true,
      })
    })
  }

  /**
   * Performs a nfc read action for a maximum number of seconds.
   */
  nfcRead() {
    return new Promise((resolve, reject) => {
      this.isNFCReading = true

      /**
       * since NFCReader is for a fixed duration, we need to re-run the read after
       * the end of the duration if we still are in reading mode (isNFCReading set to true).
       */
      const runNfcRead = () =>
        BfastSdk.nfcRead(Bfast.READ_DURATION)
          .then(res => {
            // If we receive a message from scanner while isNFCReading is set to false, ignore it
            if (this.isNFCReading) {
              if (res?.status && !isEmpty(res?.scannedContent)) {
                console.log('BFAST = NFC OK = ', JSON.stringify(res))
                resolve({
                  readData: res.scannedContent,
                })
              } else if (res?.details === 'TIME_REACH' || res?.details === 'COULD_NOT_READ') {
                console.log('BFAST = NFC EMPTY = ', JSON.stringify(res))
                // scan duration ended (timeout), we re-run the scan
                runNfcRead()
              } else if (
                res?.details === 'NOT_FOUND_IN_TABLE' ||
                res?.details === 'NO_RFID_DETECTED'
              ) {
                Logger.warn('Bfast Kiosk', 'NFC not found', { res })
                reject({ ...res, canRetry: true })
              } else {
                Logger.error('Bfast Kiosk', 'NFC read error', { res })
                reject(res)
              }
            }
          })
          .catch(exception => {
            Logger.error('Bfast Kiosk', 'NFC read exception', { exception })
            reject({
              exception,
            })
          })

      runNfcRead()
    })
  }

  /**
   * Stop reading state
   */
  nfcStop() {
    this.isNFCReading = false
  }

  /**
   * Performs a scan action for a maximum number of seconds.
   */
  scan() {
    return new Promise((resolve, reject) => {
      this.isScanning = true

      /**
       * Since scan listen for a fixed duration, we need to re-run the scan after
       * the end of the duration if we still are in scanning mode (isScanning set to true).
       */
      const runScan = () =>
        BfastSdk.scan(Bfast.SCAN_DURATION)
          .then(res => {
            // If we receive a message from scanner while isScanning is set to false, ignore it
            if (this.isScanning) {
              if (res?.status && !isEmpty(res?.scannedContent)) {
                console.log('BFAST = SCAN OK = ', res)
                resolve({
                  scannedData: res.scannedContent,
                })
              } else if (res?.details === 'TIME_REACH' || res?.details === 'COULD_NOT_READ') {
                console.log('BFAST = SCAN EMPTY = ', res)
                // scan duration ended (timeout), we re-run the scan
                runScan()
              } else {
                Logger.error('Bfast Kiosk', 'Scan read error', { res })
                reject(res)
              }
            }
          })
          .catch(exception => {
            Logger.error('Bfast Kiosk', 'Scan read exception', { exception })
            reject({
              exception,
            })
          })

      runScan()
    })
  }

  /**
   * Stop scanning state
   */
  scanStop() {
    this.isScanning = false
  }

  /**
   * Send command to perform a payment transaction.
   */
  pay(amount, options) {
    const amountInCents = getAmountInCents(amount)

    return new Promise((resolve, reject) => {
      console.log('BFAST = RUN PAY', amountInCents, options.id)
      BfastSdk.pay(amountInCents, options.id)
        .then(res => {
          console.log('BFAST = PAYMENT OK = ', JSON.stringify(res))
          if (res?.infos?.status === 'Success') {
            resolve({
              ...res,
              hasClientReceipt: false,
            })
          } else {
            Logger.error('Bfast Kiosk', 'Payment error', {
              error: JSON.stringify(res),
              amountInCents,
              id: options.id,
            })
            reject(res)
          }
        })
        .catch(err => {
          Logger.error('Bfast Kiosk', 'Payment exception', {
            error: JSON.stringify(err),
            amountInCents,
            id: options.id,
          })
          reject({
            error: err,
            amountInCents,
            id: options.id,
          })
        })
    })
  }
}

const BfastInteractor = new Bfast()

export default BfastInteractor
