import { tr } from 'pmt-modules/i18n'
import { isValidEmail } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

const defaultData = {
  email: null,
  marketing: {
    allowContact: false,
  },
}

const validationRules = () => ({
  email: [[isValidEmail, tr('global.post_payment_email.form.validation.email_invalid')]],
})

export default createForm(FormType.CHECKOUT_PAYMENT_EMAIL, validationRules, defaultData)
