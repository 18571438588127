import ApiEndpoints from '../config/ApiEndpoints'
import TopUpErrors from '../errors/TopUpErrors'

const TopUpApi = {
  postTopUpWithCreditCard: (userId, userAccountId, userCardId, topUp) => {
    return {
      endpoint: ApiEndpoints.DEPOSITS,
      type: 'POST',
      body: {
        idempotencyKey: topUp.idempotencyKey,
        userAccountId,
        userCardId,
        amount: topUp.amount,
        type: topUp.type,
        userId,
        browserInfo: topUp.browserInfo,
        asyncPaymentData: topUp.asyncPaymentData,
      },
      errorHandler: TopUpErrors.POST,
    }
  },

  // this call is made as a restaurant so we need userId
  postTopUp: (userAccountId, userId, topUp) => {
    return {
      endpoint: ApiEndpoints.DEPOSITS,
      type: 'POST',
      body: {
        idempotencyKey: topUp.idempotencyKey,
        userAccountId,
        userId,
        amount: topUp.amount,
        type: topUp.type,
      },
      errorHandler: TopUpErrors.POST,
    }
  },
}

export default TopUpApi
