import React, { useEffect } from 'react'

import { tr } from 'pmt-modules/i18n'
import { ORDER_MINIMUM_AMOUNT } from 'pmt-modules/order'
import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import { IconPaymentMethod } from 'pmt-ui/Icon'
import MessageBlock from 'pmt-ui/MessageBlock'
import ErrorOutline from 'pmt-ui/svg-icons/action/error-outline'
import NavigationChevronLeft from 'pmt-ui/svg-icons/navigation/chevron-left'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import OrderHeader from '../../../../components/OrderHeader'
import SquareMode from '../../../../components/SquareMode'
import LoadingPostOrder from '../components/LoadingPostOrder'

const styles = theme => ({
  backToCartContainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(8),
  },
  iconBackContainer: {
    float: 'left',
    width: theme.spacing(5),
    height: theme.spacing(6),
    marginRight: theme.spacing(3),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    borderRadius: 3,
  },
  iconBack: {
    width: '100%',
    height: '100%',
  },
  backToCatalogLabel: {
    height: theme.spacing(6),
    lineHeight: `${theme.spacing(6)}px`,
    color: theme.palette.primary.main,
  },
  title: {
    marginTop: theme.spacing(25),
  },
  squareGridContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(12),

    '& > :nth-child(even)': {
      marginLeft: theme.spacing(15),
    },
  },
  squareGrid: {
    position: 'relative',
    marginBottom: theme.spacing(11),
  },
  disabled: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.pmt.colors.white,
    opacity: 0.7,
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  square: {
    margin: '0 auto',
    justifyContent: 'center',

    '& p': {
      margin: '0 auto',
      maxWidth: theme.spacing(34),
    },
  },
  icon: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontSize: 160,
  },
  errors: {
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing(16),
    padding: theme.spacing(9),
    color: theme.pmt.colors.red800,
  },
  errorBlock: {
    fontSize: 28,
    marginBottom: theme.spacing(2),
    lineHeight: 1.04,
  },
  messageBlock: {
    width: theme.spacing(119),
    lineHeight: 1.04,
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconError: {
    height: 33,
    width: 33,
    marginRight: theme.spacing(1),
  },
})

const View = ({
  classes,
  frontAppConfig,
  kioskSettings,
  kioskPeripheralsStatus,
  onGoToCart,
  orderPostError,
  onSelectMethod,
  paymentError,
  totalCartPriceAndFees,
  restaurant,
}) => {
  useEffect(
    () => {
      // if there is no irlPaymentAllowed (ie. credit card is the only payment method)
      // and there is no error on peripheral status (payment or printer)
      // we automatically select credit card payment mode
      if (
        !kioskSettings.order.irlPaymentAllowed &&
        kioskPeripheralsStatus?.payment?.status &&
        kioskPeripheralsStatus?.printer?.status &&
        !paymentError
      ) {
        onSelectMethod(PaymentMethodsAllowed.CREDIT_CARD)
      }
    },
    [kioskSettings, kioskPeripheralsStatus, onSelectMethod, paymentError]
  )

  return (
    <div>
      <LoadingPostOrder />
      <OrderHeader
        frontAppConfig={frontAppConfig}
        restaurant={restaurant}
        kioskSettings={kioskSettings}
      />
      <div className={classes.backToCartContainer} onClick={onGoToCart}>
        <div className={classes.iconBackContainer}>
          <NavigationChevronLeft className={classes.iconBack} />
        </div>
        <TypographyCustom type="247" className={classes.backToCatalogLabel}>
          {tr('kiosk.order.back_to_cart')}
        </TypographyCustom>
      </div>
      <TypographyCustom type="487" align="center" className={classes.title}>
        {tr('kiosk.order.page.payment.mode.title')}
      </TypographyCustom>
      <Grid container className={classes.squareGridContainer}>
        {totalCartPriceAndFees > ORDER_MINIMUM_AMOUNT && (
          <Grid item className={classes.squareGrid}>
            {(!kioskPeripheralsStatus || !kioskPeripheralsStatus.payment.status) && (
              <div className={classes.disabled} />
            )}
            <SquareMode
              label={tr(`kiosk.order.page.payment.mode.label.credit_card`)}
              icon={
                <IconPaymentMethod
                  type={PaymentMethodsAllowed.CREDIT_CARD}
                  classes={{ root: classes.icon }}
                />
              }
              classes={{
                root: classes.square,
              }}
              onClick={() =>
                kioskPeripheralsStatus &&
                kioskPeripheralsStatus.payment.status &&
                onSelectMethod(PaymentMethodsAllowed.CREDIT_CARD)
              }
            />
          </Grid>
        )}
        {kioskSettings.order.irlPaymentAllowed && (
          <Grid item className={classes.squareGrid}>
            <SquareMode
              label={tr(`kiosk.order.page.payment.mode.label.irl`)}
              icon={
                <IconPaymentMethod
                  type={PaymentMethodsAllowed.IRL}
                  classes={{ root: classes.icon }}
                />
              }
              classes={{
                root: classes.square,
              }}
              onClick={() => onSelectMethod(PaymentMethodsAllowed.IRL)}
            />
          </Grid>
        )}
      </Grid>

      <div className={classes.errors}>
        {orderPostError && (
          <ErrorBlock
            error={orderPostError}
            mode={ErrorBlock.Mode.CUSTOM}
            customElement={
              <MessageBlock
                className={classes.errorBlock}
                icon={<ErrorOutline className={classes.iconError} />}
              />
            }
          />
        )}
        {(!kioskPeripheralsStatus || !kioskPeripheralsStatus.payment.status) && (
          <MessageBlock
            className={classes.messageBlock}
            icon={<ErrorOutline className={classes.iconError} />}
            text={
              <TypographyCustom type="284" skipColor>
                {tr('kiosk.order.page.payment.mode.tpe.disabled')}
              </TypographyCustom>
            }
          />
        )}
        {(!kioskPeripheralsStatus || !kioskPeripheralsStatus.printer.status) && (
          <MessageBlock
            className={classes.messageBlock}
            icon={<ErrorOutline className={classes.iconError} />}
            text={
              <TypographyCustom type="284" skipColor>
                {tr('kiosk.order.page.payment.mode.printer.disabled')}
              </TypographyCustom>
            }
          />
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(View)
