import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { getOrderPreview } from 'pmt-modules/orderPreview'
import LoyaltyIcon from '@material-ui/icons/Loyalty'

import Tooltip from 'pmt-ui/Tooltip'

import { TypographyCustom } from 'pmt-ui/Typography'

import { withStyles } from 'pmt-ui/styles'

import ItemQuantity from './ItemQuantity'
import ItemName from './ItemName'
import ItemPrice from './ItemPrice'

const styles = theme => ({
  root: {},
  itemContainer: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    alignItems: 'flex-start',
  },
  isEmptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartIcon: {
    width: 100,
    height: 89,
    color: theme.pmt.colors.grey91,
  },
  cartEmptyText: {
    marginTop: theme.spacing(3),
    color: theme.pmt.colors.grey91,
  },
  catalogButton: {
    marginTop: theme.spacing(12),
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'inherit',
    '&:focus,&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
  },
  modifiers: {
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
    '& > div:last-child': {
      marginBottom: theme.spacing(4),
    },
  },
  modifier: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  loyalty: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
})

const ItemsContainer = ({
  classes,
  itemList,
  fromRoute,
  restaurant,
  orderPreview,
  withEdit = false,
}) => (
  <div className={classes.root}>
    {orderPreview?.modifiers && (
      <div className={classes.modifiers}>
        {(orderPreview?.modifiers || []).map(
          modifier =>
            (modifier.isComplex || !modifier.isAutomatic) && (
              <Tooltip key={modifier.id} title={modifier.description} placement="bottom-start">
                <TypographyCustom type="247" className={classes.modifier}>
                  <LoyaltyIcon className={classes.loyalty} />
                  {modifier.name}
                </TypographyCustom>
              </Tooltip>
            )
        )}
      </div>
    )}
    {itemList.map(item => (
      <div key={`item-${item.id}`} className={classes.itemContainer}>
        <ItemQuantity item={item} restaurant={restaurant} withEdit={withEdit} />
        <ItemName item={item} restaurant={restaurant} fromRoute={fromRoute} withEdit={withEdit} />
        <ItemPrice item={item} />
      </div>
    ))}
  </div>
)

const mapStateToProps = state => ({
  orderPreview: getOrderPreview(state),
})

export default compose(withStyles(styles), connect(mapStateToProps, {}))(ItemsContainer)
