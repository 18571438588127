import isNil from 'lodash/isNil'
import invariant from 'invariant'

import ErrorPage from '../pages/error'
import HomePage from '../pages/home'
import LoginPage from '../pages/login'
import CheckoutCheckPage from '../pages/checkout/check'
import CheckoutConfirmPage from '../pages/checkout/confirm'
import CheckoutHomePage from '../pages/checkout/home'
import CheckoutPaymentPage from '../pages/checkout/payment'
import CheckoutScanPage from '../pages/checkout/scan'
import TopUpAccountPage from '../pages/topUp/account'
import TopUpHomePage from '../pages/topUp/home'
import TopUpLoginPage from '../pages/topUp/login'
import TopUpPaymentPage from '../pages/topUp/payment'
import TopUpPaymentConfirmationPage from '../pages/topUp/paymentConfirmation'
import OrderHomePage from '../pages/order/home'
import OrderModePage from '../pages/order/mode'
import OrderCategoryPage from '../pages/order/category'
import OrderCatalogPage from '../pages/order/catalog'
import OrderCartPage from '../pages/order/cart'
import OrderProductPage from '../pages/order/product'
import OrderMenuPage from '../pages/order/menu'
import OrderPaymentModePage from '../pages/order/payment/mode'
import OrderPaymentCreditCardPage from '../pages/order/payment/creditCard'
import OrderPostErrorPage from '../pages/order/error'
import OrderConfirmPage from '../pages/order/confirm'
import OrderScanPage from '../pages/order/scan'

import { DISABLE_FORM_DEV_DATA_QUERY_PARAM } from 'pmt-modules/form'
import { FlowTypes } from 'pmt-modules/frontFlow/constants'

import RoutingEnum from './routingEnum'

// list of the queries that are global to all our urls, and should be kept
const globalQueries = [DISABLE_FORM_DEV_DATA_QUERY_PARAM, 'apiConsumer', 'apiVersion', 'l']

// merge our globalQueries with the specific queries of the route
const getQueries = (queries = []) => [...globalQueries, ...queries]

/**
 * We define  the `handler` for each route of our RoutingEnum.
 * The `handler` is the React Component to display for the route.
 * It should be a container in most cases.
 */
const routes = [
  {
    definition: RoutingEnum.HOME,
    handler: HomePage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.LOGIN,
    handler: LoginPage,
    props: {
      authRequired: false,
    },
  },

  /**
   * TOP UP
   */
  {
    definition: RoutingEnum.KIOSK__TOP_UP__HOME,
    handler: TopUpHomePage,
    props: {
      authRequired: true,
      flowType: FlowTypes.TOPUP,
    },
  },
  {
    definition: RoutingEnum.KIOSK__TOP_UP__LOGIN,
    handler: TopUpLoginPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.TOPUP,
    },
  },
  {
    definition: RoutingEnum.KIOSK__TOP_UP__ACCOUNT,
    handler: TopUpAccountPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.TOPUP,
    },
  },
  {
    definition: RoutingEnum.KIOSK__TOP_UP__PAYMENT,
    handler: TopUpPaymentPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.TOPUP,
    },
  },
  {
    definition: RoutingEnum.KIOSK__TOP_UP__PAYMENT_CONFIRMATION,
    handler: TopUpPaymentConfirmationPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.TOPUP,
    },
  },

  /**
   * ORDER
   */
  {
    definition: RoutingEnum.KIOSK__ORDER__HOME,
    handler: OrderHomePage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__MODE,
    handler: OrderModePage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__CATEGORY,
    handler: OrderCategoryPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__CATALOG,
    handler: OrderCatalogPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__CART,
    handler: OrderCartPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__PRODUCT,
    handler: OrderProductPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__MENU,
    handler: OrderMenuPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__SCAN,
    handler: OrderScanPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__PAYMENT__MODE,
    handler: OrderPaymentModePage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__PAYMENT__CREDIT_CARD,
    handler: OrderPaymentCreditCardPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__POST__ERROR,
    handler: OrderPostErrorPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ORDER__CONFIRM,
    handler: OrderConfirmPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.ORDER,
    },
  },
  {
    definition: RoutingEnum.KIOSK__ERROR,
    handler: ErrorPage,
    props: {
      authRequired: false,
    },
  },

  /**
   * CHECKOUT
   */
  {
    definition: RoutingEnum.KIOSK__CHECKOUT__CHECK__CODE,
    handler: CheckoutCheckPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.CHECKOUT,
    },
  },
  {
    definition: RoutingEnum.KIOSK__CHECKOUT__CONFIRM,
    handler: CheckoutConfirmPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.CHECKOUT,
    },
  },
  {
    definition: RoutingEnum.KIOSK__CHECKOUT__HOME,
    handler: CheckoutHomePage,
    props: {
      authRequired: true,
      flowType: FlowTypes.CHECKOUT,
    },
  },
  {
    definition: RoutingEnum.KIOSK__CHECKOUT__PAYMENT,
    handler: CheckoutPaymentPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.CHECKOUT,
    },
  },
  {
    definition: RoutingEnum.KIOSK__CHECKOUT__SCAN,
    handler: CheckoutScanPage,
    props: {
      authRequired: true,
      flowType: FlowTypes.CHECKOUT,
    },
  },
]

export default routes.map(route => {
  // handle props.query to set global queries
  if (!route.props.query) {
    route.props.query = getQueries([])
  }

  // check config is ok.
  invariant(
    !isNil(route.props.authRequired),
    `${route.definition.name}: props authRequired not set`
  )
  invariant(!isNil(route.handler), `${route.definition.name}: handler is required`)
  invariant(!isNil(route.definition), `${route}: definition is required`)

  return route
})
