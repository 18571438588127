// return true if the success date is not the same as the last order preview send,
// or if the date of sending the post order preview is earlier than the last modification of cart
export const isLastPreview = (date, sendDate, modificationDate) => {
  if (sendDate !== date || sendDate <= modificationDate) {
    // do nothing
    return false
  }

  return true
}
