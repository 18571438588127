import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { getRoute } from 'pmt-modules/routing'

import ButtonLink from 'pmt-ui/ButtonLink'
import { withStyles } from 'pmt-ui/styles'
import CardKo from 'pmt-ui/svg-icons/hardware/card-ko'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.pmt.colors.grey91,
  },
  checkErrorContent: {
    flex: 1,
  },
  cardKoIcon: {
    width: 120,
    height: 120,
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(4),
  },
  errorButton: {
    lineHeight: 1,
    marginTop: theme.spacing(12),
    padding: `${theme.spacing(3)}px ${theme.spacing(9)}px`,
    border: `3px solid ${theme.palette.primary.main}`,
  },
  errorCaption: {
    marginBottom: theme.spacing(11),
  },
})

const CheckError = ({ classes, check }) => (
  <div className={classes.root}>
    <div className={classes.checkErrorContent}>
      <CardKo className={classes.cardKoIcon} />
      <TypographyCustom type="327" align="center" skipColor>
        {check && (isEmpty(check.entries) || check.outstandingBalance === 0)
          ? tr('kiosk.checkout.page.check.check_empty')
          : tr('kiosk.checkout.page.check.check_error')}
      </TypographyCustom>
      <ButtonLink
        variant="outlined"
        color="primary"
        className={classes.errorButton}
        to={getRoute('KIOSK__CHECKOUT__SCAN')}
      >
        <TypographyCustom type="327" skipColor>
          {tr('kiosk.checkout.page.check.scan_again')}
        </TypographyCustom>
      </ButtonLink>
    </div>
    <TypographyCustom type="327" align="center" skipColor className={classes.errorCaption}>
      {check && (isEmpty(check.entries) || check.outstandingBalance === 0)
        ? tr('kiosk.checkout.page.check.check_empty.caption')
        : tr('kiosk.checkout.page.check.check_error.caption')}
    </TypographyCustom>
  </div>
)

export default withStyles(styles)(CheckError)
