import concat from 'lodash/concat'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { 
  sendEvent,
  sendRemoveFromCart,
  sendPaymentSucceeded,
  sendAddPaymentInfo,
  sendAddToCart,
  sendCartSubmitted,
  sendViewCart,
  sendViewItem,
 } from 'pmt-modules/analytics'

const events = {
  // BREADCRUMB
  ON_BREADCRUMB_LINK: 'EVENT::ORDER::BREADCRUMB::LINK',

  // MODE
  ON_MODE_SELECT: 'EVENT::ORDER::MODE::SELECT',

  // CATALOG
  ON_CATALOG_CATEGORY_SELECT: 'EVENT::ORDER::CATALOG::CATEGORY::SELECT',

  // CATALOG ITEM
  ON_CATALOG_ITEM_DETAIL: 'EVENT::ORDER::CATALOG::ITEM::DETAIL',
  ON_CATALOG_ITEM_ADD_PRODUCT: 'EVENT::ORDER::CATALOG::ITEM::ADD_PRODUCT',
  ON_CATALOG_ITEM_ADD_MENU: 'EVENT::ORDER::CATALOG::ITEM::ADD_MENU',

  // CATALOG MENU
  ON_CATALOG_MENU_ADD_PRODUCT: 'EVENT::ORDER::CATAOLOG:LMENU::ADD_PRODUCT',
  ON_CATALOG_MENU_DETAIL: 'EVENT::ORDER::CATALOG::MENU::DETAIL',
  ON_CATALOG_MENU_ITEM_DETAIL: 'EVENT::ORDER::CATALOG::MENU::ITEM_DETAIL',

  // CATALOG CART
  ON_CATALOG_CART_SUBMIT: 'EVENT::ORDER::CATALOG::CART::SUBMIT',

  // CART
  ON_CART_ITEM_DETAIL: 'EVENT::ORDER::CART::ITEM_DETAIL',
  ON_CART_ITEM_DELETE: 'EVENT::ORDER::CART::ITEM_DELETE',
  ON_CART_ITEM_INCREASE: 'EVENT::ORDER::CART::ITEM_INCREASE',
  ON_CART_ITEM_DECREASE: 'EVENT::ORDER::CART::ITEM_DECREASE',
  ON_CART_SUBMIT: 'EVENT::ORDER::CART::SUBMIT',

  // PAYMENT
  ON_PAYMENT_MODE_SELECT: 'EVENT::ORDER::PAYMENT::MODE_SELECT',

  // CONFIRM
  ON_CONFIRM_CALLBACK: 'EVENT::ORDER::CONFIRM::CALLBACK',
}

const ACTION = {
  CTA: 'calls-to-action',
  SEC_CTA: 'secondary-calls-to-action',
  LINKS: 'links',
}

const formatItem = (item, category, restaurant) => ({
  item_id: item.id,
  item_name: item.name,
  item_category: !isNil(category) ? category.name : null,
  item_brand: !isNil(restaurant) ? restaurant.name : null,
  price: (!isNil(item.totalPriceWithQuantity) ? item.totalPriceWithQuantity : item.price).toFixed(
    2
  ),
  quantity: item.quantity || 1,
})

const makeEvent = (catPrefix = '', action, label, value) =>
  sendEvent({
    category: `${catPrefix}${window.location.pathname.replace(/\//g, '-')}`,
    action,
    label,
    value,
  })

const getLabelForMode = mode => {
  switch (mode) {
    case 0:
      return 'on-site'
    case 1:
      return 'take-away'
    case 2:
      return 'delivery'
    default:
      return 'unknown'
  }
}

const listeners = {
  [EventManager.Events.ON_ANALYTICS_INIT]: () => {
     // init plugin if needed
  },
  [events.ON_BREADCRUMB_LINK]: ({ data }) => {
    makeEvent('breadcrumb', ACTION.LINKS, data.link)
  },
  [events.ON_MODE_SELECT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, getLabelForMode(data.mode), data.mode)
  },
  [events.ON_CATALOG_CATEGORY_SELECT]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'catalog-category')
  },
  [events.ON_CATALOG_ITEM_DETAIL]: ({ data }) => {
    sendViewItem(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.LINKS, 'details-items')
  },
  [events.ON_CATALOG_ITEM_ADD_PRODUCT]: ({ data }) => {
    sendAddToCart(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.CTA, 'add-product')
  },
  [events.ON_CATALOG_ITEM_ADD_MENU]: ({ data }) => {
    sendAddToCart(formatItem(data.item, data.category, data.restaurant))
    makeEvent('page', ACTION.CTA, 'add-menu')
  },
  [events.ON_CATALOG_MENU_ADD_PRODUCT]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'add-product-menu')
  },
  [events.ON_CATALOG_MENU_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-menu')
  },
  [events.ON_CATALOG_MENU_ITEM_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-menu-item')
  },
  [events.ON_CATALOG_CART_SUBMIT]: ({ data }) => {
    const formattedItems = data.items.map((item) => formatItem(item, '', data.restaurant))
    sendViewCart(formattedItems, 1)
    makeEvent('page', ACTION.CTA, 'main-order-cart')
  },
  [events.ON_CART_ITEM_DETAIL]: ({ data }) => {
    makeEvent('page', ACTION.LINKS, 'details-items')
  },
  [events.ON_CART_ITEM_DELETE]: ({ data }) => {
    sendRemoveFromCart(formatItem(data.item, '', data.restaurant))
    makeEvent('page', ACTION.SEC_CTA, 'delete-item')
  },
  [events.ON_CART_ITEM_INCREASE]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'increase-item-quantity')
  },
  [events.ON_CART_ITEM_DECREASE]: ({ data }) => {
    makeEvent('page', ACTION.SEC_CTA, 'decrease-item-quantity')
  },
  [events.ON_CART_SUBMIT]: ({ data }) => {
    const formattedItems = data.items.map((item) => formatItem(item, '', data.restaurant))
    sendCartSubmitted(formattedItems, 2)
    makeEvent('page', ACTION.CTA, 'main-order-final')
  },
  [events.ON_PAYMENT_MODE_SELECT]: ({ data }) => {
    sendAddPaymentInfo([], data.paymentMode, 4 )
    makeEvent('page', ACTION.SEC_CTA, 'payment-mode-select', data.paymentMode)
  },
  [EventManager.Events.ON_PAYMENT_SUCCESS]: ({ data }) => {
    const formattedItems = concat(data.order.products, data.order.menus).map(
      (item) => item && formatItem(item, '', data.restaurant)
    )
    sendPaymentSucceeded(
      formattedItems,
      data.order.id,
      data.order.totalPrice.toFixed(2),
      data.order.currency
    )
    makeEvent('page', ACTION.CTA, 'payment-success')
  },
  [events.ON_CONFIRM_CALLBACK]: ({ data }) => {
    makeEvent('page', ACTION.CTA, 'callback')
  },
}

const EventsConfig = {
  events,
  listeners,
}

export default EventsConfig
