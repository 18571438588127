import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import CardLanguages from '../../../components/CardLanguages'
import GlobalErrorBlock from '../../../components/GlobalErrorBlock'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  darkLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.25,
    zIndex: 1,
  },
  overContainer: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  ribbon: {
    position: 'relative',
    width: '100%',
    height: 300,
    background: 'rgba(255,255,255,.7)',
    top: '50%',
    marginTop: -200,
    ...theme.pmt.appConfig.homeRibbon,
  },
  logoContainer: {
    transform: 'translateY(-50%)',
    textAlign: 'center',
  },
  logo: {
    maxWidth: 500,
    maxHeight: 300,
  },
  text: {
    position: 'absolute',
    bottom: 70,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  languages: {
    position: 'absolute',
    bottom: theme.spacing(9),
  },
})

const View = ({
  catalogError,
  restaurant,
  frontAppConfig,
  isFetchingRestaurant,
  kioskSettings,
  kioskPeripheralsStatus,
  handleRedirection,
  classes,
}) => (
  <div
    className={classes.background}
    style={{ backgroundImage: `url('${kioskSettings.order.backgroundImage}')` }}
    onClick={() => handleRedirection(kioskSettings.order.modes)}
  >
    <div className={classes.darkLayout} />
    <div className={classes.overContainer}>
      <LoadingBlockWrapper show={isFetchingRestaurant} />

      <GlobalErrorBlock
        frontAppConfig={frontAppConfig}
        catalogError={catalogError}
        kioskPeripheralsStatus={kioskPeripheralsStatus}
        kioskSettings={kioskSettings}
        restaurant={restaurant}
      />
      <div className={classes.ribbon}>
        {frontAppConfig.theme.logo.display && (
          <div className={classes.logoContainer}>
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          </div>
        )}

        <TypographyCustom component="p" type="607" className={classes.text}>
          {tr('kiosk.order.page.home.touch')}
        </TypographyCustom>
      </div>

      <CardLanguages className={classes.languages} locales={kioskSettings.order.locales} />
    </div>
  </div>
)

export default withStyles(styles)(View)
