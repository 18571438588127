import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import BackspaceIcon from '@material-ui/icons/Backspace'

import { TypographyCustom } from 'pmt-ui/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  number: {
    width: 140,
    height: 140,
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 50,
  },
}))

const NumericPad = ({ onSelect, onErase }) => {
  const classes = useStyles()

  const KeyPad = ({ number, onClick }) => (
    <Button variant="outlined" className={classes.number} onClick={() => onClick(number)}>
      <TypographyCustom type="607" align="center">
        {number}
      </TypographyCustom>
    </Button>
  )

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <KeyPad number="1" onClick={onSelect} />
        <KeyPad number="2" onClick={onSelect} />
        <KeyPad number="3" onClick={onSelect} />
      </div>
      <div className={classes.row}>
        <KeyPad number="4" onClick={onSelect} />
        <KeyPad number="5" onClick={onSelect} />
        <KeyPad number="6" onClick={onSelect} />
      </div>
      <div className={classes.row}>
        <KeyPad number="7" onClick={onSelect} />
        <KeyPad number="8" onClick={onSelect} />
        <KeyPad number="9" onClick={onSelect} />
      </div>
      <div className={classes.row}>
        <div className={classes.number} />
        <KeyPad number="0" onClick={onSelect} />
        <KeyPad number={<BackspaceIcon className={classes.icon} />} onClick={onErase} />
      </div>
    </div>
  )
}

export default NumericPad
