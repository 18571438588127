import React from 'react'

import AlertError from 'pmt-ui/svg-icons/alert/error'
import Button from 'pmt-ui/Button'
import CardReader from 'pmt-ui/svg-icons/hardware/card-reader'
import Divider from 'pmt-ui/Divider'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Grid from 'pmt-ui/Grid'
import QrCode from 'pmt-ui/svg-icons/hardware/qr-code'
import Typography from 'pmt-ui/Typography'
import { LoadingBlockWrapper, LoadingBlock } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'

import ArrowBounce from '../../../components/ArrowBounce'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
  },
  title: {
    textTransform: 'uppercase',
  },
  divider: {
    width: 300,
    margin: '25px auto',
  },
  caption: {
    marginTop: 200,
    marginBottom: 100,

    '& > strong': {
      color: theme.palette.primary.main,
    },
  },
  authType: {
    marginTop: theme.spacing(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  authTypeLabel: {
    marginTop: theme.spacing(10),
  },
  icons: {
    color: theme.palette.primary.main,
    height: 270,
    width: 270,
  },
  errorTitle: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
})

const View = ({
  classes,
  restaurant,
  errorUserAccounts,
  isAliveSharingMessage,
  isFetchingUserAccounts,
  onErrorRetry,
}) => (
  <div className={classes.root}>
    {isFetchingUserAccounts && <LoadingBlock show />}

    {errorUserAccounts && (
      <ErrorBlock
        mode={ErrorBlock.Mode.DIALOG}
        error={errorUserAccounts}
        options={{
          title: (
            <Typography
              variant="title"
              color="primary"
              classes={{
                root: classes.errorTitle,
              }}
            >
              <AlertError /> Erreur lors de l'identification
            </Typography>
          ),
          actions: (
            <div>
              <Button onClick={onErrorRetry} color="primary" variant="contained" autoFocus>
                Réessayer
              </Button>
            </div>
          ),
        }}
      />
    )}

    <img src={restaurant.logo} alt={restaurant.name} />

    <Typography className={classes.title} variant="display2">
      Borne de rechargement
    </Typography>

    <Divider className={classes.divider} />

    <Typography className="u-marginTop40" variant="display2" component="p">
      Bienvenue dans votre restaurant<br />
      {restaurant.name}
    </Typography>

    <Typography className={classes.caption} variant="display2" component="p">
      Veuillez scanner<br />
      <strong>votre QR Code</strong> ou <strong>votre badge</strong>
      <br />
      dans le lecteur ci-dessous
    </Typography>

    <Grid className={classes.authType} container>
      <Grid item xs={5}>
        <QrCode className={classes.icons} />
        <Typography
          className={classes.authTypeLabel}
          variant="display2"
          component="p"
          color="primary"
        >
          QR Code
        </Typography>
      </Grid>
      <Typography variant="display2" component="p">
        ou
      </Typography>
      <Grid item xs={5}>
        <div className="u-relative">
          <LoadingBlockWrapper show={!isAliveSharingMessage} />
          <CardReader className={classes.icons} />
        </div>
        <Typography
          className={classes.authTypeLabel}
          variant="display2"
          component="p"
          color="primary"
        >
          Badge
        </Typography>
      </Grid>
    </Grid>

    <ArrowBounce />
  </div>
)

export default withStyles(styles)(View)
