import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'
import { DEFAULT_GEOLOCATION_ID } from './constants'

// TODO: all of this should be make selectors

const getGeolocation = state => state.entities.geolocation

const getGeolocationIdOnProps = (state, props) =>
  // DEFAULT is used on order plugin store locator and when we do not need a specific id.
  // specific ID is used when we generate a user address. See order plugin components SearchAddress.
  isNil(props) || isNil(props.geolocationId) ? DEFAULT_GEOLOCATION_ID : props.geolocationId

export const getGeolocationIsFetching = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)
    if (isNil(geolocation)) {
      return false
    }

    return geolocation.get('isFetching', false)
  }
)

export const getGeolocationOrigin = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)
    if (isNil(geolocation)) {
      return null
    }

    return geolocation.get('origin', null)
  }
)

export const getGeolocationAddress = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)
    if (isNil(geolocation)) {
      return null
    }

    return geolocation.get('address', null)
  }
)

export const getGeolocationComputeAddress = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)

    if (isNil(geolocation)) {
      return null
    }

    const computeAdress= geolocation.get('computeAddress', null)
    if (!computeAdress){
      return null
    }
    return computeAdress.toJS()
  }
)

export const getGeolocationComputeAddressExtra = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)

    if (isNil(geolocation)) {
      return null
    }

    const computeAddressExtra = geolocation.get('computeAddressExtra', null)
    if (!computeAddressExtra) {
      return null
    }

    return computeAddressExtra.toJS()
  }
)

export const getGeolocationCoordinates = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)

    if (isNil(geolocation)) {
      return {
        latitude: null,
        longitude: null,
      }
    }

    const geolocationForReal = geolocation.toJS()

    if (isNil(geolocationForReal.latitude) || isNil(geolocationForReal.longitude)) {
      return {
        latitude: null,
        longitude: null,
      }
    }

    return {
      latitude: geolocationForReal.latitude,
      longitude: geolocationForReal.longitude,
    }
  }
)

export const getGeolocationError = createSelector(
  [getGeolocation, getGeolocationIdOnProps],
  (geolocationData, geolocationIdOnProps) => {
    const geolocation = geolocationData.get(geolocationIdOnProps)
    if (isNil(geolocation)) {
      return null
    }

    return geolocation.get('error', null)
  }
)
