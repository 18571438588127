import React from 'react'

import { PaymentMethodsAllowed } from 'pmt-modules/orderSettings'

import PaymentCreditCard from 'pmt-ui/svg-icons/pmt/PaymentCreditCard'
import PaymentOnSite from 'pmt-ui/svg-icons/pmt/PaymentOnSite'

// TODO: add TRD
const Icons = {
  [PaymentMethodsAllowed.CREDIT_CARD]: <PaymentCreditCard />,
  [PaymentMethodsAllowed.IRL]: <PaymentOnSite />,
}

const IconPaymentMethod = ({ type, ...props }) => React.cloneElement(Icons[type], props)

export default IconPaymentMethod
