import { createApiCallAction, createApiEnumAction } from '../api/utils'

import KioskApi from '../api/calls/KioskApi'

export const GetKioskSettingsAction = createApiEnumAction('KIOSK_SETTINGS::GET')

export const getKioskSettings = restaurantId =>
  createApiCallAction(GetKioskSettingsAction, KioskApi.getKioskSettings(restaurantId), {
    restaurantId,
  })

export const RefreshKioskSettingsAction = createApiEnumAction('KIOSK_SETTINGS::REFRESH')

export const refreshKioskSettings = (restaurantId, options) =>
  createApiCallAction(RefreshKioskSettingsAction, KioskApi.getKioskSettings(restaurantId), {
    restaurantId,
    options,
  })
