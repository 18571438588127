import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import ProductView from '../components/ProductView'

const styles = theme => ({
  root: {
    position: 'relative',
    height: '100%',
    padding: theme.spacing(4),
  },
  productSectionContainer: {
    overflow: 'hidden',
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
})

const View = ({
  restaurant,
  orderProduct,
  viewOptions,
  handleSelectOptionValue,
  handleUnselectOptionValue,
  handleSubmitOrderProduct,
  onClose,
  showDietaryInfoDialog,
  classes,
}) => (
  <div className={classes.root}>
    <div className={classes.closeArea} onClick={onClose}>
      <CloseIcon className={classes.closeBtn} />
    </div>
    <div className={classes.productSectionContainer}>
      <ProductView
        orderProduct={orderProduct}
        restaurant={restaurant}
        viewOptions={viewOptions}
        handleSelectOptionValue={handleSelectOptionValue}
        handleUnselectOptionValue={handleUnselectOptionValue}
        handleSubmitOrderProduct={handleSubmitOrderProduct}
        showDietaryInfoDialog={showDietaryInfoDialog}
      />
    </div>
  </div>
)

export default withStyles(styles)(View)
