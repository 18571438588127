import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Button from 'pmt-ui/Button'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import CardLanguages from '../../../components/CardLanguages'
import GlobalErrorBlock from '../../../components/GlobalErrorBlock'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  overContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    position: 'relative',
  },
  logoContainer: {
    marginTop: theme.spacing(45),
  },
  logo: {
    maxWidth: 480,
    maxHeight: 480,
  },
  caption: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(11),
    whiteSpace: 'pre-line',
  },
  button: {
    padding: `${theme.spacing(8)}px ${theme.spacing(15)}px`,
  },
  languages: {
    position: 'absolute',
    bottom: theme.spacing(9),
    boxShadow: 'none',
  },
})

const View = ({
  restaurant,
  frontAppConfig,
  isFetchingRestaurant,
  kioskSettings,
  kioskPeripheralsStatus,
  handleRedirection,
  classes,
}) => (
  <div
    className={classes.background}
    style={
      frontAppConfig.theme.background.display &&
      (frontAppConfig.theme.background.path || kioskSettings.order.backgroundImage)
        ? {
            backgroundImage: `url('${frontAppConfig.theme.background.path ||
              kioskSettings.order.backgroundImage}')`,
          }
        : {}
    }
  >
    <div
      className={classes.overContainer}
      onClick={() => handleRedirection(kioskSettings.order.modes)}
    >
      <LoadingBlockWrapper show={isFetchingRestaurant} />

      <GlobalErrorBlock
        frontAppConfig={frontAppConfig}
        kioskPeripheralsStatus={kioskPeripheralsStatus}
        kioskSettings={kioskSettings}
        restaurant={restaurant}
      />

      {frontAppConfig.theme.logo.display && (
        <div className={classes.logoContainer}>
          <img
            src={frontAppConfig.theme.logo.path || restaurant.logo}
            className={classes.logo}
            alt="logo"
          />
        </div>
      )}

      <CustomTextsContainer>
        {({ texts }) =>
          !isNil(texts) &&
          !isNil(texts.KIOSK__CHECKOUT__HOME__CAPTION) && (
            <TypographyCustom
              type="404"
              align="center"
              className={classes.caption}
              dangerouslySetInnerHTML={{
                __html: texts.KIOSK__CHECKOUT__HOME__CAPTION,
              }}
            />
          )
        }
      </CustomTextsContainer>

      <Button className={classes.button} variant="contained" color="primary">
        <TypographyCustom type="407" skipColor>
          {tr('kiosk.checkout.page.home.touch')}
        </TypographyCustom>
      </Button>

      <CardLanguages className={classes.languages} locales={kioskSettings.order.locales} />
    </div>
  </div>
)

export default withStyles(styles)(View)
