import isEmpty from 'lodash/isEmpty'

import { createFormatter } from 'pmt-utils/format'

import { formatCategory } from './category'
import { formatItemsCartModifiers } from './cartModifiers'

const formatUpsellingsCategories = catalog => {
  catalog.upsellingCategories = catalog.upsellingCategories.map(category =>
    formatCategory(category)
  )

  // clean empty categories
  catalog.upsellingCategories = catalog.upsellingCategories.filter(
    category => !isEmpty(category.items)
  )

  catalog.hasUpsellings = false
  if (!isEmpty(catalog.upsellingCategories)) {
    catalog.upsellingCategory = catalog.upsellingCategories[0]
    catalog.upsellingCategory.items = catalog.upsellingCategory.items.filter(item => item.available)

    if (!isEmpty(catalog.upsellingCategory.items)) {
      catalog.hasUpsellings = true
      catalog.upsellingCategory.items = formatItemsCartModifiers(catalog.upsellingCategory.items)
    }
  }

  return catalog
}

export const formatUpsellings = createFormatter(formatUpsellingsCategories)
