import { createSelector } from 'reselect'

import { getOrderProperties, getOrderFrontDatas } from 'pmt-modules/orderFront'

import { formatOrderData } from './format'

/**
 * Create the order body data for the API.
 * See postOrder action.
 */
export const getOrderData = createSelector(
  [getOrderFrontDatas, getOrderProperties],
  (orderFrontData, orderProperties) => formatOrderData(orderFrontData, orderProperties)
)
