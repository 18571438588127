import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '../../SvgIcon';

let HardwareTpe = (props) => (
  <SvgIcon {...props}>
    <path d="M16.5,2.1c0-1-0.8-1.7-1.8-1.7c-2,0-4,0-6,0C7.7,0.4,7,1.2,7,2.1c0,0.4,0,0.8,0,1.2c0,1.8,0,3.5,0,5.3     c0,1.2,0.1,2.4,0.2,3.6c0.1,1.1,0.1,2.2,0.2,3.3c0,0.6,0.5,0.9,1,0.9c0.1,0,0.2,0,0.3,0c0-0.2,0-0.5,0-0.5c0,0,0,0,0,0     c-0.6,0-0.8-0.1-0.9-0.6c-0.1-1.3-0.2-2.6-0.3-4c-0.1-0.9-0.1-1.8-0.2-2.7c0-1,0-2,0-3.1c0,0,0,0,0,0c0-1.1,0-2.2,0-3.3     c0-0.9,0.5-1.4,1.4-1.4c1.9,0,3.8,0,5.8,0c0.7,0,1.2,0.4,1.4,1c0,0.1,0,0.2,0,0.3c0,2.2,0,4.3,0,6.5c0,1.1-0.1,2.2-0.2,3.3     c-0.1,1.1-0.2,2.3-0.2,3.4c0,0.5-0.2,0.7-0.7,0.7c-0.1,0-0.2,0-0.3,0c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0,0.2,0,0.3,0c0.6,0,1-0.4,1-1     c0-0.7,0.1-1.4,0.1-2.1c0.1-1.5,0.2-3.1,0.3-4.6c0-1.1,0-2.2,0-3.3c0,0,0,0,0,0C16.5,4.4,16.5,3.3,16.5,2.1z"/>
    <path d="M11.7,8.1c-1,0-2,0-3,0C8.2,8.1,8,7.9,8,7.3c0-1.5,0-3,0-4.4c0-0.5,0.3-0.8,0.8-0.8c2,0,4,0,6,0c0.3,0,0.7,0.3,0.7,0.6     c0,0.2,0,0.3,0,0.5c0,1.4,0,2.8,0,4.2c0,0.5-0.2,0.7-0.8,0.7C13.7,8.1,12.7,8.1,11.7,8.1z M11.7,2.6c-1,0-2,0-2.9,0     c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.2,0,0.3c0,1.3,0,2.6,0,4c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.4,0.3c1.9,0,3.9,0,5.8,0     c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3c0-1.3,0-2.6,0-3.9c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.3-0.4-0.3     C13.6,2.6,12.6,2.6,11.7,2.6z"/>
    <path d="M11.7,15.4c-1,0-1.9,0-2.9,0c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.3c0.1,0,0.1,0,0.2,0     c1.9,0,3.8,0,5.8,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.4,0     C13.5,15.4,12.6,15.4,11.7,15.4z"/>
    <path d="M14,13.2c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6-0.3-0.6-0.6     c0-0.3,0.3-0.6,0.6-0.6C13.7,13.2,13.8,13.2,14,13.2C14,13.2,14,13.2,14,13.2z M14,13.9C14,13.9,14,13.9,14,13.9     c0.1,0,0.3,0,0.4,0c0,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.5,0-0.7,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1     C13.7,14,13.8,13.9,14,13.9z"/>
    <path d="M11.7,14.4c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C12,14.4,11.8,14.4,11.7,14.4z M11.7,13.9C11.7,13.9,11.7,13.9,11.7,13.9c0.1,0,0.2,0,0.3,0     c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0.1,0.1,0.2,0.1     C11.5,14,11.6,13.9,11.7,13.9z"/>
    <path d="M9.5,13.2c0.1,0,0.2,0,0.4,0c0.4,0,0.6,0.3,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6-0.3-0.6-0.6     c0-0.3,0.3-0.6,0.6-0.6C9.2,13.2,9.3,13.2,9.5,13.2z M9.5,13.7c-0.1,0-0.2,0-0.4,0c-0.1,0-0.1,0.1-0.2,0.1c0.1,0,0.1,0.1,0.2,0.1     c0.2,0,0.5,0,0.7,0c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1C9.7,13.7,9.6,13.7,9.5,13.7z"/>
    <path d="M9.5,9.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6C8.5,9,8.7,8.7,9,8.7c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C9.7,9.9,9.6,9.9,9.5,9.9C9.5,9.9,9.5,9.9,9.5,9.9z M9.4,9.4c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1     c0,0-0.1-0.1-0.1-0.1c-0.2,0-0.5,0-0.7,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1C9.2,9.4,9.3,9.4,9.4,9.4z"/>
    <path d="M9.4,10.2c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6-0.3-0.6-0.6     c0-0.3,0.2-0.6,0.6-0.6C9.2,10.2,9.3,10.2,9.4,10.2z M9.4,10.9C9.4,10.9,9.4,10.9,9.4,10.9c0.1,0,0.3,0,0.4,0     c0.1,0,0.1-0.1,0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0.1-0.2,0.1c0.1,0,0.1,0.1,0.2,0.1     C9.2,10.9,9.3,10.9,9.4,10.9z"/>
    <path d="M14,11.4c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C14.3,11.4,14.1,11.4,14,11.4C14,11.4,14,11.4,14,11.4z M14,10.9c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1     c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0.1-0.2,0.1c0.1,0,0.1,0.1,0.2,0.1C13.7,11,13.9,10.9,14,10.9z"/>
    <path d="M11.7,9.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C12,9.9,11.9,9.9,11.7,9.9z M11.7,9.4C11.7,9.4,11.7,9.4,11.7,9.4c0.1,0,0.2,0,0.4,0     c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.3,0-0.5,0-0.8,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1     C11.5,9.4,11.6,9.4,11.7,9.4z"/>
    <path d="M14,9.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.8,0C14.7,8.7,15,9,15,9.3     c0,0.3-0.2,0.6-0.6,0.6C14.3,9.9,14.1,9.9,14,9.9z M14,9.4c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1     c-0.2,0-0.4,0-0.7,0c-0.1,0-0.1,0.1-0.2,0.1c0.1,0,0.1,0.1,0.2,0.1C13.7,9.4,13.9,9.4,14,9.4z"/>
    <path d="M11.7,10.2c0.1,0,0.3,0,0.4,0c0.3,0,0.6,0.3,0.6,0.6c0,0.3-0.2,0.6-0.6,0.6c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6-0.3-0.6-0.6     c0-0.3,0.2-0.6,0.6-0.6C11.4,10.2,11.6,10.2,11.7,10.2z M11.7,10.9c0.1,0,0.3,0,0.4,0c0.1,0,0.1-0.1,0.1-0.1     c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.1C11.5,10.9,11.6,10.9,11.7,10.9z"/>
    <path d="M11.7,12.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.2-0.6,0.6-0.6c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C12,12.9,11.9,12.9,11.7,12.9z M11.7,12.4c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1     c-0.2,0-0.5,0-0.7,0c-0.1,0-0.1,0.1-0.1,0.1c0.1,0,0.1,0.1,0.2,0.1C11.5,12.4,11.6,12.4,11.7,12.4z"/>
    <path d="M14,12.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.2,0.6-0.6,0.6C14.2,12.9,14.1,12.9,14,12.9z M14,12.4c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1     c-0.2,0-0.5,0-0.7,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1C13.7,12.4,13.9,12.4,14,12.4z"/>
    <path d="M9.4,12.9c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.5,0,0.8,0c0.3,0,0.6,0.3,0.6,0.6     c0,0.3-0.3,0.6-0.6,0.6C9.7,12.9,9.6,12.9,9.4,12.9z M9.4,12.4c0.1,0,0.2,0,0.4,0c0.1,0,0.1-0.1,0.2-0.1c0,0-0.1-0.1-0.1-0.1     c-0.2,0-0.5,0-0.7,0c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.1,0.1,0.1C9.2,12.4,9.3,12.4,9.4,12.4z"/>
    <g>
      <path d="M14.9,23.2l0-7.4c0-0.4-0.4-0.8-0.8-0.8l-4.8,0c-0.4,0-0.8,0.4-0.8,0.8v7.4c0,0.4,0.4,0.8,0.8,0.8h4.8     C14.5,24,14.9,23.6,14.9,23.2z M14.3,15.7v7.4c0,0.1-0.1,0.3-0.3,0.3h-0.4v-8h0.4C14.2,15.5,14.3,15.6,14.3,15.7L14.3,15.7z      M13.1,23.4h-0.4v-8l0.4,0V23.4z M9,23.2v-7.4c0-0.1,0.1-0.3,0.3-0.3h2.9v8H9.3C9.1,23.4,9,23.3,9,23.2z"/>
      <path d="M10.9,16.3v1.4c0,0.1,0.1,0.3,0.2,0.3c0.2,0,0.3-0.1,0.3-0.3v-1.4c0-0.1-0.1-0.3-0.2-0.3C11.1,16,10.9,16.1,10.9,16.3z"/>
      <path d="M10.3,19.6v-3.2c0-0.1-0.1-0.3-0.2-0.3c-0.2,0-0.3,0.1-0.3,0.3v3.2c0,0.1,0.1,0.3,0.3,0.3C10.2,19.8,10.3,19.7,10.3,19.6     L10.3,19.6z"/>
    </g>
  </SvgIcon>
);
HardwareTpe = pure(HardwareTpe);
HardwareTpe.displayName = 'HardwareTpe';
HardwareTpe.muiName = 'SvgIcon';

export default HardwareTpe;
