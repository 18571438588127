import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'

import CategoryCard from '../../../../components/CategoryCard'

const styles = theme => ({
  gridContainer: {
    marginTop: theme.spacing(9),
  },
  cardContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    paddingLeft: `${theme.spacing(8)}px !important`,
    paddingRight: `${theme.spacing(8)}px !important`,
  },
  categoryCard: {
    width: 310,
    height: 280,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    boxShadow: 'none',
  },
  categoryCardSmall: {
    width: 240,
  },
  categoryImage: {
    maxWidth: 140,
    maxHeight: 140,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  categoryName: {
    minHeight: 32,
    maxHeight: 72,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '32px',
  },
  textContainer: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
})

const CATEGORY_SMALL_LIMIT = 9

const CategoryList = ({ categories, restaurant, handleOnCategoryClick, classes }) => (
  <Grid container spacing={3} className={classes.gridContainer}>
    <Grid item xs={12} className={classes.cardContainer}>
      {categories.map((category, index) => (
        <CategoryCard
          key={index}
          restaurant={restaurant}
          category={category}
          classes={{
            root: classNames(classes.categoryCard, {
              [classes.categoryCardSmall]: categories.length > CATEGORY_SMALL_LIMIT,
            }),
            image: classes.categoryImage,
            name: classes.categoryName,
            textContainer: classes.textContainer,
          }}
          onClick={handleOnCategoryClick}
        />
      ))}
    </Grid>
  </Grid>
)

export default withStyles(styles)(CategoryList)
