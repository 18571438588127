import React, { useMemo } from 'react'
import classNames from 'classnames'
import { removeArrayDuplicates } from 'pmt-utils/array'
import { withStyles } from 'pmt-ui/styles'
import CategoryCard from '../CategoryCard'
import ItemCard from '../ItemCard'

const styles = theme => ({
  root: {
    overflowY: 'scroll',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(3)}px`,
    boxSizing: 'content-box',
    width: '100%',
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
  itemCardSmall: {
    width: 181,
  },
  itemCardImageContainerBig: {
    height: 193,
  },
  itemCardName: {
    minHeight: 45,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '22px',
  },
  itemCardpriceContainer: {
    marginTop: theme.spacing(2),
  },
  subCategoryImage: {
    maxWidth: 125,
    maxHeight: 125,
  },
  addOverlaySmall: {
    left: theme.spacing(5.5),
  },
})

const CARD_SMALL_LIMIT = 15

function CategoryContentLayout({ category, restaurant, onSubCategoryClick, classes }) {
  // HOTFIX: JJ  Le Pian had all its items on the category display two times.
  // Couldn't reproduce nor debug it easily, so we trick a fix here.
  // https://www.notion.so/paymytable/JJ-borne-produits-affich-s-en-double-dd756230c7fa4beb8872fcf6c20e1b6c
  const { items, categories } = useMemo(() => {
    return {
      items: removeArrayDuplicates(category?.items || [], item => `${item.type}_${item.id}`),
      categories: removeArrayDuplicates(category?.categories || [], category => category.id),
    }
  }, [category])

  const isSmall = category.totalItemsAndSubcategories > CARD_SMALL_LIMIT

  return (
    <div className={classes.root}>
      <div className={classes.cardsContainer}>
        {categories.map((cat, index) => (
          <CategoryCard
            key={`cat_${cat.id}`}
            data-id={`cat_${cat.id}`}
            category={cat}
            restaurant={restaurant}
            nameType="207"
            displayLink
            onClick={onSubCategoryClick}
            classes={{
              root: classNames({
                [classes.itemCardSmall]: isSmall,
              }),
              image: classes.subCategoryImage,
              name: classes.itemCardName,
            }}
          />
        ))}

        {items.map(item => (
          <ItemCard
            key={`item_${item.type}_${item.id}`}
            data-id={`item_${item.type}_${item.id}`}
            item={item}
            restaurant={restaurant}
            category={category}
            nameType="204"
            price={item.priceFormatted}
            hasCartModifier={item.hasCartModifier}
            basePrice={item.hasCartModifier ? item.basePriceFormatted : null}
            classes={{
              root: classNames({
                [classes.itemCardSmall]: isSmall,
              }),
              name: classes.itemCardName,
              addOverlay: classNames({
                [classes.addOverlaySmall]: isSmall,
              }),
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(CategoryContentLayout)
