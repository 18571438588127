import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Card, { CardContent } from 'pmt-ui/Card'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {},
  squareCard: {
    width: 360,
    height: 360,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  label: {
    color: theme.palette.primary.main,
  }
})

/**
 * @specs N/A
 */
class SquareMode extends React.PureComponent {
  render() {
    const {
      label,
      icon,
      classes,
      ...otherProps
    } = this.props

    return (
      <Card className={classNames(classes.squareCard, classes.root)} {...otherProps}>
        <CardContent>
          {icon}
          <TypographyCustom type="407" component="p" align="center" className={classes.label}>
            {label}
          </TypographyCustom>
        </CardContent>
      </Card>
    )
  }
}

SquareMode.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
}

export default withStyles(styles)(SquareMode)
