import { tr } from 'pmt-modules/i18n'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import React from 'react'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Checkbox from 'pmt-ui/Checkbox'
import CountryChooser from 'pmt-ui/CountryChooser'
import DateField from 'pmt-ui/DateField'
import { FormControlLabel } from 'pmt-ui/Form'
import { InputAdornment } from 'pmt-ui/Input'
import PasswordField from 'pmt-ui/PasswordField'
import PhoneField from 'pmt-ui/PhoneField'
import InfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import TextField from 'pmt-ui/TextField'
import Tooltip from 'pmt-ui/Tooltip'
import { TypographyCustom } from 'pmt-ui/Typography'

export const FirstName = ({ value, validation, onChange, ...otherProps }) => (
  <CustomTextsContainer>
    {({ texts }) => (
      <TextField
        name="FirstName"
        label={
          !isNil(texts) && !isNil(texts.ORDER__FORM_USER_FIRSTNAME)
            ? texts.ORDER__FORM_USER_FIRSTNAME
            : tr('global.form.user.firstname')
        }
        className="u-sizeFullWidth u-marginTop10"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        error={value !== null && !isEmpty(validation.message)}
        helperText={value !== null && validation.message}
        {...otherProps}
      />
    )}
  </CustomTextsContainer>
)

export const LastName = ({ value, validation, onChange, ...otherProps }) => (
  <CustomTextsContainer>
    {({ texts }) => (
      <TextField
        name="LastName"
        label={
          !isNil(texts) && !isNil(texts.ORDER__FORM_USER_LASTNAME)
            ? texts.ORDER__FORM_USER_LASTNAME
            : tr('global.form.user.lastname')
        }
        className="u-sizeFullWidth u-marginTop10"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        error={value !== null && !isEmpty(validation.message)}
        helperText={value !== null && validation.message}
        {...otherProps}
      />
    )}
  </CustomTextsContainer>
)

export const PhoneNumber = ({ value, validation, onChange, ...otherProps }) => (
  <div>
    <CustomTextsContainer>
      {({ texts }) => (
        <PhoneField
          name="PhoneNumber"
          label={
            !isNil(texts) && !isNil(texts.ORDER__FORM_USER_PHONE)
              ? texts.ORDER__FORM_USER_PHONE
              : tr('global.form.user.phone')
          }
          displayFlag
          className="u-sizeFullWidth u-marginTop10"
          placeholder="6 12 34 56 78" // TODO: adapt hint regarding country code
          value={value || ''}
          onChange={phoneNumber => onChange(phoneNumber)}
          error={value !== null && !isEmpty(validation.message)}
          helperText={value !== null && validation.message}
          {...otherProps}
        />
      )}
    </CustomTextsContainer>
  </div>
)

export const BadgeId = ({ value, validation, onChange, ...otherProps }) => (
  <TextField
    name="BadgeId"
    label={tr('global.form.user.badge_id')}
    className="u-sizeFullWidth u-marginTop10"
    value={value || ''}
    onChange={event => onChange(event.target.value)}
    error={value !== null && !isEmpty(validation.message)}
    helperText={value !== null && validation.message}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip
            disabletriggertouch
            title={tr('global.form.user.badgeId.tooltip')}
            placement="bottom-end"
          >
            <InfoOutline color="primary" />
          </Tooltip>
        </InputAdornment>
      ),
    }}
    {...otherProps}
  />
)

export const Email = ({ value, validation, onChange, className, ...otherProps }) => (
  <CustomTextsContainer>
    {({ texts }) => (
      <TextField
        type="email"
        name="Email"
        label={
          !isNil(texts) && !isNil(texts.ORDER__FORM_USER_EMAIL)
            ? texts.ORDER__FORM_USER_EMAIL
            : tr('global.form.user.email')
        }
        className={clsx('u-sizeFullWidth u-marginTop10', className)}
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        error={value !== null && !isEmpty(validation.message)}
        helperText={value !== null && validation.message}
        {...otherProps}
      />
    )}
  </CustomTextsContainer>
)

export const Password = ({ value, validation, onChange, ...otherProps }) => (
  <CustomTextsContainer>
    {({ texts }) => (
      <PasswordField
        fullWidth
        name="password"
        label={
          !isNil(texts) && !isNil(texts.ORDER__FORM_USER_PASSWORD)
            ? texts.ORDER__FORM_USER_PASSWORD
            : tr('global.form.user.password')
        }
        className="u-sizeFullWidth u-marginTop10 u-marginBottom10"
        value={value || ''}
        onChange={event => onChange(event.target.value)}
        error={value !== null && !isEmpty(validation.message)}
        helperText={value !== null && validation.message}
        {...otherProps}
      />
    )}
  </CustomTextsContainer>
)

export const CountryOfResidence = ({ value, validation, onChange, ...otherProps }) => (
  <CountryChooser
    label={tr('global.form.user.countryOfResidence', {
      fr: 'Pays de résidence',
      context: '',
      desc: 'User form country of residence',
    })}
    className="u-sizeFullWidth u-marginTop10"
    value={value || ''}
    onChange={country => onChange(country)}
    error={value !== null && !isEmpty(validation.message)}
    helperText={value !== null && validation.message}
    {...otherProps}
  />
)

export const Nationality = ({ value, validation, onChange, ...otherProps }) => (
  <CountryChooser
    label={tr('global.form.user.nationality', {
      fr: 'Nationalité',
      context: '',
      desc: 'User form nationality',
    })}
    className="u-sizeFullWidth u-marginTop10"
    value={value || ''}
    onChange={country => onChange(country)}
    error={value !== null && !isEmpty(validation.message)}
    helperText={value !== null && validation.message}
    {...otherProps}
  />
)

export const Birthdate = ({ value, validation, onChange, ...otherProps }) => (
  <DateField
    mode={DateField.Mode.INLINE}
    label={tr('global.form.user.birthdate', {
      fr: 'Date de naissance',
      context: '',
      desc: 'User form birthdate',
    })}
    value={value || ''}
    onChange={onChange}
    helperText={value !== null && validation.message}
    options={{
      defaultDate: moment()
        .subtract(30, 'year')
        .startOf('day'),
      minDate: moment()
        .subtract(100, 'year')
        .startOf('day'),
      maxDate: moment()
        .subtract(12, 'year')
        .startOf('day'),
    }}
    {...otherProps}
  />
)

/**
 * /!\ any changes made to this component must be reflected on the backoffice preview (component MarketingOptimPreview)*
 */
export const MarketingOptim = ({ value, onChange, className, ...otherProps }) => (
  <CustomTextsContainer>
    {({ texts }) =>
      !isNil(texts) &&
      !isNil(texts.ORDER__LOGIN__MARKETING__OPTIN) && (
        <FormControlLabel
          label={
            <TypographyCustom type="164">{texts.ORDER__LOGIN__MARKETING__OPTIN}</TypographyCustom>
          }
          className={clsx('u-sizeFullWidth u-marginTop10', className)}
          control={
            <Checkbox
              color="primary"
              checked={value}
              onChange={(event, isInputChecked) => onChange(isInputChecked)}
              {...otherProps}
            />
          }
        />
      )
    }
  </CustomTextsContainer>
)
