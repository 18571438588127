import createAction from '../redux/createAction'

export const OrderProductAction = {
  SELECT_PRODUCT: 'ORDER_PRODUCT_ACTION::SELECT_PRODUCT',
  SELECT_OPTION_VALUE: 'ORDER_PRODUCT_ACTION::SELECT_OPTION_VALUE',
  SET_RECLAIM_LATER: 'ORDER_PRODUCT_ACTION::SET_RECLAIM_LATER',
  UNSELECT_OPTION_VALUE: 'ORDER_PRODUCT_ACTION::UNSELECT_OPTION_VALUE',
  UPDATE_COMMENT: 'ORDER_PRODUCT_ACTION::UPDATE_COMMENT',
}

/**
 *
 * @param {*} product
 * @param {*} options
 *    - isMenuMode {boolean} (false)
 *    - partId {Part} (null) to define if isMenuMode is true
 *    - restaurantId {string}
 *    - isEditMode {boolean} (false)
 *    - orderProduct {object} (null)
 */
export const openProduct = (product, options) =>
  createAction(OrderProductAction.SELECT_PRODUCT, {
    product,
    viewOptions: {
      isMenuMode: false,
      partId: null,
      partCategoryId: null,
      restaurantId: '',
      isEditMode: false,
      orderProduct: null,
      showDialog: true,
      ...options,
    },
  })

//
//
//

export const selectOptionValue = (option, value) =>
  createAction(OrderProductAction.SELECT_OPTION_VALUE, {
    option,
    value,
  })

export const unselectOptionValue = (option, value) =>
  createAction(OrderProductAction.UNSELECT_OPTION_VALUE, {
    option,
    value,
  })

//
//
//

export const updateOrderProductComment = comment =>
  createAction(OrderProductAction.UPDATE_COMMENT, {
    comment,
  })

export const setReclaimLater = isChecked =>
  createAction(OrderProductAction.SET_RECLAIM_LATER, {
    isChecked,
  })
