import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import OrderHeader from '../../../components/OrderHeader'
import IconLabelMode, { TYPE_COLORS } from '../../../components/IconLabelMode'
import OrderFooter from '../../../components/OrderFooter'

import CategoryList from './components/CategoryList'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: theme.pmt.colors.white,
  },
  overContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
    textAlign: 'center',
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  contentGridContainer: {
    marginTop: theme.spacing(20),
  },
  loadingText: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  modeContainer: {
    marginTop: theme.spacing(50),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    flex: 1,
    overflowY: 'scroll',
    alignContent: 'flex-start',
  },
  iconLabelContainer: {
    height: 140,
    marginTop: 60,
  }
})

const View = ({
  frontAppConfig,
  isFetching,
  kioskSettings,
  restaurant,
  catalog,
  mode,
  itemList,
  handleOnCategoryClick,
  classes
}) => (
  <div className={classes.background}>
    {isFetching ? (
      <div className={classes.overContainer}>
        <div className={classes.logoContainer}>
          {restaurant && (
            <img src={frontAppConfig.theme.logo.path || restaurant.logo} className={classes.logo} alt="logo" />
          )}
        </div>
        <Grid container spacing={3} className={classes.contentGridContainer}>
          <Grid item xs={12}>
            <div>
              <LoadingBlock show size={200} />
              <TypographyCustom type="247" className={classes.loadingText} align="center">
                {tr('kiosk.order.page.category.loading')}
              </TypographyCustom>
            </div>
            <IconLabelMode mode={mode} color={TYPE_COLORS.MAIN} classes={{ root: classes.modeContainer }} />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div className={classes.content}>
        <OrderHeader frontAppConfig={frontAppConfig} restaurant={restaurant} kioskSettings={kioskSettings} />
        <Grid container spacing={3} className={classes.bodyContainer}>
          <Grid item xs={12} className={classes.iconLabelContainer}>
            <IconLabelMode mode={mode} type="484" color={TYPE_COLORS.MAIN} />
          </Grid>
          <Grid item xs={12} className="u-marginTop15">
            <TypographyCustom type="487" align="center">
              {tr('kiosk.order.page.category.choice')}
            </TypographyCustom>
            <CategoryList categories={catalog.categories} restaurant={restaurant} handleOnCategoryClick={handleOnCategoryClick} />
          </Grid>
        </Grid>
        <OrderFooter mode={mode} itemList={itemList} enableEmptyCartButton={!isEmpty(itemList)} />
      </div>
    )}
  </div>
)

export default withStyles(styles)(View)
