import React from 'react'
import { connect } from 'react-redux'

import { redirectTo, getRoute } from 'pmt-modules/routing'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

/**
 * @specs N/A
 */
class UserAccount extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userAccounts !== null &&
      nextProps.userAccounts.length === 0 &&
      !nextProps.isFetchingUserAccounts
    ) {
      this.props.redirectTo(getRoute('KIOSK__TOP_UP__HOME'))
    }
  }

  render() {
    const { children, userAccount, isFetchingUserAccounts } = this.props

    return isFetchingUserAccounts || userAccount === null ? <LoadingBlockWrapper show /> : children
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(
  mapStateToProps,
  {
    redirectTo,
  }
)(UserAccount)
