import React from 'react'

import CheckContainer from './CheckContainer'

/**
 * Add the CheckContainer as HOC for the given component
 * Requirements
 * - checkId in props or routeParams
 * - restaurantId in props or routeParams
 */
const withCheck = CheckWrappedComponent => props => (
  <CheckContainer
    CheckWrappedComponent={CheckWrappedComponent}
    restaurantId={props.routeParams ? props.routeParams.restaurantId : props.restaurantId}
    checkId={props.routeParams ? props.routeParams.checkId : props.checkId}
    tableNumber={props.routeParams ? props.routeParams.tableId : props.tableId}
    code={props.routeParams ? props.routeParams.code : props.code}
    {...props}
  />
)

export default withCheck
