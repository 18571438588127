import React, { useEffect, useRef } from 'react'
import isNil from 'lodash/isNil'
import clsx from 'clsx'

import CategoryCard from 'app/components/CategoryCard'

const FixedVerticalCarousel = ({ classes, catalog, category, restaurant, onSelectCategory }) => {
  const myRef = useRef(null)

  useEffect(() => {
    scrollIntoView('instant')
  }, [])

  useEffect(() => {
    scrollIntoView('smooth')
  }, [category])

  const scrollIntoView = behavior => {
    myRef.current && myRef.current.scrollIntoView({ behavior, block: 'center', inline: 'center' })
  }

  return (
    <div className={classes.categoriesContainer}>
      {catalog.categories.map(c => (
        <div key={c.id} ref={c.id === category?.id ? myRef : null}>
          <CategoryCard
            restaurant={restaurant}
            category={c}
            classes={{
              root: clsx(classes.categoryCard, {
                [classes.selectedBar]: !isNil(c) && !isNil(category) && c.id === category.id,
              }),
              content: classes.categoryCardContent,
              image: classes.categoryCardImage,
              name: clsx(classes.categoryCardName, {
                [classes.colorPrimary]: !isNil(c) && !isNil(category) && c.id === category.id,
              }),
              textContainer: classes.categoryCardTextContainer,
            }}
            nameType="247"
            onClick={onSelectCategory}
          />
        </div>
      ))}
    </div>
  )
}

export default FixedVerticalCarousel
