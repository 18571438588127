import React from 'react'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'

import { withStyles } from 'pmt-ui/styles'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'

const styles = theme => ({
  verticalCarouselContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    // hide the scrollbar
    boxSizing: 'content-box',
    paddingRight: 17,
  },
  element: {
    position: 'relative',
  },
  selectedBar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 7,
    background: theme.palette.primary.main,
  },
})

class InfiniteVerticalCarousel extends React.Component {
  state = {
    multipleValue: 3,
    elements: null,
    elementsIndex: null,
    eventDone: false,
    initialTopDone: false,
    addedElements: 0,
    scrollTopFakeZero: 0,
  }

  elements = []

  componentDidMount() {
    const { elements } = this.props

    if (!isNil(elements)) {
      this.setState({
        // we triple the number of elements so that we are constantly in the middle of two entire lists
        // [] -> previous list of items
        // [x] -> exposed list of items
        // [] -> next list of items
        // it allows us to be more fluid for UX navigation
        elements: elements.concat(elements.concat(elements)),
      })
    }
  }

  componentDidUpdate() {
    this.defineListener()
    const { elements, elementHeight, selectedElement } = this.props

    // initiate the scroll to the middle list of elements
    if (!this.state.scrollTopFakeZero && !isNil(elements)) {
      const scrollTopFakeZero = elementHeight * elements.length

      this.carousel.scrollTop = scrollTopFakeZero
      this.setState({
        scrollTopFakeZero,
      })

      this.initElementsIndex()
    }

    // scrolling down to the selected element in the middle list
    if (!isNil(this.state.elementsIndex) && !this.state.initialTopDone) {
      let initialIndex = 0

      this.state.elements.forEach((element, index) => {
        if (this.props.isSelected(element, selectedElement) && initialIndex < elements.length) {
          initialIndex = index
        }
      })

      this.topElement(initialIndex)
      this.setState({
        initialTopDone: true,
      })
    }
  }

  // here we set a constantly evolving array (named "elementsIndex")
  // that set the current index displayed for each initial index of elements
  initElementsIndex = () => {
    let elementsIndex = []
    this.state.elements.forEach((element, index) => {
      elementsIndex.push(index)
    })

    this.setState({
      elementsIndex,
    })
  }

  // the "elementsIndex" is built here at each scroll (if necessary)
  buildElementsIndex = (indexToMove, first = false) => {
    let elementsIndex = []

    if (indexToMove === 0 && first) {
      // first to move to bottom
      const firstIndex = this.state.elementsIndex[indexToMove]

      this.state.elementsIndex.forEach((elementIndex, index) => {
        if (index !== indexToMove) {
          elementsIndex.push(elementIndex)
        }
      })

      elementsIndex.push(firstIndex)
    } else {
      // last to move to top
      elementsIndex.push(indexToMove)
      const lastIndex = this.state.elementsIndex.length - 1

      this.state.elementsIndex.forEach((elementIndex, index) => {
        if (index !== lastIndex) {
          elementsIndex.push(elementIndex)
        }
      })
    }

    return elementsIndex
  }

  // moving an element from the bottom to the top or the reverse case
  copyElementOutOfContainer = () => {
    if (!isNil(this.carousel)) {
      const scrollTop = this.carousel.scrollTop
      const scrollTopFakeZero = this.state.scrollTopFakeZero
      let elementsIndex = []

      // scrolling down
      if (scrollTop - this.props.elementHeight >= scrollTopFakeZero) {
        this.carousel.appendChild(this.elements[this.state.elementsIndex[0]])
        elementsIndex = this.buildElementsIndex(0, true)
        this.setState({
          elementsIndex,
        })
        // scrolling up
      } else if (scrollTop + this.props.elementHeight <= scrollTopFakeZero) {
        const lastIndex = this.state.elementsIndex[this.state.elementsIndex.length - 1]
        this.carousel.insertBefore(
          this.elements[lastIndex],
          this.elements[this.state.elementsIndex[0]]
        )

        elementsIndex = this.buildElementsIndex(lastIndex)
        this.setState({
          elementsIndex,
        })
      }
    }
  }

  defineListener = () => {
    if (!isUndefined(this.carousel) && !this.state.eventDone) {
      this.carousel.addEventListener('scroll', () => {
        this.copyElementOutOfContainer()
      })

      this.setState({
        eventDone: true,
      })
    }
  }

  topElement = index => {
    let currentElementIndex = 0
    this.state.elementsIndex.forEach((elementIndex, ii) => {
      if (index === elementIndex) {
        currentElementIndex = ii
      }
    })

    const scrollTop =
      this.state.scrollTopFakeZero +
      this.props.elementHeight * (currentElementIndex - this.props.elements.length)
    this.carousel.scrollTop = scrollTop
  }

  handleElementOnClick = clickedIndex => {
    this.topElement(clickedIndex)
  }

  render() {
    const { selectedElement, isSelected, renderComponent, classes } = this.props

    if (isNil(this.state.elements)) {
      return <LoadingBlock show />
    }

    return (
      <div
        className={classes.verticalCarouselContainer}
        ref={ref => {
          this.carousel = ref
        }}
      >
        {this.state.elements.map((element, index) => {
          const selected = isSelected(element, selectedElement)
          return (
            <div
              key={index}
              className={classes.element}
              ref={ref => {
                this.elements[index] = ref
              }}
              onClick={() => this.handleElementOnClick(index)}
            >
              {selected && <div className={classes.selectedBar} />}
              {renderComponent(element, selected)}
            </div>
          )
        })}
      </div>
    )
  }
}

export default withStyles(styles)(InfiniteVerticalCarousel)
