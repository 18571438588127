import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { resetKiosk } from 'pmt-modules/kiosk'

import { withStyles } from 'pmt-ui/styles'
import IconHome from 'pmt-ui/svg-icons/action/home'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(5),
    display: 'flex',
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(3),
  }
})

const BakcHome = ({
  classes,
  resetKiosk
}) => (
  <div className={classes.root} onClick={resetKiosk}>
    <IconHome className={classes.icon} />
    <TypographyCustom type="247" skipColor>
      {tr('kiosk.checkout.global.back_home')}
    </TypographyCustom>
  </div>
)


const mapStateToProps = () => ({})

const mapDispatchToProps = {
  resetKiosk,
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(
  BakcHome
)
