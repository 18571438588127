import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { getPublicUrl } from 'pmt-modules/environment'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import BackHome from '../components/BackHome'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    maxWidth: 230,
    maxHeight: 230,
  },
  caption: {
    marginTop: theme.spacing(8),
    whiteSpace: 'pre-line',
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
  scanRfid: {
    marginTop: theme.spacing(17),
    flex: 1,
  },
  qrCaption: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(14),
  },
})

const View = ({ classes, frontAppConfig, restaurant }) => (
  <div className={classes.root}>
    <BackHome />

    {frontAppConfig.theme.logo.display && (
      <div className={classes.logoContainer}>
        <img
          src={frontAppConfig.theme.logo.path || restaurant.logo}
          className={classes.logo}
          alt="logo"
        />
      </div>
    )}

    <CustomTextsContainer>
      {({ texts }) =>
        !isNil(texts) && !isNil(texts.KIOSK__CHECKOUT__SCAN__CAPTION) ? (
          <TypographyCustom
            type="604"
            align="center"
            className={classes.caption}
            dangerouslySetInnerHTML={{
              __html: texts.KIOSK__CHECKOUT__SCAN__CAPTION,
            }}
          />
        ) : (
          <TypographyCustom type="604" align="center" className={classes.caption}>
            {tr('kiosk.checkout.page.scan.caption.1')}
            <span>{tr('kiosk.checkout.page.scan.caption.2')}</span>
            {tr('kiosk.checkout.page.scan.caption.3')}
          </TypographyCustom>
        )
      }
    </CustomTextsContainer>

    <div className={classes.scanRfid}>
      <img src={getPublicUrl() + '/img/scan_rfid.png'} alt="scan rfid" />
    </div>

    <TypographyCustom type="404" align="center" className={classes.qrCaption}>
      <CustomTextsContainer>
        {({ texts }) =>
          !isNil(texts) && !isNil(texts.KIOSK__CHECKOUT__SCAN__QR_CAPTION)
            ? texts.KIOSK__CHECKOUT__SCAN__QR_CAPTION
            : tr('kiosk.checkout.page.scan.qr_caption')
        }
      </CustomTextsContainer>
    </TypographyCustom>
  </div>
)

export default withStyles(styles)(View)
