import { createAction } from '../redux'

import { ModalType, createShowDialogAction, createHideDialogAction } from '../dialog'

export const SuggestionActions = {
  CREATE: 'SUGGESTION::CREATE',
}

/**
 * create a suggestion based on the pending item being added to cart
 * this is trigger in cart middleware
 */
export const createSuggestionFromItem = orderItem =>
  createAction(SuggestionActions.CREATE, {
    orderItem,
  })

/**
 * @param {*} orderItem item pending to be add to cart
 *
 * Handle by the middleware `addToCartMiddleware` in suggestion module
 */
export const showSuggestionDialog = orderItem =>
  createShowDialogAction(ModalType.SUGGESTION_VIEW, {
    orderItem,
  })

export const hideSuggestionDialog = () => createHideDialogAction(ModalType.SUGGESTION_VIEW)
