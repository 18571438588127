import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const generateFeesFromSettings = (orderSettingsFees, { orderMode, totalCartPrice }) => {
  const fees = (orderSettingsFees || []).map(orderSettingsFee => {
    //  {
    //   "type": "DELIVERY",
    //   "name": [
    //     {
    //       "locale": "fr-FR",
    //       "text": "Frais de livraison"
    //     },
    //     {
    //       "locale": "en-US",
    //       "text": "Delivery price"
    //     }
    //   ],
    //   "amountIncludingTax": 2.42,
    //   "taxPercent": 20,
    //   "taxAmount": 0.4
    // }
    const filterModes = get(orderSettingsFee, 'settings.filters.modes', [])
    const shouldBeApplied =
      orderSettingsFee.isStatusEnabled &&
      (isEmpty(filterModes) || filterModes.indexOf(orderMode) >= 0) &&
      (!orderSettingsFee.settings.hasMinimumPriceForFree ||
        orderSettingsFee.settings.minimumPriceForFree > totalCartPrice)
    return {
      name: orderSettingsFee.name,
      publicDescription: orderSettingsFee.publicDescription,
      type: orderSettingsFee.type,

      amountIncludingTax: orderSettingsFee.settings.amountIncludingTax,
      hasMinimumPriceForFree: orderSettingsFee.settings.hasMinimumPriceForFree,
      minimumPriceForFree: orderSettingsFee.settings.minimumPriceForFree,
      taxAmount: orderSettingsFee.settings.taxAmount,
      taxPercent: orderSettingsFee.settings.taxPercent,

      shouldBeApplied,
    }
  })

  return fees.filter(fee => fee.shouldBeApplied)
}
