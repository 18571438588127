import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'

import TemplateApi from 'pmt-modules/api/calls/TemplateApi'

export const GetCustomTextsAction = createApiEnumAction('CUSTOM_TEXTS::GET')

export const getCustomTexts = customTextsContainerId =>
  createApiCallAction(
    GetCustomTextsAction,
    TemplateApi.getCustomTextsContainer(customTextsContainerId),
    {
      customTextsContainerId,
    }
  )
