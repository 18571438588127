/**
 * Create an enum to use for a Kiosk request action
 */
const createKioskEnumAction = prefix => ({
  REQUEST: `${prefix}::REQUEST`,
  SUCCESS: `${prefix}::SUCCESS`,
  FAILURE: `${prefix}::FAILURE`,
})

export default createKioskEnumAction
