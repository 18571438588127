import BfastSdk from '@paymytable/bfast-web-sdk'

import repeat from 'lodash/repeat'

class BfastPrinterBuilder {
  constructor() {
    this.data = new BfastSdk.printer()

    // each line will be printed on a Y position, we have to increment it ourselves
    this.lineY = 0
  }

  static DEFAULT_CHAR_SIZE = 1

  static ItemsTableColSize = {
    COL_QTY: 3,
    COL_PRODUCT: 20,
    COL_UNIT: 6,
    COL_TOTAL: 6,
    COL_VAT: 5,
  }

  static MaxCharPerSize = {
    SIZE_1: 38,
    SIZE_2: 38,
    SIZE_3: 36,
    SIZE_4: 32,
  }

  /**
   * Default font options for addText method
   *  - bold: boolean whether to bold text or not
   *  - align: string left/center/right to align text
   *  - size: number 1-4 font size of text
   */
  static DefaultFontOptions = {
    bold: false,
    align: 'left',
    size: 1,
  }

  addText(text = '', options = {}) {
    let extra = ''
    let spaces = 0
    let str = text

    options = { ...BfastPrinterBuilder.DefaultFontOptions, ...options }

    // If text length superior to maximum chars printable by line, we cut text in two and
    // recursively call addText method with the remaining part of text
    if (text.length * options.size > BfastPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`]) {
      str = text.substr(
        0,
        BfastPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`] / options.size
      )
      extra = text.substr(
        (BfastPrinterBuilder.MaxCharPerSize[`SIZE_${options.size}`] - text.length * options.size) /
          options.size
      )
    }

    /**
     * @param  {string} text
     * @param  {number} y
     * @param  {number} fontSize
     * @param  {string} fontFamily
     * @param  {string} fontStyle
     */
    const fontSize = this.getFontSize(options.size)

    this.data.addTextAlign(
      `${this.generateSpaces(spaces)}${str}\n`,
      this.lineY,
      options.align,
      fontSize,
      'Consolas',
      options.bold ? 'Bold' : ''
    )

    this.lineY += this.getLineHeight(fontSize)

    if (extra) {
      this.addText(extra, options)
    }
  }

  getLineHeight(fontSize) {
    return fontSize * 1.5
  }

  // not supported on bfast
  addTextRaw(text = '') {
    //this.data.addText(text)
  }

  getSpaceToAlignCenter(str, size = 1) {
    return this.getSpacesLeft(str, size) / 2
  }

  getSpaceToAlignRight(str, size = 1) {
    return this.getSpacesLeft(str, size)
  }

  getSpacesLeft(
    str,
    size = 1,
    lineLength = BfastPrinterBuilder.MaxCharPerSize[`SIZE_${BfastPrinterBuilder.DEFAULT_CHAR_SIZE}`]
  ) {
    return size === 1 ? lineLength - str.length : lineLength - str.length * size
  }

  getFontSize(size = BfastPrinterBuilder.DEFAULT_CHAR_SIZE) {
    switch (size) {
      case 1:
        return 9
      case 2:
        return 16
      case 3:
        return 20
      case 4:
        return 24
      default:
        return 12
    }
  }

  /**
   * cut the paper to percentage value
   * warning: 100% totally cuts the paper making it fall!
   */
  addCut(percent = 90) {
    //
  }

  printPayment(content) {
    // content.forEach(line => {
    //   this.addText(line)
    // })
    // this.addCut()
    // const message = this.toString()
    // console.log(message)
    // return message
  }

  addFeedLine(nbLines = 1) {
    for (let i = 0; i < nbLines; i++) {
      this.addText()
    }
  }

  fillWithSpaces(nb, width) {
    return this.generateSpaces(width - nb)
  }

  /**
   * Lodash repeat seems to be the fastest solution
   * String.prototype.repeat() requires a polyfill
   * see :
   *   - https://jsperf.com/repeating-character
   *   - https://jsperf.com/repeating-char
   */
  generateSpaces(nb) {
    return repeat(' ', nb)
  }

  toString() {
    return this.data
  }

  getItemsTableColSize(key) {
    return BfastPrinterBuilder.ItemsTableColSize[key]
  }
}

export default BfastPrinterBuilder
