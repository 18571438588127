import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import {
  authenticateRestaurant,
  getAuthRestaurantError,
  isFetchingAuthRestaurant,
  isLoggedIn,
} from 'pmt-modules/authRestaurant'
import { makeIsFormValid } from 'pmt-modules/form'

import { initForm, FormType, withForm } from 'pmt-modules/form'

import { redirectTo, getRoute } from 'pmt-modules/routing'

import View from './View'

/**
 * @specs N/A
 */
class LoginPage extends React.Component {
  componentWillMount() {
    if (this.props.isLoggedIn) {
      this.props.redirectTo(getRoute('HOME'))
    }
  }

  componentDidMount() {
    if (
      !isEmpty(this.props.location.query.restaurantId) &&
      !isEmpty(this.props.location.query.apiConsumer)
    ) {
      this.props.initForm(FormType.RESTAURANT_LOGIN, {
        restaurantId: this.props.location.query.restaurantId,
        apiConsumer: this.props.location.query.apiConsumer,
        password: this.props.location.query.password,
      })
    }
  }

  handleSubmitLogin = () => {
    const { apiConsumer, restaurantId, password } = this.props.formData

    this.props.authenticateRestaurant(restaurantId, password, apiConsumer)
  }

  render() {
    const { authError, formIsValid, isFetchingAuthRestaurant } = this.props

    return (
      // <KioskPage route={this.props.route}>
      <View
        isFetchingAuthRestaurant={isFetchingAuthRestaurant}
        authError={authError}
        formIsValid={formIsValid}
        onSubmitLogin={this.handleSubmitLogin}
      />
      // </KioskPage>
    )
  }
}

LoginPage.defaultProps = {
  formType: FormType.RESTAURANT_LOGIN,
}

const mapStateToProps = (state, props) => {
  return {
    isFetchingAuthRestaurant: isFetchingAuthRestaurant(state),
    isLoggedIn: isLoggedIn(state),
    authError: getAuthRestaurantError(state),
    formIsValid: makeIsFormValid(state, LoginPage.defaultProps),
  }
}

export default withForm(FormType.RESTAURANT_LOGIN)(
  connect(mapStateToProps, {
    authenticateRestaurant,
    initForm,
    redirectTo,
  })(LoginPage)
)
