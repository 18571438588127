import { tr } from 'pmt-modules/i18n'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import moment from 'moment'

import { getOrderModeLabel } from 'pmt-modules/order'

import { formatPrice } from 'pmt-utils/currency'

import Printer from './'

class OrderTicketPrinter extends Printer {
  format(order, itemList, options = {}) {
    this.order = order
    this.restaurant = options.restaurant
    this.itemList = itemList

    // default option has client receipt
    // i.e.: print payment receipt with order receipt
    this.options = { hasClientReceipt: true, ...options }

    this.renderClientInfo()

    this.renderItemsTable()

    this.renderPaymentMode()

    this.renderLeftToPay()

    if (this.order.orderId) {
      this.builder.addText(`${tr('global.printer.acrelec.order_number')}${this.order.orderId}`)
    }

    this.builder.addText(`--------------------------------------`, { align: 'center' })
    this.renderCustomText()

    if (this.order.irlPayment !== null && this.options.hasClientReceipt === true) {
      this.renderPayment()
    }

    this.builder.addFeedLine(3)
    this.builder.addCut()

    const message = this.builder.toString()

    console.log(message)
    return message
  }

  renderClientInfo() {
    const paymentMode =
      this.order.irlPayment !== null
        ? tr('global.printer.acrelec.payment_mode.credit_card.abbr')
        : tr('global.printer.acrelec.payment_mode.counter.abbr')

    const orderId = this.order.truncOrderId ? ` ${this.order.truncOrderId}` : ''

    this.builder.addText(`${paymentMode}${orderId}`, { bold: true, align: 'center', size: 3 })
    this.builder.addFeedLine()

    this.builder.addText(getOrderModeLabel(this.order.mode), {
      align: 'center',
      size: 2,
    })
    this.builder.addFeedLine()

    if (this.order.pagerId) {
      this.builder.addText(`${tr('global.printer.acrelec.pagerId')} ${this.order.pagerId}`, {
        align: 'center',
        size: 1,
      })
      this.builder.addFeedLine()
    }

    this.builder.addText(this.restaurant.name, { align: 'center' })
    this.builder.addText(this.restaurant.address.street, { align: 'center' })
    this.builder.addText(`${this.restaurant.address.postCode} ${this.restaurant.address.city}`, {
      align: 'center',
    })

    if (!isEmpty(this.restaurant.legal) && !isEmpty(this.restaurant.legal.siren)) {
      this.builder.addText(`SIRET ${this.restaurant.legal.siren}`, { align: 'center' })
    }

    this.builder.addFeedLine()

    const createdDate = `${moment(this.order.creationDate).format('DD/MM/YYYY')} - ${moment(
      this.order.creationDate
    ).format('HH:mm')}`
    this.builder.addText(createdDate, { align: 'center' })

    this.builder.addFeedLine()
  }

  renderItemsTable() {
    let quantityLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.quantity'),
        1,
        this.builder.getItemsTableColSize('COL_QTY')
      )
    )}${tr('global.printer.acrelec.quantity')}`

    let productLabel = `${tr('global.printer.acrelec.product')}${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.product'),
        1,
        this.builder.getItemsTableColSize('COL_PRODUCT')
      )
    )}`

    let unitLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.unit_price'),
        1,
        this.builder.getItemsTableColSize('COL_UNIT')
      )
    )}${tr('global.printer.acrelec.unit_price')}`

    let totalLabel = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        tr('global.printer.acrelec.total'),
        1,
        this.builder.getItemsTableColSize('COL_TOTAL')
      )
    )}${tr('global.printer.acrelec.total')}`

    this.builder.addText(`${quantityLabel} ${productLabel} ${unitLabel} ${totalLabel}`)

    forEach(this.itemList, item => {
      if (item.isProduct) {
        this.renderProduct(item)
      } else if (item.isMenu) {
        this.renderMenu(item)
      }
    })

    this.builder.addFeedLine()
    this.renderTotal()
    this.builder.addFeedLine()
  }

  renderMenu({ name, quantity, totalPrice, totalPriceWithQuantity, selectedProducts }) {
    this.renderItemTitle(
      name,
      quantity,
      formatPrice(totalPrice),
      formatPrice(totalPriceWithQuantity)
    )

    forEach(selectedProducts, ({ name, options }) => {
      this.renderItemLine(name, 2)
      this.renderOptions(options, 4)
    })
  }

  renderProduct({ name, quantity, totalPrice, totalPriceWithQuantity, options, reclaimLater }) {
    this.renderItemTitle(
      name,
      quantity,
      formatPrice(totalPrice),
      formatPrice(totalPriceWithQuantity)
    )
    this.renderOptions(options, 2)

    if (reclaimLater) {
      this.renderItemLine(tr('kiosk.order.product.reclaimLater.label'), 2)
    }
  }

  renderItemTitle(name, quantity, unit, total) {
    quantity = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(
        quantity.toString(),
        1,
        this.builder.getItemsTableColSize('COL_QTY')
      )
    )}${quantity}`
    name = this.getProductFormatted(name)
    unit = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(unit, 1, this.builder.getItemsTableColSize('COL_UNIT'))
    )}${unit}`
    total = `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(total, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${total}`

    this.builder.addText(`${quantity} ${name} ${unit} ${total}  `)
  }

  renderItemLine(name, margin = 0) {
    this.builder.addText(
      `${this.builder.generateSpaces(
        this.builder.getItemsTableColSize('COL_QTY')
      )} ${this.getProductFormatted(name, margin)}`
    )
  }

  getProductFormatted(name, margin = 0) {
    let productLabel = `${this.builder.generateSpaces(margin)}${name.substr(
      0,
      this.builder.getItemsTableColSize('COL_PRODUCT') - margin
    )}`
    productLabel =
      name.length > productLabel.length
        ? `${productLabel.substr(0, productLabel.length - 1)}…`
        : productLabel
    productLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(productLabel, 1, this.builder.getItemsTableColSize('COL_PRODUCT'))
    )}`

    return productLabel
  }

  renderOptions(options, margin = 0) {
    forEach(options, option => {
      forEach(option.values, value => {
        if (value.quantity > 0) {
          this.renderItemLine(value.name, margin)
        }
      })
    })
  }

  renderTotal() {
    let strTotal = formatPrice(this.order.totalPrice)
    let strTotalLabel = tr('global.printer.acrelec.total_order')
    strTotalLabel += `${this.builder.generateSpaces(
      this.builder.getItemsTableColSize('COL_VAT')
    )}   `
    strTotalLabel += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(strTotal, 1, this.builder.getItemsTableColSize('COL_TOTAL'))
    )}${strTotal} €`
    this.builder.addText(strTotalLabel, { align: 'right', bold: true })

    if (!isNil(this.order.check) && !isNil(this.order.check.tax)) {
      forEach(this.order.check.tax, tax => {
        let vatLabel = tr('global.printer.acrelec.vat')
        // tax name is a free field, we clean a potential vat wording in it
        const taxName = tax.name
          .toLowerCase()
          .replace('tva', '')
          .trim()
        vatLabel += `${this.builder.generateSpaces(
          this.builder.getSpacesLeft(taxName, 1, this.builder.getItemsTableColSize('COL_VAT'))
        )}`
        vatLabel += taxName
        vatLabel += `${this.builder.generateSpaces(
          this.builder.getSpacesLeft(
            formatPrice(tax.amoutOfTax),
            1,
            this.builder.getItemsTableColSize('COL_TOTAL')
          )
        )}${formatPrice(tax.amoutOfTax)} €`

        this.builder.addText(vatLabel, { align: 'right', bold: true })
      })
    }
  }

  renderCustomText() {
    if (
      !isNil(this.options.kioskSettings) &&
      !isNil(this.options.kioskSettings.order) &&
      !isNil(this.options.kioskSettings.order.ticketSettings) &&
      !isEmpty(this.options.kioskSettings.order.ticketSettings.customText)
    ) {
      this.builder.addTextRaw(this.options.kioskSettings.order.ticketSettings.customText)
    }
  }

  renderPaymentMode() {
    if (this.order.irlPayment !== null) {
      const strTotal = formatPrice(this.order.totalPrice)
      this.builder.addText(`--------------------------------------`, { align: 'center' })
      this.builder.addText(tr('global.printer.acrelec.payment_mode'))

      let label = tr('global.printer.acrelec.payment_mode.credit_card')
      label += `${this.builder.generateSpaces(
        this.builder.getSpacesLeft(`${label}${strTotal} €`)
      )}${strTotal} €`
      this.builder.addText(label, { align: 'right' })
    }
  }

  renderLeftToPay() {
    const strTotal =
      this.order.irlPayment !== null ? formatPrice(0) : formatPrice(this.order.totalPrice)

    this.builder.addText(`--------------------------------------`, { align: 'center' })
    let label = tr('global.printer.acrelec.left_to_pay')
    label += `${this.builder.generateSpaces(
      this.builder.getSpacesLeft(`${label}${strTotal} €`)
    )}${strTotal} €`
    this.builder.addText(label, { align: 'right', bold: true })
    this.builder.addFeedLine(2)
  }
}

export default OrderTicketPrinter
