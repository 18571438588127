import { createSimpleSelector } from '../redux'

import { formatUserAccounts } from './format'

const getUserAccounts = createSimpleSelector(
  state => state.entities.restaurantUserAccounts,
  formatUserAccounts
)

export const isFetchingUserAccounts = getUserAccounts.isFetching
export const getDataUserAccounts = getUserAccounts.getData
export const getErrorUserAccounts = getUserAccounts.getError
