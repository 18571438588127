import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import {
  getErrorKioskPayment,
  getDataKioskPeripheralsStatus,
  sendPayment,
} from 'pmt-modules/kioskInteractor'
import withCheck from 'pmt-modules/payment/components/withCheck'
import { getPaymentError, processIrlPayment } from 'pmt-modules/payment/payment'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { goBack } from 'pmt-modules/routing'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'

import Logger from 'pmt-utils/logger'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-967
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b557fe3c42e98852fd06b3c
 */
@withAppConfig
@withRestaurant
@withCheck
class CheckoutPaymentPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.sendPayment(props)
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.check && nextProps.check) {
      this.sendPayment(nextProps)
    }
  }

  sendPayment({ check, payment, restaurant }) {
    if (check && check.outstandingBalance) {
      console.log('Sending payment : ', check.outstandingBalance)
      this.props.sendPayment(check.outstandingBalance, {
        check,
        payment,
        restaurant,
      })
    }
  }

  handleCancel = () => {
    this.props.hideErrorDialog()
    this.props.goBack()
  }

  handleErrorRetry = () => {
    this.props.hideErrorDialog()
    if (!isNil(this.props.paymentKioskError)) {
      this.sendPayment(this.props)
    } else if (!isNil(this.props.paymentError)) {
      Logger.info('PAYMENT', 'post irl payment has failed', { ...this.props })
      this.props.processIrlPayment(
        this.props.restaurant,
        this.props.check,
        this.props.payment,
      )
    }
  }

  render() {
    const {
      route,
      check,
      frontAppConfig,
      kioskPeripheralsStatus,
      paymentKioskError,
      paymentError,
      restaurant,
    } = this.props

    return (
      <KioskPage route={route} isFetching={!check || !restaurant}>
        <View
          check={check}
          frontAppConfig={frontAppConfig}
          kioskPeripheralsStatus={kioskPeripheralsStatus}
          onCancel={this.handleCancel}
          onErrorRetry={this.handleErrorRetry}
          paymentKioskError={paymentKioskError}
          paymentError={paymentError}
          restaurant={restaurant}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  kioskPeripheralsStatus: getDataKioskPeripheralsStatus(state),
  paymentKioskError: getErrorKioskPayment(state),
  paymentError: getPaymentError(state),
})

const mapDispatchToProps = { goBack, hideErrorDialog, sendPayment, processIrlPayment }

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentPage)
