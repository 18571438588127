import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { findOnArray } from 'pmt-utils/array'

export const getParentCategoriesLeft = (category, parentCategories) => {
  let parentCategoriesLeft = []
  let found = false

  parentCategories.forEach(parentCategory => {
    if (!found && parentCategory.id !== category.id) {
      parentCategoriesLeft.push(parentCategory)
    } else {
      found = true
    }
  })

  return parentCategoriesLeft
}

// get the number of products chosen in part and all subcategories under
const recursivePartNbChosenValues = (part, nbChosenValues) => {
  if (!isNil(part.categories) && !isEmpty(part.categories)) {
    part.categories.forEach(category => {
      nbChosenValues = recursivePartNbChosenValues(category, nbChosenValues)
    })
  }

  if (!isNil(part.products)) {
    nbChosenValues += part.products.reduce((nb, product) => {
      return nb + product.quantity
    }, 0)
  }

  return nbChosenValues
}

export const getNbChosenValuesValuesFromPart = part => {
  let nbChosenValues = 0
  nbChosenValues = recursivePartNbChosenValues(part, nbChosenValues)

  return nbChosenValues
}

// find a product in a part, recursively looping in the part subcategories
const recursiveFindPartProduct = (part, partProduct, partProductFound) => {
  if (isNil(partProductFound) && !isNil(part.products)) {
    partProductFound = findOnArray(part.products, product => product.id === partProduct.id)

    if (isNil(partProductFound) && !isNil(part.categories) && !isEmpty(part.categories)) {
      part.categories.forEach(partCategoryRoot => {
        partProductFound = recursiveFindPartProduct(partCategoryRoot, partProduct, partProductFound)
      })
    }
  }

  return partProductFound
}

export const getProductFromPart = (part, partProduct) => {
  const partProductFound = recursiveFindPartProduct(part, partProduct, null)

  return partProductFound
}

// find subcategories (partCategory) in a part, recursively looping in the part subcategories
const recursiveFindPartCategory = (part, partCategory, partCategoryFound) => {
  if (isNil(partCategoryFound)) {
    if (!isNil(part.categories) && !isEmpty(part.categories)) {
      part.categories.forEach(partCategoryRoot => {
        partCategoryFound = recursiveFindPartCategory(
          partCategoryRoot,
          partCategory,
          partCategoryFound
        )
      })
    }

    if (part.id === partCategory.id) {
      partCategoryFound = part
    }
  }

  return partCategoryFound
}

export const getCategoryFromPart = (part, partCategory) => {
  const partCategoryFound = recursiveFindPartCategory(part, partCategory, null)

  return partCategoryFound
}

// get every products selected in a category, looping in every sub-category recursively
const recursivePartCategorySelectedProducts = (partCategory, selectedProducts) => {
  if (!isEmpty(partCategory.categories)) {
    partCategory.categories.forEach(category => {
      selectedProducts = recursivePartSelectedProducts(category, selectedProducts)
    })
  }

  if (!isNil(partCategory.products)) {
    selectedProducts = selectedProducts.concat(
      partCategory.products.filter(product => {
        // adding only the id of the part category that the product belongs to (else, reference problem)
        // for PartView needs (unselect products from small post-its)
        // TODO: why we add partCategory here and not on format ?
        product.partCategory = { id: partCategory.id }
        return product.quantity > 0
      })
    )
  }

  return selectedProducts
}

// get every products selected in a part, looping in every part recursively
const recursivePartSelectedProducts = (part, selectedProducts) => {
  // searching on categories if the part has categories
  if (!isEmpty(part.categories)) {
    part.categories.forEach(category => {
      selectedProducts = recursivePartCategorySelectedProducts(category, selectedProducts)
    })
  }

  // use the part.products data
  if (!isNil(part.products)) {
    selectedProducts = selectedProducts.concat(
      part.products.filter(product => {
        // adding only the id of the part category that the product belongs to (else, reference problem)
        // for PartView needs (unselect products from small post-its)
        // there is no partCategory here, only a part
        // TODO: why we add partCategory here and not on format ?
        // TRICK: we set the partCategory the id of the part. Otherwise the front crash because this
        //  value is needed.
        // When from cart, there is no part.categories given, only part.products
        // we use this to unselect a product from a part / part category.
        product.partCategory = { id: part.id }
        return product.quantity > 0
      })
    )
  }

  return selectedProducts
}

export const getPartSelectedProducts = part => {
  return recursivePartSelectedProducts(part, [])
}
