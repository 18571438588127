const CheckErrors = {
  GET_CHECK: {
    100: 'global.check.check_not_found',
    default: 'global.check.check_not_found',
  },
  GET_CHECK_CODE: {
    default: 'global.check.check_not_found',
  },
  PUT_MERGE_CHECKS: {
    default: 'global.check.check_not_found',
  },
}

export default CheckErrors
