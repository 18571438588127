import React from 'react'

import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  background: {
    flex: 1,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '700px',
    height: '700px',
    marginTop: '-350px',
    marginLeft: '-350px',
    display: 'flex',
    alignItems: 'center',
    zIndex: '10',
  },
  logoPicture: {
    margin: '0 auto',
    width: '100%',
    height: 'auto',
  },
  infos: {
    background: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '0',
    width: '100%',
    height: '440px',
    display: 'flex',
    alignItems: 'flex-end',

    '& p': {
      fontWeight: '700',
      margin: '0 auto 80px',
      fontSize: '48px',
      lineHeight: '68px',
      color: theme.palette.common.white,
    },
  },
})

const View = ({ classes, kioskSettings, restaurant, onStart }) => (
  <div className={classes.root}>
    <div
      className={classes.background}
      style={{
        backgroundImage: `url(${kioskSettings.order.backgroundImage})`,
      }}
      onClick={onStart}
    >
      <div className={classes.logo}>
        <img className={classes.logoPicture} src={restaurant.logo} alt={restaurant.name} />
      </div>
      <div className={classes.infos}>
        <Typography component="p">Toucher pour commencer</Typography>
      </div>
    </div>
  </div>
)

export default withStyles(styles)(View)
