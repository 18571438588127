import React from 'react'
import PropTypes from 'prop-types'

import { countryCodes, getCountryNameForCode } from 'pmt-utils/country'

import ListChooser from 'pmt-ui/ListChooser'

class CountryChooser extends React.Component {

  render() {
    const { label, value = 'FR', onChange, error, helperText, ...otherProps } = this.props

    const options = countryCodes.map(country => ({
      label: country.name,
      value: country.code,
    }))

    return (
      <ListChooser
        label={label}
        title='Choisissez un pays'
        value={value}
        valueFormatter={value => getCountryNameForCode(value)}
        options={options}
        onChange={this.props.onChange}
        {...otherProps}
      />
    )

  }
}

CountryChooser.propTypes = {
  label: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,

  value: PropTypes.string,
}

export default CountryChooser
