import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import NavigationChevronRight from 'pmt-ui/svg-icons/navigation/chevron-right'
import NavigationChevronLeft from 'pmt-ui/svg-icons/navigation/chevron-left'

import OrderHeader from '../../../components/OrderHeader'
import CatalogLayout from '../../../components/CatalogLayout'
import CategoryContentLayout from '../../../components/CategoryContentLayout'
import IconLabelMode, { TYPE_COLORS } from '../../../components/IconLabelMode'
import OrderFooter from '../../../components/OrderFooter'

import CategoriesCarousel from 'app/components/CategoriesCarousel'

const styles = theme => ({
  background: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: theme.pmt.colors.white,
  },
  overContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 20,
  },
  logoContainer: {
    marginTop: theme.spacing(25),
    textAlign: 'center',
  },
  logo: {
    maxWidth: 600,
    maxHeight: 300,
  },
  contentGridContainer: {
    marginTop: theme.spacing(20),
  },
  loadingText: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  modeContainer: {
    marginTop: theme.spacing(50),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    flex: 1,
    overflowY: 'scroll',
  },
  categorySection: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
    marginBottom: theme.spacing(6),
    zIndex: 1000,
  },
  categoryName: {
    height: 55,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    lineHeight: '50px',
    color: theme.pmt.colors.grey4A,
  },
  breadcrumbContainer: {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(9),
  },
  breadcrumbElement: {
    float: 'left',
    height: 20,
    lineHeight: '18px',
  },
  breadcrumbArrow: {
    float: 'left',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  iconNameContainer: {
    float: 'left',
    display: 'inline',
    width: 45,
    height: 55,
    marginRight: theme.spacing(3),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    borderRadius: 3,
  },
  iconName: {
    width: '100%',
    height: '100%',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
})

const View = ({
  frontAppConfig,
  isFetching,
  kioskSettings,
  restaurant,
  mode,
  category,
  catalog,
  itemList,
  parentCategories,
  onCategoryClick,
  onSubCategoryClick,
  onBackToPreviousCategory,
  onParentCategoryClick,
  classes,
}) => (
  <div className={classes.background}>
    {isFetching ? (
      <div className={classes.overContainer}>
        <div className={classes.logoContainer}>
          {restaurant && (
            <img
              src={frontAppConfig.theme.logo.path || restaurant.logo}
              className={classes.logo}
              alt="logo"
            />
          )}
        </div>
        <Grid container spacing={3} className={classes.contentGridContainer}>
          <Grid item xs={12}>
            <div>
              <LoadingBlock show size={200} />
              <TypographyCustom type="247" className={classes.loadingText} align="center">
                {tr('kiosk.order.page.category.loading')}
              </TypographyCustom>
            </div>
            <IconLabelMode
              mode={mode}
              color={TYPE_COLORS.MAIN}
              classes={{ root: classes.modeContainer }}
            />
          </Grid>
        </Grid>
      </div>
    ) : (
      <div className={classes.content}>
        <OrderHeader
          frontAppConfig={frontAppConfig}
          restaurant={restaurant}
          kioskSettings={kioskSettings}
        />
        <CatalogLayout
          leftPart={
            <CategoriesCarousel
              catalog={catalog}
              category={category}
              onSelectCategory={onCategoryClick}
              restaurant={restaurant}
            />
          }
          rightPart={
            <React.Fragment>
              {category && (
                <React.Fragment>
                  {!isEmpty(parentCategories) ? (
                    <div className={classes.categorySection}>
                      <div className={classes.iconNameContainer} onClick={onBackToPreviousCategory}>
                        <NavigationChevronLeft className={classes.iconName} />
                      </div>
                      <TypographyCustom type="487" className={classes.categoryName}>
                        {category.name}
                      </TypographyCustom>
                      <div className={classes.breadcrumbContainer}>
                        {parentCategories.map((parentCategory, index) => (
                          <span key={index}>
                            {index > 0 && (
                              <NavigationChevronRight
                                className="u-floatLeft u-marginLeft5 u-marginRight5"
                                onClick={onBackToPreviousCategory}
                              />
                            )}
                            <TypographyCustom
                              type="164"
                              component="span"
                              className={classNames(
                                classes.breadcrumbElement,
                                classes.colorPrimary
                              )}
                              onClick={() => onParentCategoryClick(parentCategory)}
                            >
                              {parentCategory.name}
                            </TypographyCustom>
                          </span>
                        ))}
                        <NavigationChevronRight
                          className={classes.breadcrumbArrow}
                          onClick={onBackToPreviousCategory}
                        />
                        <TypographyCustom
                          type="164"
                          component="span"
                          className={classNames(classes.breadcrumbElement)}
                        >
                          {category.name}
                        </TypographyCustom>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.categorySection}>
                      <TypographyCustom type="487" className={classes.categoryName}>
                        {category.name}
                      </TypographyCustom>
                    </div>
                  )}

                  <CategoryContentLayout
                    category={category}
                    restaurant={restaurant}
                    onSubCategoryClick={onSubCategoryClick}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
        <OrderFooter
          mode={mode}
          catalog={catalog}
          itemList={itemList}
          enableEmptyCartButton={!isEmpty(itemList)}
        />
      </div>
    )}
  </div>
)

export default withStyles(styles)(View)
