import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import { nfcStart, scanStart } from 'pmt-modules/kioskInteractor/actions'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-967
 * https://app.zeplin.io/project/5bb4da9dd6625831b889a1e0/screen/5bd1875c0cd71b0a7b1b6a75
 */
@withAppConfig
@withRestaurant
class ScanPage extends React.PureComponent {
  constructor(props) {
    super(props)

    props.scanStart()
    props.nfcStart()
  }

  render() {
    const { frontAppConfig, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={!restaurant}>
        <View
          frontAppConfig={frontAppConfig}
          restaurant={restaurant}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = () => ({})

export default connect(
  mapStateToProps,
  {
    nfcStart,
    scanStart,
  }
)(ScanPage)
