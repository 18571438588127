import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { getFrontSettings } from 'pmt-modules/appConfig'
import { isFetchingPostPaymentAtTable } from 'pmt-modules/payment/payment'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    color: theme.palette.primary.main,
    background: theme.pmt.colors.white,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.modal,
    textAlign: 'center',
    flex: 1,
  },
  logoContainer: {
    marginTop: theme.spacing(15),
  },
  logo: {
    maxWidth: 230,
    maxHeight: 230,
  },
  contentGridContainer: {
    marginTop: theme.spacing(40),
  },
  loadingText: {
    marginTop: theme.spacing(8),
  },
  loadingBlock: {
    background: 'transparent',
  },
})

@withRestaurant
class LoadingPostPayment extends React.PureComponent {
  render() {
    const { classes, frontAppConfig, isFetchingPostPaymentAtTable, restaurant } = this.props

    return (
      isFetchingPostPaymentAtTable && (
        <div className={classes.root}>
          {frontAppConfig.theme.logo.display && (
            <div className={classes.logoContainer}>
              <img
                src={frontAppConfig.theme.logo.path || restaurant.logo}
                className={classes.logo}
                alt="logo"
              />
            </div>
          )}
          <Grid container className={classes.contentGridContainer}>
            <Grid item xs={12}>
              <div>
                <LoadingBlock
                  show
                  size={200}
                  classes={{
                    loadingBlock: classes.loadingBlock,
                    circularProgress: classes.circularProgress,
                  }}
                />
                <TypographyCustom
                  type="247"
                  className={classes.loadingText}
                  align="center"
                  skipColor
                >
                  {tr('kiosk.checkout.page.payment.payment_in_progress')}
                </TypographyCustom>
              </div>
            </Grid>
          </Grid>
        </div>
      )
    )
  }
}

const mapStateToProps = state => ({
  frontAppConfig: getFrontSettings(state),
  isFetchingPostPaymentAtTable: isFetchingPostPaymentAtTable(state),
})

export default compose(connect(mapStateToProps, {}), withStyles(styles))(LoadingPostPayment)
