import { createApiCallAction, createApiEnumAction } from '../api/utils'
import createAction from '../redux/createAction'

import UserApi from '../api/calls/UserApi'

export const GetUserMeAction = createApiEnumAction('USER::ME::GET')

export const fetchUserMe = props =>
  createApiCallAction(GetUserMeAction, UserApi.getUserMe(), {
    props,
  })

export const PutUserMeAction = createApiEnumAction('USER::ME::PUT')

/**
 * Update user profile
 */
export const updateProfile = (user, props) =>
  createApiCallAction(PutUserMeAction, UserApi.putProfile(user), {
    user,
    userId: user.id,
    props,
  })

export const UserMeActions = {
  SET_USER: 'USER::ME::SET',
}

export const setUserMe = user =>
  createAction(UserMeActions.SET_USER, {
    user,
  })
