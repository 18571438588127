import React from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import { connect } from 'react-redux'

import { getKioskSettings } from '../actions'
import {
  isFetchingGetKioskSettings,
  getDataGetKioskSettings,
  getErrorGetKioskSettings,
} from '../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurant and pass it to the children
 *
 * Requirements:
 * - restaurantId
 *
 * see `withRestaurant`
 *
 */
class KioskSettingsContainer extends React.PureComponent {

  componentWillMount() {
    if (isNull(this.props.kioskSettings)) {
      this.loadKioskSettings(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (!props.isFetchingGetKioskSettings
      && nextProps.restaurantId !== props.restaurantId
      && ((!props.kioskSettings || props.kioskSettings.restaurantId) && nextProps.restaurantId)) {
      this.loadKioskSettings(nextProps)
    }
  }

  loadKioskSettings(props) {
    if (!isNil(props.restaurantId)) {
      props.getKioskSettings(
        props.restaurantId,
      )
    }
  }

  render() {
    const {
      children,
      isFetchingGetKioskSettings,
      kioskSettings,
      kioskSettingsError,
      ...otherProps
    } = this.props

    return children({
      isFetchingGetKioskSettings,
      kioskSettings,
      kioskSettingsError,
      ...otherProps
    })
  }
}

KioskSettingsContainer.propTypes = {
  children: PropTypes.func.isRequired,
  getKioskSettings: PropTypes.func.isRequired,
  isFetchingGetKioskSettings: PropTypes.bool,
  kioskSettings: PropTypes.object,
  kioskSettingsError: PropTypes.object,
}

const mapStateToProps = (state, props) => {
  return {
    kioskSettings: getDataGetKioskSettings(state, props),
    kioskSettingsError: getErrorGetKioskSettings(state, props),
    isFetchingGetKioskSettings: isFetchingGetKioskSettings(state, props),
  }
}

export default connect(mapStateToProps, {
  getKioskSettings,
})(KioskSettingsContainer)
