import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import OrderHeader from '../../../components/OrderHeader'
import BackTo from '../../../components/BackTo'

import CartFooter from './components/CartFooter'
import CartContent from './components/CartContent'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  content: {
    height: 'calc(100% - 275px)',
  },
  backToCatalogContainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(8),
  },
  iconBackContainer: {
    float: 'left',
    width: theme.spacing(5),
    height: theme.spacing(6),
    marginRight: theme.spacing(3),
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    borderRadius: 3,
  },
  iconBack: {
    width: '100%',
    height: '100%',
  },
  backToCatalogLabel: {
    height: theme.spacing(6),
    lineHeight: `${theme.spacing(6)}px`,
    color: theme.palette.primary.main,
  },
  cartRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
    height: 'calc(100% - 64px)', // theme spacing 8-^
  },
  title: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(6)}px`,
  },
})

const View = ({
  classes,
  frontAppConfig,
  isFetchingOrderPreview,
  itemList,
  kioskSettings,
  mode,
  onGoToCatalog,
  orderAppConfig,
  restaurant,
  route,
  totalCartPriceAndFees,
}) => (
  <div className={classes.root}>
    <OrderHeader
      frontAppConfig={frontAppConfig}
      restaurant={restaurant}
      kioskSettings={kioskSettings}
    />
    <div className={classes.content}>
      <BackTo
        text={tr('kiosk.order.back_to_catalog')}
        handleOnClick={onGoToCatalog}
        classes={{ root: classes.backToCatalogContainer }}
      />
      <div className={classes.cartRoot}>
        <TypographyCustom type="407" className={classes.title}>
          {tr('kiosk.order.page.cart.title')}
        </TypographyCustom>

        <CartContent fromRoute={route} restaurant={restaurant} itemList={itemList} />

        <CartFooter
          isEmpty={isEmpty(itemList)}
          isFetchingOrderPreview={isFetchingOrderPreview}
          itemList={itemList}
          mode={mode}
          orderAppConfig={orderAppConfig}
          restaurant={restaurant}
          totalCartPriceAndFees={totalCartPriceAndFees}
        />
      </div>
    </div>
  </div>
)

export default withStyles(styles)(View)
