import { getApiUrl } from 'pmt-modules/environment'
import { getBasicToken, getApiConsumer } from 'pmt-modules/authRestaurant'
import { getLocale } from 'pmt-modules/kiosk'

const getApiManagerOptions = state => ({
  apiUrl: getApiUrl(),

  headersMiddleware: () => {
    const headers = {
      'api-consumer': `Basic ${getApiConsumer(state)}`,
      'Authorization': getBasicToken(state),
      'Accept-Language': getLocale(state),
    }

    return headers
  },
})

export default getApiManagerOptions
