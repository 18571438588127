//
// Async payment
//
// -------------------------------------------------------------------------------------------------
// Mangopay
// -------
// For payments with 3D Secure you can use these cards: 3D Secure starts from 50€.
// Therefore in the Sandbox environment, all payments over 50€ must be processed with one of
// the following cards - please use the correct password shown below to avoid blocking the card:

// https://docs.mangopay.com/guide/testing-payments
// use challenge flow or frictionless flow card
// -------------------------------------------------------------------------------------------------

export * from './actions'
export * from './constants'
export * from './middlewares'
export * from './utils'
