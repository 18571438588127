import ApiEndpoints from '../config/ApiEndpoints'
import OrderErrors from '../errors/OrderErrors'

const OrderPreviewApi = {
  post: (
    orderPreview,
    {
      applyCartModifiers = true,
      getAdmissionFeesAmount = true,
      getMaxGrantableAmount = true,
      // verifyUserAccount = useful for catering
      // but we don't have any catering customer anymore
      // except BETC but they directly use the API
      // AND we have a bug with CashPad where the verification fails even if the message says
      // "Missing customer id or external_id, a new customer will be created on order push"
      // aka. not a blocking error, so it should not make the OrderPreview fails
      // (which is problematic now that we need the OrderPreview to be succeeded before we post the Order)
      // => desactivating the userAccount verification when OrderPreview is made from our UI
      verifyUserAccount = false,
      postOrderIfSucceeded = false,
    }
  ) => ({
    endpoint: ApiEndpoints.ORDER_PREVIEW,
    query: {
      applyCartModifiers,
      getAdmissionFeesAmount,
      getMaxGrantableAmount,
      verifyUserAccount,
      postOrderIfSucceeded,
    },
    type: 'POST',
    body: orderPreview,
    errorHandler: OrderErrors.POST,
  }),
}

export default OrderPreviewApi
