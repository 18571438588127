import isEmpty from 'lodash/isEmpty'

import createMiddleware from 'pmt-modules/redux/createMiddleware'
import { LOGOUT_ACTION } from 'pmt-modules/auth'
import { getItemListFromCart } from 'pmt-modules/cart'
import { showUpsellingDialog } from 'pmt-modules/catalog/actions'
import { removeItemsFromUpsellings } from 'pmt-modules/catalog/utils'
import { EventManager } from 'pmt-modules/event'
import { setLocale } from 'pmt-modules/i18n'
import { postOrder, PostOrderAction } from 'pmt-modules/orderPost'
import { resetOrderPreview } from 'pmt-modules/orderPreview'
import { setUserMe } from 'pmt-modules/userMe'
import { resetUserAccount } from 'pmt-modules/userAccount'
import { generateIdempotencyKey } from 'pmt-utils/idempotency'
import { OrderFrontAction, setIdempotencyKey } from './actions'

const logoutMiddleware = createMiddleware(LOGOUT_ACTION, ({ dispatch }) => {
  // user has logout
  // we reset current user fetched data
  dispatch(setUserMe(null))

  // reset order preview in order to reset subsidy / admission fees
  dispatch(resetOrderPreview())

  // reset fetched user account
  dispatch(resetUserAccount())
})

const postOrderMiddleware = createMiddleware(
  OrderFrontAction.POST_ORDER,
  ({ action, dispatch }) => {
    dispatch(postOrder(action.orderData, action.options))
  }
)

const displayUpsellingMiddleware = createMiddleware(
  OrderFrontAction.DISPLAY_UPSELLING,
  ({ action, dispatch, getState }) => {
    const cartItems = getItemListFromCart(getState())
    const upsellingCategory = removeItemsFromUpsellings(action.upsellingCategory, cartItems)

    if (!isEmpty(upsellingCategory.items)) {
      dispatch(showUpsellingDialog(upsellingCategory))
    }
  }
)

const postOrderSuccessMiddleware = createMiddleware(
  [PostOrderAction.SUCCESS],
  ({ action, dispatch, getState }) => {
    EventManager.dispatch(EventManager.Events.ON_PAYMENT_SUCCESS, {
      order: action.response,
    })
  }
)

const postOrderFailureMiddleware = createMiddleware(
  [PostOrderAction.FAILURE],
  ({ action, dispatch, getState }) => {
    // The API found an order with the same idempotency key.
    // The order is still processing, so we must wait.
    if (action.error?.errorCode === 'IDEMPOTENCY_PROCESSING') {
      // WAIT x seconds and retry post?
    }
    // The API found an order with the same idempotency key. This order is marked as failed.
    // So we must try a new order. A new order must have a new idempotency key.
    // but the data is not the same, so we must reload the idempotency key.
    // this happens when we have not reload the idempotency key on our side.
    if (action.error?.errorCode === 'INVALID_IDEMPOTENCY_DATA') {
      dispatch(setIdempotencyKey(generateIdempotencyKey()))
    }
  }
)

const setLocaleMiddleware = createMiddleware([OrderFrontAction.SET_LOCALE], ({ action }) => {
  setLocale(action.locale)
})

const resetOrderFrontMiddleware = createMiddleware([OrderFrontAction.RESET], ({ dispatch }) => {
  // reset order preview in order to reset sendDate + subsidy / admission fees
  dispatch(resetOrderPreview())
})

export const orderFrontMiddlewares = [
  logoutMiddleware,
  postOrderMiddleware,
  postOrderFailureMiddleware,
  displayUpsellingMiddleware,
  postOrderSuccessMiddleware,
  resetOrderFrontMiddleware,
  setLocaleMiddleware,
]
