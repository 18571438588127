import { createMiddleware } from '../redux'
import { getRoute, replaceWith, reloadTo } from '../routing'
import Logger from 'pmt-utils/logger'

import {
  saveApiConsumer,
  saveBasicToken,
  saveRestaurantId,
  removeApiConsumer,
  removeBasicToken,
  removeRestaurantId,
} from './utils'

import { AuthRestaurantAction, LOGOUT_RESTAURANT } from './actions'

/**
 * Intercepts LOGIN action and redirects to home screen if the login succeeded
 *
 * @returns {Function}
 */
const authRestaurantSuccessMiddleware = createMiddleware(
  AuthRestaurantAction.SUCCESS,
  ({ dispatch, next, action }) => {
    Logger.setRestaurantContext(Logger.RestaurantContext.RESTAURANT, action.data)

    saveBasicToken(action.data.authorization)
    saveApiConsumer(action.data.apiConsumer)
    saveRestaurantId(action.data.restaurantId)

    next(action) // send it to next so identity will be set

    return dispatch(replaceWith(getRoute('HOME')))
  }
)

const authLogoutMiddleware = createMiddleware(LOGOUT_RESTAURANT, ({ dispatch }) => {
  removeApiConsumer()
  removeBasicToken()
  removeRestaurantId()
  dispatch(reloadTo(getRoute('LOGIN')))
})

export const authRestaurantMiddlewares = [authRestaurantSuccessMiddleware, authLogoutMiddleware]
