import { createMuiTheme, dark, light, getLuminance } from 'pmt-ui/styles'

import grey from 'pmt-ui/colors/grey'
import lightGreen from 'pmt-ui/colors/lightGreen'
import orange from 'pmt-ui/colors/orange'
import red from 'pmt-ui/colors/red'

import { getQueryParam } from 'pmt-utils/url'

const PMTColors = {
  grey100: grey[100],
  grey500: grey[500],
  grey700: grey[700],
  lightGreen400: lightGreen[400],
  orange300: orange[300],
  red400: red[400],
  red800: red[800],
  white: '#fff',
  greyEF: '#efeff4',
  grey91: '#919ca7',
  grey4A: '#4a4a4a',
  grey26: '#262626',
  black: '#000',
}

const getPMTColor = color => PMTColors[color]

const getPMTColors = () => PMTColors

const muiTheme = createMuiTheme({
  palette: {
    type: getQueryParam('theme', window.location.uri) || 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#9dc212',
    },
    secondary: {
      main: '#00897B',
    },
    contrastThreshold: 0.5,
  },
  pmt: {
    appConfig: {}, // can be overwritten by api consumer theme settings
    colors: getPMTColors(),
    status: {
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      inactive: getPMTColor('grey500'),
      valid: getPMTColor('lightGreen400'),
    },
    bottomButtonBar: {
      height: 150,
    },
    circularProgress: {
      width: 50
    },
    // https://material.io/guidelines/components/buttons-floating-action-button.html#buttons-floating-action-button-floating-action-button
    floatingActionButton: {
      fromLeftEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
      fromBottomEdge: {
        default: 24,
        xs: 16,
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      },
    },
    loading: {
      color: grey[400],
    },
    // a set of css mixins
    mixins: {
      textEllipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    overlay: {
      background: 'rgba(255, 255, 255, .6)',
      backgroundBlack: 'rgba(0, 0, 0, .7)',
    },
    typography: {
      fonts: {
        '107': {
          style: {
            fontSize: 10,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '164': {
          style: {
            fontSize: 16,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '167': {
          style: {
            fontSize: 16,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '204': {
          style: {
            fontSize: 20,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '207': {
          style: {
            fontSize: 20,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '207i': {
          style: {
            fontSize: 20,
            fontStyle: 'italic',
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '244': {
          style: {
            fontSize: 24,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '247': {
          style: {
            fontSize: 24,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '284': {
          style: {
            fontSize: 28,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '287': {
          style: {
            fontSize: 28,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '324': {
          style: {
            fontSize: 32,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '327': {
          style: {
            fontSize: 32,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '364': {
          style: {
            fontSize: 36,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '367': {
          style: {
            fontSize: 36,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '407': {
          style: {
            fontSize: 40,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '404': {
          style: {
            fontSize: 40,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '484': {
          style: {
            fontSize: 48,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '487': {
          style: {
            fontSize: 48,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '604': {
          style: {
            fontSize: 60,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '607': {
          style: {
            fontSize: 60,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '804': {
          style: {
            fontSize: 80,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
        '807': {
          style: {
            fontSize: 80,
            fontWeight: 700,
            margin: 0,
            lineHeight: 1,
          },
        },
        '2004': {
          style: {
            fontSize: 200,
            fontWeight: 400,
            margin: 0,
            lineHeight: 1,
          },
        },
      }
    }
  }
})

const theme = {
  muiTheme,
}

// https://github.com/callemall/material-ui/issues/8183
// override getContrastText function to have a white color on green buttons
// TODO : check the evolution of the issue above
theme.muiTheme.palette.getContrastText = color => {
  if (getLuminance(color) <= theme.muiTheme.palette.contrastThreshold) {
    return dark.text.primary
  } else {
    return light.text.primary
  }
}

export default theme
