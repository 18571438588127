import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Delivery = props => (
  <SvgIcon {...props}>
    <g
      id="coffee-beans"
      transform="translate(12.500000, 12.500000) scale(-1, 1) translate(-12.500000, -12.500000) translate(7.000000, 6.000000)"
      fillRule="nonzero"
    >
      <path
        d="M9.49184963,0.277264013 C9.47831848,0.24189272 9.43730159,0.185714286 9.35,0.185714286 C7.7124669,0.185714286 5.20216248,0.185714286 1.81908673,0.185714286 C1.79333063,0.185714286 1.72652795,0.207315216 1.69625051,0.247364804 C1.54264567,0.450546129 0,4.39922684 0,4.45714286 L0,12.7460937 C0,12.8863262 0.117254762,13 0.261904762,13 C0.36297381,13 10.4840214,13 10.7380952,13 C10.8827452,13 11,12.8863262 11,12.7460937 L11,4.39257812 C11,4.33651562 11,4.21966797 9.49184963,0.277264013 Z M8.9047619,12.4921875 L7.85714286,12.4921875 L7.85714286,4.47905859 L8.9047619,1.48571429 L8.9047619,12.4921875 Z M10.4761905,12.4921875 L9.42857143,12.4921875 L9.42857143,1.48571429 L10.4761905,4.4790332 L10.4761905,12.4921875 Z M8.61666667,0.742857143 L7.33333333,4.45714286 L7.33333333,12.4892857 L0.5225,12.4892857 L0.5225,4.45714286 L2.01666667,0.742857143 L8.61666667,0.742857143 Z"
        id="Shape"
      />
      <path
        d="M1.65,7.98571429 C2.82212485,7.98571429 4.83884457,7.98571429 6.05,7.98571429 C6.11111111,7.98571429 6.14166667,8.01666667 6.14166667,8.07857143 L6.14166667,10.6785714 C6.14166667,10.7404762 6.11111111,10.7714286 6.05,10.7714286 C4.83697325,10.7714286 2.81475393,10.7714286 1.65,10.7714286 C1.56256615,10.7696572 1.56016595,10.7324075 1.55833333,10.6785714 C1.55833333,9.81190476 1.55833333,8.9452381 1.55833333,8.07857143 C1.56115521,8.01784759 1.59171077,7.98689521 1.65,7.98571429 Z M1.925,8.35714286 L1.925,10.4 L5.775,10.4 L5.775,8.35714286 L1.925,8.35714286 Z"
        id="Combined-Shape"
      />
      <path
        d="M3.44880952,1.57857143 L5.62619048,1.57857143 C6.11338452,1.57857143 6.50833333,1.97352024 6.50833333,2.46071429 C6.50833333,2.94790833 6.11338452,3.34285714 5.62619048,3.34285714 L3.44880952,3.34285714 C2.96161548,3.34285714 2.56666667,2.94790833 2.56666667,2.46071429 C2.56666667,1.97352024 2.96161548,1.57857143 3.44880952,1.57857143 Z M3.49047619,1.91378571 C3.18277469,1.91378571 2.93333333,2.16322707 2.93333333,2.47092857 C2.93333333,2.77863007 3.18277469,3.02807143 3.49047619,3.02807143 L5.58452381,3.02807143 C5.89222531,3.02807143 6.14166667,2.77863007 6.14166667,2.47092857 C6.14166667,2.16322707 5.89222531,1.91378571 5.58452381,1.91378571 L3.49047619,1.91378571 Z"
        id="Combined-Shape"
      />
    </g>
  </SvgIcon>
)
Delivery = pure(Delivery)
Delivery.displayName = 'Delivery'
Delivery.muiName = 'SvgIcon'

export default Delivery
