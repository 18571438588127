import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let CardKo = props => (
  <SvgIcon {...props}>
    <g id="credit-card_2" transform="translate(0.000000, 4.000000)" fillRule="nonzero">
      <path
        d="M21.5000576,0 L2.49998926,0 C1.12200219,0 0,1.12199781 0,2.50002637 L0,13.5000205 C0,14.8780022 1.12200219,16 2.49998926,16 L21.5000107,16 C22.8779978,16 24,14.8780022 24,13.5000205 L24,2.50002637 C24.0000469,1.12199781 22.8780447,0 21.5000576,0 Z M23.0000605,13.4999736 C23.0000605,14.3269876 22.3270749,14.9999707 21.5000576,14.9999707 L2.49998926,14.9999707 C1.67297202,14.9999707 0.999986328,14.3269876 0.999986328,13.4999736 L0.999986328,2.50002637 C0.999986328,1.67301236 1.67297202,1.0000293 2.49998926,1.0000293 L21.5000107,1.0000293 C22.327028,1.0000293 23.0000137,1.67301236 23.0000137,2.50002637 L23.0000137,13.4999736 L23.0000605,13.4999736 Z"
        id="Shape"
      />
      <path
        d="M23.4999385,2.89156627 L0.500014648,2.89156627 C0.224015187,2.89156627 0,3.1182791 0,3.39760222 L0,6.43372308 C0,6.7130462 0.224015187,6.93975904 0.500014648,6.93975904 L23.4999854,6.93975904 C23.7759848,6.93975904 24,6.7130462 24,6.43372308 L24,3.39760222 C23.9999531,3.1182791 23.7759379,2.89156627 23.4999385,2.89156627 Z M22.9999707,5.92768712 L0.999982422,5.92768712 L0.999982422,3.90359074 L22.9999707,3.90359074 L22.9999707,5.92768712 Z"
        id="Shape"
      />
      <path
        d="M16.8725683,11.1301635 C16.9322189,11.1895861 17.0104491,11.2192771 17.0886386,11.2192771 C17.1668281,11.2192771 17.2450583,11.1895455 17.3047496,11.1301635 L17.5784332,10.8577118 L17.8521167,11.1301635 C17.911808,11.1895861 17.9899975,11.2192771 18.0682277,11.2192771 C18.1464172,11.2192771 18.2246474,11.1895455 18.284298,11.1301635 C18.4036806,11.0113588 18.4036806,10.8187728 18.2843387,10.6999276 L18.0106552,10.4274353 L18.2843387,10.1549431 C18.4036806,10.0361385 18.4036806,9.84351187 18.284298,9.72470721 C18.1649969,9.60586199 17.9714993,9.60590255 17.8521575,9.72470721 L17.5784739,9.99715886 L17.3047903,9.72470721 C17.1854485,9.60590255 16.9919509,9.60590255 16.8726091,9.72470721 C16.7532265,9.84351187 16.7532265,10.0360979 16.8725683,10.1549431 L17.1462519,10.4274353 L16.8725683,10.6999276 C16.7531857,10.8187728 16.7531857,11.0113588 16.8725683,11.1301635 Z"
        id="Shape"
      />
      <path
        d="M14.3968184,9.58846047 L14.3968184,9.15482034 C14.3968184,8.98302336 14.2188069,8.84374421 13.9991162,8.84374421 C13.7794255,8.84374421 13.6014141,8.98302336 13.6014141,9.15482034 L13.6014141,9.58846047 C13.6014141,9.76025745 13.7794255,9.89953661 13.9991162,9.89953661 C14.2188069,9.89953661 14.3968184,9.76025745 14.3968184,9.58846047 Z"
        id="Shape"
      />
      <path
        d="M5.73968528,13.0248254 C5.80125632,13.0860814 5.88195011,13.1167513 5.96260187,13.1167513 C6.04325363,13.1167513 6.12394741,13.0861233 6.18551845,13.0248254 L6.46782062,12.7437756 L6.75012279,13.0248254 L7.03246699,13.305917 L6.75012279,13.5870086 C6.62702274,13.7095626 6.62702274,13.9082682 6.75012279,14.0307803 C6.81169383,14.0920363 6.89238762,14.1227062 6.97303938,14.1227062 C7.05369114,14.1227062 7.13438492,14.0920782 7.19595596,14.0307803 L7.47825813,13.7497305 L7.7605603,14.0307803 C7.82213134,14.0920363 7.90282513,14.1227062 7.98347689,14.1227062 C8.06412865,14.1227062 8.14482244,14.0920782 8.20639348,14.0307803 C8.32949353,13.9082263 8.32949353,13.7095208 8.20639348,13.5870086 L7.92404928,13.305917 L8.20639348,13.0248254 C8.32949353,12.9022714 8.32949353,12.7035658 8.20639348,12.5810537 C8.08325139,12.4584998 7.88370238,12.4584998 7.76060233,12.5810537 L7.47830016,12.8621035 L7.19599799,12.5810537 L6.91369582,12.2999621 L7.19599799,12.0189123 C7.31909805,11.8963584 7.31909805,11.6976528 7.19599799,11.5751407 C7.07285591,11.4525867 6.8733069,11.4525867 6.75020685,11.5751407 L6.46790468,11.8561905 L6.18560251,11.5751407 C6.06246043,11.4525867 5.86291142,11.4525867 5.73976934,11.5751407 C5.61666929,11.6976946 5.61666929,11.8964002 5.73976934,12.0189123 L6.02207151,12.2999621 L5.73976934,12.5810537 C5.61658523,12.7036077 5.61658523,12.9023133 5.73968528,13.0248254 Z"
        id="Shape"
      />
      <path
        d="M9.6243924,9.58846047 L9.6243924,9.15482034 C9.6243924,8.98302336 9.44638091,8.84374421 9.22669023,8.84374421 C9.00699956,8.84374421 8.82898807,8.98302336 8.82898807,9.15482034 L8.82898807,9.58846047 C8.82898807,9.76025745 9.00699956,9.89953661 9.22669023,9.89953661 C9.44632788,9.89953661 9.6243924,9.76025745 9.6243924,9.58846047 Z"
        id="Shape"
      />
      <path
        d="M11.903216,10.2346617 C12.128876,10.2346617 12.3435057,10.1365667 12.4920324,9.96554433 C12.5640341,9.88266138 12.5554373,9.75687681 12.472802,9.68461307 C12.3901931,9.61232278 12.2649175,9.62100398 12.1928893,9.70388694 C12.1197502,9.78812384 12.0141547,9.83644126 11.903216,9.83644126 C11.7922772,9.83644126 11.6866818,9.78812384 11.6135426,9.70388694 C11.5415144,9.62097744 11.4161859,9.61234933 11.33363,9.68461307 C11.2510211,9.75685026 11.2424243,9.88263483 11.3143995,9.96554433 C11.4629262,10.1365933 11.6775559,10.2346617 11.903216,10.2346617 Z"
        id="Shape"
        transform="translate(11.903226, 9.935125) scale(1, -1) translate(-11.903226, -9.935125) "
      />
    </g>
  </SvgIcon>
)
CardKo = pure(CardKo)
CardKo.displayName = 'CardKo'
CardKo.pmtName = 'SvgIcon'

export default CardKo
