import { createSimpleReducer } from '../redux'

import { GetKioskSettingsAction, RefreshKioskSettingsAction } from './actions'

export * from './actions'
export * from './selectors'

export const kioskSettingsReducer = createSimpleReducer(GetKioskSettingsAction, (state, action) => {
  switch (action.type) {
    case RefreshKioskSettingsAction.REQUEST:
      return state.set('isFetching', true)

    case RefreshKioskSettingsAction.SUCCESS:
      return state.merge({
        data: action.response,
        isFetching: false,
        error: null,
      })

    default:
      return state
  }
})
