import { KIOSK_CALL } from '../constants'

/**
 * Create an action for a kiosk call that will be handled by runKioskCallMiddleware
 *
 * @param  {Object} typeEnum    the enum that define the action. Create with `createKioskEnumAction`
 * @param  {Object} func        the func data. Ex: `getKiosk().isConnected`
 * @param  {Object} [data=null] data to attach to all the actions. Ex: { restaurantId }
 *
 * @return {KioskAction}        KioskAction
 */
const createKioskCallAction = (typeEnum, func, data = null) => {
  return {
    type: KIOSK_CALL,
    types: {
      request: typeEnum.REQUEST,
      success: typeEnum.SUCCESS,
      failure: typeEnum.FAILURE,
    },
    func,
    data,
  }
}

export default createKioskCallAction
