import createAction from '../redux/createAction'

import { generateUUID } from 'pmt-utils/uuid'

import { getKiosk } from './config'
import { createKioskCallAction, createKioskEnumAction } from './utils'

/**
 * Verify if the kiosk is correctly initialized
 */
export const KioskConnectivtyAction = createKioskEnumAction('KIOSK::GET::CONNECTIVITY')

export const fetchKioskConnectivity = () =>
  createKioskCallAction(KioskConnectivtyAction, getKiosk().isConnected)

/**
 * Register app on the local network
 * Once registered we can send/receive message from other app installed on kiosk
 */
export const KioskRegistrationAction = createKioskEnumAction('KIOSK::GET::REGISTRATION')

export const fetchKioskRegistration = () =>
  createKioskCallAction(KioskRegistrationAction, getKiosk().register)

/**
 * Send print command with specified content
 * e.g.: used to print payment order/deposit ticket
 */
export const KioskPrintAction = createKioskEnumAction('KIOSK::PRINT')

export const printContent = content =>
  createKioskCallAction(KioskPrintAction, getKiosk().print, content)

/**
 * Retrieve kiosk peripherals status
 * Used to check if every peripherals are connected and available
 */
export const KioskGetPeripheralsStatusAction = createKioskEnumAction('KIOSK::GET::PERIPHERALS')

export const getKioskPeripheralsStatus = () =>
  createKioskCallAction(KioskGetPeripheralsStatusAction, getKiosk().getPeripheralsStatus)

/**
 * Interact barcode/QR code scanner
 * Once scan start has been dispatched, kiosk will be listening to new scan and then dispatch a
 * receivedData action
 * scanStop deactivate scanner listening
 */
export const KioskScanAction = {
  START: 'KIOSK::SCANNER::START',
  STOP: 'KIOSK::SCANNER::STOP',
  RECEIVED_DATA: 'KIOSK::SCANNER::RECEIVED_DATA',
}

export const scanStart = () => createAction(KioskScanAction.START, {})

export const scanStop = () => createAction(KioskScanAction.STOP, {})

export const scanRececivedData = data =>
  createAction(KioskScanAction.RECEIVED_DATA, {
    data,
  })

/**
 * Interact NFC reader
 * Once read start has been dispatched, kiosk will be reading
 * readData action
 * nfcStop deactivate nfc reader listening
 */
export const KioskNfcActions = {
  START: 'KIOSK::NFC::START',
  STOP: 'KIOSK::NFC::STOP',
  READ_DATA: 'KIOSK::NFC::READ_DATA',
  FAILURE: 'KIOSK::NFC::FAILURE',
}

export const nfcStart = () => createAction(KioskNfcActions.START, {})

export const nfcStop = () => createAction(KioskNfcActions.STOP, {})

export const nfcReadData = data => createAction(KioskNfcActions.READ_DATA, { data })

export const nfcFailure = error => createAction(KioskNfcActions.FAILURE, { error })

/**
 * Sharing allow kiosk apps to communicate between each others
 * Once listen start has been dispatched, kiosk will be listening to new message and then dispatch a
 * received action depending on the message content
 *   - status tells if the reader is available and confirms communication is working
 *   - heartbeat allows us to know if everything is still working on regular basis
 *   - badge is the content read by the magnetic card reader and identify users
 */
export const KioskMessagesAction = {
  LISTEN: 'KIOSK::SHARING::LISTEN',
  START_LISTEN_BADGE_ID: 'KIOSK::SHARING::START_LISTEN_BADGE_ID',
  STOP_LISTEN_BADGE_ID: 'KIOSK::SHARING::STOP_LISTEN_BADGE_ID',
  RECEIVED_BADGE: 'KIOSK::SHARING::RECEIVED_BADGE',
  RECEIVED_HEARTBEAT: 'KIOSK::SHARING::RECEIVED_HEARTBEAT',
  RECEIVED_STATUS: 'KIOSK::SHARING::RECEIVED_STATUS',
}

export const sharingListenMessages = () => createAction(KioskMessagesAction.LISTEN, {})

export const sharingListenMessageBadgeIdStart = () =>
  createAction(KioskMessagesAction.START_LISTEN_BADGE_ID, {})

export const sharingListenMessageBadgeIdStop = () =>
  createAction(KioskMessagesAction.STOP_LISTEN_BADGE_ID, {})

export const sharingRececivedBadge = data =>
  createAction(KioskMessagesAction.RECEIVED_BADGE, {
    data,
  })

export const sharingRececivedHeartbeat = data =>
  createAction(KioskMessagesAction.RECEIVED_HEARTBEAT, {
    data,
  })
export const sharingRececivedStatus = data =>
  createAction(KioskMessagesAction.RECEIVED_STATUS, {
    data,
  })

/**
 * Perform a payment through kiosk
 */
export const KioskPaymentAction = {
  REQUEST: 'KIOSK:PAYMENT:REQUEST',
  SUCCESS: 'KIOSK:PAYMENT:SUCCESS',
  FAILURE: 'KIOSK:PAYMENT:FAILURE',
  RESET: 'KIOSK:PAYMENT:RESET',
}

/**
 * options is used to pass different data through action lifecycle
 *   - id: autogenerated identifier for each payment
 *   - isTopUp: to know whether payment is made for a top up or not
 *   - restaurant: restaurant for which payment is made
 *   - topUp: top up object
 *   - userAccount: userAccount which will be used to proceed to payment
 *   - redirectTo: Route that will be used to redirect on success
 *   - redirectToParams: optional params to generate route that will be used for redirect
 */
export const sendPayment = (amount, options) =>
  createAction(KioskPaymentAction.REQUEST, {
    amount,
    options: {
      id: generateUUID(),
      ...options,
    },
  })

export const paymentSuccess = (data, options) =>
  createAction(KioskPaymentAction.SUCCESS, {
    data,
    options,
  })

export const paymentFailure = error =>
  createAction(KioskPaymentAction.FAILURE, {
    error,
  })

export const resetPayment = () => createAction(KioskPaymentAction.RESET)
