import moment from 'moment'

import { authRestaurantReducer } from 'pmt-modules/authRestaurant'
import { appConfigReducer } from 'pmt-modules/appConfig'
import { cartReducer } from 'pmt-modules/cart'
import { cartDiffReducer } from 'pmt-modules/cartDiff'
import { catalogReducer } from 'pmt-modules/catalog'
import { frontFlowReducer } from 'pmt-modules/frontFlow'
import { i18nReducer } from 'pmt-modules/i18n'
import { kioskReducer } from 'pmt-modules/kiosk'
import { kioskInteractorReducer } from 'pmt-modules/kioskInteractor'
import { kioskSettingsReducer } from 'pmt-modules/kioskSettings'
import { orderFrontReducer } from 'pmt-modules/orderFront'
import { orderMenuReducer } from 'pmt-modules/orderMenu'
import { orderPostReducer } from 'pmt-modules/orderPost'
import { orderPreviewReducer } from 'pmt-modules/orderPreview'
import { orderProductReducer } from 'pmt-modules/orderProduct'
import { orderSettingsReducer } from 'pmt-modules/orderSettings'
import { checkReducer, paymentReducer, postPaymentReducer, postPaymentEmailReducer } from 'pmt-modules/payment'
import { restaurantReducer } from 'pmt-modules/restaurant'
import { restaurantUserAccountsReducer } from 'pmt-modules/restaurantUserAccount'
import { suggestionReducer } from 'pmt-modules/suggestion'
import { topUpReducer } from 'pmt-modules/topUp'


const persistExpiresAt = moment().add(60, 'minutes')
const persistOptions = { persist: true, persistExpiresAt }
cartReducer.options = { ...persistOptions }
frontFlowReducer.options = { ...persistOptions }
kioskReducer.options = { ...persistOptions }
paymentReducer.options = { ...persistOptions }
orderFrontReducer.options = { ...persistOptions }

export default {
  appConfig: appConfigReducer,
  authRestaurant: authRestaurantReducer,
  cart: cartReducer,
  cartDiff: cartDiffReducer,
  catalog: catalogReducer,
  check: checkReducer,
  frontFlow: frontFlowReducer,
  i18n: i18nReducer,
  kiosk: kioskReducer,
  kioskInteractor: kioskInteractorReducer,
  kioskSettings: kioskSettingsReducer,
  orderFront: orderFrontReducer,
  orderMenu: orderMenuReducer,
  orderPost: orderPostReducer,
  orderPreview: orderPreviewReducer,
  orderProduct: orderProductReducer,
  orderSettings: orderSettingsReducer,
  payment: paymentReducer,
  postPayment: postPaymentReducer,
  postPaymentEmail: postPaymentEmailReducer,
  restaurant: restaurantReducer,
  restaurantUserAccounts: restaurantUserAccountsReducer,
  suggestion: suggestionReducer,
  topUp: topUpReducer,
}
