import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import CheckContainer from 'pmt-modules/payment/components/CheckContainer'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import BackHome from '../components/BackHome'
import CheckContent from './components/CheckContent'
import CheckFooter from './components/CheckFooter'
import CheckError from './components/CheckError'

const styles = theme => ({
  root: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    maxWidth: 230,
    maxHeight: 230,
  },
  logoIsFetching: {
    marginTop: theme.spacing(9),
  },
  loading: {
    position: 'absolute',
    top: '50%',
    marginTop: '-136px',
    width: '100%',
  },
  loadingText: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  caption: {
    padding: `0 ${theme.spacing(12)}px`,
    whiteSpace: 'pre-line',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
  },
})

const View = ({ classes, code, frontAppConfig, onScanToMerge, restaurant, ui, updateUI }) => (
  <div className={classes.root}>
    <CheckContainer restaurantId={restaurant.id} code={code} forceReload>
      {({ check, checkError, isFetchingCheck }) => {
        const hasError =
          (!check && checkError) ||
          (check && (isEmpty(check.entries) || check.outstandingBalance === 0))

        return (
          <Fragment>
            {!isFetchingCheck && <BackHome />}

            {frontAppConfig.theme.logo.display && (
              <div className={isFetchingCheck ? classes.logoIsFetching : null}>
                <img
                  src={frontAppConfig.theme.logo.path || restaurant.logo}
                  className={classes.logo}
                  alt="logo"
                />
              </div>
            )}

            {check &&
              !hasError && (
                <CustomTextsContainer>
                  {({ texts }) =>
                    !isNil(texts) &&
                    !isNil(texts.KIOSK__CHECKOUT__CHECK__CAPTION) && (
                      <TypographyCustom type="407" align="center" className={classes.caption}>
                        {texts.KIOSK__CHECKOUT__CHECK__CAPTION}
                      </TypographyCustom>
                    )
                  }
                </CustomTextsContainer>
              )}

            {isFetchingCheck && !check ? (
              <div className={classes.loading}>
                <LoadingBlock show size={200} />
                <TypographyCustom type="247" className={classes.loadingText} align="center">
                  {tr('kiosk.checkout.page.scan.loading')}
                </TypographyCustom>
              </div>
            ) : (
              <div className={classes.content}>
                {hasError && <CheckError check={check} />}

                {check &&
                  !hasError && (
                    <Fragment>
                      <CheckContent
                        check={check}
                        checkError={checkError}
                        isFetchingCheck={isFetchingCheck}
                        ui={ui}
                        updateUI={updateUI}
                      />
                      <CheckFooter
                        check={check}
                        isFetchingCheck={isFetchingCheck}
                        onScanToMerge={onScanToMerge}
                        ui={ui}
                      />
                    </Fragment>
                  )}
              </div>
            )}
          </Fragment>
        )
      }}
    </CheckContainer>
  </div>
)

export default withStyles(styles)(View)
