import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getCustomTexts } from '../actions'
import { isFetchingCustomTexts, getCustomTextsData, getCustomTextsError } from '../selectors'

import { getAppTexts } from 'pmt-modules/appConfig/selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the custom texts container and pass it to the children
 *
 * Requirements:
 * - containerId
 *
 */
class CustomTextsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.loadCustomTextsContainer(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customTextsContainerId !== this.props.customTextsContainerId) {
      this.loadCustomTextsContainer(nextProps)
    }
  }

  loadCustomTextsContainer(props) {
    if (!props.isFetchingCustomTexts && props.customTextsContainerId) {
      props.fetchCustomTextsContainer(props.customTextsContainerId)
    }
  }

  render() {
    const {
      customTexts,
      customTextsError,
      isFetchingCustomTexts,
      appConfigCustomTexts,
      children,
    } = this.props

    return children({
      customTexts,
      customTextsError,
      isFetchingCustomTexts,

      // custom texts loaded from app config
      texts: {
        ...appConfigCustomTexts,
        ...(customTexts?.texts || {}),
      },
    })
  }
}

CustomTextsContainer.defaultProps = {
  customTextsContainerId: null,
}

CustomTextsContainer.propTypes = {
  customTextsContainerId: PropTypes.number,
}

const mapStateToProps = state => ({
  isFetchingCustomTexts: isFetchingCustomTexts(state),
  customTexts: getCustomTextsData(state),
  customTextsError: getCustomTextsError(state),
  appConfigCustomTexts: getAppTexts(state),
})

export default connect(
  mapStateToProps,
  { getCustomTexts }
)(CustomTextsContainer)
