import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import Typography from 'pmt-ui/Typography'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  amountFormatted: {
    marginTop: theme.spacing(5),
  },
})

const View = ({ classes, isFetchingUserAccounts, userAccount, balanceLabel }) => (
  <div>
    <Typography variant="h2">{balanceLabel}</Typography>

    {isFetchingUserAccounts ? (
      <LoadingBlock show />
    ) : (
      !isEmpty(userAccount) && (
        <Typography variant="h1" className={classes.amountFormatted}>
          {userAccount.amountFormatted}
        </Typography>
      )
    )}

    {!isFetchingUserAccounts &&
      isEmpty(userAccount) && (
        <ErrorBlock
          mode={ErrorBlock.Mode.SNACKBAR}
          error={{
            message: tr('global.user_account.error', {
              context: '',
              desc: 'Global error displayed when we were unable to retrieve user account',
            }),
          }}
        />
      )}
  </div>
)

export default withStyles(styles)(View)
