import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'

import { goToAnchor } from 'pmt-ui/ScrollableAnchor'
import { withStyles } from 'pmt-ui/styles'

import PartView from './PartView'

const styles = theme => ({
  root: {
    overflowY: 'scroll',
    width: 'calc(100% + 17px)',
    scrollBehavior: 'smooth',
  },
})

class PartsView extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!props.viewOptions.isEditMode) {
      props.updateUI({
        openedPart: props.parts[0].id,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.ui.openedPart !== prevProps.ui.openedPart) {
      goToAnchor(this.props.ui.openedPart, false)
    }
  }

  render() {
    const {
      parts,
      category,
      restaurant,
      onSelectCategory,
      onReturnToPreviousPartCategory,
      onSelectOrderProduct,
      onUnselectOrderProduct,
      onAddToCartFromShortcut,
      ui,
      updateUI,
      classes,
    } = this.props

    return (
      <div className={classes.root}>
        {parts.map((part, index) => (
          <PartView
            key={index}
            uiParent={ui}
            updateUIParent={updateUI}
            part={part}
            category={category}
            restaurant={restaurant}
            isOpen={ui.openedPart === part.id}
            onReturnToPreviousPartCategory={onReturnToPreviousPartCategory}
            onSelectCategory={onSelectCategory}
            onSelectOrderProduct={onSelectOrderProduct}
            onUnselectOrderProduct={onUnselectOrderProduct}
            onAddToCartFromShortcut={onAddToCartFromShortcut}
          />
        ))}
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      openedPart: null,
    },
  }),
  withStyles(styles)
)(PartsView)
