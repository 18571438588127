import transformOrderItemForApi from './transformOrderItemForApi'
import transformOrderProductForApi from './transformOrderProductForApi'

const transformOrderProductsForApi = products =>
  products
  // keep only valid products
  // use quantity and not `isValid` since a product can be valid, but not chosen 
  // (product withou options)
  .filter(product => product.quantity > 0)
  .map(product => transformOrderProductForApi(product, {
    isMenuMode: true,
  }))

const transformOrderMenuPartForApi = part => ({
  id: part.id,
  name: part.name,
  products: transformOrderProductsForApi(part.products),
})

const transformOrderMenuPartsForApi = parts =>
  parts.map(part => transformOrderMenuPartForApi(part))

const transformOrderMenuForApi = orderMenu => ({
  ...transformOrderItemForApi(orderMenu),
  parts: transformOrderMenuPartsForApi(orderMenu.parts || []),
})

export default transformOrderMenuForApi
