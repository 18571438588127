import React from 'react'
import isNil from 'lodash/isNil'

import { getPublicUrl } from 'pmt-modules/environment'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import './animatedCheckmark.scss'

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    position: 'relative',
  },
  entries: {
    overflow: 'auto',
  },
  entry: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    alignItems: 'flex-start',
  },
  quantity: {
    marginRight: theme.spacing(7),
  },
  name: {
    flex: 1,
    margin: `0 ${theme.spacing(4)}px`,
  },
  img: {
    zIndex: 1,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    opacity: 0.5,
    background: theme.pmt.colors.greyEF,
    width: '100%',
    height: '100%',
  },
})

class CheckContent extends React.Component {
  componentWillReceiveProps(nextProps) {
    const updateUI = this.props.updateUI

    if (this.props.isFetchingCheck) {
      updateUI({
        isScanning: false,
      })
    }

    if (this.props.isFetchingCheck && !nextProps.isFetchingCheck) {
      updateUI({
        isSuccess: isNil(nextProps.checkError),
      })
      setTimeout(() => {
        updateUI({
          isSuccess: null,
        })
      }, 2000)
    }
  }

  render() {
    const { classes, check, isFetchingCheck, ui } = this.props

    return (
      <div className={classes.root}>
        {isFetchingCheck && <LoadingBlockWrapper show />}
        {ui.isScanning && (
          <div className={classes.absolute}>
            <div className={classes.overlay} />
            <img src={getPublicUrl() + '/img/scan_rfid.png'} alt="scan rfid" className={classes.img} />
          </div>
        )}
        {ui.isSuccess && (
          <div className={classes.absolute}>
            <div className={classes.overlay} />
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
          </div>
        )}
        <div className={classes.entries}>
          {check.entries.filter(entry => entry.price).map(entry => (
            <div key={entry.id} className={classes.entry}>
              <TypographyCustom type="284" className={classes.quantity}>
                x{entry.quantity}
              </TypographyCustom>
              <TypographyCustom type="284" className={classes.name}>
                {entry.name}
              </TypographyCustom>
              <TypographyCustom type="364" className={classes.price}>
                <Price value={entry.priceWithCurrencyFormatted} />
              </TypographyCustom>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(CheckContent)
