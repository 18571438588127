import isNil from 'lodash/isNil'

import Logger from 'pmt-utils/logger'

import { createMiddleware } from '../redux'

import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { FlowTypes, getFlowType } from 'pmt-modules/frontFlow'
import { fetchRestaurantUserAccounts } from 'pmt-modules/restaurantUserAccount'
import { getRoute } from 'pmt-modules/routing'
import { postTopUp } from 'pmt-modules/topUp/makeTopUp/actions'
import TopUpTicketPrinter from 'pmt-modules/kioskInteractor/utils/printer/TopUpTicket'

import {
  KioskMessagesAction,
  KioskNfcActions,
  KioskPaymentAction,
  KioskScanAction,
  nfcFailure,
  nfcReadData,
  paymentSuccess,
  paymentFailure,
  printContent,
  scanRececivedData,
  sharingRececivedBadge,
  sharingRececivedHeartbeat,
  sharingRececivedStatus,
} from './actions'

import { getKiosk } from './config'
import { KIOSK_CALL, MessageType } from './constants'
import { isListeningSharingMessageBadgeId } from './selectors'

/**
 * Global middleware to handle kiosk actions
 * we first call the action function
 * then we next the request in order to replace our current action by the request action.
 * We make the api call and dispatch the success / failure action.
 * It handle actions created via createKioskCallAction
 */
const runKioskCallMiddleware = createMiddleware(KIOSK_CALL, ({ dispatch, next, action }) => {
  action
    .func(action.data)
    .then(
      response => {
        dispatch({
          type: action.types.success,
          data: action.data,
          response,
        })
      },
      error => {
        dispatch({
          type: action.types.failure,
          data: action.data,
          error,
        })
      }
    )
    .catch(exception => {
      dispatch({
        type: action.types.failure,
        data: action.data,
        exception,
      })
    })

  return next({
    type: action.types.request,
    data: action.data,
  })
})

const paymentSendMiddleware = createMiddleware(
  KioskPaymentAction.REQUEST,
  ({ getState, dispatch, action }) => {
    getKiosk()
      .pay(action.amount, action.options)
      .then(
        data => {
          Logger.info('PAYMENT', 'payment success', { data, action })
          dispatch(paymentSuccess(data, action.options))
        },
        data => {
          Logger.warn('PAYMENT', 'payment failed', { data, action, e: new Error().stack })
          dispatch(paymentFailure(data))
        }
      )
      .catch(e => {
        Logger.logException(e)
      })
  }
)

/* TODO: move to kioskTopUp middlewares when module will be created*/
const paymentSuccessMiddleware = createMiddleware(
  KioskPaymentAction.SUCCESS,
  ({ getState, dispatch, action }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.TOPUP) {
      const { restaurant, topUp, userAccount, redirectTo, redirectToParams } = action.options

      const printer = new TopUpTicketPrinter()
      const content = printer.format(restaurant, topUp.amount, userAccount, action.options)

      dispatch(printContent(content))

      let options = {}
      if (!isNil(redirectTo)) {
        options = {
          redirectTo,
          redirectToParams: redirectToParams || {},
        }
      }

      dispatch(postTopUp(userAccount, userAccount.userId, topUp, options))
    }
  }
)

const scanStartMiddleware = createMiddleware(KioskScanAction.START, ({ dispatch }) => {
  getKiosk()
    .scan()
    .then(
      data => {
        dispatch(scanRececivedData(data))
      },
      () => {
        // do nothing
      }
    )
    .catch(e => Logger.logException(e))
})

const scanStopMiddleware = createMiddleware(KioskScanAction.STOP, () => {
  getKiosk().scanStop()
})

const scanRececivedDataMiddleware = createMiddleware(
  KioskScanAction.RECEIVED_DATA,
  ({ getState, dispatch, action }) => {
    const flowType = getFlowType(getState())
    if (flowType === FlowTypes.TOPUP) {
      dispatch(
        fetchRestaurantUserAccounts(
          getRestaurantId(getState()),
          {
            token: action.data.scannedData,
          },
          {
            redirectTo: getRoute('KIOSK__TOP_UP__ACCOUNT'),
          }
        )
      )
    }
  }
)

const nfcReadStartMiddleware = createMiddleware(KioskNfcActions.START, ({ dispatch }) => {
  getKiosk()
    .nfcRead()
    .then(
      data => {
        dispatch(nfcReadData(data))
      },
      data => {
        dispatch(nfcFailure(data))
      }
    )
    .catch(e => Logger.logException(e))
})

const nfcReadStopMiddleware = createMiddleware(KioskNfcActions.STOP, () => {
  getKiosk().nfcStop()
})

const sharingListenMessagesMiddleware = createMiddleware(
  KioskMessagesAction.LISTEN,
  ({ dispatch, getState }) => {
    getKiosk().onNewMessage(result => {
      const { type, data, date } = result.message

      switch (type) {
        case MessageType.HEARTBEAT:
          dispatch(
            sharingRececivedHeartbeat({
              date,
            })
          )
          break

        case MessageType.BADGE:
          const isListeningBadgeId = isListeningSharingMessageBadgeId(getState())
          if (isListeningBadgeId) {
            dispatch(sharingRececivedBadge(data))
          }
          break

        case MessageType.STATUS:
          dispatch(sharingRececivedStatus(data))
          break

        default:
          Logger.warn('Kiosk MessageType', `Type not supported: ${type}`)
      }
    })
  }
)

const sharingRececivedBadgeMiddleware = createMiddleware(
  KioskMessagesAction.RECEIVED_BADGE,
  ({ getState, dispatch, action }) => {
    dispatch(
      fetchRestaurantUserAccounts(
        getRestaurantId(getState()),
        {
          badgeId: action.data.badge,
        },
        {
          redirectTo: getRoute('KIOSK__TOP_UP__ACCOUNT'),
        }
      )
    )
  }
)

export const kioskInteractorMiddlewares = [
  nfcReadStartMiddleware,
  nfcReadStopMiddleware,
  paymentSendMiddleware,
  paymentSuccessMiddleware,
  runKioskCallMiddleware,
  scanStartMiddleware,
  scanStopMiddleware,
  scanRececivedDataMiddleware,
  sharingListenMessagesMiddleware,
  sharingRececivedBadgeMiddleware,
]
