import isNil from 'lodash/isNil'

import ApiEndpoints from '../config/ApiEndpoints'
import CatalogErrors from '../errors/CatalogErrors'
import { getDefaultLocale } from 'pmt-modules/i18n'

const CatalogSource = {
  /**
   * to use only the local menu information
   */
  LOCAL_ONLY: 'LOCAL_ONLY',

  /**
   * to use both local and POS menu information merged (can cause performance issues, depending on
   * the POS).
   */
  LOCAL_AND_POS: 'LOCAL_AND_POS',

  /**
   * to use only the POS menu information (can cause performance issues, depending on the POS)
   */
  POS_ONLY: 'POS_ONLY',
}

const CatalogApi = {
  getCatalog: (restaurantId, date, mode) => {
    let query = {
      loadStocks: true,
      menuSource: CatalogSource.LOCAL_ONLY,
      loadCartModifiers: true,
      mode,
      locale: getDefaultLocale(), // locale coming from browser OR overriden by the queryParam "l"
    }

    if (!isNil(date)) {
      query = { ...query, date }
    }

    return {
      endpoint: ApiEndpoints.CATALOG,
      type: 'GET',
      params: {
        restaurantId,
      },
      query,
      errorHandler: CatalogErrors.GET,
    }
  },
}

export default CatalogApi
