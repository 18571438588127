import { createSelector } from 'reselect'

import { getDefaultLocale } from 'pmt-modules/i18n'

const getKiosk = state => state.entities.kiosk

export const getInactivityTimeoutInstance = createSelector([getKiosk], kiosk =>
  kiosk.get('inactivityTimeoutInstance', null)
)

export const getIsListeningInactivity = createSelector([getKiosk], kiosk =>
  kiosk.get('isListeningInactivity', false)
)

export const getLocale = createSelector([getKiosk], kiosk =>
  kiosk.get('locale', getDefaultLocale())
)
