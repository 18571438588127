import React from 'react'
import { connect } from 'react-redux'

import { getRestaurantId } from 'pmt-modules/authRestaurant/selectors'
import { makeGetRestaurant } from 'pmt-modules/restaurant/selectors'
import { makeGetTopUpForAccount } from 'pmt-modules/topUp/makeTopUp/selectors'
import { getDataUserAccounts } from 'pmt-modules/restaurantUserAccount/selectors'
import { getErrorKioskPayment } from 'pmt-modules/kioskInteractor/selectors'
import { RestaurantUserAccountsContainer } from 'pmt-modules/restaurantUserAccount'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { sendPayment } from 'pmt-modules/kioskInteractor/actions'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import { hideErrorDialog } from 'pmt-ui/ErrorBlock/actions'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import Logger from 'pmt-utils/logger'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withRestaurant
class TopUpPaymentPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.sendPayment(this.props)
  }

  sendPayment(props) {
    const { restaurant, topUp, userAccounts } = props

    if (topUp !== null && restaurant !== null && userAccounts !== null && userAccounts.length > 0) {
      props.sendPayment(topUp.amount, {
        isTopUp: true,
        restaurant,
        topUp,
        userAccount: userAccounts[0],
        redirectTo: getRoute('KIOSK__TOP_UP__PAYMENT_CONFIRMATION'),
        redirectToParams: {
          userAccountId: props.routeParams.userAccountId,
        },
      })
    } else {
      Logger.warn('Kiosk payment', 'Cannot send payment', {
        topUp,
        restaurant,
        userAccounts,
      })
    }
  }

  handleCancel = () => {
    this.props.hideErrorDialog()
    this.props.redirectTo(getRoute('HOME'))
  }

  handleErrorRetry = () => {
    this.sendPayment(this.props)
    this.props.hideErrorDialog()
  }

  render() {
    const { isFetchingRestaurant, restaurant, route } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {() => (
          <RestaurantUserAccountsContainer
            restaurantId={restaurant.id}
            userAccountId={this.props.routeParams.userAccountId}
          >
            {({ userAccount, userAccounts, isFetchingUserAccounts }) =>
              isFetchingUserAccounts || userAccount === null ? (
                <LoadingBlockWrapper show />
              ) : (
                <View
                  restaurant={restaurant}
                  userAccount={userAccount}
                  onCancel={this.handleCancel}
                  onErrorRetry={this.handleErrorRetry}
                  paymentError={this.props.paymentError}
                />
              )
            }
          </RestaurantUserAccountsContainer>
        )}
      </KioskPage>
    )
  }
}

const makeMapStateToProps = () => {
  const getTopUpForAccount = makeGetTopUpForAccount()
  const getRestaurant = makeGetRestaurant()

  const mapStateToProps = (state, props) => {
    return {
      restaurant: getRestaurant(state, {
        restaurantId: getRestaurantId(state),
      }),
      paymentError: getErrorKioskPayment(state),
      topUp: getTopUpForAccount(state, {
        userAccountId: parseInt(props.routeParams.userAccountId, 10),
      }),
      userAccounts: getDataUserAccounts(state, props),
    }
  }

  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    hideErrorDialog,
    sendPayment,
    redirectTo,
  }
)(TopUpPaymentPage)
