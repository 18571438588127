import Immutable from 'immutable'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { updateArrayObject } from 'pmt-utils/array'

import { createReducer } from '../redux'

import { OrderMenuAction } from './actions'
import { createOrderMenuFromMenu } from './utils'
import { orderMenuModifier } from './modifiers'

import { createOrderProductFromProduct } from 'pmt-modules/orderProduct/utils'
import transformOrderMenuForStore from 'pmt-modules/cart/utils/transformOrderMenuForStore'

export * from './actions'
export * from './middlewares'
export * from './modifiers'
export * from './selectors'
export * from './utils'

const DEFAULT_STATE = Immutable.fromJS({
  orderMenu: null,
  selectedMenu: null,
  // a set of the options to pass to the view:
  // - isEditMode
  viewOptions: null,

  // handle subcategories in menu part
  selectedCategory: null,
  parentCategories: [],
})

//
// state modifiers
//

// use to update all subcategories in menu parts
const recursiveUpdatePart = (part, action, func) => {
  if (!isNil(part.categories) && !isEmpty(part.categories)) {
    part.categories = part.categories.map(category => recursiveUpdatePart(category, action, func))
  }

  return func(part)
}

const updateOrderMenu = (state, orderMenuUpdaterFunc) => {
  return state.updateIn(['orderMenu'], orderMenuState => {
    return Immutable.fromJS(
      transformOrderMenuForStore(orderMenuModifier(orderMenuUpdaterFunc(orderMenuState.toJS())))
    )
  })
}

//
// reducer functions
//

const selectMenu = (state, action) => {
  if (isNil(action.menu)) {
    return state
  }

  return state
    .set('selectedMenu', Immutable.fromJS(action.menu))
    .set(
      'orderMenu',
      Immutable.fromJS(
        transformOrderMenuForStore(
          action.menu.isOrderMenu === true
            ? action.menu // note: here menu is already the orderMenu
            : createOrderMenuFromMenu(action.menu)
        )
      )
    )
    .set('viewOptions', Immutable.fromJS(action.options))
}

const editMenu = (state, action) => {
  return (
    state
      // Note: the menu is the same as the orderMenu (see the action)
      .set('selectedMenu', Immutable.fromJS(transformOrderMenuForStore(action.menu)))
      .set('orderMenu', Immutable.fromJS(transformOrderMenuForStore(action.orderMenu)))
      .set('viewOptions', Immutable.fromJS(action.options))
  )
}

const addProduct = (state, action) =>
  updateOrderMenu(state, orderMenu => {
    orderMenu.parts = orderMenu.parts.map(part => {
      part = recursiveUpdatePart(part, action, part => {
        if (part.id === action.partCategoryId && !part.hasReachedMaxQuantity) {
          const orderProduct = action.orderProduct
          orderProduct.quantity = 1

          part.products = updateArrayObject(part.products, orderProduct, action.orderProduct.id)
        }

        return part
      })

      return part
    })

    return orderMenu
  })

const addProductItem = (state, action) =>
  updateOrderMenu(state, orderMenu => {
    orderMenu.parts = orderMenu.parts.map(part => {
      part = recursiveUpdatePart(part, action, part => {
        if (part.id === action.partCategory.id && !part.hasReachedMaxQuantity) {
          const orderProduct = createOrderProductFromProduct(action.item, {
            isMenuMode: true,
            partId: part.id,
            partCategoryId: action.partCategory.id,
          })
          orderProduct.quantity = 1

          part.products = updateArrayObject(part.products, orderProduct, orderProduct.id)
        }

        return part
      })

      return part
    })

    return orderMenu
  })

const updateProduct = (state, action) =>
  updateOrderMenu(state, orderMenu => {
    orderMenu.parts = orderMenu.parts.map(part => {
      part = recursiveUpdatePart(part, action, part => {
        if (part.id === action.partCategoryId) {
          const orderProduct = action.orderProduct

          part.products = updateArrayObject(
            part.products,
            orderProduct,
            // update with orderId
            p => orderProduct.orderId === p.orderId
          )
        }

        return part
      })

      return part
    })

    return orderMenu
  })

const unselectProduct = (state, action) =>
  updateOrderMenu(state, orderMenu => {
    orderMenu.parts = orderMenu.parts.map(part => {
      part = recursiveUpdatePart(part, action, part => {
        if (part.id === action.partCategory.id) {
          const orderProduct = action.orderProduct

          // put quantity to 0
          orderProduct.quantity = 0

          // put options values quantity to 0
          orderProduct.options.map(option => {
            option.values = option.values.map(value => {
              value.quantity = 0
              return value
            })
            return option
          })

          part.products = updateArrayObject(
            part.products,
            orderProduct,
            // update with orderId
            p => orderProduct.orderId === p.orderId
          )
        }

        return part
      })

      return part
    })

    return orderMenu
  })

const setCurrentPart = (state, action) =>
  updateOrderMenu(state, orderMenu => {
    orderMenu.currentPart = action.part
    return orderMenu
  })

//
//
//

export const setSelectedCategoryInPart = (state, action) =>
  state.set('selectedCategory', Immutable.fromJS(action.category))

export const setParentCategoriesInPart = (state, action) =>
  state.set('parentCategories', Immutable.fromJS(action.parentCategories))

export const orderMenuReducer = createReducer(DEFAULT_STATE, {
  [OrderMenuAction.SELECT_MENU]: selectMenu,
  [OrderMenuAction.EDIT_MENU]: editMenu,
  [OrderMenuAction.ADD_PRODUCT]: addProduct,
  [OrderMenuAction.UPDATE_PRODUCT]: updateProduct,
  [OrderMenuAction.UNSELECT_PRODUCT]: unselectProduct,
  [OrderMenuAction.ADD_PRODUCT_ITEM]: addProductItem,
  [OrderMenuAction.SET_CURRENT_PART]: setCurrentPart,
  [OrderMenuAction.SET_PART_SELECTED_CATEGORY]: setSelectedCategoryInPart,
  [OrderMenuAction.SET_PART_PARENT_CATEGORIES]: setParentCategoriesInPart,
})
