import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import { displayCart, getItemListFromCart } from 'pmt-modules/cart'
import { withCatalog } from 'pmt-modules/catalog'
import { EventManager } from 'pmt-modules/event'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { isFetchingOrderPreview } from 'pmt-modules/orderPreview'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-697
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b5b0afd768852603a3b4e92
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5b50951994d8f6ef1ab3167e
 */
@withAppConfig
@withRestaurant
@withCatalog
class OrderCartPage extends React.PureComponent {
  constructor(props) {
    super(props)

    // dispatch action even if catalog is not loaded yet in order to trigger isFetchingOrderPreview
    props.displayCart()
  }

  componentWillReceiveProps(nextProps) {
    // security when catalog wasn't loaded on constructor (typically refresh on page)
    if (!this.props.catalog && nextProps.catalog) {
      nextProps.displayCart()
    }
  }

  handleGoToCatalog = () => {
    EventManager.dispatch(EventManager.Events.ON_BREADCRUMB_LINK, {
      link: getRoute('KIOSK__ORDER__CATEGORY').path,
    })
    this.props.redirectTo(getRoute('KIOSK__ORDER__CATEGORY'))
  }

  render() {
    const {
      orderAppConfig,
      frontAppConfig,
      isFetchingOrderPreview,
      isFetchingRestaurant,
      itemList,
      orderProperties,
      restaurant,
      route,
    } = this.props

    return (
      <KioskPage route={route} isFetching={isFetchingRestaurant || !restaurant}>
        {({ kioskSettings }) => (
          <View
            orderAppConfig={orderAppConfig}
            frontAppConfig={frontAppConfig}
            route={route}
            isFetchingOrderPreview={isFetchingOrderPreview}
            kioskSettings={kioskSettings}
            itemList={itemList}
            mode={orderProperties.mode}
            onGoToCatalog={this.handleGoToCatalog}
            restaurant={restaurant}
            totalCartPriceAndFees={orderProperties.totalCartPriceAndFeesFormatted}
          />
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  orderProperties: getOrderProperties(state),
  isFetchingOrderPreview: isFetchingOrderPreview(state),
  itemList: getItemListFromCart(state),
})

const mapDispatchToProps = { displayCart, redirectTo }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCartPage)
