import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
// import { addOrderProductToCart, updateOrderProductOnCart } from 'pmt-modules/cart'
import { EventManager } from 'pmt-modules/event'
import {
  withCatalog,
  withCategory,
  selectCategory,
  setParentCategories,
  resetParentCategories,
  showDietaryInfoDialog,
} from 'pmt-modules/catalog'
import {
  addOrderMenuToCart,
  getSuspendedDatas,
  setSuspendedDatas,
  updateOrderMenuOnCart,
} from 'pmt-modules/cart'
import { getOrderProperties, selectMode } from 'pmt-modules/orderFront'
import SelectedMenuContainer from 'pmt-modules/orderMenu/components/SelectedMenuContainer'
import {
  setPartParentCategories,
  resetPartParentCategories,
  setPartSelectedCategory,
  resetPartSelectedCategory,
  selectMenuProduct,
  unselectMenuProduct,
  addProductItemToMenu,
  getViewOptions,
  getPartParentCategories,
  getParentCategoriesLeft,
} from 'pmt-modules/orderMenu'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
@withCatalog
@withCategory
class OrderMenuPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (isNil(props.orderProperties.mode)) {
      props.redirectTo(getRoute('KIOSK__ORDER__MODE'))
    }
  }

  resetPartCategory = () => {
    this.props.resetPartParentCategories()
    this.props.resetPartSelectedCategory()
  }

  handleBackToCatalogClick = () => {
    const { selectCategory, selectedCategory, firstSelectableCategory, redirectTo } = this.props

    this.resetPartCategory()
    if (selectedCategory) {
      selectCategory(selectedCategory)
      redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
        categoryId: selectedCategory?.id,
      })
    } else {
      redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
        categoryId: firstSelectableCategory.id,
      })
    }
  }

  handleOnCategoryClick = category => {
    const { selectCategory, resetParentCategories, redirectTo } = this.props

    this.resetPartCategory()
    resetParentCategories()
    selectCategory(category)
    redirectTo(getRoute('KIOSK__ORDER__CATALOG'), {
      categoryId: category.id,
    })
  }

  handleRedirectToPreviousPage = () => {
    const { isFromCart, redirectTo } = this.props

    if (isFromCart) {
      this.resetPartCategory()
      redirectTo(getRoute('KIOSK__ORDER__CART'))
    } else {
      this.handleBackToCatalogClick()
    }
  }

  handleSelectCategory = (category, parentCategory) => {
    const { partParentCategories, setPartParentCategories, setPartSelectedCategory } = this.props

    setPartSelectedCategory({ id: category.id })
    partParentCategories.push({
      id: parentCategory.id,
      name: parentCategory.name,
      categories: parentCategory.categories,
      products: parentCategory.products,
    })
    setPartParentCategories(partParentCategories)
  }

  handleReturnToPreviousPartCategory = parentCategory => {
    const { setPartParentCategories, setPartSelectedCategory } = this.props
    const partParentCategories = getParentCategoriesLeft(
      parentCategory,
      this.props.partParentCategories
    )

    setPartParentCategories(partParentCategories)
    setPartSelectedCategory({ id: parentCategory.id })
  }

  handleAddProductFromShortcut = (part, partCategory, productItem, menu, category) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_PRODUCT, {
      category,
      item: productItem,
      menu,
      restaurant: this.props.restaurant,
    })

    this.props.addProductItemToMenu(part, partCategory, productItem, options)
  }

  handleSelectOrderProduct = (part, partCategory, productItem, menu) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ITEM_DETAIL, {
      item: productItem,
      menu,
      restaurant: this.props.restaurant,
    })

    this.props.selectMenuProduct(part, partCategory, productItem, options)
  }

  handleUnselectOrderProduct = (part, partCategory, productItem) => {
    const options = {
      restaurantId: this.props.restaurant.id,
    }

    this.props.unselectMenuProduct(part, partCategory, productItem, options)
  }

  handleSubmitOrderMenu = orderMenu => {
    const {
      cartSuspendedDatas,
      viewOptions,
      updateOrderMenuOnCart,
      addOrderMenuToCart,
      restaurant,
      selectedCategory,
      setSuspendedDatas,
    } = this.props

    if (orderMenu.isValid) {
      if (viewOptions.isEditMode) {
        updateOrderMenuOnCart(orderMenu)
        this.handleRedirectToPreviousPage()
      } else {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
          category: selectedCategory,
          item: orderMenu,
          restaurant,
        })
        addOrderMenuToCart(orderMenu)
        // suggested item has been added, remove suspended datas
        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }
        this.handleBackToCatalogClick()
      }
    }
  }

  render() {
    const {
      frontAppConfig,
      route,
      params,
      restaurant,
      orderProperties,
      selectedCategory,
      catalog,
      viewOptions,
      isFromCart,
      parentCategories,
      showDietaryInfoDialog,
    } = this.props

    return (
      <KioskPage route={route}>
        {({ kioskSettings }) => (
          <SelectedMenuContainer
            catalog={catalog}
            selectedCategory={selectedCategory}
            menuId={params.menuId}
          >
            {({ selectedMenu, orderMenu }) => (
              <View
                frontAppConfig={frontAppConfig}
                isFetching={!restaurant || !catalog || isNil(orderMenu)}
                kioskSettings={kioskSettings}
                restaurant={restaurant}
                mode={orderProperties.mode}
                catalog={catalog}
                category={selectedCategory}
                orderMenu={orderMenu}
                viewOptions={viewOptions}
                isFromCart={isFromCart}
                parentCategories={parentCategories}
                onCategoryClick={this.handleOnCategoryClick}
                handleBackToCatalogClick={this.handleBackToCatalogClick}
                handleRedirectToPreviousPage={this.handleRedirectToPreviousPage}
                onSelectCategory={this.handleSelectCategory}
                onReturnToPreviousPartCategory={this.handleReturnToPreviousPartCategory}
                onSelectOrderProduct={(part, partCategory, productItem) =>
                  this.handleSelectOrderProduct(part, partCategory, productItem, orderMenu)
                }
                onUnselectOrderProduct={this.handleUnselectOrderProduct}
                onAddToCartFromShortcut={(part, partCategory, productItem) =>
                  this.handleAddProductFromShortcut(
                    part,
                    partCategory,
                    productItem,
                    orderMenu,
                    selectedCategory
                  )
                }
                onSubmitOrderMenu={this.handleSubmitOrderMenu}
                showDietaryInfoDialog={() => showDietaryInfoDialog(selectedMenu)}
              />
            )}
          </SelectedMenuContainer>
        )}
      </KioskPage>
    )
  }
}

const mapStateToProps = state => {
  const viewOptions = getViewOptions(state)

  return {
    cartSuspendedDatas: getSuspendedDatas(state),
    orderProperties: getOrderProperties(state),
    viewOptions,
    isFromCart:
      viewOptions &&
      viewOptions.fromRoute &&
      viewOptions.fromRoute.name === getRoute('KIOSK__ORDER__CART').name,
    partParentCategories: getPartParentCategories(state),
  }
}

export default connect(mapStateToProps, {
  redirectTo,
  selectMode,
  selectCategory,
  setParentCategories,
  resetParentCategories,
  setPartParentCategories,
  resetPartParentCategories,
  setPartSelectedCategory,
  resetPartSelectedCategory,
  selectMenuProduct,
  unselectMenuProduct,
  addProductItemToMenu,
  addOrderMenuToCart,
  updateOrderMenuOnCart,
  setSuspendedDatas,
  showDietaryInfoDialog,
})(OrderMenuPage)
