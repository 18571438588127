//
// Note: this file should never be import directly. Use a getter via getters.js
//

import find from 'lodash/find'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'

const Env = {
  COMMON: {
    VERSION: '0.0.1',

    Logger: {
      SENTRY_URL: 'https://b2fd6143454a45368bfbce56ba3129d4@sentry.io/268966',
      DISPLAY_REPORT_DIALOG: true,
    },

    IS_LOCAL: false,
    IS_DEV: false,
    IS_PREPROD: false,
    IS_PROD: false,

    IS_KIOSK: true,

    SHOULD_USE_APP_CONFIG: true,
  },

  LOCAL: {
    ENV: 'DEV',

    BASE_URL: /localhost/,
    HOSTNAME: 'localhost',
    API_URL: 'http://localhost:8080/rest',
    API_CONSUMER: 'Basic NTY0NDIzOTAwNjMzNDk3NjpBT29VeGs5N0JFdTV5Zw==',

    IS_DEV: true,
  },

  QA: {
    ENV: 'DEV',

    HOSTNAME: 'kiosk.qa1.paymytable.com',
    BASE_URL: /kiosk.qa[0-9]{1}.paymytable.com/,
    API_URL: 'https://paymytabledev.appspot.com/rest',
    API_CONSUMER: '',

    IS_DEV: true,
    IS_QA: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'kiosk.preprod.paymytable.com',
    BASE_URL: /kiosk.preprod.paymytable.com/,
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    API_CONSUMER: '',

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'kiosk.paymytable.com',
    BASE_URL: /kiosk.paymytable.com/,
    API_URL: 'https://api.paymytable.com/rest',
    API_CONSUMER: '',

    IS_PROD: true,
  },
}

let predicate = null

const hostname = window.location.hostname

if (!isNil(window.globalEnvironmentSettings)) {
  predicate = config => window.globalEnvironmentSettings.env === config.ENV
} else {
  predicate = config => (!isNil(config.BASE_URL) ? hostname.match(config.BASE_URL) : null)
}

let currentEnv = find(Env, predicate) || Env.QA

// for QA, we need to set the right URL (qa1, qa2, qa3) in hostname in order to get the right fr.json/en.json files
if (currentEnv.IS_QA) {
  currentEnv.HOSTNAME = hostname
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
