import React from 'react'

import OrderMode from 'pmt-modules/order/constants/OrderMode'

import Delivery from 'pmt-ui/svg-icons/pmt/Delivery'
import OnSite from 'pmt-ui/svg-icons/pmt/OnSite'
import TakeAway from 'pmt-ui/svg-icons/pmt/TakeAway'

const Icons = {
  [OrderMode.DELIVERY]: <Delivery />,
  [OrderMode.ON_SITE]: <OnSite />,
  [OrderMode.TAKE_AWAY]: <TakeAway />,
}

const IconOrderMode = ({ type, ...props }) => React.cloneElement(Icons[type], props)

export default IconOrderMode
