import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Grid from 'pmt-ui/Grid'
import Button from 'pmt-ui/Button'

import OptionValue from './OptionValue'

const styles = theme => ({
  productOption: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(4),
    background: theme.pmt.colors.greyEF,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  productOptionHighlight: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  optionTitle: {
    overflow: 'hidden',
  },
  optionName: {
    float: 'left',
    width: 'calc(100% - 95px)',
    minHeight: 36,
    maxHeight: 73,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '32px',
  },
  optionModify: {
    float: 'right',
    lineHeight: '32px',
    color: theme.palette.primary.main,
  },
  optionOptionnal: {
    float: 'right',
    lineHeight: '32px',
    color: theme.pmt.colors.grey4A,
  },
  optionIntro: {
    marginTop: theme.spacing(1),
    color: theme.pmt.colors.grey4A,
  },
  optionIntroPrimary: {
    color: theme.palette.primary.main,
  },
  optionValueContainer: {
    marginTop: theme.spacing(2),
  },
  hr: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    borderTop: `1px solid ${theme.pmt.colors.grey91}`,
  },
  buttonOk: {
    float: 'right',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    border: `3px solid ${theme.pmt.colors.grey26}`,
    textTransform: 'initial',
  },
})

class OptionContainer extends React.PureComponent {
  componentWillReceiveProps(nextProps) {
    if (
      this.props.option.nbChosenValues < nextProps.option.nbChosenValues &&
      nextProps.option.nbChosenValues === nextProps.option.quantityMax
    ) {
      nextProps.updateUI({
        modifyOptionValue: false,
      })
      nextProps.handleGoToOption(nextProps.index + 1)
    }
  }

  render() {
    const {
      restaurant,
      option,
      index,
      handleSelectOptionValue,
      handleUnselectOptionValue,
      handleGoToOption,
      isOpen,
      ui,
      updateUI,
      classes,
    } = this.props

    const hasImageValue = option.hasImageValue

    return (
      <div
        id={option.id}
        className={classNames(classes.productOption, {
          [classes.productOptionHighlight]: isOpen,
        })}
        onClick={() => {
          updateUI({
            modifyOptionValue: true,
            showOptionValue: true,
          })
          handleGoToOption(index)
        }}
      >
        <div className={classes.optionTitle}>
          <TypographyCustom type="324" className={classes.optionName}>
            {option.name}
          </TypographyCustom>
          {(option.nbChosenValues === option.quantityMax &&
            (!ui.modifyOptionValue || !ui.showOptionValue)) ||
          (!isOpen && option.nbChosenValues > 0) ? (
            <TypographyCustom type="247" className={classes.optionModify}>
              {tr('global.order.change')}
            </TypographyCustom>
          ) : (
            !option.mandatory && (
              <TypographyCustom type="204" className={classes.optionOptionnal}>
                {tr('kiosk.order.product.optionnal')}
              </TypographyCustom>
            )
          )}
        </div>
        <TypographyCustom type="204" className={classes.optionIntro}>
          {option.quantityMin === 1 && option.quantityMax === 1 && (
            <React.Fragment>
              {option.nbChosenValues === 0
                ? tr('kiosk.order.product.max_element', {
                    max: option.quantityMax,
                  })
                : tr('kiosk.order.product.max_element_selected', {
                    max: option.quantityMax,
                  })}
            </React.Fragment>
          )}
          {option.quantityMin === option.quantityMax && option.quantityMax > 1 && (
            <React.Fragment>
              {option.nbChosenValues === 0
                ? tr('kiosk.order.product.min_max_element', {
                    smart_count: option.quantityMax,
                  })
                : tr('kiosk.order.product.min_max_element_selected', {
                    smart_count: option.nbChosenValues,
                    max: option.quantityMax,
                  })}
            </React.Fragment>
          )}
          {option.quantityMin < option.quantityMax && (
            <React.Fragment>
              {option.nbChosenValues === 0
                ? tr('kiosk.order.product.min_less_max_element', {
                    min: option.quantityMin,
                    max: option.quantityMax,
                  })
                : tr('kiosk.order.product.min_less_max_element_selected', {
                    smart_count: option.nbChosenValues,
                    max: option.quantityMax,
                  })}
            </React.Fragment>
          )}
        </TypographyCustom>
        <Grid container className={classes.optionValueContainer} spacing={2}>
          {option.values.map(value => (
            <OptionValue
              key={`optionValue-${value.id}`}
              show={isOpen}
              hasImageValue={hasImageValue}
              value={value}
              restaurant={restaurant}
              type={
                option.quantityMin === option.quantityMax && option.quantityMin === 1
                  ? OptionValue.ButtonType.ROUND
                  : OptionValue.ButtonType.SQUARE
              }
              checked={
                (option.nbChosenValues === option.quantityMax && !ui.modifyOptionValue) ||
                !ui.showOptionValue ||
                (!isOpen && value.quantity > 0)
              }
              disabled={
                // when there is multiple choices (square buttons), we disabled the values that can't
                // be selected (hasReachedMaxQuantity)
                (option.hasReachedMaxQuantity &&
                  (option.quantityMax !== option.quantityMin || option.quantityMin > 1) &&
                  value.quantity === 0) ||
                value.stock === 0
              }
              handleOnClickOptionValue={e => {
                e.stopPropagation()

                // for now, an option value can be selected only once
                if (value.quantity === 0) {
                  handleSelectOptionValue(option, value)
                } else {
                  handleUnselectOptionValue(option, value)
                }
              }}
            />
          ))}
          {((option.quantityMin < option.quantityMax &&
            option.nbChosenValues < option.quantityMax &&
            option.nbChosenValues > 0) ||
            (option.quantityMin === 0 && option.nbChosenValues < option.quantityMax)) &&
            ui.showOptionValue &&
            isOpen && (
              <Grid item xs={12}>
                <hr className={classes.hr} />
                <TypographyCustom type="247">
                  {({ className }) => (
                    <Button
                      variant="outlined"
                      classes={{
                        root: classes.buttonOk,
                        label: className,
                      }}
                      onClick={e => {
                        e.stopPropagation()

                        updateUI({
                          showOptionValue: false,
                        })
                        handleGoToOption(index + 1)
                      }}
                    >
                      {option.nbChosenValues === 0
                        ? tr('global.order.no_thanks')
                        : tr('global.order.ok_all')}
                    </Button>
                  )}
                </TypographyCustom>
              </Grid>
            )}
        </Grid>
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      showOptionValue: true,
      modifyOptionValue: false,
    },
  }),
  withStyles(styles)
)(OptionContainer)
