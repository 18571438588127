import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'

import { goToAnchor } from 'pmt-ui/ScrollableAnchor'
import { withStyles } from 'pmt-ui/styles'

import OptionContainer from './OptionContainer'
import ReclaimLater from './ReclaimLater'

const styles = theme => ({
  productOptionContainer: {
    overflow: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: 'calc(100% + 17px)',
    scrollBehavior: 'smooth',
  },
})

class OptionsContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!props.viewOptions.isEditMode) {
      props.updateUI({
        openedOption: props.orderProduct.options[0],
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.orderProduct.id !== nextProps.orderProduct.id &&
      !this.props.viewOptions.isEditMode
    ) {
      nextProps.updateUI({
        openedOption: nextProps.orderProduct.options[0],
      })
    }
  }

  componentDidUpdate() {
    if (this.props.ui.openedOption) {
      goToAnchor(this.props.ui.openedOption.id, false)
    }
  }

  handleGoToOption = index => {
    const { orderProduct, ui, updateUI } = this.props

    if (orderProduct.options[index] && !isEqual(ui.openedOption, orderProduct.options[index])) {
      updateUI({
        openedOption: orderProduct.options[index],
      })
    } else if (!orderProduct.options[index]) {
      updateUI({
        openedOption: null,
      })
    }
  }

  render() {
    const {
      restaurant,
      orderProduct,
      handleSelectOptionValue,
      handleUnselectOptionValue,
      setReclaimLater,
      ui,
      classes,
    } = this.props

    return (
      <div className={classes.productOptionContainer}>
        {orderProduct.options.map((option, index) => (
          <OptionContainer
            key={index}
            index={index}
            option={option}
            isOpen={!isNil(ui.openedOption) && ui.openedOption.id === option.id}
            handleGoToOption={this.handleGoToOption}
            handleSelectOptionValue={handleSelectOptionValue}
            handleUnselectOptionValue={handleUnselectOptionValue}
            restaurant={restaurant}
          />
        ))}
        <ReclaimLater orderProduct={orderProduct} setReclaimLater={setReclaimLater} />
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      openedOption: null,
    },
  }),
  withStyles(styles)
)(OptionsContainer)
