import React from 'react'
import { connect } from 'react-redux'

import { getItemListFromCart } from 'pmt-modules/cart'
import { showPostOrderBackHomeDialog } from 'pmt-modules/kioskOrder'
import { getOrderProperties } from 'pmt-modules/orderFront'
import { withRestaurant } from 'pmt-modules/restaurant/components'

import KioskPage from '../../../components/KioskPage'
import View from './View'

/**
 *
 * https://app.zeplin.io/project/5b51f8a68bc41aa06a354787/screen/5ba369eebf8fc954447b527d
 */
@withRestaurant
class OrderPostErrorPage extends React.PureComponent {
  handleBackHome = () => {
    this.props.showPostOrderBackHomeDialog()
  }

  render() {
    const { itemList, orderProperties, restaurant, route } = this.props
    return (
      <KioskPage route={route} isFetching={!restaurant}>
        <View
          itemList={itemList}
          mode={orderProperties.mode}
          onBackHome={this.handleBackHome}
          restaurant={restaurant}
          totalCartPrice={orderProperties.totalCartPriceAndFeesFormatted}
        />
      </KioskPage>
    )
  }
}

const mapStateToProps = state => ({
  itemList: getItemListFromCart(state),
  orderProperties: getOrderProperties(state),
})

export default connect(
  mapStateToProps,
  {
    showPostOrderBackHomeDialog,
  }
)(OrderPostErrorPage)
