import React from 'react'
import pure from 'recompose/pure'

import { withStyles } from '../../styles'
import SvgIcon from '../../SvgIcon'

const styles = theme => ({
  st0: {
    fill: '#96C522',
  },
  st1: {
    fill: '#CACACA',
  },
  st2: {
    fill: '#C9C9C9',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  st3: {
    fill: '#95C422',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  st4: {
    fill: '#CACACA',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  }
})

let LogoGrey = (props) => {
  const { classes, ...otherProps } = props
  return (
    <SvgIcon {...otherProps}>
      <g>
        <path className={classes.st0} d="M62.2,21.6c1.1-0.4,2-0.8,2.8-1.4c0.8-0.5,1.5-1.2,2.1-1.9c0.5-0.8,0.8-1.6,0.8-2.6c0-0.7-0.2-1.3-0.5-1.9
          c-0.3-0.6-0.7-1-1.2-1.5c-0.5-0.4-1.1-0.8-1.8-1.1c-0.7-0.3-1.3-0.6-2-0.8c-0.7-0.2-1.4-0.4-2.1-0.5c-0.7-0.1-1.3-0.3-1.8-0.3
          c-0.6-0.1-1.3-0.2-2-0.2c-0.7-0.1-1.4-0.1-2-0.1c-1.1,0-2.1,0.1-3.2,0.2C50.1,9.6,49,9.8,48,10.1c-1.1,0.3-2.1,0.6-3.1,1
          c-1,0.4-1.9,0.9-2.7,1.5c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.2-0.2,0.3-0.2,0.5
          c0,0.2,0.2,0.4,0.7,0.4c0.2,0,0.3,0,0.6-0.1c0.2,0,0.5-0.1,0.9-0.3c0.4-0.1,0.9-0.3,1.5-0.5c0.6-0.2,1.5-0.5,2.5-0.9
          c1.3-0.3,2.5-0.6,3.8-0.8c1.3-0.2,2.6-0.3,3.9-0.3c0.6,0,1.3,0,2.1,0.1c0.8,0.1,1.6,0.1,2.4,0.3c0.8,0.1,1.6,0.3,2.4,0.5
          c0.8,0.2,1.5,0.5,2.1,0.8c0.6,0.3,1.1,0.7,1.5,1.1c0.4,0.4,0.6,0.9,0.6,1.4c0,0.7-0.2,1.3-0.7,1.8c-0.5,0.5-1.1,1-1.8,1.4
          c-0.7,0.4-1.5,0.8-2.5,1.1c-0.9,0.3-1.8,0.6-2.7,0.8c-0.9,0.2-1.7,0.4-2.5,0.5c-0.8,0.1-1.4,0.2-1.8,0.3c0-0.3,0.1-0.6,0.2-1
          c0.1-0.4,0.2-0.9,0.2-1.3c0.1-0.5,0.1-0.9,0.1-1.4c0-0.5,0-0.9-0.1-1.3c-0.1-0.4-0.3-0.7-0.5-0.9c-0.2-0.2-0.6-0.4-1-0.4
          c-0.2,0-0.4,0.1-0.5,0.4c-0.1,0.3-0.2,0.7-0.3,1.1c-0.1,0.4-0.1,0.9-0.2,1.5c-0.1,0.5-0.1,1-0.1,1.5c0,0.5-0.1,1-0.1,1.4
          c0,0.4-0.1,0.7-0.1,0.8c-0.8,0.1-1.6,0.2-2.4,0.3c-0.8,0.1-1.6,0.2-2.3,0.2c-0.3,0-0.7,0.1-1.3,0.2c-0.6,0.1-1.2,0.1-1.9,0.2
          c-0.6,0.1-1.2,0.2-1.7,0.4c-0.5,0.1-0.7,0.3-0.7,0.5c0,0.2,0.1,0.3,0.4,0.4c0.3,0.1,0.6,0.1,0.9,0.2c0.4,0,0.8,0,1.2,0
          c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.1,0.9-0.1c0.3,0,0.4,0,0.4,0c0.8-0.1,1.7-0.2,2.5-0.2c0.9-0.1,1.7-0.2,2.6-0.3
          c0,1.5,0,4.4,0.1,5.5c0.1,1,0.2,1.9,0.5,2.6c0.1,0.3,0.2,0.5,0.4,0.5c0.3,0,0.5-0.3,0.7-0.8c0.2-0.5,0.3-1.2,0.5-1.9
          c0.1-0.8,0.2-3.2,0.3-4c0.1-0.8,0.1-1.6,0.1-2.2c0.6-0.1,1.4-0.2,2.4-0.3c1-0.2,2-0.4,3-0.6C60.1,22.3,61.1,22,62.2,21.6z"/>
        <path className={classes.st0} d="M73.2,23.8c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2,0,0.4,0,0.6
          c0,0.1,0,0.2-0.1,0.4c-0.1,0.3-0.1,0.7-0.2,1.1c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.3-0.2,0.6-0.3,0.8
          c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.3-0.2-0.7c0-0.5,0-1,0.1-1.7c0.1-0.7,0.1-1.3,0.2-2.1c0.1-0.7,0.1-1.4,0.1-2.1
          c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.1-0.5-0.2-0.8-0.1c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.4,0.4-0.4,0.8c0,0.6,0,1.1,0,1.8
          c0,0.3,0,0.5,0,0.8c0,0,0,0,0,0c0,0.2-0.1,0.5-0.3,1c-0.1,0.4-0.3,0.8-0.5,1.3c-0.2,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.3,0.5-0.4,0.5
          c-0.2,0-0.3-0.2-0.3-0.5c-0.1-0.4-0.1-0.8,0-1.3c0-0.5,0.1-1,0.1-1.6c0.1-0.5,0.1-1,0.2-1.4c0-0.3,0-0.5-0.2-0.7
          c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.4,0.1-0.6,0.3c-0.1-0.2-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-0.7-0.4
          c-0.7-0.1-1.4,0-1.9,0.3c-0.5,0.4-0.9,0.9-1.2,1.5c-0.3,0.6-0.5,1.4-0.6,2.2c-0.1,0.8-0.1,1.6,0,2.3c0.1,0.7,0.4,1.4,0.7,2
          c0.3,0.6,0.8,0.9,1.4,1.1c0.3,0.1,0.7,0.1,1.1-0.1c0.4-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.5-0.8,0.7-1.3c0.3,0.5,0.7,0.9,1.3,1.1
          c0.4,0.1,0.7,0.1,1-0.1c0.3-0.2,0.6-0.4,0.8-0.8c0.3-0.3,0.5-0.7,0.7-1.1c0,0,0,0,0,0c0,0.2,0,0.5,0.1,0.6c0.1,0.3,0.2,0.6,0.4,0.8
          c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.5,0.2,0.8,0.1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.6-0.8,0.8-1.4c0,0.7-0.1,1.4-0.1,2.1
          c0,0.7-0.1,1.3-0.1,1.9c0,0.6-0.1,1.1-0.1,1.6c0,0.5-0.1,0.9-0.1,1.1c0,0.3,0.1,0.5,0.3,0.6c0.2,0,0.5,0,0.8-0.2
          c0.3-0.2,0.6-0.4,0.9-0.7c0.3-0.3,0.4-0.7,0.4-1.1c0-0.2,0-0.5,0-1c0-0.5,0-1.1,0-1.7c0-0.7,0-1.4,0-2.2c0-0.8,0-1.5,0.1-2.3
          c0-0.8,0.1-1.5,0.1-2.1c0-0.7,0.1-1.2,0.2-1.6C73.5,24.1,73.4,23.9,73.2,23.8z M63.3,28.2c-0.1,0.6-0.2,1.2-0.4,1.7
          c-0.2,0.5-0.4,0.9-0.6,1.2c-0.2,0.3-0.5,0.5-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.6c-0.1-0.4-0.2-0.8-0.2-1.3c0-0.5,0-1.1,0.1-1.6
          c0.1-0.6,0.2-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.6-1.2c0.2-0.3,0.5-0.4,0.9-0.3c0.2,0,0.4,0.2,0.5,0.5c0.1,0.3,0.2,0.7,0.2,1
          C63.4,27,63.4,27.6,63.3,28.2z"/>
      </g>
      <g>
        <path className={classes.st1} d="M101.9,11.3c-0.5,0-1.1,0.2-1.7,0.6c-0.6,0.4-1.2,0.9-1.7,1.5c-0.6,0.6-1.1,1.3-1.7,2.1
          c-0.6,0.8-1.1,1.5-1.5,2.3c-0.5,0.8-0.9,1.5-1.3,2.2c-0.4,0.7-0.7,1.2-0.9,1.7c-0.5,0.9-1,1.9-1.6,3c-0.6,1.1-1.1,2-1.6,2.9
          c-0.5,0.9-1.1,1.6-1.5,2.2c-0.5,0.6-0.9,0.9-1.3,0.9c-0.4,0-0.7-0.2-1.1-0.5c-0.3-0.3-0.7-0.7-1-1.1c-0.3-0.4-0.6-0.9-0.9-1.4
          c-0.3-0.5-0.5-0.9-0.7-1.3v0c-0.7-1.3-1.4-2.6-2-3.7c-0.6-1.1-1.2-2.2-1.7-3.1c-0.6-0.9-1.2-1.8-1.8-2.5c-0.6-0.7-1.3-1.4-2.2-1.9
          c0,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.2,0.4,0.5,0.9
          c0.3,0.4,0.7,0.9,1.1,1.5c0.4,0.6,0.9,1.2,1.3,1.8c0.4,0.6,0.7,1.3,0.9,1.8c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0,0.4
          c-0.1,0.7-0.1,1.4-0.3,2.1c-0.1,0.7-0.2,1.5-0.4,2.2c-0.1,0.7-0.3,1.4-0.5,2.1c-0.2,0.7-0.4,1.3-0.6,1.9c-0.1,0.3-0.2,0.7-0.3,1
          c-0.1,0.3-0.2,0.7-0.2,1.1c0,0.1,0.1,0.3,0.2,0.3c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3,0,0.4,0
          c0.7,0,1.2-0.3,1.5-0.9c0.5-0.8,0.8-1.7,0.9-2.6c0.1-0.9,0.2-1.8,0.2-2.8c0-0.3,0-0.7,0-1.1c0-0.4,0-1,0.1-1.8
          c0.5,1.1,1,2.1,1.7,3.2c0.6,1.1,1.3,2.1,2,3.1c0.2,0.3,0.4,0.6,0.7,0.9c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.6,0.4,0.9,0.6
          c0.3,0.2,0.7,0.2,1.1,0.2c0.7,0,1.4-0.3,2.1-0.9c0.7-0.6,1.4-1.3,2-2.2c0.6-0.9,1.3-1.9,1.9-3c0.6-1.1,1.2-2.2,1.7-3.3
          c0.6-1.1,1.1-2.1,1.5-3c0.5-0.9,0.9-1.7,1.3-2.2c0,1-0.1,2-0.2,3.1c-0.1,1.1-0.2,2.1-0.2,3.1c0,0.4,0,0.9,0,1.5
          c0,0.6,0.1,1.3,0.1,1.9c0.1,0.7,0.1,1.4,0.2,2c0.1,0.7,0.2,1.3,0.4,1.8c0.1,0.5,0.3,1,0.5,1.3c0.2,0.3,0.4,0.5,0.7,0.5
          c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.3,0.1-0.5,0.1-0.8c0-0.5-0.1-1-0.2-1.5
          c-0.1-0.6-0.2-1.1-0.3-1.6c-0.2-0.6-0.3-1.4-0.4-2.3c-0.1-0.9-0.2-1.9-0.3-2.9c-0.1-1.1-0.2-2.1-0.2-3.3c-0.1-1.1-0.1-2.3-0.2-3.4
          c0.7-0.9,1.2-1.6,1.5-2.1c0.3-0.5,0.6-1,0.9-1.5c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.2-0.5,0.2-0.7c0-0.1,0-0.2-0.1-0.3
          C102.1,11.4,102,11.3,101.9,11.3z"/>
        <path className={classes.st1} d="M108.1,23.8c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2,0,0.4,0,0.6
          c0,0.1,0,0.2-0.1,0.4c-0.1,0.3-0.1,0.7-0.2,1.1c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.3-0.2,0.6-0.3,0.8
          c-0.1,0.2-0.2,0.3-0.3,0.2c-0.1,0-0.2-0.3-0.2-0.7c0-0.5,0-1,0.1-1.7c0.1-0.7,0.1-1.3,0.2-2.1c0.1-0.7,0.1-1.4,0.2-2.1
          c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.1-0.5-0.2-0.8-0.1c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.4,0.4-0.4,0.8c0,0.6,0,1.1,0,1.8
          c0,0.6,0,1.2,0,1.8c0,0.6,0,1.1,0,1.6c0,0.5,0.1,0.9,0.1,1.2c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.5
          c0.3,0.1,0.5,0.2,0.8,0.1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.6-0.8,0.8-1.4c0,0.7-0.1,1.4-0.1,2.1c0,0.7-0.1,1.3-0.1,1.9
          c0,0.6-0.1,1.1-0.1,1.6c0,0.5-0.1,0.9-0.1,1.1c0,0.3,0.1,0.5,0.3,0.6c0.3,0,0.5,0,0.8-0.2c0.3-0.2,0.6-0.4,0.9-0.7
          c0.3-0.3,0.4-0.7,0.4-1.1c0-0.2,0-0.5,0-1c0-0.5,0-1.1,0-1.7c0-0.7,0-1.4,0-2.2c0-0.8,0-1.5,0.1-2.3c0-0.8,0.1-1.5,0.1-2.1
          c0-0.7,0.1-1.2,0.1-1.6C108.4,24.1,108.3,23.9,108.1,23.8z"/>
      </g>
      <g>
        <path className={classes.st0} d="M116.6,24.9c0-0.5,0-1.2,0-2c0-0.8,0-1.7-0.1-2.5c0-0.9-0.1-1.8-0.1-2.6c-0.1-0.9-0.1-1.6-0.2-2.2l6.7-0.2
          c0.4,0,0.9,0,1.5,0c0.6,0,1.1,0,1.7-0.1c0.5,0,1-0.1,1.4-0.3c0.4-0.1,0.6-0.3,0.6-0.6c0-0.3-0.1-0.6-0.3-0.8
          c-0.2-0.2-0.5-0.4-0.8-0.6c-0.3-0.2-0.6-0.3-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-1.5-0.1-2.9-0.1-4.4,0c-1.4,0.1-2.9,0.2-4.3,0.4
          c-1.4,0.2-2.9,0.3-4.3,0.5c-1.4,0.2-2.8,0.4-4.2,0.5c-0.4,0.1-0.8,0.1-1.3,0.1c-0.5,0-1,0-1.5,0.1c-0.5,0.1-0.9,0.2-1.2,0.3
          c-0.3,0.2-0.5,0.4-0.5,0.7c0,0.2,0.1,0.3,0.4,0.5c0.3,0.2,0.6,0.3,1.1,0.3c1.7,0,3.3,0,4.9-0.1c1.6-0.1,3.2-0.2,4.8-0.2
          c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.4-0.1,0.9-0.2,1.6c-0.1,0.7-0.1,1.5-0.1,2.5c0,1-0.1,2.3-0.1,3.9c0,0.5,0,1-0.1,1.7
          c-0.1,0.7-0.1,1.3-0.1,2c0,0.7,0,1.3,0,1.9c0,0.6,0.2,1.1,0.3,1.4c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.4,0.4
          c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.1,0.3,0.1,0.4,0.1c0.2,0,0.3-0.2,0.4-0.5c0.1-0.3,0.2-0.8,0.3-1.3c0.1-0.5,0.1-1.1,0.2-1.7
          c0-0.6,0.1-1.2,0.1-1.8c0-0.6,0-1.1,0-1.5C116.6,25.4,116.6,25.1,116.6,24.9z"/>
        <path className={classes.st0} d="M134.5,24.2c-0.4-0.4-0.8-0.6-1.4-0.6c-0.3,0-0.6,0.1-0.9,0.2c-0.2,0.1-0.5,0.3-0.6,0.5c0.1-0.7,0.1-1.4,0.2-2
          c0.1-0.7,0.1-1.2,0.2-1.8c0-0.5,0.1-1,0.1-1.3c0-0.4,0.1-0.6,0.1-0.7c0.1-0.3,0-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3
          c-0.3,0-0.6,0.1-0.8,0.3c-0.3,0.2-0.4,0.5-0.4,1c0,0.2,0,0.6,0,1.2c0,0.6-0.1,1.3-0.1,2.1c0,0.8-0.1,1.7-0.2,2.6
          c0,0.7-0.1,1.3-0.1,1.9c0,0.2-0.1,0.5-0.2,0.8c-0.1,0.4-0.3,0.8-0.5,1.3c-0.2,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.3,0.5-0.4,0.5
          c-0.2,0-0.3-0.2-0.3-0.5c-0.1-0.4-0.1-0.8,0-1.3c0-0.5,0.1-1,0.1-1.6c0.1-0.5,0.1-1,0.2-1.4c0-0.3,0-0.5-0.2-0.7
          c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.4,0.1-0.6,0.3c-0.1-0.2-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.3-0.7-0.4
          c-0.7-0.1-1.4,0-1.9,0.3c-0.5,0.4-0.9,0.9-1.2,1.5c-0.3,0.6-0.5,1.4-0.6,2.2c-0.1,0.8-0.1,1.6,0,2.3c0.1,0.7,0.4,1.4,0.7,2
          c0.3,0.6,0.8,0.9,1.4,1.1c0.3,0.1,0.7,0.1,1.1-0.1c0.4-0.2,0.8-0.5,1.1-0.9c0.3-0.4,0.5-0.8,0.7-1.3c0.3,0.5,0.7,0.9,1.3,1.1
          c0.4,0.1,0.7,0.1,1-0.1c0.3-0.2,0.6-0.4,0.8-0.8c0.1-0.1,0.2-0.3,0.3-0.5c0,0.6-0.1,1.2-0.1,1.6c0,0.3,0.1,0.5,0.3,0.6
          c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.5-0.1,0.8-0.2c0.2-0.2,0.4-0.4,0.4-0.6v-0.9c0.3,0.4,0.7,0.5,1.3,0.5c0.6,0,1.1-0.2,1.6-0.6
          c0.4-0.4,0.8-0.9,1-1.4c0.2-0.6,0.4-1.2,0.4-1.9c0.1-0.7,0-1.3-0.2-1.9C135.1,25.1,134.9,24.6,134.5,24.2z M125.3,28.2
          c-0.1,0.6-0.2,1.2-0.4,1.7c-0.2,0.5-0.4,0.9-0.6,1.2c-0.2,0.3-0.5,0.5-0.7,0.4c-0.3,0-0.5-0.2-0.6-0.6c-0.1-0.4-0.2-0.8-0.2-1.3
          c0-0.5,0-1.1,0.1-1.6c0.1-0.6,0.2-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.6-1.2c0.3-0.3,0.5-0.4,0.9-0.3c0.2,0,0.4,0.2,0.5,0.5
          c0.1,0.3,0.2,0.7,0.2,1C125.4,27,125.4,27.6,125.3,28.2z M133.4,26.3c0,0.4,0,0.8-0.1,1.3c-0.1,0.5-0.1,0.9-0.3,1.3
          c-0.1,0.4-0.2,0.7-0.4,0.9c-0.2,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.2-0.6-0.5c-0.1-0.3-0.2-0.6-0.3-1c0-0.1,0-0.3,0-0.4
          c0-0.1,0-0.3,0-0.4c0-0.3,0.1-0.7,0.2-1.1c0.1-0.4,0.2-0.7,0.4-1.1c0.1-0.3,0.3-0.6,0.5-0.7c0.2-0.2,0.4-0.2,0.6-0.2
          c0.1,0,0.2,0.2,0.3,0.5C133.4,25.6,133.5,25.9,133.4,26.3z"/>
        <path className={classes.st0} d="M148.9,26.1c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1-0.1,0.4-0.3,0.7
          c-0.1,0.3-0.3,0.7-0.5,1c-0.2,0.4-0.4,0.8-0.7,1.2c-0.3,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3,0.2-0.6,0.2-0.8,0.1
          c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.2-0.2-0.5-0.2-0.8c-0.1-0.3-0.1-0.7-0.1-1.1c0-0.4,0-0.8,0.1-1.2c0.2,0,0.5-0.1,0.8-0.1
          c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.3,0.3-0.6,0.3-1.1c0-0.5-0.1-0.9-0.3-1.4
          c-0.2-0.4-0.5-0.8-0.9-1.2c-0.4-0.3-0.8-0.5-1.3-0.5c-0.5,0-1,0.3-1.4,0.8c-0.4,0.5-0.8,1.1-1,1.9c-0.2,0.4-0.3,0.9-0.4,1.4v0
          c-0.1,0.2-0.2,0.5-0.4,1c-0.2,0.5-0.4,1.1-0.6,1.6c-0.2,0.6-0.4,1.1-0.6,1.5c-0.2,0.4-0.4,0.7-0.5,0.7c-0.2,0-0.3-0.3-0.4-0.8
          c-0.1-0.5-0.1-1.2-0.1-2c0-0.8,0-1.7,0.1-2.7c0.1-1,0.1-1.9,0.2-2.8c0.1-0.9,0.2-1.7,0.3-2.4c0.1-0.7,0.2-1.2,0.2-1.4
          c0.1-0.3,0-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.4,0.5-0.4,1c0,0.4,0,1.1-0.1,1.9
          c-0.1,0.9-0.2,1.8-0.2,2.8c-0.1,1-0.1,2.1-0.2,3.2c0,1.1,0,2.1,0.1,2.9c0.1,0.9,0.3,1.6,0.6,2.2c0.3,0.6,0.7,0.9,1.2,0.9
          c0.6,0,1.1-0.3,1.5-0.8c0.4-0.5,0.8-1.1,1.1-1.8c0-0.1,0.1-0.2,0.1-0.3c0.1,0.7,0.4,1.2,0.8,1.7c0.4,0.5,1,0.8,1.7,0.8
          c0.6,0,1.1-0.1,1.6-0.4c0.5-0.3,0.9-0.6,1.3-1c0.4-0.4,0.7-0.8,1-1.3c0.3-0.5,0.5-0.9,0.7-1.4c0.2-0.4,0.3-0.8,0.4-1.2
          c0.1-0.3,0.2-0.6,0.2-0.7C149,26.4,149,26.2,148.9,26.1z M144,24.5c0.2-0.5,0.3-0.7,0.5-0.8c0.1,0,0.2,0,0.2,0.2
          c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0,0.5,0,0.7c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
          C143.7,25.5,143.8,24.9,144,24.5z"/>
        </g>
      <rect x="14.2" y="22.1" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -4.1665 40.3682)" width="18.7" height="1.5"/>
      <rect x="14.4" y="22.1" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -3.991 40.5907)" width="18.7" height="1.5"/>
      <rect x="14.4" y="22.3" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -4.2134 40.7662)" width="18.7" height="1.5"/>
      <rect x="14.4" y="22.5" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -4.4359 40.9417)" width="18.7" height="1.5"/>
      <rect x="14.4" y="22.3" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -4.2134 40.7662)" width="18.7" height="1.5"/>
      <rect x="14.2" y="22.1" transform="matrix(0.2326 -0.9726 0.9726 0.2326 -4.1665 40.3682)" width="18.7" height="1.5"/>
      <path className={classes.st2} d="M32.5,28.1c-0.1,0.7,2.1,1.3,2,2.2c-0.1,0.4-4.4,2.2-6,2.7C20,35.3,7.1,31.4,2.5,26.8c0.7-0.8,2-0.9,2.8-1.6
        c-2.3-0.5-3.5,0.5-4.7,1.5c-0.3,1.8-0.5,3.4,0.7,4.1C1.5,30,1.1,28.5,1.8,28c-0.2,3.3,7.7,8.8,8.7,11.2c4.5,0.5,9.8,0.7,14.3,1.2
        c2.8-2.2,10-6,10.6-9.1c0.1,0.9,0.4,1.6,0,2.8C37.8,32.3,36.5,27.8,32.5,28.1z M10.2,37c0.6-0.3,2.8-0.1,3.4,0.4
        C13.2,38.3,10.5,37.6,10.2,37z"/>
      <path className={classes.st3} d="M33.1,15.1l-7.8-1.8c-0.7-0.2-1.4,0.3-1.6,1.1l-3.9,16.9c-0.1,0.5,0,1,0.3,1.3c3.8,0.3,7.5,0,10.6-1.1
        c0,0,0,0,0,0L34,16.7C34.2,16,33.8,15.2,33.1,15.1z M22.1,32.1l-1.5-0.4L25,13.6l1.5,0.4L22.1,32.1z"/>
      <path className={classes.st4} d="M31.5,2C28.8,1.4,29,5.1,31,5.3C32.6,5.4,33.9,2.5,31.5,2z"/>
      <path className={classes.st2} d="M27.6,7.3c-1.2-0.1-1.4,2.2,0,2.2C28.8,9.5,29.3,7.5,27.6,7.3z"/>
      <path className={classes.st1} d="M14.7,19.7c1.8,1.5,0.9,7.3-0.2,11.8c0.9,0.3,1.8,0.5,2.5,0.7c1.1-8.9,3.1-20.2,3.9-28.4
        C17.6,6.8,14.6,13.5,14.7,19.7z"/>
      <path className={classes.st1} d="M10.8,19.3c0.3-1.3,2.1-1.6,2.4-3.3c0.7-3.7-0.9-8.4-1.3-12.1c-0.3,1.8-0.1,4.7,0,7c0,1,0.5,2.2-0.3,2.8
        c-0.9-0.4-0.5-1.8-0.5-2.7c-0.1-1.5-0.3-3.4-0.4-4.9c0-0.8,0.3-1.8-0.4-2.3c-0.1,1.5,0.1,3.2,0.1,4.8c0.1,1.8,0.3,3.8-0.4,5.2
        c-1-1-0.4-3.1-0.5-4.9C9.4,7.6,9.3,5.5,9.2,4C8.5,4.4,8.8,5.5,8.8,6.3c0,2.2,0.2,5.5-0.3,7.4c-1-0.3-0.7-1.8-0.7-2.8
        c0-2.3,0-4.6-0.3-6.8C7.5,7.7,5.8,13.8,7.4,17c0.5,1,1.6,1.2,2.1,2.1c0.1,3.3,0,7-0.1,10.5c0.8,0.3,1.7,0.6,2.7,1
        c-0.5-2.7-0.7-5.7-1-8.7C11,21.1,10.7,20.1,10.8,19.3z"/>
    </SvgIcon>
  )
}

LogoGrey = pure(LogoGrey)
LogoGrey.displayName = 'LogoGrey'
LogoGrey.pmtName = 'SvgIcon'

export default withStyles(styles)(LogoGrey)

