import isNil from 'lodash/isNil'
import moment from 'moment'
import { createSelector } from 'reselect'

import { createSimpleSelector } from '../redux'

const kioskConnectivity = createSimpleSelector(state => state.entities.kioskInteractor.connectivity)

export const isFetchingKioskConnectivity = kioskConnectivity.isFetching
export const getDataKioskConnectivity = kioskConnectivity.getData
export const getErrorKioskConnectivity = kioskConnectivity.getError

const kioskPeripheralsStatus = createSimpleSelector(
  state => state.entities.kioskInteractor.peripherals
)

export const isFetchingKioskPeripheralsStatus = kioskPeripheralsStatus.isFetching
export const getDataKioskPeripheralsStatus = kioskPeripheralsStatus.getData
export const getErrorKioskPeripheralsStatus = kioskPeripheralsStatus.getError

const kioskPrint = createSimpleSelector(state => state.entities.kioskInteractor.print)

export const isFetchingKioskPrint = kioskPrint.isFetching
export const getDataKioskPrint = kioskPrint.getData
export const getErrorKioskPrint = kioskPrint.getError

const kioskPayment = createSimpleSelector(state => state.entities.kioskInteractor.payment)
export const isFetchingKioskPayment = kioskPayment.isFetching
export const getDataKioskPayment = kioskPayment.getData
export const getErrorKioskPayment = kioskPayment.getError

const kioskRegistration = createSimpleSelector(state => state.entities.kioskInteractor.registration)

export const isFetchingKioskRegistration = kioskRegistration.isFetching
export const getDataKioskRegistration = kioskRegistration.getData
export const getErrorKioskRegistration = kioskRegistration.getError

const kioskNfc = createSimpleSelector(state => state.entities.kioskInteractor.nfc)
export const getErrorKioskNfc = kioskNfc.getError

const getSharingMessageData = state => state.entities.kioskInteractor.sharingMessage

export const isConnectedSharingMessage = createSelector(
  [getSharingMessageData],
  sharingMessageData => {
    const status = sharingMessageData.get('status')

    if (isNil(status)) {
      return false
    }

    return status.get('magCardReaderConnected', false)
  }
)

export const isAliveSharingMessage = createSelector(
  [isConnectedSharingMessage, getSharingMessageData],
  (isConnected, sharingMessageData) => {
    const heartbeat = sharingMessageData.get('heartbeat')

    if (isNil(heartbeat)) {
      return false
    }

    // message sharing is alive (ie: true) if we have received an heartbeat less than 40 seconds ago
    const isAlive = moment(heartbeat.get('date')).isAfter(moment().subtract(40, 'seconds'))

    return isConnected && isAlive
  }
)

export const isListeningSharingMessage = createSelector(
  [getSharingMessageData],
  sharingMessageData => sharingMessageData.get('isListening', false)
)

export const isListeningSharingMessageBadgeId = createSelector(
  [getSharingMessageData],
  sharingMessageData => sharingMessageData.get('isListeningBadgeId', false)
)

export const getSharingMessage = createSelector([getSharingMessageData], sharingMessageData => {
  const data = sharingMessageData.get('data')

  if (isNil(data)) {
    return null
  }

  return data.toJS()
})
