import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from 'pmt-ui/styles'
import Button from 'pmt-ui/Button'
import { TypographyCustom } from 'pmt-ui/Typography'
import ActionDelete from 'pmt-ui/svg-icons/action/delete'

const styles = theme => ({
  root: {
    display: 'block',
    width: '100%',
    background: theme.pmt.colors.grey91,
    textTransform: 'none',
  },
  icon: {
    display: 'block',
    width: 40,
    height: 42,
    margin: '0 auto',
    color: theme.pmt.colors.white,
  },
  text: {
    display: 'block',
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.white,
  },
})

const EmptyCartButton = ({ onClick, classes }) => (
  <Button variant="contained" className={classes.root} onClick={onClick}>
    <React.Fragment>
      <ActionDelete className={classes.icon} />
      <TypographyCustom
        type="207"
        className={classes.text}
        align="center"
        component="div"
      >
        {tr('kiosk.order.button.empty_cart')}
      </TypographyCustom>
    </React.Fragment>
  </Button>
)

EmptyCartButton.defaultProps = {
  onClick: () => {},
}

EmptyCartButton.propTypes = {
  onClick: PropTypes.func,
}

export default withStyles(styles)(EmptyCartButton)
